import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { BsUpload } from "react-icons/bs";
import { AiFillRightCircle } from "react-icons/ai";
import { FaTelegramPlane } from "react-icons/fa";

import {
  Button,
  Loader,
  Layout,
  Modal,
  Text,
  dateFormatter,
  TextField,
} from "../../components";
import { Colors } from "../../config";
import { ApplicationAction } from "../../store/actions";

import univisionIcon from "../../assets/images/univision.png";

import styles from "./styles";

function Applications() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { approvedApplications, pendingApplications, isLoading } = useSelector(
    (state) => state.application
  );

  const studentDetail = useSelector((state) => state.auth.user.user);
  const studentId = useSelector((state) => state.auth.user.user.studentId);

  const [showModal, setShowModal] = useState(false);
  const [isvisible, setIsvisible] = useState(false);
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [applicationUniversity, setApplicationUniversity] = useState({});
  const [applicationURL, setApplicationURL] = useState("");
  const [email, setEmail] = useState("");

  console.log(applicationUniversity);
  useEffect(() => {
    const payload = { studentId };
    dispatch(ApplicationAction.getApplicationsStudent(payload));
  }, []);

  const handleApplicatioModal = (application) => {
    setApplicationUniversity(application);
    setApplicationURL(application.proofOfApplication);
    setShowModal(true);
  };

  const handleTabOpen = () => {
    window.open(applicationURL, "_blank");
  };

  const applicationsData = [
    {
      id: 1,
      title: "Submitted Applications ",
      array: approvedApplications,
    },
    {
      id: 2,
      title: "Pending Applications ",
      array: pendingApplications,
    },
  ];

  const addApplication = () => {
    const currentDate = new Date();
    const validUntilDate = new Date(studentDetail.validUntil);
    if (
      validUntilDate < currentDate &&
      studentDetail.studentType === "individualStudent" &&
      studentDetail.subscriptionPlan === "Free"
    ) {
      setIsvisible(true);
    } else {
      navigate("/applications/addapplication");
    }
  };

  return (
    <>
      <Text.Heading
        fontSize={"18px"}
        fontWeight={"600"}
        color={Colors.Primary}
        text="Applications"
      />

      <Layout.Spacer height={20} />

      {applicationsData?.map((item) => (
        <div key={item.id}>
          <Text.Heading
            fontSize={"16px"}
            fontWeight={"600"}
            color={Colors.Primary}
            text={item.title}
          />

          {isLoading ? (
            <Loader.Circular />
          ) : (
            <div>
              {item?.array?.map((single) => {
                return (
                  <div
                    className="container-fluid"
                    style={styles.applicationRow}
                    key={single._id}
                  >
                    <div style={styles.applicationImageContainer}>
                      <img
                        src={single.universityId.profilePic}
                        style={styles.applicationImage}
                      />
                    </div>
                    <div
                      style={styles.applicationInstituteContainer}
                      onClick={() => handleApplicatioModal(single)}
                    >
                      <div>
                        <Layout.Spacer height={10} />
                        {single?.instituteName.split(" ").length > 4 ? (
                          <Text.Heading
                            style={styles.applicationHeaderText}
                            text={`${single.instituteName
                              .split(" ")
                              .splice(0, 5)
                              .join(" ")} ...`}
                          />
                        ) : (
                          <Text.Heading
                            style={styles.applicationHeaderText}
                            text={`${single.instituteName} Application`}
                          />
                        )}

                        <Layout.Spacer width={10} />
                        {single?.instituteName.split(" ").length > 4 ? (
                          <Text.Heading
                            style={styles.applicationSubText}
                            text={`${single.instituteName
                              .split(" ")
                              .splice(0, 5)
                              .join(" ")} ...`}
                          />
                        ) : (
                          <Text.Heading
                            style={styles.applicationSubText}
                            text={`${single.instituteName}`}
                          />
                        )}
                      </div>
                    </div>
                    <div
                      style={styles.applicationDateContainer}
                      onClick={() => handleApplicatioModal(single)}
                    >
                      <div>
                        <Layout.Spacer height={8} />
                        <Text.Heading
                          style={styles.applicationHeaderText}
                          text="Date of Submission"
                        />
                        <Layout.Spacer width={10} />
                        <Text.Heading
                          style={styles.applicationSubText}
                          text={dateFormatter(single.appliedDate)}
                        />
                      </div>
                    </div>
                    <div style={styles.applicationButtonContainer}>
                      <div>
                        <Layout.Spacer height={15} />
                        <AiFillRightCircle
                          style={styles.applicationArrowButton}
                          onClick={() => handleApplicatioModal(single)}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}

          <Layout.Spacer height={40} />
        </div>
      ))}

      <div style={styles.flexRowCenter}>
        <Button.Primary
          onClick={addApplication}
          style={styles.addApplicationButton}
        >
          Add Application
        </Button.Primary>
      </div>

      {showModal && (
        <Modal.Basic
          isModalVisible={showModal}
          centered={true}
          handleCancel={() => setShowModal(false)}
          closable={false}
        >
          <Layout.Spacer height={20} />
          <div style={styles.flexCenter}>
            <div style={styles.flexRowStart}>
              <Text.Heading
                fontSize={"20px"}
                fontWeight={"600"}
                color={Colors.Primary}
                text={"Application Details"}
              />
            </div>
          </div>
          <Layout.Spacer height={10} />
          <div style={styles.flexRowSpace}>
            <div style={styles.flexRowStart}>
              <Text.Heading
                fontSize={"16px"}
                fontWeight={"600"}
                color={Colors.Black}
                text={"University Name"}
              />
            </div>
            <div style={styles.flexRowStart}>
              <Text.Heading
                fontSize={"12px"}
                fontWeight={"400"}
                color={Colors.Black}
                text={applicationUniversity?.instituteName}
                textTransform="capitalize"
              />
            </div>
          </div>

          <Layout.Spacer height={10} />
          <div style={styles.flexRowSpace}>
            <div style={styles.flexRowStart}>
              <Text.Heading
                fontSize={"16px"}
                fontWeight={"600"}
                color={Colors.Black}
                text={"Application Name"}
              />
            </div>
            <div style={styles.flexRowStart}>
              <Text.Heading
                fontSize={"12px"}
                fontWeight={"400"}
                color={Colors.Black}
                text={`${applicationUniversity?.instituteName} Application`}
                textTransform="capitalize"
              />
            </div>
          </div>
          <Layout.Spacer height={10} />
          <div style={styles.flexRowSpace}>
            <div style={styles.flexRowStart}>
              <Text.Heading
                fontSize={"16px"}
                fontWeight={"600"}
                color={Colors.Black}
                text={"Date of Application"}
              />
            </div>
            <div style={styles.flexRowStart}>
              <Text.Heading
                fontSize={"12px"}
                fontWeight={"400"}
                color={Colors.Black}
                text={moment(
                  new Date(applicationUniversity?.dateOfApplication)
                ).format("DD/MM/yyyy")}
              />
            </div>
          </div>
          <Layout.Spacer height={10} />
          <div style={styles.flexRowSpace}>
            <div style={styles.flexRowStart}>
              <Text.Heading
                fontSize={"16px"}
                fontWeight={"600"}
                color={Colors.Black}
                text={"Applied at"}
              />
            </div>
            <div style={styles.flexRowStart}>
              <Text.Heading
                fontSize={"12px"}
                fontWeight={"400"}
                color={Colors.Black}
                text={moment(
                  new Date(applicationUniversity?.appliedDate)
                ).format("DD/MM/yyyy")}
              />
            </div>
          </div>
          <Layout.Spacer height={10} />
          <div style={styles.flexRowSpace}>
            <div style={styles.flexRowStart}>
              <Text.Heading
                fontSize={"16px"}
                fontWeight={"600"}
                color={Colors.Black}
                text={"Status"}
              />
            </div>
            <div style={styles.flexRowStart}>
              <Text.Heading
                fontSize={"12px"}
                fontWeight={"400"}
                color={Colors.Black}
                text={applicationUniversity?.status}
                textTransform="capitalize"
              />
            </div>
          </div>
          <Layout.Spacer height={20} />
          <div style={styles.flexRowStart}>
            <Text.Heading
              fontSize={"16px"}
              fontWeight={"500"}
              color={Colors.Primary}
              text={"Proof of Application"}
            />
          </div>
          <div style={{ ...styles.flexRow, justifyContent: "center" }}>
            <Layout.Spacer height={20} />
            <div style={styles.modalCardContainer}>
              <div style={styles.modalCard} onClick={handleTabOpen}>
                <Layout.Spacer height={10} />
                <div style={styles.flexColumnCenter}>
                  <BsUpload style={styles.modalCardIcon} />
                  <Text.Heading
                    fontSize={"12px"}
                    fontWeight={"200"}
                    color={Colors.Black}
                    text="File attached. Click to open"
                  />
                </div>
                <Layout.Spacer height={10} />
              </div>
            </div>
          </div>
          <Layout.Spacer height={30} />
          <div style={styles.flexCenter}>
            <Button.Primary
              style={styles.modalButton}
              onClick={() => {
                setShowModal(false);
              }}
            >
              Close
            </Button.Primary>
          </div>
          <Layout.Spacer height={10} />
        </Modal.Basic>
      )}

      {isvisible && (
        <Modal.Basic
          isModalVisible={isvisible}
          centered={true}
          handleCancel={() => setIsvisible(false)}
          closable={false}
          sx={{ width: "30%", border: "1px solid red" }}
        >
          <div style={styles.proApplication}>
            <img src={univisionIcon} alt="UniVision Icon" />
            <div>
              <Text.Heading
                fontSize={"32px"}
                fontWeight={"600"}
                color={Colors.Black}
                text="Subscribe"
              />
            </div>
            <div style={styles.proApplicationContent}>
              To view more subscribe to our Pro Package, Enter your email
              address we will send details
            </div>
            <div style={styles.proApplicationInput}>
              <TextField.CustomInput
                placeholder="Enter your email address here"
                suffix={
                  <div style={styles.proApplicationStyleIcon}>
                    <FaTelegramPlane
                      color={Colors.White}
                      style={{ width: "25.19px", height: "25.19px" }}
                    />
                  </div>
                }
                style={styles.proApplicationStyle}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <Button.Primary
              onClick={() => setIsvisible(false)}
              style={styles.addApplicationBtn}
            >
              Back
            </Button.Primary>
          </div>
        </Modal.Basic>
      )}
    </>
  );
}

export default Applications;
