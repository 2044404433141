import { Navigate } from "react-router";

import {
  AdminChat,
  AddProduct,
  AddApplication,
  Announcements,
  Applications,
  Assignments,
  Books,
  CashOnDelivery,
  CashOnDeliveryFlow2,
  CashOnDeliveryMobileAppGetPro,
  CardPayment,
  CardPaymentFlow2,
  CashOnDeliveryMobileApp,
  CardPaymentMobileAppGetPro,
  CardFormMobileApp,
  CardPaymentMobileApp,
  CardFormMobileAppGetPro,
  ContactInfoForm,
  ChangePassword,
  Compare,
  Chatbot,
  ContactUs,
  CounselorChat,
  COD,
  CardPaymentForm,
  Dashboard,
  EmailForm,
  Essay,
  Etextbook,
  FAQs,
  Filter,
  ForgotPassword,
  Flow2OTP,
  Flow2PasswordForm,
  GetPro,
  InteractiveLearning,
  MeetingDetails,
  Marketplace,
  Message,
  More,
  Meetings,
  MobileLogin,
  NewMeetings,
  Notifications,
  OwnProduct,
  OTP,
  Papers,
  PasswordForm,
  PaymentSelect,
  PaymentSelectFlow2,
  PaymentMethodSelect,
  PaymentSelectMobileApp,
  PaymentSelectMobileAppGetPro,
  PersonalInfo,
  PrivacyPolicy,
  Profile,
  ResetPassword,
  Refer,
  Recommenders,
  RecommenderPreferences,
  RecommendersQualifications,
  RecommendersResults,
  SignIn,
  SignupRedirect,
  SpecificProduct,
  Stationery,
  SubscriptionSelectForm,
  SubscriptionSelectFormFlow2,
  Subscription,
  Support,
  TermsAndCondition,
  Tour,
  Tracker,
  TrackerDetails,
  UniversityDetailsMain,
  VerifyOTP,
  Watchlist,
  Settings,
} from "../../containers";

export const routes = {
  protected: {
    home: "/home",

    announcements: "/announcements",

    studentUniversityFilter: "/universities/filter/:page",
    studentUniversityCompare: "/universities/compare",

    universityGeneralDetails: "/universities/:id/general",
    universityStudentLifeDetails: "/universities/:id/student-life",
    universityVisaDetails: "/universities/:id/visa",
    universityProgramsDetails: "/universities/:id/programs",
    universityFinanceDetails: "/universities/:id/finances",
    universityPostGraduationDetails: "/universities/:id/post-graduation",
    universityApplicationJourneyDetails:
      "/universities/:id/application-journey",
    universitySummaryDetails: "/universities/:id/summary",

    watchlist: "/watchlist",

    meetings: "/meetings",
    newmeetings: "/meetings/newmeetings",
    meetingdetails: "/meetings/meetingdetails",
    message: "/meetings/meetingdetails/message",

    notifications: "/notifications",

    applications: "/applications",
    addapplication: "/applications/addapplication",

    profile: "/profile",

    settings: "/settings",
    changePassword: "/settings/change-password",
    termsAndConditions: "/termsAndConditions",
    privacyPolicy: "/privacyPolicy",

    contactUs: "/support",
    faqs: "/support/faqs",

    recommender: "/recommender",
    recommenderPreferences: "/recommender/preferences",
    recommenderQualifications: "/recommender/qualifications",
    recommenderResults: "/recommender/results",

    more: "/more",

    essay: "/essay",
    tour: "/more/tour",
    etextbook: "/more/eTextBook",

    tracker: "/more/statusTracker",
    trackerDetails: "/more/statusTracker/trackerDetails",

    chatbot: "/more/chatbot",
    adminChat: "/more/chatbot/admins",
    counselorChat: "/more/chatbot/counselors",

    marketplace: "/more/marketplace",
    addProduct: "/more/marketplace/addproduct",
    books: "/more/marketplace/books",
    papers: "/more/marketplace/papers",
    stationery: "/more/marketplace/stationery",
    assignments: "/more/marketplace/assignments",
    product: "/more/marketplace/product/:id",
    yourProducts: "/more/marketplace/yourProducts/",

    interactiveLearning: "/interactiveLearning",

    refer: "/refer",

    getPro: "/getPro",
    paymentMethodSelect: "/paymentMethodSelect",
    cod: "/cod",
    card: "/card",

    subscription: "/subscription",
  },
  public: {
    login: "/login",
    mobileLogin: "/mobile-login",
    resetPassword: "/reset-password",
    forgotPassword: "/forgot-password",
    verifyCode: "/verify-code",

    // signupEmail: "/signup/email",
    // otp: "/signup/otp",
    // signupPassword: "/signup/password",
    // subscriptionSelect: "/signup/subscriptionSelect",
    // signupDetails: "/signup/details",
    // paymentSelect: "/signup/paymentSelect",
    // cashOnDelivery: "/signup/cashOnDelivery",
    // cardPayment: "/signup/cardPayment",

    // signup flow 2
    personalInfo: "/signup/personalInfo",
    contactInfo: "/signup/contactInfo",
    signupotp: "/signup/otpform",
    signuppasword: "/signup/passwordForm",
    subscriptionSelectForm: "/signup/subscriptionSelectForm",
    paymentSelectForm: "/signup/paymentSelectForm",
    cashOnDeliveryForm: "/signup/cashOnDeliveryForm",
    cardPaymentForm: "/signup/cardPaymentForm",

    signupRedirect: "/signup/personalInfo-mobile",

    // mobile app payment
    paymentSelectFormMobileApp: "/select-payment",
    cashOnDeliveryFormMobileApp: "/cash-on-Delivery",
    cardPaymentFormMobileApp: "/card-payment",

    // mobile app payment get pro
    paymentSelectFormMobileAppGetPro: "/select-payment-getpro",
    cashOnDeliveryFormMobileAppGetPro: "/cash-on-Delivery-getpro",
    cardPaymentFormMobileAppGetPro: "/card-payment-getpro",
  },
};

export const PROTECTED_ROUTES = [
  // home
  {
    path: routes.protected.home,
    exact: false,
    component: <Dashboard />,
  },
  // profile
  {
    path: routes.protected.profile,
    exact: false,
    component: <Profile />,
  },
  // settings
  {
    path: routes.protected.settings,
    exact: false,
    component: <Settings />,
  },
  {
    path: routes.protected.changePassword,
    exact: false,
    component: <ChangePassword />,
  },
  {
    path: routes.protected.termsAndConditions,
    exact: false,
    component: <TermsAndCondition />,
  },
  {
    path: routes.protected.privacyPolicy,
    exact: false,
    component: <PrivacyPolicy />,
  },
  // support
  {
    path: routes.protected.support,
    exact: false,
    component: <Support />,
  },
  {
    path: routes.protected.contactUs,
    exact: false,
    component: <ContactUs />,
  },
  {
    path: routes.protected.faqs,
    exact: false,
    component: <FAQs />,
  },

  // annouoncements
  {
    path: routes.protected.announcements,
    exact: false,
    component: <Announcements />,
  },
  // nootifications
  {
    path: routes.protected.notifications,
    exact: false,
    component: <Notifications />,
  },
  // university
  {
    path: routes.protected.studentUniversityFilter,
    exact: false,
    component: <Filter />,
  },
  {
    path: routes.protected.universityGeneralDetails,
    exact: false,
    component: <UniversityDetailsMain />,
  },
  {
    path: routes.protected.universityStudentLifeDetails,
    exact: false,
    component: <UniversityDetailsMain />,
  },
  {
    path: routes.protected.universityApplicationJourneyDetails,
    exact: false,
    component: <UniversityDetailsMain />,
  },
  {
    path: routes.protected.universityFinanceDetails,
    exact: false,
    component: <UniversityDetailsMain />,
  },
  {
    path: routes.protected.universityPostGraduationDetails,
    exact: false,
    component: <UniversityDetailsMain />,
  },
  {
    path: routes.protected.universityProgramsDetails,
    exact: false,
    component: <UniversityDetailsMain />,
  },
  {
    path: routes.protected.universitySummaryDetails,
    exact: false,
    component: <UniversityDetailsMain />,
  },
  {
    path: routes.protected.universityVisaDetails,
    exact: false,
    component: <UniversityDetailsMain />,
  },
  {
    path: routes.protected.studentUniversityCompare,
    exact: false,
    component: <Compare />,
  },
  // meeting
  {
    path: routes.protected.meetings,
    exact: false,
    component: <Meetings />,
  },
  {
    path: routes.protected.newmeetings,
    exact: false,
    component: <NewMeetings />,
  },
  {
    path: routes.protected.meetingdetails,
    exact: false,
    component: <MeetingDetails />,
  },
  {
    path: routes.protected.message,
    exact: false,
    component: <Message />,
  },
  // applications
  {
    path: routes.protected.applications,
    exact: false,
    component: <Applications />,
  },
  {
    path: routes.protected.addapplication,
    exact: false,
    component: <AddApplication />,
  },
  // watchlist
  {
    path: routes.protected.watchlist,
    exact: false,
    component: <Watchlist />,
  },
  // recommender
  {
    path: routes.protected.recommender,
    exact: false,
    component: <Recommenders />,
  },
  {
    path: routes.protected.recommenderPreferences,
    exact: false,
    component: <RecommenderPreferences />,
  },
  {
    path: routes.protected.recommenderQualifications,
    exact: false,
    component: <RecommendersQualifications />,
  },
  {
    path: routes.protected.recommenderResults,
    exact: false,
    component: <RecommendersResults />,
  },
  // refer
  {
    path: routes.protected.refer,
    exact: false,
    component: <Refer />,
  },
  // more
  {
    path: routes.protected.more,
    exact: false,
    component: <More />,
  },
  // chatbot
  {
    path: routes.protected.chatbot,
    exact: false,
    component: <Chatbot />,
  },
  {
    path: routes.protected.adminChat,
    exact: false,
    component: <AdminChat />,
  },
  {
    path: routes.protected.counselorChat,
    exact: false,
    component: <CounselorChat />,
  },
  // autooomated essay grading
  {
    path: routes.protected.essay,
    exact: false,
    component: <Essay />,
  },
  // virtaul tour
  {
    path: routes.protected.tour,
    exact: false,
    component: <Tour />,
  },
  // etextbook
  {
    path: routes.protected.etextbook,
    exact: false,
    component: <Etextbook />,
  },
  // applciation status tracker
  {
    path: routes.protected.tracker,
    exact: false,
    component: <Tracker />,
  },
  {
    path: routes.protected.trackerDetails,
    exact: false,
    component: <TrackerDetails />,
  },
  // interactive learning
  {
    path: routes.protected.interactiveLearning,
    exact: false,
    component: <InteractiveLearning />,
  },
  // marketplace
  {
    path: routes.protected.marketplace,
    exact: false,
    component: <Marketplace />,
  },
  {
    path: routes.protected.addProduct,
    exact: false,
    component: <AddProduct />,
  },
  {
    path: routes.protected.books,
    exact: false,
    component: <Books />,
  },
  {
    path: routes.protected.papers,
    exact: false,
    component: <Papers />,
  },
  {
    path: routes.protected.stationery,
    exact: false,
    component: <Stationery />,
  },
  {
    path: routes.protected.assignments,
    exact: false,
    component: <Assignments />,
  },
  {
    path: routes.protected.product,
    exact: false,
    component: <SpecificProduct />,
  },
  {
    path: routes.protected.yourProducts,
    exact: false,
    component: <OwnProduct />,
  },
  // get pro
  {
    path: routes.protected.getPro,
    exact: false,
    component: <GetPro />,
  },
  {
    path: routes.protected.paymentMethodSelect,
    exact: false,
    component: <PaymentMethodSelect />,
  },
  {
    path: routes.protected.cod,
    exact: false,
    component: <COD />,
  },
  {
    path: routes.protected.card,
    exact: false,
    component: <CardPaymentForm />,
  },
  // subscription
  {
    path: routes.protected.subscription,
    exact: false,
    component: <Subscription />,
  },
  {
    path: "*",
    exact: false,
    component: <Navigate to={routes.protected.home} />,
  },
];

export const PUBLIC_ROUTES = [
  // login
  {
    path: routes.public.login,
    exact: false,
    component: <SignIn />,
  },
  {
    path: routes.public.mobileLogin,
    exact: false,
    component: <MobileLogin />,
  },
  {
    path: routes.public.forgotPassword,
    exact: false,
    component: <ForgotPassword />,
  },
  {
    path: routes.public.resetPassword,
    exact: false,
    component: <ResetPassword />,
  },
  {
    path: routes.public.verifyCode,
    exact: false,
    component: <VerifyOTP />,
  },
  // signup
  // {
  //   path: routes.public.signupEmail,
  //   exact: false,
  //   component: <EmailForm />,
  // },
  // {
  //   path: routes.public.otp,
  //   exact: false,
  //   component: <OTP />,
  // },
  // {
  //   path: routes.public.signupPassword,
  //   exact: false,
  //   component: <PasswordForm />,
  // },
  // {
  //   path: routes.public.subscriptionSelect,
  //   exact: false,
  //   component: <SubscriptionSelectForm />,
  // },
  // {
  //   path: routes.public.paymentSelect,
  //   exact: false,
  //   component: <PaymentSelect />,
  // },
  // {
  //   path: routes.public.cashOnDelivery,
  //   exact: false,
  //   component: <CashOnDelivery />,
  // },
  // {
  //   path: routes.public.cardPayment,
  //   exact: false,
  //   component: <CardPayment />,
  // },
  // signup flow 2
  {
    path: routes.public.personalInfo,
    exact: false,
    component: <PersonalInfo />,
  },
  {
    path: routes.public.contactInfo,
    exact: false,
    component: <ContactInfoForm />,
  },
  {
    path: routes.public.signupotp,
    exact: false,
    component: <Flow2OTP />,
  },
  {
    path: routes.public.signuppasword,
    exact: false,
    component: <Flow2PasswordForm />,
  },
  {
    path: routes.public.subscriptionSelectForm,
    exact: false,
    component: <SubscriptionSelectFormFlow2 />,
  },
  {
    path: routes.public.paymentSelectForm,
    exact: false,
    component: <PaymentSelectFlow2 />,
  },
  {
    path: routes.public.cardPaymentForm,
    exact: false,
    component: <CardPaymentFlow2 />,
  },
  {
    path: routes.public.cashOnDeliveryForm,
    exact: false,
    component: <CashOnDeliveryFlow2 />,
  },

  {
    path: routes.public.signupRedirect,
    exact: false,
    component: <SignupRedirect />,
  },
  // mobile app payment
  {
    path: routes.public.paymentSelectFormMobileApp,
    exact: false,
    component: <PaymentSelectMobileApp />,
  },
  {
    path: routes.public.cardPaymentFormMobileApp,
    exact: false,
    component: <CardPaymentMobileApp />,
  },
  {
    path: routes.public.cashOnDeliveryFormMobileApp,
    exact: false,
    component: <CashOnDeliveryMobileApp />,
  },
  // mobile app payment get pro
  {
    path: routes.public.paymentSelectFormMobileAppGetPro,
    exact: false,
    component: <PaymentSelectMobileAppGetPro />,
  },
  {
    path: routes.public.cardPaymentFormMobileAppGetPro,
    exact: false,
    component: <CardPaymentMobileAppGetPro />,
  },
  {
    path: routes.public.cashOnDeliveryFormMobileAppGetPro,
    exact: false,
    component: <CashOnDeliveryMobileAppGetPro />,
  },

  {
    path: "*",
    exact: false,
    component: <Navigate to={routes.public.login} />,
  },
];
