import { put } from "redux-saga/effects";

import { ApiCaller } from "../../config";
import { DeadlineAction } from "../actions";

export default class DeadlineMiddleware {
  static *getDeadlines({ payload, cb }) {
    try {
      const res = yield ApiCaller.Get(
        `/deadlines/getDeadlines?studentId=${payload.studentId}`
      );
      if (res.status === 200) {
        yield put(DeadlineAction.getDeadlinesSuccess(res.data.data));
      } else {
        yield put(DeadlineAction.getDeadlinesFailure([]));
      }
      if (cb) {
        cb(res.data.data);
      }
    } catch (err) {
      yield put(DeadlineAction.getDeadlinesFailure());
    }
  }
}
