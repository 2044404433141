import { all, takeLatest } from "redux-saga/effects";

import {
  ADD_NOTIFICATIONS,
  ADD_APPLICATIONS,
  ADD_CONTACT,
  ADD_MEETING,
  ADD_MESSAGE,
  ADD_PRODUCT,
  ADD_OR_REMOVE_FROM_WISHLIST,
  ALERT_INDIVIDUAL_STUDENT,
  CANCEL_SUBSCRIPTION,
  CREATE_CHECKOUT_SESSION,
  CREATE_CHECKOUT_SESSION_PRO,
  CREATE_SUBSCRIPTION,
  CHECK_EMAIL,
  CHANGE_ANNOUNCEMENT_STATUS,
  CHANGE_INDIVIDUAL_ANNOUNCEMENT_STATUS,
  CHANGE_NOTIFICATIONS_STATUS,
  CHANGE_MEETING_STATUS,
  CHANGE_PASSWORD,
  COMPARE_UNIVERSITIES,
  DELETE_PRODUCT,
  EDIT_PROFILE,
  FORGOT_PASSWORD,
  GET_ANNOUNCEMENTS,
  GET_INDIVIDUAL_ANNOUNCEMENTS,
  GET_RECOMMENDER,
  GET_NOTIFICATIONS,
  GET_APPLICATIONS_STUDENT,
  GET_MEETINGS_INVITES_AND_REQUESTS,
  GET_ALL_MEETINGS_AND_COUNT,
  GET_MEETINGS_BASED_ON_DATE,
  GET_PROFILE,
  GET_COUNSELORS,
  GET_STUDENT_DETAILS,
  GET_STUDENT_WISHLIST,
  GET_UNIVERSITIES,
  GET_ALL_UNIVERSITIES,
  GET_UNIVERSITY_DETAILS,
  GET_ALL_TRACKER,
  GET_TRACKER,
  GET_SUBSCRIPTION,
  GET_MESSAGES,
  GET_UNREAD_MESSAGES,
  GET_PRODUCT,
  GET_ALL_PRODUCT,
  GET_USERS,
  GET_UDEMY_COURSES,
  GET_KHAN_ACADEMY_COURSES,
  GET_DEADLINES,
  GET_SCHOOLS,
  GET_STUDENT_PRODUCT,
  GET_ETEXTBOOKS,
  LOGIN,
  LOGOUT,
  RESET_PASSWORD,
  SIGNUPSTUDENT,
  SET_TRIAL,
  SEND_EMAIL_ADMIN,
  UPDATE_INDIVIDUAL_STUDENT,
  UPDATE_PRODUCT,
  UPDATE_CONVERTER,
  UPLOAD_PROOF,
  VERIFY_OTP_SIGNUP,
  VERIFY_OTP,
} from "../constants";
import {
  AnnouncementMiddleware,
  ApplicationMiddleware,
  AuthMiddleware,
  ChatbotMiddleware,
  ContactMiddleware,
  ConverterMiddleware,
  CounselorMiddleware,
  DeadlineMiddleware,
  ETextbookMiddleware,
  InteractiveLearningMiddleware,
  IndividualStudentMiddleware,
  MeetingMiddleware,
  MarketplaceMiddleware,
  NotificationsMiddleware,
  ProfileMiddleware,
  RecommenderMiddleware,
  StudentMiddleware,
  TrackerMiddleware,
  UniversityMiddleware,
  WatchlistMiddleware,
} from "../middlewares";

export function* Sagas() {
  yield all([
    //login flow
    yield takeLatest(LOGIN, AuthMiddleware.SignIn),
    yield takeLatest(FORGOT_PASSWORD, AuthMiddleware.ForgotPassword),
    yield takeLatest(RESET_PASSWORD, AuthMiddleware.ResetPassword),
    yield takeLatest(VERIFY_OTP, AuthMiddleware.VerifyOtp),
    yield takeLatest(SIGNUPSTUDENT, AuthMiddleware.studentSignup),
    yield takeLatest(CHECK_EMAIL, AuthMiddleware.CheckEmail),
    yield takeLatest(VERIFY_OTP_SIGNUP, AuthMiddleware.VerifySignupOtp),
    yield takeLatest(LOGOUT, AuthMiddleware.Logout),

    //university
    yield takeLatest(GET_UNIVERSITIES, UniversityMiddleware.getUniversities),
    yield takeLatest(
      GET_ALL_UNIVERSITIES,
      UniversityMiddleware.getAllUniversities
    ),
    yield takeLatest(
      GET_UNIVERSITY_DETAILS,
      UniversityMiddleware.getUniversityDetails
    ),
    yield takeLatest(
      COMPARE_UNIVERSITIES,
      UniversityMiddleware.compareUniverities
    ),

    //students
    yield takeLatest(GET_STUDENT_DETAILS, StudentMiddleware.getStudentDetails),
    yield takeLatest(GET_SCHOOLS, StudentMiddleware.getSchools),
    yield takeLatest(GET_STUDENT_WISHLIST, WatchlistMiddleware.getWatchlist),

    //user profile
    yield takeLatest(GET_PROFILE, ProfileMiddleware.getUserProfile),
    yield takeLatest(EDIT_PROFILE, ProfileMiddleware.editUserProfile),
    yield takeLatest(CHANGE_PASSWORD, ProfileMiddleware.changePassword),

    //miscellaneous
    yield takeLatest(ADD_CONTACT, ContactMiddleware.addContact),

    //counselors
    yield takeLatest(GET_COUNSELORS, CounselorMiddleware.getCounselors),

    //announcements
    yield takeLatest(
      GET_ANNOUNCEMENTS,
      AnnouncementMiddleware.getAnnouncements
    ),
    yield takeLatest(
      CHANGE_ANNOUNCEMENT_STATUS,
      AnnouncementMiddleware.changeAnnouncementStatus
    ),
    //individual announcements
    yield takeLatest(
      GET_INDIVIDUAL_ANNOUNCEMENTS,
      AnnouncementMiddleware.getIndividualAnnouncements
    ),
    yield takeLatest(
      CHANGE_INDIVIDUAL_ANNOUNCEMENT_STATUS,
      AnnouncementMiddleware.changeIndividualAnnouncementStatus
    ),

    // deadlines
    yield takeLatest(GET_DEADLINES, DeadlineMiddleware.getDeadlines),

    //notifications
    yield takeLatest(
      ADD_NOTIFICATIONS,
      NotificationsMiddleware.addNotifications
    ),
    yield takeLatest(
      GET_NOTIFICATIONS,
      NotificationsMiddleware.getNotifications
    ),
    yield takeLatest(
      CHANGE_NOTIFICATIONS_STATUS,
      NotificationsMiddleware.changeNotificationsStatus
    ),

    // recommender
    yield takeLatest(GET_RECOMMENDER, RecommenderMiddleware.getRecommender),

    //meetings
    yield takeLatest(ADD_MEETING, MeetingMiddleware.addMeeting),
    yield takeLatest(
      GET_MEETINGS_INVITES_AND_REQUESTS,
      MeetingMiddleware.getMeetingInvitesAndRequests
    ),
    yield takeLatest(
      GET_ALL_MEETINGS_AND_COUNT,
      MeetingMiddleware.getAllMeetingsAndCount
    ),
    yield takeLatest(
      GET_MEETINGS_BASED_ON_DATE,
      MeetingMiddleware.getMeetingsBasedOnDate
    ),
    yield takeLatest(
      CHANGE_MEETING_STATUS,
      MeetingMiddleware.changeMeetingStatus
    ),

    //applications

    yield takeLatest(
      GET_APPLICATIONS_STUDENT,
      ApplicationMiddleware.getApplicationsStudent
    ),
    yield takeLatest(ADD_APPLICATIONS, ApplicationMiddleware.addApplications),

    // watchlist
    yield takeLatest(
      ADD_OR_REMOVE_FROM_WISHLIST,
      WatchlistMiddleware.addOrRemoveWishlist
    ),

    //marketplace
    yield takeLatest(ADD_PRODUCT, MarketplaceMiddleware.addProduct),
    yield takeLatest(GET_PRODUCT, MarketplaceMiddleware.getProduct),
    yield takeLatest(GET_ALL_PRODUCT, MarketplaceMiddleware.getAllProduct),
    yield takeLatest(UPDATE_PRODUCT, MarketplaceMiddleware.updateProduct),
    yield takeLatest(DELETE_PRODUCT, MarketplaceMiddleware.deleteProduct),
    yield takeLatest(
      GET_STUDENT_PRODUCT,
      MarketplaceMiddleware.getStudentProduct
    ),

    //converter
    yield takeLatest(UPDATE_CONVERTER, ConverterMiddleware.updateConverter),

    //tracker
    yield takeLatest(GET_ALL_TRACKER, TrackerMiddleware.getAllTracker),
    yield takeLatest(GET_TRACKER, TrackerMiddleware.getTracker),

    //chatbot
    yield takeLatest(GET_MESSAGES, ChatbotMiddleware.getMessages),
    yield takeLatest(GET_UNREAD_MESSAGES, ChatbotMiddleware.getUnreadMessages),
    yield takeLatest(ADD_MESSAGE, ChatbotMiddleware.addMessage),
    yield takeLatest(GET_USERS, ChatbotMiddleware.getUsers),

    //interactive learning
    yield takeLatest(
      GET_UDEMY_COURSES,
      InteractiveLearningMiddleware.getUdemyCourses
    ),
    yield takeLatest(
      GET_KHAN_ACADEMY_COURSES,
      InteractiveLearningMiddleware.getKhanAcademyCourses
    ),

    //e textbooks
    yield takeLatest(GET_ETEXTBOOKS, ETextbookMiddleware.getETextbooks),

    // indiVidual student
    yield takeLatest(
      UPDATE_INDIVIDUAL_STUDENT,
      IndividualStudentMiddleware.updateIndividualStudent
    ),
    yield takeLatest(
      ALERT_INDIVIDUAL_STUDENT,
      IndividualStudentMiddleware.alertIndividualStudent
    ),
    yield takeLatest(
      CANCEL_SUBSCRIPTION,
      IndividualStudentMiddleware.cancelSubscription
    ),
    yield takeLatest(
      CREATE_CHECKOUT_SESSION,
      IndividualStudentMiddleware.createCheckout
    ),
    yield takeLatest(
      CREATE_CHECKOUT_SESSION_PRO,
      IndividualStudentMiddleware.createCheckoutPro
    ),
    yield takeLatest(
      CREATE_SUBSCRIPTION,
      IndividualStudentMiddleware.createSubscription
    ),
    yield takeLatest(UPLOAD_PROOF, IndividualStudentMiddleware.uploadProof),
    yield takeLatest(
      GET_SUBSCRIPTION,
      IndividualStudentMiddleware.getSubscriptionStatus
    ),
    yield takeLatest(SET_TRIAL, IndividualStudentMiddleware.setTrial),
    yield takeLatest(
      SEND_EMAIL_ADMIN,
      IndividualStudentMiddleware.sendEmailToAdmin
    ),
  ]);
}
