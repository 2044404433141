import { Layout } from '../../components';

function Support({ children }) {
  const steps = [
    {
      heading: 'Contact Us',
      subHeading: 'Contact us form',
      route: '/support',
    },
    {
      heading: 'FAQs',
      subHeading: 'Explore FAQ section',
      route: '/support/faqs',
    },
  ];

  return (
    <Layout.StepsSection
      heading={'Support'}
      stepsList={steps}
      children={children}
    />
  );
}

export default Support;
