import { put } from "redux-saga/effects";

import { IndividualStudentAction } from "../actions";
import { ApiCaller } from "../../config";

export default class IndividualStudentMiddleware {
  static *alertIndividualStudent({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post(
        `/individualStudents/alertIndividualStudent`,
        payload
      );

      if (res.status === 200) {
        yield put(
          IndividualStudentAction.alertIndividualStudentSuccess(res.data.data)
        );
      } else {
        yield put(IndividualStudentAction.alertIndividualStudentFailure());
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(IndividualStudentAction.alertIndividualStudentFailure());
    }
  }
  static *cancelSubscription({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post(
        `/individualStudents/cancel-subscription`,
        payload
      );

      if (res.status === 200) {
        yield put(
          IndividualStudentAction.cancelSubscriptionSuccess(res.data.data)
        );
      } else {
        yield put(IndividualStudentAction.cancelSubscriptionFailure());
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(IndividualStudentAction.cancelSubscriptionFailure());
    }
  }
  static *createCheckout({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post(
        `/individualStudents/create-checkout-session`,
        payload
      );

      if (res.status === 200) {
        yield put(IndividualStudentAction.createCheckoutSuccess(res.data.data));
      } else {
        yield put(IndividualStudentAction.createCheckoutFailure());
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(IndividualStudentAction.createCheckoutFailure());
    }
  }
  static *createCheckoutPro({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post(
        `/individualStudents/create-checkout-session-pro`,
        payload
      );

      if (res.status === 200) {
        yield put(
          IndividualStudentAction.createCheckoutProSuccess(res.data.data)
        );
      } else {
        yield put(IndividualStudentAction.createCheckoutProFailure());
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(IndividualStudentAction.createCheckoutProFailure());
    }
  }
  static *createSubscription({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post(
        `/individualStudents/create-subscription`,
        payload
      );

      console.log(res);

      if (res.status === 200) {
        yield put(
          IndividualStudentAction.createSubscriptionSuccess(res.data.data)
        );
      } else {
        yield put(IndividualStudentAction.createSubscriptionFailure());
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(IndividualStudentAction.createSubscriptionFailure());
    }
  }
  static *updateIndividualStudent({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post(
        `/individualStudents/updateIndividualStudent`,
        payload
      );

      if (res.status === 200) {
        yield put(
          IndividualStudentAction.updateIndividualStudentSuccess(res.data.data)
        );
      } else {
        yield put(IndividualStudentAction.updateIndividualStudentFailure());
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(IndividualStudentAction.updateIndividualStudentFailure());
    }
  }
  static *getSubscriptionStatus({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post(
        `/individualStudents/get-subscription-status`,
        payload
      );
      if (res.status === 200) {
        yield put(
          IndividualStudentAction.getSubscriptionStatusSuccess(res.data.data)
        );
      } else {
        yield put(IndividualStudentAction.getSubscriptionStatusFailure());
      }
      if (cb) {
        cb(res);
      }
    } catch (err) {
      yield put(IndividualStudentAction.getSubscriptionStatusFailure());
    }
  }
  static *setTrial({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post(`/individualStudents/setTrial`, payload);
      if (res.status === 200) {
        yield put(IndividualStudentAction.setTrialSuccess(res.data.data));
      } else {
        yield put(IndividualStudentAction.setTrialFailure());
      }
      if (cb) {
        cb(res);
      }
    } catch (err) {
      yield put(IndividualStudentAction.setTrialFailure());
    }
  }
  static *sendEmailToAdmin({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post(
        `/individualStudents/sendEmailToAdmin`,
        payload
      );
      if (res.status === 200) {
        yield put(
          IndividualStudentAction.sendEmailToAdminSuccess(res.data.data)
        );
      } else {
        yield put(IndividualStudentAction.sendEmailToAdminFailure());
      }
      if (cb) {
        cb(res);
      }
    } catch (err) {
      yield put(IndividualStudentAction.sendEmailToAdminFailure());
    }
  }
  static *uploadProof({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post(`/individualStudents/codProof`, payload);

      if (res.status === 200) {
        yield put(IndividualStudentAction.uploadProofSuccess(res.data.data));
      } else {
        yield put(IndividualStudentAction.uploadProofFailure());
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(IndividualStudentAction.uploadProofFailure());
    }
  }
}
