import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router";

import { HiOutlineEyeOff, HiOutlineEye } from "react-icons/hi";
import { MdCancel, MdCheckCircle } from "react-icons/md";

import { Button, Layout } from "../../components";
import { Utils } from "../../config";

import styles from "./styles";

function Flow2PasswordForm() {
  const navigate = useNavigate();
  const location = useLocation();

  const [state, setState] = useState({
    password: "",
    confirmPassword: "",
    passwordError: "",
    confirmPasswordError: "",
  });
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [passwordRequirements, setPasswordRequirements] = useState({
    minLength: false,
    uppercase: false,
    lowercase: false,
    number: false,
  });

  const handlePasswordChange = (password) => {
    const updatedRequirements = {
      minLength: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      number: /[0-9]/.test(password),
    };
    setPasswordRequirements(updatedRequirements);
  };

  const handleNextPassword = () => {
    if (state.password === "") {
      setState({
        ...state,
        passwordError: "Password can not be left empty",
      });
    } else if (Utils.passwordRegex.test(state.password) === false) {
      setState({
        ...state,
        passwordError: "Your password does not match the required pattern",
      });
    } else if (state.confirmPassword === "") {
      setState({
        ...state,
        passwordError: "",
        confirmPasswordError: "Password can not be left empty",
      });
    } else if (state.password !== state.confirmPassword) {
      setState({
        ...state,
        confirmPasswordError: "Passwords did not match",
      });
    } else {
      setState({
        ...state,
        passwordError: "",
        confirmPasswordError: "",
      });

      navigate("/signup/subscriptionSelectForm", {
        state: {
          email: location.state.details.email,
          name: location.state.details.fullname,
          password: state.password,
          phoneNumber: location.state.details.phoneNumber,
          gender: location.state.details.gender,
          role: location.state.details.role,
          location: location.state.details.location,
          referral: location.state.details.referral,
        },
      });
    }
  };

  return (
    <Layout.LoginLayout backLink={"/signup/email"}>
      <Layout.HeaderSection
        title="Set New Password"
        subtitle="Enter new password and confirm password"
      />
      <Layout.Spacer height={20} />
      <div style={styles.container}>
        <label style={styles.labelStyle}>
          Set Password <span style={{ color: "red" }}>*</span>
        </label>
        <Layout.Spacer height={5} />

        <div style={styles.inputFieldBorder}>
          <input
            type={showPassword1 ? "text" : "password"}
            placeholder="Password"
            value={state.password}
            onChange={(e) => {
              setState({
                ...state,
                password: e.target.value,
                passwordError: "",
              });
              handlePasswordChange(e.target.value);
            }}
            style={{
              outline: "none",
              background: "transparent",
              border: "none",
              width: "93%",
            }}
          />
          <div>
            {showPassword1 ? (
              <HiOutlineEye
                style={styles.passwordIcon}
                onClick={() => setShowPassword1(false)}
              />
            ) : (
              <HiOutlineEyeOff
                style={styles.passwordIcon}
                onClick={() => setShowPassword1(true)}
              />
            )}
          </div>
        </div>

        {state.passwordError && (
          <div className="text-danger">{state.passwordError}</div>
        )}

        <Layout.Spacer height={20} />
        <label style={styles.labelStyle}>
          Confirm New Password <span style={{ color: "red" }}>*</span>
        </label>
        <Layout.Spacer height={5} />
        <div style={styles.inputFieldBorder}>
          <input
            type={showPassword2 ? "text" : "password"}
            placeholder="Password"
            value={state.confirmPassword}
            onChange={(e) => {
              setState({
                ...state,
                confirmPassword: e.target.value,
                confirmPasswordError: "",
              });
            }}
            style={{
              outline: "none",
              background: "transparent",
              border: "none",
              width: "93%",
            }}
          />
          <div>
            {showPassword2 ? (
              <HiOutlineEye
                style={styles.passwordIcon}
                onClick={() => setShowPassword2(false)}
              />
            ) : (
              <HiOutlineEyeOff
                style={styles.passwordIcon}
                onClick={() => setShowPassword2(true)}
              />
            )}
          </div>
        </div>
        {state.confirmPasswordError && (
          <div className="text-danger">{state.confirmPasswordError}</div>
        )}

        <Layout.Spacer height={10} />

        <div style={{ marginLeft: "10px" }}>
          <p style={{ margin: "0px", fontWeight: "500" }}>
            Password Requirements:
          </p>
          <div style={styles.passwordCheck}>
            {passwordRequirements.minLength ? (
              <MdCheckCircle color="green" fontSize="18px" />
            ) : (
              <MdCancel color="red" fontSize="18px" />
            )}
            At least 8 characters
          </div>
          <div style={styles.passwordCheck}>
            {passwordRequirements.uppercase ? (
              <MdCheckCircle color="green" fontSize="18px" />
            ) : (
              <MdCancel color="red" fontSize="18px" />
            )}
            At least one uppercase letter
          </div>
          <div style={styles.passwordCheck}>
            {passwordRequirements.lowercase ? (
              <MdCheckCircle color="green" fontSize="18px" />
            ) : (
              <MdCancel color="red" fontSize="18px" />
            )}
            At least one lowercase letter
          </div>
          <div style={styles.passwordCheck}>
            {passwordRequirements.number ? (
              <MdCheckCircle color="green" fontSize="18px" />
            ) : (
              <MdCancel color="red" fontSize="18px" />
            )}
            At least one number
          </div>
        </div>

        <Layout.Spacer height={10} />

        <Button.Primary style={styles.buttonStyle} onClick={handleNextPassword}>
          Continue
        </Button.Primary>

        <Layout.Spacer height={20} />
      </div>
    </Layout.LoginLayout>
  );
}

export default Flow2PasswordForm;
