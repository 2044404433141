import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Pagination } from "antd";

import { AiFillRightCircle } from "react-icons/ai";
import { MdLocationPin } from "react-icons/md";

import { Colors } from "../../config";
import { Layout, Table, Text, convertCurrency } from "../../components";
import { WatchlistAction } from "../../store/actions";

import styled from "styled-components";
import styles from "./styles";

import premiumContent from "../../assets/images/premiunContent.svg";

function Watchlist() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const currency = useSelector((state) => state.converter.converted);
  const convertedCurrency = useSelector((state) => state.converter.convertTo);
  const watchlist = useSelector((state) => state.watchlist);
  const studentDetail = useSelector((state) => state.auth.user.user);

  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [blurData, setBlurData] = useState(undefined);
  const [indexTable, setIndexTable] = useState(undefined);

  useEffect(() => {
    const currentDate = new Date();
    const validUntilDate = new Date(studentDetail.validUntil);

    if (
      validUntilDate < currentDate &&
      studentDetail.studentType === "individualStudent" &&
      studentDetail.subscriptionPlan === "Free"
    ) {
      setBlurData(true);
    }

    if (watchlist.watchlists.length > 3) {
      setIndexTable(true);
    }
  }, [watchlist, studentDetail]);

  useEffect(() => {
    const payload = {
      limit: 10,
      offset: 0,
    };
    dispatch(WatchlistAction.getWatchlist(payload));
  }, []);

  const handlePaginationChange = (pageNumber) => {
    let offset = (pageNumber - 1) * limit;
    setOffset(offset);
    setPage(pageNumber);
    const payload = {
      limit,
      offset: offset,
    };
    dispatch(WatchlistAction.getWatchlist(payload));
  };

  const modalTop =
    watchlist.watchlists.length == 3 ||
    watchlist.watchlists.length == 4 ||
    watchlist.watchlists.length == 5
      ? "74%"
      : watchlist.watchlists.length == 6
      ? "60%"
      : "50%";

  return (
    <>
      <div style={styles.flexSpaceBetween}>
        <div style={styles.flexRow}>
          <Text.Heading
            fontSize={"18px"}
            fontWeight={"600"}
            color={Colors.Primary}
            style={styles.mr10}
            text="Watchlist"
          />
        </div>
      </div>
      <Layout.Spacer height={20} />
      <div style={styles.flexContent}>
        <Table.AntDesign
          rowKey={(record) => record._id}
          columns={[
            {
              title: "Name",
              dataIndex: "name",
              onCell: (record, index) => ({
                onClick: () => {
                  if (blurData) {
                    if (index <= 2) {
                      navigate(
                        `/universities/${record?.universityId?._id}/general`
                      );
                    }
                  } else {
                    navigate(
                      `/universities/${record?.universityId?._id}/general`
                    );
                  }
                },
              }),
              width: 400,
              render: (text, record, index) => (
                <ColumnDiv
                  style={{
                    filter: blurData && (index > 2 ? "blur(4px)" : "none"),
                  }}
                >
                  <img
                    src={record?.universityId?.profilePic}
                    alt=""
                    style={{ backgroundColor: "white" }}
                  />
                  <span>
                    <Text.Heading
                      fontSize={"12px"}
                      fontWeight={"600"}
                      color={Colors.BlackText}
                      textAlign="left"
                      textTransform="capitalize"
                      text={record?.universityId?.name}
                    />
                    <span>
                      <MdLocationPin
                        color={Colors.GrayText}
                        style={styles.mr5}
                      />
                      <Text.Heading
                        fontSize={"10px"}
                        fontWeight={"400"}
                        color={Colors.GrayText}
                        textAlign="left"
                        text={record?.universityId?.location}
                      />
                    </span>
                  </span>
                </ColumnDiv>
              ),
            },
            {
              title: "QS World Ranking",
              dataIndex: "qsWorldRanking",
              align: "center",
              width: 200,
              onCell: (record, index) => ({
                onClick: () => {
                  if (blurData) {
                    if (index <= 2) {
                      navigate(
                        `/universities/${record?.universityId?._id}/general`
                      );
                    }
                  } else {
                    navigate(
                      `/universities/${record?.universityId?._id}/general`
                    );
                  }
                },
              }),
              render: (text, record, index) => (
                <span
                  style={{
                    ...styles.wishlistTableText,
                    filter: blurData && (index > 2 ? "blur(4px)" : "none"),
                  }}
                >
                  {record?.universityId?.summary?.qsWorldRanking}
                </span>
              ),
            },
            {
              title: !currency
                ? "Average Fee"
                : `Average Fee (${convertedCurrency})`,
              dataIndex: "feeStructure",
              align: "center",
              onCell: (record, index) => ({
                onClick: () => {
                  if (blurData) {
                    if (index <= 2) {
                      navigate(
                        `/universities/${record?.universityId?._id}/general`
                      );
                    }
                  } else {
                    navigate(
                      `/universities/${record?.universityId?._id}/general`
                    );
                  }
                },
              }),
              width: 350,
              render: (text, record, index) => {
                const converted = convertCurrency(
                  record?.universityId?.summary?.fee_structure,
                  convertedCurrency
                );
                return (
                  <div
                    style={{
                      ...styles.wishlistTableText,
                      filter: blurData && (index > 2 ? "blur(4px)" : "none"),
                    }}
                  >
                    {currency
                      ? converted
                      : record?.universityId?.summary?.fee_structure}
                  </div>
                );
              },
            },
            {
              title: " ",
              dataIndex: "action",
              align: "center",
              width: 50,
              onCell: (record, index) => ({
                onClick: () => {
                  if (blurData) {
                    if (index <= 2) {
                      navigate(
                        `/universities/${record?.universityId?._id}/general`
                      );
                    }
                  } else {
                    navigate(
                      `/universities/${record?.universityId?._id}/general`
                    );
                  }
                },
              }),
              render: (text, record, index) => (
                <span
                  style={{
                    filter: blurData && (index > 2 ? "blur(4px)" : "none"),
                  }}
                >
                  <AiFillRightCircle
                    fontSize={"30px"}
                    cursor={index > 2 ? (blurData ? "" : "pointer") : "pointer"}
                    color={Colors.Primary}
                  />
                </span>
              ),
            },
          ]}
          loading={watchlist.isLoading}
          data={watchlist.watchlists}
          pagination={false}
        />
        {indexTable && blurData ? (
          <div style={{ ...styles.premiumModal, top: modalTop }}>
            <img src={premiumContent} />
            <p>
              To view more <b>Subscribe to our Pro Package</b>
            </p>
          </div>
        ) : (
          ""
        )}
      </div>
      <Layout.Spacer height={20} />
      {blurData ? (
        ""
      ) : (
        <div style={styles.flexRowCenter}>
          <Pagination
            responsive="true"
            showSizeChanger={false}
            hideOnSinglePage={true}
            current={page}
            pageSize={10}
            total={watchlist.count}
            onChange={handlePaginationChange}
          />
        </div>
      )}
    </>
  );
}

export default Watchlist;

const ColumnDiv = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-start;
  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: contain;
    margin-right: 10px;
  }
  span {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    span:last-of-type {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
`;
