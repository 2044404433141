import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Pagination } from "antd";

import { FaSearch } from "react-icons/fa";

import { ETextbookAction } from "../../store/actions";

import { Layout, Loader, Text, TextField } from "../../components";
import { Colors, Images } from "../../config";

import styles from "./styles";

const Etextbook = () => {
  const dispatch = useDispatch();

  const books = useSelector((state) => state?.eTextbook?.books);
  const booksLoading = useSelector((state) => state?.eTextbook?.isLoading);

  const [pageNo, setPageNo] = useState(1);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState("ebooks");
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const payload = {
      pageNo: pageNo,
      search,
      filter,
    };
    dispatch(ETextbookAction.getETextbooks(payload));
  }, [pageNo, search, filter]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSearch = (e) => {
    setSearch(e);
    const payload = {
      pageNo: pageNo,
      search: e,
      filter,
    };
    dispatch(ETextbookAction.getETextbooks(payload));
  };

  const handlePagination = (pageNumber) => {
    setPageNo(pageNumber);
    const payload = {
      pageNo: pageNumber,
      search: search,
      filter: filter,
    };
    dispatch(ETextbookAction.getETextbooks(payload));
  };

  return (
    <div>
      <Text.Heading
        fontSize={"18px"}
        fontWeight={"600"}
        color={Colors.Primary}
        text="E-text Books"
      />
      <Layout.Spacer height={10} />
      <div style={styles.flexSpaceBetween}>
        <div style={{ width: "100%", marginRight: "10px" }}>
          <TextField.CustomInput
            placeholder="Search Books"
            suffix={<FaSearch color={Colors.Primary} />}
            style={styles.customTextfieldStyle}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </div>
        <div
          style={{
            marginRight: "10px",
            position: "relative",
          }}
        >
          <div style={styles.filterButton} onClick={toggleDropdown}>
            <img src={Images.Sort} style={{ width: "100%" }} />
          </div>
          {isOpen && (
            <div style={styles.filterDropdown}>
              <div
                style={{
                  ...styles.filterDropdownValue,
                  fontWeight: filter === "all" ? "700" : "400",
                  borderBottom: "1px solid white",
                }}
                onClick={() => {
                  setFilter("ebooks");
                  toggleDropdown();
                }}
              >
                All
              </div>
              <div
                style={{
                  ...styles.filterDropdownValue,
                  fontWeight: filter === "paid" ? "700" : "400",
                  borderBottom: "1px solid white",
                }}
                onClick={() => {
                  setFilter("paid-ebooks");
                  toggleDropdown();
                }}
              >
                Paid
              </div>
              <div
                style={{
                  ...styles.filterDropdownValue,
                  fontWeight: filter === "free" ? "700" : "400",
                }}
                onClick={() => {
                  setFilter("free-ebooks");
                  toggleDropdown();
                }}
              >
                Free
              </div>
            </div>
          )}
        </div>
        <div style={styles.searchButton} onClick={handleSearch}>
          Search
        </div>
      </div>
      <Layout.Spacer height={10} />
      <div>
        <Text.Heading
          fontSize={"18px"}
          fontWeight={"600"}
          color={Colors.Primary}
          text="Results"
        />
      </div>
      <Layout.Spacer height={10} />
      <div>
        {booksLoading ? (
          <Loader.Circular />
        ) : (
          <div style={styles.courseDivContainer}>
            {books?.items?.map((book) => {
              return (
                <div
                  style={styles.courseDiv}
                  onClick={() => {
                    window.open(book?.volumeInfo?.previewLink, "_blank");
                  }}
                  key={book?.id}
                >
                  <div>
                    <img
                      src={book?.volumeInfo?.imageLinks?.thumbnail}
                      style={styles.courseImage}
                    />
                  </div>
                  <div style={styles.coursePrice}>
                    {book?.saleInfo?.saleability}
                  </div>
                  {book?.volumeInfo?.authors?.length > 0 ? (
                    <div style={styles.courseInstructor}>
                      <b>Author:</b>{" "}
                      {book?.volumeInfo?.authors?.map((author, index) => (
                        <span style={{ margin: "0px" }}>
                          {book?.volumeInfo?.authors?.length === 1 ? (
                            <>{author}</>
                          ) : (
                            <>{`, ${author}`}</>
                          )}
                        </span>
                      ))}
                    </div>
                  ) : (
                    <div style={styles.courseInstructor}>
                      <b>Author:</b> N/A
                    </div>
                  )}
                  <div style={styles.courseTitle}>
                    <b>Title:</b>{" "}
                    {book?.volumeInfo?.title.length > 50
                      ? `${book?.volumeInfo?.title.slice(0, 50)}...`
                      : book?.volumeInfo?.title}
                  </div>
                  {book?.volumeInfo?.categories?.length > 0 ? (
                    <div style={styles.courseInstructor}>
                      <b>Categories:</b>{" "}
                      {book?.volumeInfo?.categories?.map((category, index) => (
                        <span style={{ margin: "0px" }}>
                          {book?.volumeInfo?.categories?.length === 1 ? (
                            <>{category}</>
                          ) : (
                            <>{`, ${category}`}</>
                          )}
                        </span>
                      ))}
                    </div>
                  ) : (
                    <div style={styles.courseInstructor}>
                      <b>Categories:</b> N/A
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        )}
        <Layout.Spacer height={10} />
        <div style={styles.flexCenter}>
          <Pagination
            responsive="true"
            showSizeChanger={false}
            hideOnSinglePage={true}
            current={pageNo}
            pageSize={10}
            total={books?.totalItems}
            onChange={handlePagination}
          />
        </div>
      </div>
    </div>
  );
};

export default Etextbook;
