import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";

import { MdContacts } from "react-icons/md";

import { MarketplaceAction } from "../../store/actions";

import { Layout, Loader, Modal, Text, Button } from "../../components";
import { Colors } from "../../config";

import styles from "./styles";

function SpecificProduct() {
  const dispatch = useDispatch();
  const location = useLocation();

  const id = location.pathname.split("/")[4];

  const studentId = useSelector((state) => state.auth.user.user.studentId);
  const schoolId = useSelector((state) => state.auth.user.user.schoolId);
  const marketplaceLoading = useSelector((state) => state.marketplace);
  const product = useSelector((state) => state.marketplace.product.product);

  const [contactModal, setContactModal] = useState(false);

  useEffect(() => {
    const payload = {
      studentId,
      schoolId,
      id: id,
    };
    dispatch(MarketplaceAction.getProduct(payload));
  }, []);

  return (
    <div>
      <Text.Heading
        fontSize={"18px"}
        fontWeight={"600"}
        color={Colors.Primary}
        text="Product Details"
      />
      <Layout.Spacer height={50} />
      {marketplaceLoading?.isLoading ? (
        <Loader.Circular />
      ) : (
        <>
          <div style={styles.justifyCenterAlignCenterRow}>
            <div>
              <img src={product?.itemPhoto} style={styles.specificItemPhoto} />
            </div>
            <div style={styles.justifyCenterAlignCenterRow}>
              <div style={styles.specificItemLabelContainer}>
                <div>Seller Name</div>
                <Layout.Spacer height={10} />
                <div>Item Name</div>
                <Layout.Spacer height={10} />
                <div>Item type</div>
                <Layout.Spacer height={10} />
                <div>Item Cost</div>
              </div>
              <div style={styles.specificItemValueContainer}>
                <div>{product?.sellerName}</div>
                <Layout.Spacer height={10} />
                <div>{product?.itemName}</div>
                <Layout.Spacer height={10} />
                <div>
                  {product?.itemType === "book"
                    ? "Book"
                    : product?.itemType === "pastassignment"
                    ? "Past Assignment"
                    : product?.itemType === "pastpaper"
                    ? "Past Paper"
                    : "Stationery"}
                </div>
                <Layout.Spacer height={10} />
                <div>
                  {product?.price.split(" ")[0]}{" "}
                  {parseFloat(product?.price.split(" ")[1]).toLocaleString()}
                </div>
              </div>
            </div>
          </div>
          <Layout.Spacer height={40} />
          <div style={styles.justifyCenterAlignCenterRow}>
            <div
              style={styles.contactButton}
              onClick={() => setContactModal(true)}
            >
              Contact Seller
            </div>
          </div>
        </>
      )}
      {contactModal && (
        <Modal.Basic
          isModalVisible={contactModal}
          centered={true}
          handleCancel={() => setContactModal(false)}
          closable={false}
        >
          <div style={styles.justifyCenterAlignCenterColumn}>
            <div>
              <MdContacts
                style={{
                  ...styles.modalIcon,
                  color: Colors.Primary,
                  padding: "10px",
                }}
              />
            </div>
            <div style={styles.successText}>Contact Information</div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div style={styles.contactModalLabelContainer}>
                <div>Seller Name</div>
                <Layout.Spacer height={10} />
                <div>Email</div>
                <Layout.Spacer height={10} />
                <div>Whatsapp</div>
              </div>
              <div style={styles.contactModalValueContainer}>
                <div style={{ textTransform: "capitalize" }}>
                  {product?.sellerName}
                </div>
                <Layout.Spacer height={10} />
                <div>{product?.email ? product?.email : "NA"}</div>
                <Layout.Spacer height={10} />
                <div>{product?.whatsapp ? `+${product?.whatsapp}` : "NA"}</div>
              </div>
            </div>
            <Layout.Spacer height={20} />
            <div>
              <Button.Primary
                style={styles.modalButton}
                onClick={() => {
                  setContactModal(false);
                }}
              >
                Okay
              </Button.Primary>
            </div>
          </div>
        </Modal.Basic>
      )}
    </div>
  );
}

export default SpecificProduct;
