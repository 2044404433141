import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Pagination } from "antd";

import { FaSearch } from "react-icons/fa";

import { InteractiveLearningAction } from "../../store/actions";

import { Layout, Loader, Text, TextField } from "../../components";
import { Colors, Images } from "../../config";

import styles from "./styles";
import premiumContent from "../../assets/images/premiunContent.svg";

function InteractiveLearning() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const studentType = useSelector((state) => state.auth.user.user.studentType);
  const udemy = useSelector((state) => state?.interactiveLearning?.udemy);
  const udemyLoading = useSelector(
    (state) => state?.interactiveLearning?.isUdemyLoading
  );
  const validUntil = useSelector(
    (state) =>
      state?.student?.student?.studentDetails?.individualStudentId?.validUntil
  );
  const subscriptionPlan = useSelector(
    (state) =>
      state?.student?.student?.studentDetails?.individualStudentId
        ?.subscriptionPlan
  );

  const [open, setOpen] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [search, setSearch] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useState("all");

  useEffect(() => {
    const payload = {
      pageNo: pageNo,
      search,
      filter,
    };
    dispatch(InteractiveLearningAction.getUdemyCourses(payload));
  }, [pageNo, search, filter]);

  useEffect(() => {
    if (
      studentType === "individualStudent" &&
      subscriptionPlan === "Free" &&
      new Date(validUntil) < new Date()
    ) {
      setOpen(true);
    } else {
      handleClose();
    }
  }, []);

  const handleClose = () => setOpen(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSearch = () => {
    const payload = {
      pageNo,
      search,
      filter,
    };
    dispatch(InteractiveLearningAction.getUdemyCourses(payload));
  };

  const handlePagination = (pageNumber) => {
    setPageNo(pageNumber);
    const payload = {
      pageNo: pageNumber,
      search: search,
    };
    dispatch(InteractiveLearningAction.getUdemyCourses(payload));
  };

  return (
    <div>
      <div
        style={
          studentType === "individualStudent" &&
          subscriptionPlan === "Free" &&
          new Date(validUntil) < new Date()
            ? { filter: "blur(4px)  grayscale(100%)", pointerEvents: "none" }
            : {}
        }
      >
        <div>
          <Text.Heading
            fontSize={"18px"}
            fontWeight={"600"}
            color={Colors.Primary}
            text="Interactive Learning"
          />
        </div>
        <Layout.Spacer height={10} />
        <div style={styles.flexSpaceBetween}>
          <div style={{ width: "100%", marginRight: "10px" }}>
            <TextField.CustomInput
              placeholder="Search Courses"
              suffix={<FaSearch color={Colors.Primary} />}
              style={styles.customTextfieldStyle}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <div
            style={{
              marginRight: "10px",
              position: "relative",
            }}
          >
            <div style={styles.filterButton} onClick={toggleDropdown}>
              <img src={Images.Sort} style={{ width: "100%" }} />
            </div>
            {isOpen && (
              <div style={styles.filterDropdown}>
                <div
                  style={{
                    ...styles.filterDropdownValue,
                    fontWeight: filter === "all" ? "700" : "400",
                    borderBottom: "1px solid white",
                  }}
                  onClick={() => {
                    setFilter("all");
                    toggleDropdown();
                  }}
                >
                  All
                </div>
                <div
                  style={{
                    ...styles.filterDropdownValue,
                    fontWeight: filter === "paid" ? "700" : "400",
                    borderBottom: "1px solid white",
                  }}
                  onClick={() => {
                    setFilter("paid");
                    toggleDropdown();
                  }}
                >
                  Paid
                </div>
                <div
                  style={{
                    ...styles.filterDropdownValue,
                    fontWeight: filter === "free" ? "700" : "400",
                  }}
                  onClick={() => {
                    setFilter("free");
                    toggleDropdown();
                  }}
                >
                  Free
                </div>
              </div>
            )}
          </div>
          <div style={styles.searchButton} onClick={handleSearch}>
            Search
          </div>
        </div>
        <Layout.Spacer height={10} />
        <div>
          <Text.Heading
            fontSize={"18px"}
            fontWeight={"600"}
            color={Colors.Primary}
            text="Udemy"
          />
        </div>
        <Layout.Spacer height={10} />
        <div>
          {udemyLoading ? (
            <Loader.Circular />
          ) : (
            <div style={styles.courseDivContainer}>
              {udemy?.results?.map((course) => {
                return (
                  <div
                    style={styles.courseDiv}
                    onClick={() => {
                      window.open(
                        `https://www.udemy.com${course.url}`,
                        "_blank"
                      );
                    }}
                    key={course.title}
                  >
                    <div>
                      <img
                        src={course.image_480x270}
                        style={styles.courseImage}
                      />
                    </div>
                    <div style={styles.coursePrice}>{course.price}</div>
                    <div style={styles.courseInstructor}>
                      <b>Instructor:</b>{" "}
                      {course?.visible_instructors[0].display_name}
                    </div>
                    <div style={styles.courseTitle}>
                      {course.title.length > 50
                        ? `${course.title.slice(0, 50)}...`
                        : course.title}
                    </div>
                    <div style={styles.courseHeadline}>
                      {course.headline.length > 100
                        ? `${course.headline.slice(0, 100)}...`
                        : course.headline}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          <Layout.Spacer height={10} />
          <div style={styles.flexCenter}>
            <Pagination
              responsive="true"
              showSizeChanger={false}
              hideOnSinglePage={true}
              current={pageNo}
              pageSize={10}
              total={udemy.count}
              onChange={handlePagination}
            />
          </div>
        </div>
      </div>
      {open && (
        <div style={styles.premiumModal}>
          <img src={premiumContent} />
          <div>
            To view more <b>Subscribe to our Pro Package</b>
          </div>
          <div
            onClick={() => {
              navigate("/getPro");
            }}
          >
            <span style={styles.getPro}>Get UniVision Pro</span>
          </div>
        </div>
      )}
    </div>
  );
}

export default InteractiveLearning;
