import { Colors } from "../../config";

const styles = {
  buttonStyle: {
    width: "200px",
    fontSize: "14px",
    height: "20px",
  },
  textfieldStyle: {
    borderRadius: "6px",
    border: `1px solid ${Colors.Primary}`,
    padding: "5px",
    width: "100%",
    fontSize: "14px",
    fontWeight: "400",
    color: Colors.BlackText2,
  },

  rowStyle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  rowCenter: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  flexSpaceBetween: {
    display: "flex",
    justifyContent: "space-between",
  },
  mr5: {
    marginRight: "5px",
  },

  // meetings
  meetingDivContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gridColumnGap: "30px",
  },
  meetingDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    height: "130px",
    width: "100%",
    background: Colors.Primary,
    borderRadius: "30px",
    boxShadow: "0px 6px 18px -4px rgba(0,0,0,0.4)",
  },

  meetingHeadlines: {
    fontSize: "18px",
    fontWeight: "600",
    color: Colors.Primary,
    marginRight: "10px",
  },
  meetingColumnText: {
    fontSize: "12px",
    fontWeight: "600",
    color: Colors.BlackText,
    textTransform: "capitalize",
  },
  upcomingAndCalendarContainer: {
    display: "grid",
    gridTemplateColumns: "3fr 1fr",
    gridColumnGap: "30px",
    justifyContent: "space-between",
  },
  upcomingMeetingsContainer: { height: "310px", overflowY: "auto" },
  dueContainer: {
    display: "flex",
    alignItems: "center",
    color: "black",
    marginRight: "5px",
  },
  dueText: {
    fontWeight: "600",
    color: "black",
    marginRight: "5px",
  },
  overdueText: {
    fontWeight: "600",
    color: "red",
    marginRight: "5px",
  },
  meetingStatus: {
    fontSize: "12px",
    fontWeight: "600",
    textTransform: "uppercase",
  },
  acceptIcon: {
    color: "#4DEB67",
    fontSize: "18px",
    marginRight: "7px",
  },
  rescheduleIcon: {
    color: Colors.Blue,
    fontSize: "18px",
    marginRight: "7px",
  },

  // new meeting
  pointerDsiabled: {
    pointerEvents: "none",
    opacity: 0.7,
  },
  boxHeader: {
    fontSize: "14px",
    color: "black",
    fontWeight: "400",
  },
  counselorDropdown: {
    color: Colors.Primary,
    width: "100%",
    borderColor: Colors.Primary,
    outline: "none",
  },

  counselorDisabled: {
    pointerEvents: "none",
    opacity: 0.7,
    color: "black",
    height: "35px",
    width: "100%",
    border: `1px solid ${Colors.Primary}`,
    borderRadius: "7px",
    padding: "5px",
  },

  meetingDatePicker: {
    color: Colors.Primary,
    height: "35px",
    width: "100%",
    borderColor: Colors.Primary,
    borderRadius: "7px",
  },

  meetingFileDsiabled: {
    width: "100%",
    border: `1px solid ${Colors.Primary}`,
    borderRadius: "6px",
    padding: "6px",
    color: "black",
    fontSize: "14px",
    opacity: 0.7,
    display: "flex",
    justifyContent: "space-between",
  },
  meetingFileInput: {
    width: "100%",
    border: `1px solid ${Colors.Primary}`,
    borderRadius: "6px",
    padding: "6px",
    color: "black",
    fontSize: "14px",
    display: "flex",
    justifyContent: "space-between",
  },

  // meeting Details
  meetingDetailsCardContainer: {
    border: "none",
    backgroundColor: Colors.LightGray,
    padding: "30px",
  },
  meetingDetailsCard: {
    display: "grid",
    gridTemplateColumns: "1fr 2fr",
    gridGap: "50px",
  },
  meetingDetailsImageContainer: {
    padding: "40px",
    height: "200px",
    borderRight: `2px solid ${Colors.Primary}`,
  },
  meetingDetailsCardHeading: {
    fontSize: "14px",
    fontWeight: "500",
    color: Colors.Black,
  },
  meetingDetailsCardDetails: {
    fontSize: "16px",
    fontWeight: "500",
    color: Colors.Primary,
  },
  meetingDetailsDividerVertical: {
    height: "200px",
    borderRight: `2px solid ${Colors.Primary}`,
  },
  meetingDetailsDividerHorizontal: {
    width: "300px",
    borderTop: `1px solid ${Colors.Primary}`,
  },
  rescheduledDiv: {
    width: "250px",
    padding: "10px",
    color: "white",
    textAlign: "center",
    background: Colors.Primary,
    borderRadius: "6px",
  },

  // modal
  modalContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  modalIcon: { width: "120px", height: "120px" },
  modalHeader: {
    fontSize: "24px",
    fontWeight: "700",
    color: Colors.Primary,
    marginBottom: "10px",
  },
  modalDescription: {
    fontSize: "16px",
    fontWeight: "400",
    color: "black",
    marginBottom: "10px",
  },
  modalButton: {
    width: "100px",
    height: "30px",
    borderRadius: "30px",
    border: `1px solid ${Colors.Primary}`,
    fontSize: "18px",
    fontWeight: "700",
  },
};

export default styles;
