import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import { Layout, Text, Loader } from "../../components";
import { Colors } from "../../config";

import styles from "./styles";

function Tracker() {
  const navigate = useNavigate();

  const student = useSelector((state) => state.student);
  const appliedUnis = useSelector(
    (state) => state?.student?.student?.studentDetails?.applications
  );

  const [university, setUniversity] = useState("");
  const [universityName, setUniversityName] = useState("");
  const [error, setError] = useState("");

  const handleNext = () => {
    if (!university) {
      setError("Please fill all the fields.");
    } else {
      navigate("/more/statusTracker/trackerDetails", {
        state: {
          university,
          universityName,
        },
      });
    }
  };

  return (
    <div>
      <div>
        <Text.Heading
          fontSize={"18px"}
          fontWeight={"600"}
          color={Colors.Primary}
          text="Application Status Tracker"
        />
      </div>
      <Layout.Spacer height={20} />
      <div>
        <Text.Heading
          fontSize={"16px"}
          fontWeight={"500"}
          color={Colors.Primary}
          text="Enter university name to know about the status"
        />
      </div>
      <Layout.Spacer height={20} />

      <div>
        <div style={styles.header}>University Name</div>
        <div style={{ width: "400px" }}>
          {student?.isLoading ? (
            <Loader.Circular />
          ) : (
            <div className="dashboard-select">
              <Select
                style={styles.uniDropdown}
                onChange={(universityJSON) => {
                  var university = JSON.parse(universityJSON);
                  setUniversity(university?.universityId?._id);
                  setUniversityName(university?.universityId?.name);
                }}
                showSearch
                placeholder="Search University "
                optionFilterProp="children"
                suffixIcon={<SearchOutlined />}
              >
                {appliedUnis?.map((dropdown) => {
                  return (
                    <Select.Option
                      value={JSON.stringify(dropdown)}
                      style={{ textTransform: "capitalize" }}
                      key={dropdown?.universityId?._id}
                    >
                      {dropdown?.universityId?.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </div>
          )}
        </div>
      </div>
      {error !== "" && <div style={{ color: "red" }}>{error}</div>}
      <Layout.Spacer height={20} />
      <div style={styles.submitButton} onClick={() => handleNext()}>
        Submit
      </div>
    </div>
  );
}

export default Tracker;
