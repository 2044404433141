import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Pagination, Select } from "antd";

import { MdCancel } from "react-icons/md";
import { FaAngleDown } from "react-icons/fa6";
import { FaSearch } from "react-icons/fa";
import { BsBookmarkStarFill } from "react-icons/bs";
import { AiFillRightCircle } from "react-icons/ai";
import { MdLocationPin } from "react-icons/md";

import { UniversityAction, WatchlistAction } from "../../store/actions";

import {
  Layout,
  Table,
  Text,
  TextField,
  convertCurrency,
} from "../../components";
import { Colors, Images } from "../../config";
import Countries from "../../config/mockData/countries";

import styled from "styled-components";
import styles from "./styles";

import premiumContent from "../../assets/images/premiunContent.svg";

function Universities() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const pageNo = location.pathname.split("/")[3];

  const currency = useSelector((state) => state.converter.converted);
  const convertedCurrency = useSelector((state) => state.converter.convertTo);
  const userId = useSelector((state) => state.auth.user.user._id);
  const universityState = useSelector((state) => state.university);
  const watchlistState = useSelector((state) => state.watchlist);
  const studentDetail = useSelector((state) => state.auth.user.user);

  const [search, setSearch] = useState("");
  const [blurData, setBlurData] = useState(undefined);
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(pageNo ? parseInt(pageNo) : 1);
  const [limit, setLimit] = useState(10);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("qsWorldRanking");
  const [country, setCountry] = useState(null);
  const [budget, setBudget] = useState("0-100000000");
  const [filterRegions, setFilterRegions] = useState([
    {
      name: "All",
      selected: true,
    },
    {
      name: "Asia",
      value: "asia",
      selected: false,
    },
    {
      name: "North America",
      value: "north america",
      selected: false,
    },
    {
      name: "Australia",
      value: "australia",
      selected: false,
    },
    {
      name: "Europe",
      value: "europe",
      selected: false,
    },
    {
      name: "Africa",
      value: "africa",
      selected: false,
    },

    {
      name: "South America",
      value: "south america",
      selected: false,
    },
  ]);

  const watchlistPayload = {
    limit: "",
    offset: "",
  };

  useEffect(() => {
    const currentDate = new Date();
    const validUntilDate = new Date(studentDetail.validUntil);

    if (
      validUntilDate < currentDate &&
      studentDetail.studentType === "individualStudent" &&
      studentDetail.subscriptionPlan === "Free"
    ) {
      setBlurData(true);
    }
  }, [studentDetail]);

  useEffect(() => {
    const allRegions = filterRegions.filter((item) => item.selected === true);
    const payload = {
      search,
      country: country ?? "",
      budget,
      region: allRegions[0].name === "All" ? "" : allRegions[0].value,
      limit,
      offset: 0,
      filter: selectedValue,
      userId,
    };
    dispatch(UniversityAction.getUniversities(payload));
    dispatch(WatchlistAction.getWatchlist(watchlistPayload));

    setPage(1);
  }, [filterRegions, selectedValue, search, country, budget]);

  useEffect(() => {
    handlePaginationChange(page);
  }, [pageNo]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleUniversityWishlist = (id) => {
    dispatch(
      WatchlistAction.addOrRemoveToWishlist(id, () => {
        dispatch(WatchlistAction.getWatchlist(watchlistPayload));
      })
    );
  };

  const handleFilterCountry = (input, option) => {
    return option.children.toLowerCase().startsWith(input.toLowerCase());
  };

  const handleFilterStatus = (status) => {
    const newStatusFilters = filterRegions.map((filter) => {
      if (filter.name === status.name) {
        return {
          ...filter,
          selected: !filter.selected,
        };
      } else {
        return {
          ...filter,
          selected: false,
        };
      }
    });
    setFilterRegions(newStatusFilters);
  };

  const handlePaginationChange = (pageNumber) => {
    const allRegions = filterRegions.filter((item) => item.selected === true);
    let offset = (pageNumber - 1) * limit;

    const payload = {
      search: search,
      country: country ?? "",
      budget,
      region: allRegions[0].name === "All" ? "" : allRegions[0].value,
      limit: limit,
      offset: offset,
      filter: selectedValue,
      userId,
    };
    dispatch(UniversityAction.getUniversities(payload));
    dispatch(WatchlistAction.getWatchlist(watchlistPayload));

    setOffset(offset);
    setPage(pageNumber);
    navigate(`/universities/filter/${pageNumber}`);
  };

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  return (
    <>
      <div>
        <div style={{ ...styles.flexRow, width: "100%" }}>
          <TextField.CustomInput
            placeholder="Search Universities"
            suffix={<FaSearch color={Colors.Primary} />}
            style={styles.customTextfieldStyle}
            onChange={handleChange}
          />
          <div
            style={{
              marginLeft: "10px",
              position: "relative",
            }}
          >
            <div style={styles.filterButton} onClick={toggleDropdown}>
              <img src={Images.Sort} style={{ width: "100%" }} />
            </div>
            {isOpen && (
              <div style={styles.filterDropdown}>
                <div
                  style={{
                    ...styles.filterDropdownValue,
                    fontWeight:
                      selectedValue === "qsWorldRanking" ? "700" : "400",
                  }}
                  onClick={() => {
                    setSelectedValue("qsWorldRanking");
                    toggleDropdown();
                  }}
                >
                  QS World Ranking
                </div>

                <div
                  style={{
                    cursor: "pointer",
                    fontWeight: selectedValue === "name" ? "700" : "400",
                  }}
                  onClick={() => {
                    setSelectedValue("name");
                    toggleDropdown();
                  }}
                >
                  Alphabetical
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Layout.Spacer height={20} />
      {/* <div style={styles.flexRow}>
        <div>
          <Text.Heading
            fontSize={"16px"}
            fontWeight={"500"}
            color={Colors.Primary}
            letterSpacing="1px"
            marginRight="30px"
            style={{ marginRight: "30px", letterSpacing: "1px" }}
            text="Filter by Region"
          />
        </div>
        <div style={styles.flexRow}>
          {filterRegions.map((region) => (
            <div
              style={{
                ...styles.filterRegion,
                backgroundColor: region?.selected
                  ? Colors.Primary
                  : Colors.White,
                color: region?.selected ? Colors.White : Colors.BlackText2,
                border: region?.selected ? "" : "1px solid #387f768c",
              }}
              onClick={() => handleFilterStatus(region)}
              key={region?.name}
            >
              {region?.name}
            </div>
          ))}
        </div>
      </div>
      <Layout.Spacer height={15} /> */}
      <div style={styles.flexRow}>
        <div style={styles.flexRow}>
          <div>
            <Text.Heading
              fontSize={"16px"}
              fontWeight={"500"}
              color={Colors.Primary}
              letterSpacing="1px"
              marginRight="30px"
              style={{ marginRight: "30px", letterSpacing: "1px" }}
              text="Filter by Country"
            />
          </div>
          <Select
            style={{ width: "200px" }}
            onChange={(e) => {
              setCountry(e);
            }}
            showSearch
            placeholder="Search Country"
            optionFilterProp="children"
            value={country}
            suffixIcon={
              country ? null : (
                <FaAngleDown color={Colors?.Primary} fontSize={18} />
              )
            }
            allowClear={{
              clearIcon: <MdCancel color={Colors?.Red} fontSize={14} />,
            }}
            className="currency-converter"
            filterOption={handleFilterCountry}
          >
            {Countries?.map((value, index) => {
              return (
                <Select.Option
                  value={value}
                  style={{ textTransform: "capitalize" }}
                  key={index}
                >
                  {value}
                </Select.Option>
              );
            })}
          </Select>
        </div>
        <Layout.Spacer width={20} />
        <div style={styles.flexRow}>
          <div>
            <Text.Heading
              fontSize={"16px"}
              fontWeight={"500"}
              color={Colors.Primary}
              letterSpacing="1px"
              marginRight="30px"
              style={{ marginRight: "30px", letterSpacing: "1px" }}
              text="Filter by Average fee"
            />
          </div>
          <div className="currency-converter">
            <Select
              defaultValue="USD"
              value={budget}
              onChange={(e) => setBudget(e)}
              style={{ width: "200px" }}
              suffixIcon={<FaAngleDown color={Colors?.Primary} fontSize={18} />}
            >
              <Select.Option value="0-100000000">All</Select.Option>
              <Select.Option value="0-20000">0 - 20,000 $</Select.Option>
              <Select.Option value="20000-40000">
                20,000 - 40,000 $
              </Select.Option>
              <Select.Option value="40000-60000">
                40,000 - 60,000 $
              </Select.Option>
              <Select.Option value="60000-80000">
                60,000 - 80,000 $
              </Select.Option>
              <Select.Option value="80000-100000000">
                {"> 80,000 $"}
              </Select.Option>
            </Select>
          </div>
        </div>
      </div>
      <Layout.Spacer height={15} />

      <Text.Heading
        marginRight="10px"
        fontSize={"18px"}
        fontWeight={"600"}
        color={Colors.Primary}
        text="Universities"
      />

      <Layout.Spacer height={20} />
      <div style={{ position: "relative" }}>
        <Table.AntDesign
          rowKey={(record) => record._id}
          columns={[
            {
              title: "Name",
              dataIndex: "name",
              width: 450,
              onCell: (record) => ({
                onClick: () => {
                  if (!blurData) {
                    navigate(`/universities/${record._id}/general`);
                  } else {
                    if (page > 1 || record.secondaryQs > 10) {
                      navigate(`/universities/filter`);
                    } else {
                      navigate(`/universities/${record._id}/general`);
                    }
                  }
                },
              }),
              render: (text, record) => (
                <ColumnDiv
                  style={
                    blurData && (page > 1 || record.secondaryQs > 10)
                      ? { filter: "blur(5px)" }
                      : {}
                  }
                >
                  <img src={record?.profilePic} alt="img" />
                  <span>
                    <Text.Heading
                      fontSize="12px"
                      fontWeight="600"
                      color="#081C15"
                      textTransform="capitalize"
                      textAlign="left"
                      text={record.name}
                    />
                    <span>
                      <MdLocationPin
                        color={Colors.GrayText}
                        style={{ marginRight: "5px" }}
                      />
                      <Text.Heading
                        fontSize="10px"
                        fontWeight="400"
                        color={Colors.GrayText}
                        textTransform="capitalize"
                        textAlign="left"
                        text={record.location}
                      />
                    </span>
                  </span>
                </ColumnDiv>
              ),
            },
            {
              title: "QS World Ranking",
              dataIndex: "QSWorldRanking",
              align: "center",
              width: 200,
              onCell: (record) => ({
                onClick: () => {
                  if (!blurData) {
                    navigate(`/universities/${record._id}/general`);
                  } else {
                    if (page > 1 || record.secondaryQs > 10) {
                      navigate(`/universities/filter`);
                    } else {
                      navigate(`/universities/${record._id}/general`);
                    }
                  }
                },
              }),
              render: (text, record) => (
                <div
                  style={
                    blurData && (page > 1 || record.secondaryQs > 10)
                      ? { filter: "blur(5px)", ...styles.universityTableText }
                      : { ...styles.universityTableText }
                  }
                >
                  {record?.summary?.qsWorldRanking}
                </div>
              ),
            },
            {
              title: !currency
                ? "Average Fee"
                : `Average Fee (${convertedCurrency})`,
              dataIndex: "averageFee",
              align: "center",
              width: 350,
              onCell: (record) => ({
                onClick: () => {
                  if (!blurData) {
                    navigate(`/universities/${record._id}/general`);
                  } else {
                    if (page > 1 || record.secondaryQs > 10) {
                      navigate(`/universities/filter`);
                    } else {
                      navigate(`/universities/${record._id}/general`);
                    }
                  }
                },
              }),
              render: (text, record) => {
                if (!currency) {
                  return (
                    <div
                      style={
                        blurData && (page > 1 || record.secondaryQs > 10)
                          ? {
                              filter: "blur(5px)",
                              ...styles.universityTableText,
                            }
                          : { ...styles.universityTableText }
                      }
                    >
                      {record?.summary?.fee_structure}
                    </div>
                  );
                } else {
                  const converted = convertCurrency(
                    record?.summary?.fee_structure,
                    convertedCurrency
                  );
                  return (
                    <div
                      style={
                        blurData && (page > 1 || record.secondaryQs > 10)
                          ? {
                              filter: "blur(5px)",
                              ...styles.universityTableText,
                            }
                          : { ...styles.universityTableText }
                      }
                    >
                      {converted}
                    </div>
                  );
                }
              },
            },
            {
              title: " ",
              dataIndex: "action",
              width: 150,
              render: (text, record) => {
                const containsValue = watchlistState.watchlists.some((obj) => {
                  return Object.values(obj.universityId).includes(record._id);
                });

                return (
                  <div
                    style={
                      blurData && (page > 1 || record.secondaryQs > 10)
                        ? {
                            filter: "blur(5px)",
                            ...styles.universityActions,
                            pointerEvents: "none",
                          }
                        : { ...styles.universityActions }
                    }
                  >
                    <BsBookmarkStarFill
                      fontSize={"26px"}
                      color={containsValue ? Colors.Primary : "#387f7662"}
                      cursor={"pointer"}
                      onClick={() => handleUniversityWishlist(record?._id)}
                      style={styles.mr5}
                    />
                    <AiFillRightCircle
                      fontSize={"30px"}
                      cursor={"pointer"}
                      color={Colors.Primary}
                      onClick={() =>
                        navigate(`/universities/${record._id}/general`)
                      }
                    />
                  </div>
                );
              },
            },
          ]}
          data={universityState.universities}
          loading={universityState.isLoading}
          pagination={false}
        />
        {blurData && page > 1 && (
          <div style={{ ...styles.premiumModal }}>
            <img src={premiumContent} />
            <p>
              To view more <b>Subscribe to our Pro Package</b>
            </p>
          </div>
        )}
      </div>
      <Layout.Spacer height={20} />
      <div style={styles.universityActions}>
        <Pagination
          responsive="true"
          showSizeChanger={false}
          hideOnSinglePage={true}
          current={page}
          pageSize={10}
          total={universityState.count}
          onChange={handlePaginationChange}
        />
      </div>
    </>
  );
}

export default Universities;

const ColumnDiv = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-start;
  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: contain;
    margin-right: 10px;
    background: white;
  }
  span {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    span:last-of-type {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
`;
