import { Colors } from "../../config";

const styles = {
  // buttons
  buttonStyle: {
    width: "200px",
    height: "40px",
    borderRadius: "6px",
    border: `1px solid ${Colors.Primary}`,
    fontFamily: "Montserrat",
    fontWeight: "500",
    fontSize: "15px",
    color: Colors.WhiteText,
    backgroundColor: Colors.Primary,
  },
  secondaryButtonStyle: {
    width: "200px",
    height: "40px",
    borderRadius: "6px",
    border: `1px solid ${Colors.Primary}`,
    fontSize: "16px",
    fontFamily: "Montserrat",
    fontWeight: "500",
    color: Colors.Primary,
    backgroundColor: Colors.White,
  },

  // flex styles
  flexColumn: {
    display: "flex",
    flexDirection: "column",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  flexRowCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  justifyRight: { display: "flex", justifyContent: "right" },

  textfieldStyle: {
    borderRadius: "6px",
    border: `1px solid ${Colors.Primary}`,
    padding: "5px",
    width: "100%",
    fontSize: "14px",
    fontWeight: "400",
    color: Colors.BlackText,
  },
  labelStyle: {
    textAlign: "left",
    color: Colors.BlackText,
    fontWeight: "400",
    fontSize: "16px",
  },

  // edit profile
  inputContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 3fr",
    gridColumnGap: "40px",
  },
  emailText: {
    color: Colors.GrayText,
    fontSize: "12px",
    fontWeight: "600",
    textAlign: "center",
  },

  positionRelative: {
    position: "relative",
  },
  profileImg: {
    width: "120px",
    height: "120px",
    objectFit: "cover",
    borderRadius: "100%",
    border: `1px solid ${Colors.Primary}`,
    padding: "0px",
  },
  imageUploadDiv: {
    position: "absolute",
    bottom: "0",
    right: "0",
    border: `1px solid ${Colors.Primary}`,
    borderRadius: "50px",
    padding: "2.5px 5px",
    background: "white",
    cursor: "pointer",
  },
  inputDisplayNone: {
    display: "none",
  },
  leftSideContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridColumnGap: "40px",
    gridRowGap: "20px",
  },
};

export default styles;
