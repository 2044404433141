const Currency = [
  { name: "AFA", value: "AFA" },
  { name: "ALL", value: "ALL" },
  { name: "DZD", value: "DZD" },
  { name: "AOA", value: "AOA" },
  { name: "ARS", value: "ARS" },
  { name: "AMD", value: "AMD" },
  { name: "AWG", value: "AWG" },
  { name: "AUD", value: "AUD" },
  { name: "AZN", value: "AZN" },
  { name: "BSD", value: "BSD" },
  { name: "BHD", value: "BHD" },
  { name: "BDT", value: "BDT" },
  { name: "BBD", value: "BBD" },
  { name: "BYR", value: "BYR" },
  { name: "BEF", value: "BEF" },
  { name: "BZD", value: "BZD" },
  { name: "BMD", value: "BMD" },
  { name: "BTN", value: "BTN" },
  { name: "BTC", value: "BTC" },
  { name: "BOB", value: "BOB" },
  { name: "BAM", value: "BAM" },
  { name: "BWP", value: "BWP" },
  { name: "BRL", value: "BRL" },
  { name: "GBP", value: "GBP" },
  { name: "BND", value: "BND" },
  { name: "BGN", value: "BGN" },
  { name: "BIF", value: "BIF" },
  { name: "KHR", value: "KHR" },
  { name: "CAD", value: "CAD" },
  { name: "CVE", value: "CVE" },
  { name: "KYD", value: "KYD" },
  { name: "XOF", value: "XOF" },
  { name: "XAF", value: "XAF" },
  { name: "XPF", value: "XPF" },
  { name: "CLP", value: "CLP" },
  { name: "CLF", value: "CLF" },
  { name: "CNY", value: "CNY" },
  { name: "COP", value: "COP" },
  { name: "KMF", value: "KMF" },
  { name: "CDF", value: "CDF" },
  { name: "CRC", value: "CRC" },
  { name: "HRK", value: "HRK" },
  { name: "CUC", value: "CUC" },
  { name: "CZK", value: "CZK" },
  { name: "DKK", value: "DKK" },
  { name: "DJF", value: "DJF" },
  { name: "DOP", value: "DOP" },
  { name: "XCD", value: "XCD" },
  { name: "EGP", value: "EGP" },
  { name: "ERN", value: "ERN" },
  { name: "EEK", value: "EEK" },
  { name: "ETB", value: "ETB" },
  { name: "EUR", value: "EUR" },
  { name: "FKP", value: "FKP" },
  { name: "FJD", value: "FJD" },
  { name: "GMD", value: "GMD" },
  { name: "GEL", value: "GEL" },
  { name: "DEM", value: "DEM" },
  { name: "GHS", value: "GHS" },
  { name: "GIP", value: "GIP" },
  { name: "GRD", value: "GRD" },
  { name: "GTQ", value: "GTQ" },
  { name: "GNF", value: "GNF" },
  { name: "GYD", value: "GYD" },
  { name: "HTG", value: "HTG" },
  { name: "HNL", value: "HNL" },
  { name: "HKD", value: "HKD" },
  { name: "HUF", value: "HUF" },
  { name: "ISK", value: "ISK" },
  { name: "INR", value: "INR" },
  { name: "IDR", value: "IDR" },
  { name: "IRR", value: "IRR" },
  { name: "IQD", value: "IQD" },
  { name: "ILS", value: "ILS" },
  { name: "ITL", value: "ITL" },
  { name: "JMD", value: "JMD" },
  { name: "JPY", value: "JPY" },
  { name: "JOD", value: "JOD" },
  { name: "KZT", value: "KZT" },
  { name: "KES", value: "KES" },
  { name: "KWD", value: "KWD" },
  { name: "KGS", value: "KGS" },
  { name: "LAK", value: "LAK" },
  { name: "LVL", value: "LVL" },
  { name: "LBP", value: "LBP" },
  { name: "LSL", value: "LSL" },
  { name: "LRD", value: "LRD" },
  { name: "LYD", value: "LYD" },
  { name: "LTC", value: "LTC" },
  { name: "LTL", value: "LTL" },
  { name: "MOP", value: "MOP" },
  { name: "MKD", value: "MKD" },
  { name: "MGA", value: "MGA" },
  { name: "MWK", value: "MWK" },
  { name: "MYR", value: "MYR" },
  { name: "MVR", value: "MVR" },
  { name: "MRO", value: "MRO" },
  { name: "MUR", value: "MUR" },
  { name: "MXN", value: "MXN" },
  { name: "MDL", value: "MDL" },
  { name: "MNT", value: "MNT" },
  { name: "MAD", value: "MAD" },
  { name: "MZM", value: "MZM" },
  { name: "MMK", value: "MMK" },
  { name: "NAD", value: "NAD" },
  { name: "NPR", value: "NPR" },
  { name: "ANG", value: "ANG" },
  { name: "TWD", value: "TWD" },
  { name: "NZD", value: "NZD" },
  { name: "NIO", value: "NIO" },
  { name: "NGN", value: "NGN" },
  { name: "KPW", value: "KPW" },
  { name: "NOK", value: "NOK" },
  { name: "OMR", value: "OMR" },
  { name: "PKR", value: "PKR" },
  { name: "PAB", value: "PAB" },
  { name: "PGK", value: "PGK" },
  { name: "PYG", value: "PYG" },
  { name: "PEN", value: "PEN" },
  { name: "PHP", value: "PHP" },
  { name: "PLN", value: "PLN" },
  { name: "QAR", value: "QAR" },
  { name: "RON", value: "RON" },
  { name: "RUB", value: "RUB" },
  { name: "RWF", value: "RWF" },
  { name: "SVC", value: "SVC" },
  { name: "WST", value: "WST" },
  { name: "STD", value: "STD" },
  { name: "SAR", value: "SAR" },
  { name: "RSD", value: "RSD" },
  { name: "SCR", value: "SCR" },
  { name: "SLL", value: "SLL" },
  { name: "SGD", value: "SGD" },
  { name: "SKK", value: "SKK" },
  { name: "SBD", value: "SBD" },
  { name: "SOS", value: "SOS" },
  { name: "ZAR", value: "ZAR" },
  { name: "KRW", value: "KRW" },
  { name: "SSP", value: "SSP" },
  { name: "XDR", value: "XDR" },
  { name: "LKR", value: "LKR" },
  { name: "SHP", value: "SHP" },
  { name: "SDG", value: "SDG" },
  { name: "SRD", value: "SRD" },
  { name: "SZL", value: "SZL" },
  { name: "SEK", value: "SEK" },
  { name: "CHF", value: "CHF" },
  { name: "SYP", value: "SYP" },
  { name: "TJS", value: "TJS" },
  { name: "TZS", value: "TZS" },
  { name: "THB", value: "THB" },
  { name: "TOP", value: "TOP" },
  { name: "TTD", value: "TTD" },
  { name: "TND", value: "TND" },
  { name: "TRY", value: "TRY" },
  { name: "TMT", value: "TMT" },
  { name: "UGX", value: "UGX" },
  { name: "UAH", value: "UAH" },
  { name: "AED", value: "AED" },
  { name: "UYU", value: "UYU" },
  { name: "USD", value: "USD" },
  { name: "UZS", value: "UZS" },
  { name: "VUV", value: "VUV" },
  { name: "VEF", value: "VEF" },
  { name: "VND", value: "VND" },
  { name: "YER", value: "YER" },
  { name: "ZMK", value: "ZMK" },
  { name: "ZWL", value: "ZWL" },
];

export default Currency;
