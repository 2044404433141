import { Colors } from "../../config";

const styles = {
  // buttons
  buttonStyle: {
    width: "200px",
    height: "40px",
    borderRadius: "6px",
    border: `1px solid ${Colors.Primary}`,
    fontFamily: "Montserrat",
    fontWeight: "500",
    fontSize: "15px",
    color: Colors.WhiteText,
    backgroundColor: Colors.Primary,
  },

  // flex styles
  flexColumn: {
    display: "flex",
    flexDirection: "column",
  },

  textfieldStyle: {
    borderRadius: "6px",
    border: `1px solid ${Colors.Primary}`,
    padding: "5px",
    width: "100%",
    fontSize: "14px",
    fontWeight: "400",
    color: Colors.BlackText,
  },
  labelStyle: {
    textAlign: "left",
    color: Colors.BlackText,
    fontWeight: "400",
    fontSize: "16px",
  },
};

export default styles;
