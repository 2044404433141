import { put } from "redux-saga/effects";

import { ApiCaller } from "../../config";
import { ApplicationAction } from "../actions";

export default class ApplicationMiddleware {
  static *addApplications({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post(`/school/add-applications`, payload);
      if (res.status === 200) {
        yield put(ApplicationAction.addApplicationsSuccess(res.data.data));
      } else {
        yield put(ApplicationAction.addApplicationsFailure());
      }
      if (cb) {
        cb(res);
      }
    } catch (err) {
      yield put(ApplicationAction.addApplicationsFailure());
    }
  }

  static *getApplicationsStudent({ payload, cb }) {
    try {
      const { limit, offset, studentId } = payload;
      const res = yield ApiCaller.Get(
        `/school/fetch-applications-student?studentId=${studentId}`
      );
      if (res.status === 200) {
        yield put(
          ApplicationAction.getApplicationsSuccessStudent(res.data.data)
        );
      } else {
        yield put(ApplicationAction.getApplicationsSuccessStudent([]));
      }
      if (cb) {
        cb(res);
      }
    } catch (err) {
      yield put(ApplicationAction.getApplicationsFailure());
    }
  }
}
