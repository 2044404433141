import {
  GET_UNIVERSITIES,
  GET_UNIVERSITIES_FAILURE,
  GET_UNIVERSITIES_SUCCESS,
  GET_ALL_UNIVERSITIES,
  GET_ALL_UNIVERSITIES_FAILURE,
  GET_ALL_UNIVERSITIES_SUCCESS,
  GET_UNIVERSITY_DETAILS,
  GET_UNIVERSITY_DETAILS_FAILURE,
  GET_UNIVERSITY_DETAILS_SUCCESS,
  COMPARE_UNIVERSITIES,
  COMPARE_UNIVERISTIES_SUCCESS,
  COMPARE_UNIVERISTIES_FAILURE,
} from "../constants";

export default class UniversityAction {
  static getUniversities(payload, cb) {
    return {
      type: GET_UNIVERSITIES,
      payload,
      cb,
    };
  }
  static getUniversitiesSuccess(payload) {
    return {
      type: GET_UNIVERSITIES_SUCCESS,
      payload,
    };
  }
  static getUniversitiesFailure(payload) {
    return {
      type: GET_UNIVERSITIES_FAILURE,
      payload,
    };
  }
  static getAllUniversities(payload, cb) {
    return {
      type: GET_ALL_UNIVERSITIES,
      payload,
      cb,
    };
  }
  static getAllUniversitiesSuccess(payload) {
    return {
      type: GET_ALL_UNIVERSITIES_SUCCESS,
      payload,
    };
  }
  static getAllUniversitiesFailure(payload) {
    return {
      type: GET_ALL_UNIVERSITIES_FAILURE,
      payload,
    };
  }
  static getUniversityDetails(id, cb) {
    return {
      type: GET_UNIVERSITY_DETAILS,
      id,
      cb,
    };
  }
  static getUniversityDetailsSuccess(payload) {
    return {
      type: GET_UNIVERSITY_DETAILS_SUCCESS,
      payload,
    };
  }
  static getUniversityDetailsFailure(payload) {
    return {
      type: GET_UNIVERSITY_DETAILS_FAILURE,
      payload,
    };
  }

  static compareUniverities(payload, cb) {
    return {
      type: COMPARE_UNIVERSITIES,
      payload,
      cb,
    };
  }
  static compareUniveritiesSuccess(payload) {
    return {
      type: COMPARE_UNIVERISTIES_SUCCESS,
      payload,
    };
  }
  static compareUniveritiesFailure(payload) {
    return {
      type: COMPARE_UNIVERISTIES_FAILURE,
      payload,
    };
  }
}
