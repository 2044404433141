import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Menu } from "antd";

import { AiFillHome } from "react-icons/ai";
import { BsFillBookmarkStarFill } from "react-icons/bs";
import { CgMoreO } from "react-icons/cg";
import { FaWpforms } from "react-icons/fa";
import { MdSchool } from "react-icons/md";
import { TbLogout } from "react-icons/tb";

import { Images } from "../../config";
import { AuthAction } from "../../store/actions";

import styles from "./styles";
import "./sidebar.css";

const StudentSidebar = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const title = ((location || {}).pathname || {}).split("/")[1];

  const studentType = useSelector(
    (state) => state.auth?.user?.user?.studentType
  );

  useEffect(() => {
    const menu = document.querySelector(".ant-menu").children;
    for (let i = 0; i < menu.length; i++) {
      if (menu[i].innerText.toLowerCase() === title) {
        menu[i].classList.add("ant-menu-item-selected");
      } else {
        menu[i].classList.remove("ant-menu-item-selected");
      }
    }
  }, [title]);

  const handleLogout = () => {
    dispatch(AuthAction.Logout());
  };

  const highlighter = (value) => {
    return title === value ? "menu-item ant-menu-item-selected" : "menu-item";
  };

  const renderMainNavOptions = () => {
    return (
      <>
        <Menu.Item
          className={highlighter("home")}
          key="1"
          id="home"
          icon={<AiFillHome style={styles.sidebarIcon} />}
        >
          <Link to="/home">Home</Link>
        </Menu.Item>

        <Menu.Item
          className={highlighter("universities")}
          id="universities"
          key="2"
          icon={<MdSchool style={styles.sidebarIcon} />}
        >
          <Link to="/universities/filter/1">Universities</Link>
        </Menu.Item>
        <Menu.Item
          className={highlighter("watchlist")}
          id="watchlist"
          key="3"
          icon={<BsFillBookmarkStarFill style={styles.sidebarIcon} />}
        >
          <Link to="/watchlist">Watchlist</Link>
        </Menu.Item>
        {studentType && studentType !== "individualStudent" && (
          <Menu.Item
            className={highlighter("meetings")}
            id="meetings"
            key="4"
            icon={
              <img
                src={Images.MeetingIcon}
                style={{
                  fontSize: "20px",
                  marginRight: "15px",
                }}
                alt="meetingIcon"
              />
            }
          >
            <Link to="/meetings">Meetings</Link>
          </Menu.Item>
        )}
        <Menu.Item
          className={highlighter("applications")}
          id="applications"
          key="5"
          icon={<FaWpforms style={styles.sidebarIcon} />}
        >
          <Link to="/applications">Applications</Link>
        </Menu.Item>
        <Menu.Item
          className={highlighter("recommender")}
          id="recommender"
          key="6"
          icon={
            <>
              {studentType && studentType === "individualStudent" && (
                <img src={Images.ProIcon} style={styles.proIconSidebar} />
              )}
              <img
                src={Images.RecommenderIcon}
                style={{ ...styles.sidebarIcon2, marginRight: "25px" }}
              />
            </>
          }
        >
          <Link to="/recommender">AI Recommender</Link>
        </Menu.Item>

        <Menu.Item
          className={highlighter("essay")}
          id="more"
          key="7"
          icon={
            <>
              {studentType && studentType === "individualStudent" && (
                <img src={Images.ProIcon} style={styles.proIconSidebar} />
              )}
              <img
                src={Images.Essay}
                style={{ ...styles.sidebarIcon2, marginRight: "25px" }}
              />
            </>
          }
        >
          <Link to="/essay">Essay Grading</Link>
        </Menu.Item>

        <Menu.Item
          className={highlighter("interactiveLearning")}
          id="more"
          key="8"
          icon={
            <>
              {studentType && studentType === "individualStudent" && (
                <img src={Images.ProIcon} style={styles.proIconSidebar} />
              )}
              <img
                src={Images.InteractiveLearning}
                style={{ ...styles.sidebarIcon2, marginRight: "25px" }}
              />
            </>
          }
        >
          <Link to="/interactiveLearning">Interactive Learning</Link>
        </Menu.Item>

        {studentType && studentType === "individualStudent" && (
          <Menu.Item
            className={highlighter("refer")}
            id="meetings"
            key="4"
            icon={
              <img
                src={Images.ReferAFriend}
                style={{
                  height: "24px",
                  marginRight: "15px",
                }}
                alt="referIcon"
              />
            }
          >
            <Link to="/refer">Refer a Friend</Link>
          </Menu.Item>
        )}

        <Menu.Item
          className={highlighter("more")}
          id="more"
          key="9"
          icon={<CgMoreO style={styles.sidebarIcon} />}
        >
          <Link to="/more">More</Link>
        </Menu.Item>
      </>
    );
  };

  return (
    <div className="sidebar">
      <div style={styles.sidebarLogoContainer}>
        <img
          src={Images.Logo}
          style={styles.sidebarImage}
          onClick={() => navigate("/home")}
        />
      </div>
      <Menu
        theme="dark"
        mode="inline"
        className="sidebar-menu"
        style={{ overflow: "auto", marginBottom: "70px" }}
      >
        {renderMainNavOptions()}
      </Menu>
      <Menu theme="dark" mode="inline" className="sidebar-menu">
        <Menu.Item
          className="menu-item sidebar-logout"
          key="12"
          icon={<TbLogout style={styles.sidebarIcon} />}
        >
          <Link to="/login" onClick={handleLogout}>
            Log out
          </Link>
        </Menu.Item>
      </Menu>
    </div>
  );
};
export default StudentSidebar;
