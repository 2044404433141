import React from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";

import { FiMap } from "react-icons/fi";

import { Layout, Text } from "../../components";
import { Colors, Images } from "../../config";

import styles from "./styles";

function More() {
  const navigate = useNavigate();

  const studentType = useSelector((state) => state.auth.user.user.studentType);

  const items = [
    {
      id: 1,
      image: <img src={Images.Chatbot} style={styles.icons} alt="chatbot" />,
      title: "Chatbot",
      route: "/more/chatbot",
    },
    {
      id: 2,
      image: (
        <FiMap
          style={{
            color: Colors.Primary,
            fontSize: "32px",
          }}
        />
      ),
      title: "University Virtual Tour",
      route: "/more/tour",
    },
    {
      id: 3,
      image: <img src={Images.Tracker} style={styles.icons} alt="tracker" />,
      title: "Application Status Tracker",
      route: "/more/statusTracker",
    },
    {
      id: 5,
      image: (
        <img src={Images.Marketplace} style={styles.icons} alt="marketplace" />
      ),
      title: "Marketplace",
      route: "/more/marketplace",
    },
    {
      id: 6,
      image: (
        <img src={Images.Etextbook} style={styles.icons} alt="eTextBooks" />
      ),
      title: "E-Text Books",
      route: "/more/eTextBook",
    },
  ];

  const itemsIndividual = [
    {
      id: 2,
      image: (
        <FiMap
          style={{
            color: Colors.Primary,
            fontSize: "32px",
          }}
        />
      ),
      title: "University Virtual Tour",
      route: "/more/tour",
    },
    {
      id: 3,
      image: <img src={Images.Tracker} style={styles.icons} alt="tracker" />,
      title: "Application Status Tracker",
      route: "/more/statusTracker",
    },
    {
      id: 5,
      image: (
        <img src={Images.Marketplace} style={styles.icons} alt="marketplace" />
      ),
      title: "Marketplace",
      route: "/more/marketplace",
    },
    {
      id: 6,
      image: (
        <img src={Images.Etextbook} style={styles.icons} alt="eTextBooks" />
      ),
      title: "E-Text Books",
      route: "/more/eTextBook",
    },
    {
      id: 7,
      image: (
        <img
          src={Images.MeetingIconGreen}
          style={styles.icons}
          alt="counselorAccess"
        />
      ),
      title: "Counselor Access",
      route: "/more/counselorAccess",
    },
  ];

  return (
    <div>
      <div>
        <Text.Heading
          fontSize={"18px"}
          fontWeight={"600"}
          color={Colors.Primary}
          text="More"
        />
      </div>
      <Layout.Spacer height={30} />
      <div style={styles.moreContainer}>
        {studentType !== "individualStudent" ? (
          <>
            {items.map((item) => (
              <div
                style={styles.moreDiv}
                onClick={() => navigate(item.route)}
                key={item.id}
              >
                {item.image}
                <Layout.Spacer height={10} />
                <Text.Heading
                  fontSize={"15px"}
                  fontWeight={"600"}
                  color={Colors.Primary}
                  textAlign="left"
                  text={item.title}
                />
              </div>
            ))}
          </>
        ) : (
          <>
            {itemsIndividual.map((item) => (
              <div
                style={{
                  ...styles.moreDiv,
                  pointerEvents: "none",
                  position: "relative",
                  overflow: "hidden",
                }}
                onClick={() => navigate(item.route)}
                key={item.id}
              >
                <div style={styles.comingSoon}>Coming Soon</div>
                <div style={{ opacity: "0.5" }}>{item.image}</div>
                <Layout.Spacer height={10} />
                <div style={{ opacity: "0.5" }}>
                  <Text.Heading
                    fontSize={"15px"}
                    fontWeight={"600"}
                    color={Colors.Primary}
                    textAlign="left"
                    text={item.title}
                  />
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
}

export default More;
