import { Colors } from "../../config";

const styles = {
  moreContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gridColumnGap: "40px",
    gridRowGap: "40px",
  },
  moreDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    height: "130px",
    width: "100%",
    background: "#F5F5F5",
    borderRadius: "30px",
    boxShadow: "0px 1px 16px -3px rgba(0,0,0,0.46)",
    cursor: "pointer",
  },
  icons: {
    height: "34px",
    width: "34px",
  },
  comingSoon: {
    position: "absolute",
    top: "25px",
    left: "-65px",
    transform: "rotateY(0deg) rotate(-45deg)",
    padding: "5px",
    background: "orange",
    fontSize: "10px",
    fontWeight: "700",
    width: "200px",
    textAlign: "center ",
    opacity: "1",
    color: "black",
  },
};

export default styles;
