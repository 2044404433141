import { MockData } from "../../config";

const convertCurrency = (currencyString, targetCurrency) => {
  if (!currencyString || !targetCurrency) {
    return "NA";
  }
  const replacedCurrencyString = currencyString.replace(/–/g, "-");
  const regex = /([A-Z]+)\s*([\d,]+)(?:\s*-\s*([\d,]+))?/;
  const matches = replacedCurrencyString.match(regex);

  if (!matches) {
    console.log("Invalid salary string format.");
    return "NA";
  }

  const currencyCode = matches[1];
  const value1 = parseInt(matches[2].replace(/,/g, ""));
  const value2 = matches[3] ? parseInt(matches[3].replace(/,/g, "")) : null;

  const sourceCurrency = MockData.Currencies.find(
    (c) => c.currency === currencyCode
  );
  const targetCurrencyRate = MockData.Currencies.find(
    (c) => c.currency === targetCurrency
  );

  if (!sourceCurrency || !targetCurrencyRate) {
    console.log("Currency not found in the conversion rates.");
    return "NA";
  }

  const value1InUSD = value1 * sourceCurrency.amount;
  const value2InUSD = value2 ? value2 * sourceCurrency.amount : null;

  const value1InTargetCurrency = value1InUSD / targetCurrencyRate.amount;
  const value2InTargetCurrency = value2InUSD
    ? value2InUSD / targetCurrencyRate.amount
    : null;

  const value1Fixed = value1InTargetCurrency?.toLocaleString().split(".")[0];
  const value2Fixed = value2InTargetCurrency?.toLocaleString().split(".")[0];

  let currencyStringTarget = "";
  if (value2InTargetCurrency !== null) {
    currencyStringTarget = `${targetCurrency} ${value1Fixed}-${value2Fixed}`;
  } else {
    currencyStringTarget = `${currencyString.replace(
      matches[0],
      `${targetCurrency} ${value1Fixed}`
    )}`;
  }

  // console.log(currencyStringTarget);
  return currencyStringTarget;
};

export default convertCurrency;
