import { Colors } from "../../config";

const styles = {
  flexBetweenAlignCenter: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  flexRowCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  flexColumnCenter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  flexSpaceBetween: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },

  dashboard: {
    display: "flex",
    justifyContent: "space-between",
    margin: "auto",
  },
  dashboardLeftPart: { width: "67%" },
  dashboardRightPart: { marginLeft: "60px", width: "30%" },

  nameContainer: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
  },

  universityDropdown: {
    color: Colors.Primary,
    width: "100%",
    borderColor: Colors.Primary,
    borderRadius: "10px",
    padding: "10px",
    textTransform: "capitalize",
    outline: "none",
  },
  promotionalPhoto: { height: "175px", width: "350px" },

  announcementDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    overflowX: "scroll",
    height: "120px",
    flexWrap: "nowrap",
  },
  announcementDivHeader: {
    marginTop: "20px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    flexWrap: "nowrap",
  },
  announcementContainer: {
    display: "flex",
    flexDirection: "column",
    background: Colors.LightGray,
    borderRadius: "10px",
    padding: "20px",
    marginRight: "20px",
    height: "100px",
    width: "100%",
  },

  analyticsContainer: {
    display: "flex",
    flexDirection: "column",
    background: Colors.LightGray,
    borderRadius: "10px",
    padding: "20px",
    justifyContent: "center",
    height: "110px",
    width: "100%",
  },
  analyticsBox: { display: "flex", justifyContent: "space-between" },
  analyticsTextDiv: { display: "flex", flexDirection: "column" },
  analyticsImageDiv: { display: "flex", alignItems: "center" },

  cursor: {
    cursor: "pointer",
  },

  dateRangeButton: {
    color: "black",
    fontWeight: "600",
    fontSize: "12px",
    cursor: "pointer",
  },
  meetingDatePickerDiv: {
    animation: "slide-down 0.3s ease",
  },
  meetingDatePickerText: {
    color: "black",
    fontWeight: "600",
    fontSize: "12px",
    textAlign: "right",
    paddingRight: "10px",
  },
  meetingDatePicker: {
    width: "100%",
    fontSize: "12px",
    fontWeight: "400",
    color: Colors.BlackText,
    height: "25px",
    borderColor: Colors.Primary,
    borderRadius: "7px",
    padding: "10px",
  },
  meetingSubmitButton: {
    padding: "5px 10px",
    backgroundColor: Colors.Primary,
    borderRadius: "10px",
    width: "100px",
    textAlign: "center",
    cursor: "pointer",
  },
  noMeeting: {
    color: "black",
    fontSize: "14px",
    fontWeight: "500",
  },
  meetingResultsContainer: { maxHeight: "220px", overflowY: "auto" },

  courseDivContainer: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
    gridGap: "20px",
    overflowX: "auto",
    height: "230px",
  },
  courseDiv: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    width: "180px",
    background: "#F5F5F5",
    borderRadius: "10px",
    boxShadow: "0px 6px 18px -4px rgba(0,0,0,0.4)",
    cursor: "pointer",
  },
  courseImage: {
    width: "100%",
    height: "auto",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
  },
  coursePrice: {
    color: Colors.White,
    background: Colors.Primary,
    padding: "5px 10px",
    fontSize: "12px",
    fontWeight: "600",
    width: "100%",
    height: "25px",
  },
  courseInstructor: {
    color: "black",
    padding: "5px",
    fontSize: "12px",
    fontWeight: "400",
    width: "100%",
    padding: "5px 10px",
  },
  courseTitle: {
    color: "black",
    fontSize: "12px",
    fontWeight: "600",
    width: "100%",
    padding: "10px 10px",
  },
  courseHeadline: {
    color: Colors.GrayText,
    padding: "10px",
    fontSize: "10px",
    fontWeight: "400",
    width: "100%",
    height: "60px",
  },
  // modal
  modalInputContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridGap: "20px",
    textAlign: "left",
  },
  textfieldStyle: {
    borderRadius: "100px",
    border: `1px solid #dfdfdd`,
    height: "36px",
    width: "100%",
    fontSize: "14px",
    fontWeight: "400",
    backgroundColor: "#f7f6f2",
    color: Colors.BlackText,
  },
  labelStyle: {
    textAlign: "left",
    color: Colors.BlackText,
    fontWeight: "400",
    fontSize: "16px",
  },
  fileInput: {
    width: "100%",
    border: `1px solid ${Colors.Primary}`,
    borderRadius: "6px",
    padding: "6px",
    color: "black",
    fontSize: "14px",
    display: "flex",
    justifyContent: "space-between",
  },
  modalButton: {
    width: "250px",
    backgroundColor: Colors.Primary,
    fontWeight: "500",
    fontSize: "14px",
    borderRadius: "50px",
  },
  freeTrialText: {
    color: Colors.GrayText,
    fontSize: "10px",
    fontWeight: "600",
    textDecoration: "underline",
    opacity: "0.6",
    cursor: "pointer",
  },
  successText: {
    fontSize: "20px",
    fontWeight: "700",
    color: "black",
    marginBottom: "10px",
  },
  modalDetails: {
    fontSize: "16px",
    fontWeight: "500",
    color: "black",
    marginBottom: "10px",
    textAlign: "center",
  },

  labelStyle: { fontSize: "14px", fontWeight: "600", paddingLeft: "10px" },

  //timer
  timer: {
    height: "119px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 40px",
  },
  timerLeftPart: {
    lineHeight: "1.2",
  },
  timerRightPart: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: ".5rem",
    fontSize: "17px",
    fontWeight: "700",
  },
  timerRightPartMain: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: ".3rem",
  },
  timerRightPart1: {
    width: "43px",
    height: "48px",
    backgroundColor: "#FBBD14",
    borderRadius: "4px",
    color: Colors.Black,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  timerRightPart2: {
    fontSize: "13px",
    fontWeight: "500",
  },
};

export default styles;
