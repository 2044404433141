import { Colors } from "../../config";

const styles = {
  flexRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  flexRowCenter: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  flexSpaceBetween: {
    display: "flex",
    justifyContent: "space-between",
  },
  mr10: {
    marginRight: "10px",
  },
  mr5: {
    marginRight: "5px",
  },
  wishlistTableText: {
    fontSize: "12px",
    fontWeight: "500",
    color: Colors.BlackText,
  },
  flexContent: {
    position: "relative",
  },
  premiumModal: {
    backgroundColor: "white",
    position: "absolute",
    // top: '70%',
    left: "30%",
    // transform: "translate(-50%, -30%)",
    padding: "25px 12px",
    zIndex: "8",
    display: "flex",
    flexDirection: "column",
    gap: "1.6rem",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "10px",
    width: "48%",
    color: "black",
  },
};

export default styles;
