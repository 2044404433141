import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker, Input, Radio, Select } from "antd";

import { CgAttachment } from "react-icons/cg";

import { Button, Text, TextField, Loader, Layout } from "../../components";
import { Colors } from "../../config";
import {
  MeetingAction,
  StudentAction,
  CounselorAction,
} from "../../store/actions";

import styles from "./styles";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

const { TextArea } = Input;
const plainOptions = ["Online", "Offline"];

const disabledDate = (current) => {
  return current < dayjs().startOf("day");
};

const NewMeetings = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fileSelect = useRef();

  const studentId = useSelector((state) => state.auth.user.user.studentId);
  const schoolIdState = useSelector((state) => state.auth.user.user.schoolId);
  const counsellor = useSelector((state) => state?.counselors);

  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [error, setError] = useState("");

  const [value1, setValue1] = useState(false);
  const [subject, setSubject] = useState("");
  const [counselor, setCounselor] = useState("");
  const [meetingDate, setMeetingDate] = useState("");
  const [meetingLink, setMeetingLink] = useState(
    location.state.edit === true ? location.state.data.meetingLink : ""
  );
  const [timeSlot, setTimeSlot] = useState(
    location.state.edit === true
      ? location.state.data.timeSlot
      : "12:00 AM - 12:30 AM"
  );
  const [message, setMessage] = useState(
    location.state.edit === true ? location.state.data.message : ""
  );
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [buttonText, setButtonText] = useState("Schedule");

  useEffect(() => {
    dispatch(StudentAction.getStudentDetails(studentId));
  }, []);

  useEffect(() => {
    const payload = {
      id: schoolIdState,
    };
    dispatch(CounselorAction.getCounselors(payload));
  }, []);

  const onChange1 = ({ target: { value } }) => {
    setValue1(value);
  };
  const onDateChange = (date, dateString) => {
    setMeetingDate(dateString);
  };

  const defaultStates = () => {
    setLoading(false);
    setButtonDisabled(false);
    setButtonText("Schedule");
  };

  const scheduleMeeting = () => {
    setLoading(true);
    setButtonDisabled(true);
    setButtonText("Scheduling...");

    if (location.state.edit === true) {
      if (meetingDate === "" || timeSlot === "" || message === "") {
        setError("Please fill all the fields.");
        defaultStates();
      } else {
        setError("");

        const body = {
          subject: location.state.data.subject,
          timeSlot,
          meetingDate,
          message,
          status: "Rescheduled",
        };

        let data = new FormData();
        data.append("timeSlot", timeSlot);
        data.append("meetingDate", meetingDate);
        data.append("status", "Rescheduled");
        data.append("message", message);
        data.append("meetingId", location.state.data._id);
        data.append("edit", location.state.edit === true ? "true" : "false");

        dispatch(
          MeetingAction.addMeeting(data, () => {
            defaultStates();
            navigate("/meetings/meetingdetails", {
              state: { data: body, sent: "true" },
            });
          })
        );
      }
    } else {
      if (
        subject === "" ||
        meetingDate === "" ||
        counselor === "" ||
        timeSlot === "" ||
        message === "" ||
        value1 === false
      ) {
        setError("Please fill all the required fields.");
        defaultStates();
      } else {
        setError("");

        const body = {
          subject,
          timeSlot,
          meetingDate,
          message,
          counselor,
          meetingLink,
          meetingType: value1,
          status: "Pending",
        };

        let data = new FormData();
        data.append("timeSlot", timeSlot);
        data.append("meetingDate", meetingDate);
        data.append("subject", subject);
        data.append("counselor", counselor);
        data.append("status", "Pending");
        data.append("studentId", studentId);
        data.append("organizer", "Student");
        data.append("schoolId", schoolIdState);
        data.append("file", selectedFile);
        data.append("message", message);
        data.append("meetingType", value1);
        data.append("meetingLink", meetingLink);
        data.append("edit", location.state.edit === true ? "true" : "false");

        dispatch(
          MeetingAction.addMeeting(data, () => {
            defaultStates();
            navigate("/meetings/meetingdetails", {
              state: { data: body, sent: "true" },
            });
          })
        );
      }
    }
  };

  return (
    <>
      <Text.Heading
        fontSize={"18px"}
        fontWeight={"600"}
        color={Colors.Primary}
        text={location.state.title}
      />
      {loading ? (
        <Loader.Circular />
      ) : (
        <div>
          <Layout.Spacer height={10} />
          {location.state.title === "New Meeting" && (
            <div>
              <Text.Heading
                fontSize={"16px"}
                fontWeight={"500"}
                color={Colors.Primary}
                text="Schedule new meeting"
              />
              <Layout.Spacer height={5} />
              <Text.Heading
                fontSize={"15px"}
                fontWeight={"400"}
                color={Colors.Black}
                text="Do you need any guidance regarding admissions from your College counselor, schedule a meeting now."
              />
              <Layout.Spacer height={20} />
            </div>
          )}

          <Text.Heading
            text="Select Counselor*"
            style={
              location.state.edit === true
                ? { ...styles.boxHeader, opacity: "0.8" }
                : { ...styles.boxHeader, opacity: "1" }
            }
          />
          {counsellor?.isLoading ? (
            <Loader.Circular />
          ) : (
            <>
              {location.state.edit === true ? (
                <div style={{ width: "100%" }}>
                  <div style={styles.counselorDisabled}>
                    {location.state.data.counselor.fullName
                      ? location.state.data.counselor.fullName
                      : "NA"}
                  </div>
                </div>
              ) : (
                <div style={{ width: "100%" }}>
                  <div className="counselor-select">
                    <Select
                      style={styles.counselorDropdown}
                      showSearch
                      placeholder="Search Counselor "
                      onChange={(e) => setCounselor(e)}
                    >
                      {counsellor?.counselors?.map((item, index) => {
                        if (item.position === "counselor")
                          return (
                            <Select.Option
                              value={item.userId}
                              key={item.userId}
                            >
                              {item.name}
                            </Select.Option>
                          );
                      })}
                    </Select>
                  </div>
                </div>
              )}
            </>
          )}

          <div
            style={
              location.state.edit === true
                ? { ...styles.pointerDsiabled, width: "100%" }
                : { width: "100%" }
            }
          >
            <Layout.Spacer height={10} />
            <div style={styles.boxHeader}>Subject*</div>
            <TextField.CustomInput
              placeholder="Subject of the meeting"
              style={styles.textfieldStyle}
              onChange={(e) => setSubject(e.target.value)}
              value={
                location.state.data.subject
                  ? location.state.data.subject
                  : subject
              }
            />
          </div>

          <Layout.Spacer height={10} />
          <Text.Heading style={styles.boxHeader} text="Meeting Date*" />
          <DatePicker
            style={styles.meetingDatePicker}
            disabledDate={disabledDate}
            onChange={onDateChange}
          />

          <Layout.Spacer height={10} />
          <div style={{ display: "flex" }}>
            <div style={{ width: "100%", marginRight: "7px" }}>
              <Text.Heading style={styles.boxHeader} text="Time Slot*" />
            </div>
            <div style={{ width: "100%" }}>
              <Text.Heading
                style={
                  location.state.edit === true
                    ? { ...styles.boxHeader, opacity: "0.8" }
                    : { ...styles.boxHeader, opacity: "1" }
                }
                text="Document Attachment"
              />
            </div>
          </div>

          <div style={styles.rowStyle} className="counselor-select">
            <div style={{ width: "100%", marginRight: "10px" }}>
              <Select
                style={styles.counselorDropdown}
                onChange={(e) => setTimeSlot(e)}
                showSearch
                placeholder="Search Time Slot "
                defaultValue={
                  location.state.data.timeSlot
                    ? location.state.data.timeSlot
                    : "12:00 AM - 12:30 AM"
                }
              >
                <Select.Option value="12:00 AM - 12:30 AM">
                  12:00 AM - 12:30 AM
                </Select.Option>
                <Select.Option value="12:30 AM - 01:00 AM">
                  12:30 AM - 01:00 AM
                </Select.Option>
                <Select.Option value="01:00 AM - 01:30 AM">
                  01:00 AM - 01:30 AM
                </Select.Option>
                <Select.Option value="01:30 AM - 02:00 AM">
                  01:30 AM - 02:00 AM
                </Select.Option>
                <Select.Option value="02:00 AM - 02:30 AM">
                  02:00 AM - 02:30 AM
                </Select.Option>
                <Select.Option value="02:30 AM - 03:00 AM">
                  02:30 AM - 03:00 AM
                </Select.Option>
                <Select.Option value="03:00 AM - 03:30 AM">
                  03:00 AM - 03:30 AM
                </Select.Option>
                <Select.Option value="03:30 AM - 04:00 AM">
                  03:30 AM - 04:00 AM
                </Select.Option>
                <Select.Option value="04:00 AM - 04:30 AM">
                  04:00 AM - 04:30 AM
                </Select.Option>
                <Select.Option value="04:30 AM - 05:00 AM">
                  04:30 AM - 05:00 AM
                </Select.Option>
                <Select.Option value="05:00 AM - 05:30 AM">
                  05:00 AM - 05:30 AM
                </Select.Option>
                <Select.Option value="05:30 AM - 06:00 AM">
                  05:30 AM - 06:00 AM
                </Select.Option>
                <Select.Option value="06:00 AM - 06:30 AM">
                  06:00 AM - 06:30 AM
                </Select.Option>
                <Select.Option value="06:30 AM - 07:00 AM">
                  06:30 AM - 07:00 AM
                </Select.Option>
                <Select.Option value="07:00 AM - 07:30 AM">
                  07:00 AM - 07:30 AM
                </Select.Option>
                <Select.Option value="07:30 AM - 08:00 AM">
                  07:30 AM - 08:00 AM
                </Select.Option>
                <Select.Option value="08:00 AM - 08:30 AM">
                  08:00 AM - 08:30 AM
                </Select.Option>
                <Select.Option value="08:30 AM - 09:00 AM">
                  08:30 AM - 09:00 AM
                </Select.Option>
                <Select.Option value="09:00 AM - 09:30 AM">
                  09:00 AM - 09:30 AM
                </Select.Option>
                <Select.Option value="09:30 AM - 10:00 AM">
                  09:30 AM - 10:00 AM
                </Select.Option>
                <Select.Option value="10:00 AM - 10:30 AM">
                  10:00 AM - 10:30 AM
                </Select.Option>
                <Select.Option value="10:30 AM - 11:00 AM">
                  10:30 AM - 11:00 AM
                </Select.Option>
                <Select.Option value="11:00 AM - 11:30 AM">
                  11:00 AM - 11:30 AM
                </Select.Option>
                <Select.Option value="11:30 AM - 12:00 PM">
                  11:30 AM - 12:00 PM
                </Select.Option>
                <Select.Option value="12:00 PM - 12:30 PM">
                  12:00 PM - 12:30 PM
                </Select.Option>
                <Select.Option value="12:30 PM - 01:00 PM">
                  12:30 PM - 01:00 PM
                </Select.Option>
                <Select.Option value="01:00 PM - 01:30 PM">
                  01:00 PM - 01:30 PM
                </Select.Option>
                <Select.Option value="01:30 PM - 02:00 PM">
                  01:30 PM - 02:00 PM
                </Select.Option>
                <Select.Option value="02:00 PM - 02:30 PM">
                  02:00 PM - 02:30 PM
                </Select.Option>
                <Select.Option value="02:30 PM - 03:00 PM">
                  02:30 PM - 03:00 PM
                </Select.Option>
                <Select.Option value="03:00 PM - 03:30 PM">
                  03:00 PM - 03:30 PM
                </Select.Option>
                <Select.Option value="03:30 PM - 04:00 PM">
                  03:30 PM - 04:00 PM
                </Select.Option>
                <Select.Option value="04:00 PM - 04:30 PM">
                  04:00 PM - 04:30 PM
                </Select.Option>
                <Select.Option value="04:30 PM - 05:00 PM">
                  04:30 PM - 05:00 PM
                </Select.Option>
                <Select.Option value="05:00 PM - 05:30 PM">
                  05:00 PM - 05:30 PM
                </Select.Option>
                <Select.Option value="05:30 PM - 06:00 PM">
                  05:30 PM - 06:00 PM
                </Select.Option>
                <Select.Option value="06:00 PM - 06:30 PM">
                  06:00 PM - 06:30 PM
                </Select.Option>
                <Select.Option value="06:30 PM - 07:00 PM">
                  06:30 PM - 07:00 PM
                </Select.Option>
                <Select.Option value="07:00 PM - 07:30 PM">
                  07:00 PM - 07:30 PM
                </Select.Option>
                <Select.Option value="07:30 PM - 08:00 PM">
                  07:30 PM - 08:00 PM
                </Select.Option>
                <Select.Option value="08:00 PM - 08:30 PM">
                  08:00 PM - 08:30 PM
                </Select.Option>
                <Select.Option value="08:30 PM - 09:00 PM">
                  08:30 PM - 09:00 PM
                </Select.Option>
                <Select.Option value="09:00 PM - 09:30 PM">
                  09:00 PM - 09:30 PM
                </Select.Option>
                <Select.Option value="09:30 PM - 10:00 PM">
                  09:30 PM - 10:00 PM
                </Select.Option>
                <Select.Option value="10:00 PM - 10:30 PM">
                  10:00 PM - 10:30 PM
                </Select.Option>
                <Select.Option value="10:30 PM - 11:00 PM">
                  10:30 PM - 11:00 PM
                </Select.Option>
                <Select.Option value="11:00 PM - 11:30 PM">
                  11:00 PM - 11:30 PM
                </Select.Option>
                <Select.Option value="11:30 PM - 12:00 AM">
                  11:30 PM - 12:00 AM
                </Select.Option>
              </Select>
            </div>

            {location.state.edit === true ? (
              <div style={{ width: "100%" }}>
                <div
                  style={styles.meetingFileDsiabled}
                  onClick={() => {
                    if (location.state.data.meetingFile) {
                      window.open(
                        location.state.data.meetingFile
                          ? location.state.data.meetingFile
                          : "#",
                        "_blank"
                      );
                    }
                  }}
                >
                  <div>
                    {location.state.data.meetingFile
                      ? "Click to open file"
                      : "No File"}
                  </div>
                  <div>
                    <CgAttachment color={Colors.Primary} />
                  </div>
                </div>
              </div>
            ) : (
              <div style={{ width: "100%" }}>
                <div
                  style={styles.meetingFileInput}
                  onClick={() => {
                    document.getElementById("getFile").click();
                  }}
                >
                  <input
                    style={{ display: "none" }}
                    type="file"
                    name="csv"
                    id="getFile"
                    ref={fileSelect}
                    onChange={(event) => {
                      setSelectedFile(event.target.files[0]);
                    }}
                    accept=".doc,.docx,.csv,.xlsx, .pdf"
                  />
                  <div>
                    {selectedFile ? selectedFile.name : "Select a file"}
                  </div>
                  <div>
                    <CgAttachment color={Colors.Primary} />
                  </div>
                </div>
              </div>
            )}
          </div>

          <Layout.Spacer height={10} />
          <Text.Heading style={styles.boxHeader} text="Message*" />

          <div>
            <TextArea
              rows={4}
              style={{ width: "100%" }}
              onChange={(e) => setMessage(e.target.value)}
              value={
                location.state.data.meeting
                  ? location.state.data.meeting
                  : message
              }
              placeholder={"Meeting description"}
            />
          </div>

          {location.state.edit === true ? (
            <>
              <div style={styles.pointerDsiabled}>
                <div style={{ width: "100%" }}>
                  <Layout.Spacer height={10} />
                  <div style={styles.boxHeader}>
                    {location.state.data.meetingType === "Online"
                      ? "Meeting Link *"
                      : "Meeting Place *"}
                  </div>
                  <TextField.CustomInput
                    placeholder={
                      location.state.data.meetingType === "Online"
                        ? "Meeting Link"
                        : "Meeting Place"
                    }
                    style={styles.textfieldStyle}
                    onChange={(e) => setMeetingLink(e.target.value)}
                    value={location.state.data.meetingLink}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              {value1 && (
                <div>
                  <div style={{ width: "100%" }}>
                    <Layout.Spacer height={10} />
                    <div style={styles.boxHeader}>
                      {value1 === "Online" ? "Meeting Link" : "Meeting Place"}
                    </div>
                    <TextField.CustomInput
                      placeholder={
                        value1 === "Online"
                          ? "Link of the Meeting"
                          : "Place of the Meeting"
                      }
                      style={styles.textfieldStyle}
                      onChange={(e) => setMeetingLink(e.target.value)}
                      value={meetingLink}
                    />
                  </div>
                </div>
              )}
            </>
          )}
          <Layout.Spacer height={10} />
          <div style={styles.rowStyle}>
            <div
              style={
                location.state.edit === true ? styles.pointerDsiabled : null
              }
            >
              <Radio.Group
                options={plainOptions}
                onChange={onChange1}
                value={
                  location.state.data.meetingType
                    ? location.state.data.meetingType
                    : value1
                }
              />
            </div>
            {error && <div style={{ color: "red" }}>{error}</div>}
            <div>
              <Button.Primary
                onClick={scheduleMeeting}
                style={styles.buttonStyle}
                disabled={buttonDisabled}
              >
                {buttonText}
              </Button.Primary>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default NewMeetings;
