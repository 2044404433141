import { Colors } from "../../config";

const styles = {
  flexRowCenter: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },

  flexRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  flexRowSpace: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  flexRowStart: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },

  flexColumnCenter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  applicationRow: {
    display: "flex",
    backgroundColor: Colors.LightGray,
    height: "70px",
    borderRadius: "10px",
    marginTop: "20px",
  },
  applicationImageContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "10px 30px",
    width: "10%",
  },
  applicationImage: {
    height: "50px",
    width: "50px",
    borderRadius: "100%",
    backgroundColor: "white",
    objectFit: "contain",
  },
  applicationInstituteContainer: {
    width: "100%",
  },
  applicationDateContainer: {
    width: "90%",
  },
  applicationButtonContainer: {
    width: "20%",
  },
  applicationHeaderText: {
    paddingTop: "10px",
    textAlign: "left",
    textTransform: "capitalize",
    fontSize: "12px",
    fontWeight: "600",
    color: Colors.BlackText,
  },
  applicationSubText: {
    marginTop: "10px",
    textAlign: "left",
    textTransform: "capitalize",
    fontSize: "10px",
    fontWeight: "400",
    color: Colors.BlackText,
  },
  applicationArrowButton: {
    color: Colors.Primary,
    fontSize: "25px",
    marginLeft: "20px",
    marginTop: "10px",
  },

  addApplicationButton: {
    width: "20%",
    backgroundColor: Colors.Primary,
    fontWeight: "500",
    fontSize: "14px",
    height: "20px",
  },

  universityDropdown: {
    color: Colors.Primary,
    height: "35px",
    width: "100%",
    borderColor: Colors.Primary,
    borderRadius: "10px",
    textTransform: "capitalize",
    outline: "none",
    fontSize: "12px",
  },
  universityDropdownSelect: {
    color: Colors.Primary,
    width: "520px",
    textTransform: "capitalize",
  },
  applicationDatepicker: {
    color: Colors.Primary,
    height: "35px",
    width: "100%",
    borderColor: Colors.Primary,
    borderRadius: "5px",
    padding: "10px",
  },
  applicationProofContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  applicationProof: {
    borderColor: Colors.Primary,
    borderStyle: "dashed",
    borderWidth: "2px",
    borderSpacing: "4px",
    borderRadius: "10px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  applicationProofIcon: {
    color: Colors.Primary,
    height: "70px",
    width: "500px",
    padding: "10px",
  },
  errortext: {
    color: "red",
    fontSize: "14px",
    display: "flex",
    justifyContent: "center",
    margin: "20px",
  },

  modalCardContainer: { marginTop: "10px", width: "250px" },
  modalCard: { borderColor: Colors.Primary, borderStyle: "dashed" },
  modalCardIcon: {
    color: Colors.Primary,
    height: "48px",
    width: "500px",
    borderColor: Colors.Primary,
    borderRadius: "10px",
    padding: "10px",
  },
  modalButton: {
    width: "200px",
    backgroundColor: Colors.Primary,
    fontFamily: "Montserrat",
    fontWeight: "500",
    fontSize: "15px",
  },

  successText: {
    fontSize: "24px",
    fontWeight: "700",
    color: Colors.Primary,
    marginBottom: "10px",
  },
  failText: {
    fontSize: "24px",
    fontWeight: "700",
    color: "red",
    marginBottom: "10px",
  },
  failIcon: {
    color: "red",
    height: "68px",
    width: "68px",
    padding: "10px",
  },
  modalDetails: {
    fontSize: "16px",
    fontWeight: "400",
    color: "black",
    marginBottom: "10px",
  },
  proApplication: {
    display: "flex",
    flexDirection: "column",
    alignItems: 'center',
    justifyContent: "center",
    gap: '.5rem',
    padding: "10px 0"
  },
  proApplicationContent : {
    width: '94%',
    fontSize: "20px",
    fontFamily: 'normal normal normal 21px/28px Montserrat',
    textAlign: "center",
    margin: "0",
    lineHeight: "1.2"
  },
  proApplicationInput : {
    width: '80%',
    height: '45px',
    margin: "14px 0 16px 0"
  },
  proApplicationStyle: {
    width: "100%",
    height: "100%",
    border: "1px solid #387F75",
    borderRadius: '100px',
    paddingRight: '0',
  },
  proApplicationStyleIcon: {
    display: "flex",
    flexDirection: "column",
    alignItems: 'center',
    justifyContent: "center",
    width: '89px',
    height: '45px',
    borderRadius: '100px',
    backgroundColor: '#387F75',
    margin: '0',
    cursor: "pointer"
  },
  addApplicationBtn: {
    width: "36%",
    backgroundColor: Colors.Primary,
    fontWeight: "500",
    fontSize: "14px",
    height: "20px",
  },
  mr10: {
    marginRight: "10px",
  },
};

export default styles;
