import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { message } from "antd";
import { Form } from "react-bootstrap";

import Lottie from "react-lottie-player";

import { IndividualStudentAction, AuthAction } from "../../store/actions";

import { Layout, Button, Modal, Text } from "../../components";
import { Colors } from "../../config";

import styles from "./styles";

import Done from "../../assets/images/57767-done.json";

const COD = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [showModal, setShowModal] = useState(false);
  const [ticket, setTicket] = useState("");
  const [state, setState] = useState({
    homeAddress: "",
    city: "",
    landmark: "",
    postalCode: "",
    error: "",
  });

  const handleNext = () => {
    if (!state.homeAddress || !state.city) {
      setState({ ...state, error: "Please fill all the required fields" });
    } else {
      setState({ ...state, error: "" });
      const body = {
        ...location.state,
        homeAddress: state.homeAddress,
        city: state.city,
        landmark: state.landmark,
        postalCode: state.postalCode,
      };
      dispatch(
        IndividualStudentAction.updateIndividualStudent(body, (res) => {
          if (res.status === 200) {
            setTicket(res.data.ticketNumber);
            setShowModal(true);
          } else {
            message.error(res.message);
          }
        })
      );
    }
  };

  return (
    <div>
      <Text.Heading
        fontSize={"18px"}
        fontWeight={"600"}
        color={Colors.Primary}
        text="Cash Collection on Delivery"
      />
      <div style={styles.subscriptionTitle}>Please enter your details</div>
      <Layout.Spacer height={20} />
      <div style={{ width: "400px", color: "black" }}>
        <div>
          <Form.Group>
            <Form.Label>Home Address*</Form.Label>
            <Form.Control
              placeholder="Your address"
              onChange={(e) => {
                setState({
                  ...state,
                  homeAddress: e.target.value,
                });
              }}
              style={styles.inputFieldBorder}
            />
          </Form.Group>
        </div>
        <Layout.Spacer height={10} />
        <div>
          <Form.Group>
            <Form.Label>City Name*</Form.Label>
            <Form.Control
              placeholder="City you live in"
              onChange={(e) => {
                setState({
                  ...state,
                  city: e.target.value,
                });
              }}
              style={styles.inputFieldBorder}
            />
          </Form.Group>
        </div>
        <Layout.Spacer height={10} />
        <div>
          <Form.Group>
            <Form.Label>Nearest Landmark</Form.Label>
            <Form.Control
              placeholder="Your nearest landmark"
              onChange={(e) => {
                setState({
                  ...state,
                  landmark: e.target.value,
                });
              }}
              style={styles.inputFieldBorder}
            />
          </Form.Group>
        </div>
        <Layout.Spacer height={10} />
        <div>
          <Form.Group>
            <Form.Label>Enter Postal Code</Form.Label>
            <Form.Control
              placeholder="Postal code of the area you live in"
              onChange={(e) => {
                setState({
                  ...state,
                  postalCode: e.target.value,
                });
              }}
              style={styles.inputFieldBorder}
            />
          </Form.Group>
        </div>
        <Layout.Spacer height={20} />
        {state.error && (
          <div style={{ textAlign: "center" }}>
            <Form.Text className="text-danger">{state.error}</Form.Text>
            <Layout.Spacer height={20} />
          </div>
        )}
        <Button.Primary style={styles.buttonStyle} onClick={handleNext}>
          Continue
        </Button.Primary>
      </div>
      {showModal && (
        <Modal.Basic
          isModalVisible={showModal}
          centered={true}
          handleCancel={() => {
            setSuccessModal(false);
            dispatch(AuthAction.Logout());
            navigate("/login");
          }}
          closable={false}
        >
          <div style={styles.flexColumnCenter}>
            <Lottie
              loop
              animationData={Done}
              play
              style={{ width: "120px", height: "120px" }}
            ></Lottie>

            <div style={styles.successText}>Your ticket number is {ticket}</div>
            <div style={styles.modalDetails}>
              The UniVision customer success manager will contact you shortly!
            </div>
            <div
              style={{ color: Colors.Red, fontWeight: "700", fontSize: "16px" }}
            >
              Ensure that you collect the payment receipt from our courier
              partner for payment verification
            </div>
            <Layout.Spacer height={20} />
            <Button.Primary
              style={styles.modalButton}
              onClick={() => {
                setShowModal(false);
                dispatch(AuthAction.Logout());
                navigate("/login");
              }}
            >
              OK
            </Button.Primary>
          </div>
        </Modal.Basic>
      )}
    </div>
  );
};

export default COD;
