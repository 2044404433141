import { useState } from "react";
import { useNavigate, useLocation } from "react-router";
import { useDispatch } from "react-redux";
import { Form } from "react-bootstrap";

import { AuthAction } from "../../store/actions";

import { HiOutlineEyeOff, HiOutlineEye } from "react-icons/hi";

import { Button, Layout } from "../../components";
import { Utils } from "../../config";

import styles from "./styles";

const ResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [state, setState] = useState({
    password: "",
    passwordErrMsg: "",
    confirmPassword: "",
    confirmPasswordErrMsg: "",
    iconClick: false,
    validEmail: true,
  });

  const handleResetPassword = () => {
    if (state.password.trim() === "") {
      setState({
        ...state,
        passwordErrMsg: "Password can not be left empty.",
        validPass: false,
      });
    } else if (Utils.passwordRegex.test(state.password) === false) {
      setState({
        ...state,
        passwordErrMsg: "Please enter a valid password.",
        validPass: false,
      });
    } else if (state.confirmPassword.trim() === "") {
      setState({
        ...state,
        passwordErrMsg: "",
        validPass: true,
        confirmPasswordErrMsg: "Confirm password can not be left empty.",
        validConfirmPass: false,
      });
    } else if (Utils.passwordRegex.test(state.confirmPassword) === false) {
      setState({
        ...state,
        passwordErrMsg: "",
        validPass: true,
        confirmPasswordErrMsg: "Please enter a valid password.",
        validConfirmPass: false,
      });
    } else if (state.password !== state.confirmPassword) {
      setState({
        ...state,
        passwordErrMsg: "",
        validPass: true,
        confirmPasswordErrMsg: "Password does not match.",
        validConfirmPass: false,
      });
    } else {
      setState({
        ...state,
        passwordErrMsg: "",
        validPass: true,
        confirmPasswordErrMsg: "",
        validConfirmPass: true,
      });
      const body = {
        email: location.state.email,
        password: state.password,
      };
      dispatch(
        AuthAction.ResetPassword(body, () => {
          navigate("/login");
        })
      );
    }
  };

  return (
    <Layout.LoginLayout backLink={"/forgot-password"}>
      <Layout.HeaderSection
        title="Reset Password"
        subtitle="Enter a strong and safe password"
      />
      <Layout.Spacer height={20} />
      <div style={styles.resetFormContainer}>
        <Form.Group>
          <Form.Label>Password</Form.Label>
          <div style={styles.passwordContainer}>
            <Form.Control
              type={showPassword1 ? "text" : "password"}
              placeholder="Enter password"
              value={state.password}
              onChange={(e) => {
                setState({
                  ...state,
                  password: e.target.value,
                });
              }}
              style={styles.inputFieldBorder}
            />
            {showPassword1 ? (
              <HiOutlineEye
                style={styles.passwordIcon}
                onClick={() => setShowPassword1(false)}
              />
            ) : (
              <HiOutlineEyeOff
                style={styles.passwordIcon}
                onClick={() => setShowPassword1(true)}
              />
            )}
          </div>
          {state.passwordErrMsg && (
            <Form.Text className="text-danger">
              {state.passwordErrMsg}
            </Form.Text>
          )}
        </Form.Group>
        <Layout.Spacer height={20} />
        <Form.Group>
          <Form.Label>Confirm Password</Form.Label>
          <div style={styles.passwordContainer}>
            <Form.Control
              type={showPassword2 ? "text" : "password"}
              placeholder="Confirm password"
              value={state.confirmPassword}
              onChange={(e) => {
                setState({
                  ...state,
                  confirmPassword: e.target.value,
                });
              }}
              style={styles.inputFieldBorder}
            />
            {showPassword2 ? (
              <HiOutlineEye
                style={styles.passwordIcon}
                onClick={() => setShowPassword2(false)}
              />
            ) : (
              <HiOutlineEyeOff
                style={styles.passwordIcon}
                onClick={() => setShowPassword2(true)}
              />
            )}
          </div>
          {state.confirmPasswordErrMsg && (
            <Form.Text className="text-danger">
              {state.confirmPasswordErrMsg}
            </Form.Text>
          )}
        </Form.Group>
        <Layout.Spacer height={30} />

        <Button.Primary
          style={styles.buttonStyle}
          onClick={handleResetPassword}
        >
          Continue
        </Button.Primary>

        <Layout.Spacer height={20} />
      </div>
    </Layout.LoginLayout>
  );
};

export default ResetPassword;
