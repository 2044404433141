import { useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";

import { AuthAction } from "../../store/actions";

import { Button, Layout } from "../../components";
import { Utils } from "../../config";

import styles from "./styles";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [state, setState] = useState({
    email: "",
    emailErrMsg: "",
    iconClick: false,
    validEmail: true,
  });

  const handleForgotPassword = () => {
    if (state.email.trim() === "") {
      setState({
        ...state,
        emailErrMsg: "Email can not be left empty.",
        validEmail: false,
      });
    } else if (Utils.emailRegex.test(state.email) === false) {
      setState({
        ...state,
        emailErrMsg: "Please enter a valid email address.",
      });
    } else {
      setState({
        ...state,
        emailErrMsg: "",
      });
      const body = {
        email: state.email,
      };
      dispatch(
        AuthAction.ForgotPassword(body, () => {
          navigate("/verify-code", {
            state: {
              email: state.email,
            },
          });
        })
      );
    }
  };

  return (
    <Layout.LoginLayout backLink={"/login"}>
      <Layout.HeaderSection
        title="Forgot Password"
        subtitle="Enter email to send OTP"
      />
      <Layout.Spacer height={30} />
      <div style={styles.forgotFormContainer}>
        <input
          style={styles.inputFieldBorder}
          type="email"
          placeholder="Enter your email"
          onChange={(e) =>
            setState({
              ...state,
              email: e.target.value,
            })
          }
        />
        {state.emailErrMsg && (
          <div className="text-danger">{state.emailErrMsg}</div>
        )}

        <Layout.Spacer height={50} />

        <Button.Primary
          style={styles.buttonStyle}
          onClick={handleForgotPassword}
        >
          Continue
        </Button.Primary>

        <Layout.Spacer height={20} />
      </div>
    </Layout.LoginLayout>
  );
};

export default ForgotPassword;
