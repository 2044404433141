import { put } from "redux-saga/effects";

import { RecommenderAction } from "../actions";
import { ApiCaller } from "../../config";

export default class RecommenderMiddleware {
  static *getRecommender({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post(`/recommender/getRecommender`, payload);

      if (res.status === 200) {
        yield put(RecommenderAction.getRecommenderSuccess(res.data.data));
      } else {
        yield put(RecommenderAction.getRecommenderSuccess([]));
      }
      if (cb) {
        cb();
      }
    } catch (err) {
      yield put(RecommenderAction.getRecommenderFailure());
    }
  }
}
