import { put } from "redux-saga/effects";

import { ApiCaller } from "../../config";
import { UniversityAction } from "../actions";

export default class UniversityMiddleware {
  static *getUniversities({ payload, cb }) {
    try {
      const { region, search, limit, offset, filter, userId, country, budget } =
        payload;

      const res = yield ApiCaller.Get(
        `/university/search-university?searchInput=${search}&country=${country}&budget=${budget}&filterRegion=${region}&limit=${limit}&offset=${offset}&filter=${filter}&userId=${userId}`
      );

      if (res.status === 200) {
        yield put(UniversityAction.getUniversitiesSuccess(res.data.data));
      } else {
        yield put(UniversityAction.getUniversitiesSuccess([]));
      }
      if (cb) {
        cb();
      }
    } catch (err) {
      yield put(UniversityAction.getUniversitiesFailure());
    }
  }

  static *getAllUniversities({ cb }) {
    try {
      const res = yield ApiCaller.Get(`/university/getAllUniversities`);
      if (res.status === 200) {
        yield put(UniversityAction.getAllUniversitiesSuccess(res.data.data));
      } else {
        yield put(UniversityAction.getAllUniversitiesSuccess([]));
      }
      if (cb) {
        cb(res.data.data);
      }
    } catch (err) {
      yield put(UniversityAction.getAllUniversitiesFailure());
    }
  }
  static *getUniversityDetails({ id, cb }) {
    try {
      const res = yield ApiCaller.Get(
        `/university/get-universityDetails/${id}`
      );
      if (res.status === 200) {
        yield put(UniversityAction.getUniversityDetailsSuccess(res.data.data));
      } else {
        yield put(UniversityAction.getUniversityDetailsFailure());
      }
      if (cb) {
        cb();
      }
    } catch (err) {
      yield put(UniversityAction.getUniversityDetailsFailure());
    }
  }

  static *compareUniverities({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post(
        "/university/compare-university",
        payload
      );
      if (res.status === 200) {
        yield put(UniversityAction.compareUniveritiesSuccess(res.data.data));
      } else {
        yield put(UniversityAction.compareUniveritiesFailure());
      }
      if (cb) {
        cb(res.data.data);
      }
    } catch (err) {
      yield put(UniversityAction.compareUniveritiesFailure());
    }
  }
}
