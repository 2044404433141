import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Select } from "antd";

import { Images, Colors } from "../../config";
import premiumContent from "../../assets/images/premiunContent.svg";

import {
  Layout,
  Modal,
  Table,
  Text,
  Charts,
  convertCurrency,
} from "../../components";

import styles from "./styles";

const convertToNumber = (value) => {
  if (value?.includes("NA")) {
    return 0;
  } else {
    const splittedValue = value.split(/[\s-]+/);
    const lastValue = splittedValue[splittedValue.length - 1].replace(/,/g, "");
    const numericLastValue = parseFloat(lastValue);
    return numericLastValue;
  }
};

function Finances({ data, programs, studentLife, location, secondaryQs }) {
  const currency = useSelector((state) => state.converter.converted);
  const convertedCurrency = useSelector((state) => state.converter.convertTo);
  const isMalaysia = location?.toLowerCase()?.includes("malaysia");

  const studentDetail = useSelector((state) => state.auth.user.user);

  const [blurData, setBlurData] = useState(undefined);
  const [eligibilityCriteria, setEligibilityCriteria] = useState("");
  const [scholarshipTitle, setScholarshipTitle] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [currencyValue, setCurrencyValue] = useState("");
  const [program, setProgram] = useState(
    programs?.length !== 0 ? programs[0].name : "NA"
  );
  const [localFee, setLocalFee] = useState(
    !programs || programs?.length === 0 ? "NA" : programs[0].localCost
  );
  const [internationalFee, setInternationalFee] = useState(
    !programs || programs?.length === 0 ? "NA" : programs[0].internationalCost
  );
  const [tutionFee, setTutionFee] = useState(
    !programs || programs?.length === 0
      ? "NA"
      : programs[0].tutionFee
      ? programs[0].tutionFee
      : programs[0].localCost
  );
  const [localHousingCost, setLocalHousingCost] = useState(
    studentLife?.onCampus?.averageMonthlyAccommodationCost
  );
  const [internationalHousingCost, setInternationalHousingCost] = useState(
    studentLife?.onCampus?.averageMonthlyAccommodationCost
  );
  const [offCampusLocalHousingCost, setOffCampusLocalHousingCost] = useState(
    studentLife?.offCampus?.averageMonthlyAccommodationCost
  );
  const [
    offCampusInternationalHousingCost,
    setOffCampusInternationalHousingCost,
  ] = useState(studentLife?.offCampus?.averageMonthlyAccommodationCost);
  const [localOtherCost, setLocalOtherCost] = useState(
    studentLife?.onCampus?.averageMonthlyOthersCost
  );
  const [internationalOtherCost, setInternationalOtherCost] = useState(
    studentLife?.onCampus?.averageMonthlyOthersCost
  );
  const [offCampusLocalOtherCost, setOffCampusLocalOtherCost] = useState(
    studentLife?.offCampus?.averageMonthlyOthersCost
  );
  const [offCampusInternationalOtherCost, setOffCampusInternationalOtherCost] =
    useState(studentLife?.offCampus?.averageMonthlyOthersCost);
  const [isOnCampus, setIsOnCampus] = useState(true);

  useEffect(() => {
    const currentDate = new Date();
    const validUntilDate = new Date(studentDetail.validUntil);

    if (
      validUntilDate < currentDate &&
      studentDetail.studentType === "individualStudent" &&
      studentDetail.subscriptionPlan === "Free" &&
      secondaryQs > 10
    ) {
      setBlurData(true);
    }
  }, []);

  useEffect(() => {
    const onCampusLocalHousingCost =
      studentLife?.onCampus?.averageMonthlyAccommodationCost;

    setLocalHousingCost(
      !currency
        ? onCampusLocalHousingCost
        : convertCurrency(onCampusLocalHousingCost, convertedCurrency)
    );

    ////////////////////////////////////
    const onCampusLocalOtherCost =
      studentLife?.onCampus?.averageMonthlyOthersCost;
    setLocalOtherCost(
      !currency
        ? onCampusLocalOtherCost
        : convertCurrency(onCampusLocalOtherCost, convertedCurrency)
    );

    ////////////////////////////////////
    const offCampusLocalHousingCost =
      studentLife?.offCampus?.averageMonthlyAccommodationCost;

    setOffCampusLocalHousingCost(
      !currency
        ? offCampusLocalHousingCost
        : convertCurrency(offCampusLocalHousingCost, convertedCurrency)
    );

    ////////////////////////////////////
    const offCampusLocalOtherCost =
      studentLife?.offCampus?.averageMonthlyOthersCost;
    setOffCampusLocalOtherCost(
      !currency
        ? offCampusLocalOtherCost
        : convertCurrency(offCampusLocalOtherCost, convertedCurrency)
    );

    ////////////////////////////////////
    const onCampusInternationalHousingCost =
      studentLife?.onCampus?.averageMonthlyAccommodationCost;
    setInternationalHousingCost(
      !currency
        ? onCampusInternationalHousingCost
        : convertCurrency(onCampusInternationalHousingCost, convertedCurrency)
    );

    ////////////////////////////////////
    const onCampusInternationalOtherCost =
      studentLife?.onCampus?.averageMonthlyOthersCost;
    setInternationalOtherCost(
      !currency
        ? onCampusInternationalOtherCost
        : convertCurrency(onCampusInternationalOtherCost, convertedCurrency)
    );

    ////////////////////////////////////
    const offCampusInternationalHousingCost =
      studentLife?.offCampus?.averageMonthlyAccommodationCost;
    setOffCampusInternationalHousingCost(
      !currency
        ? offCampusInternationalHousingCost
        : convertCurrency(offCampusInternationalHousingCost, convertedCurrency)
    );

    ////////////////////////////////////
    const offCampusInternationalOtherCost =
      studentLife?.offCampus?.averageMonthlyOthersCost;
    setOffCampusInternationalOtherCost(
      !currency
        ? offCampusInternationalOtherCost
        : convertCurrency(offCampusInternationalOtherCost, convertedCurrency)
    );

    if (location.toLowerCase().includes("malaysia")) {
      if (!programs || programs?.length === 0) {
        setLocalFee("NA");
        setInternationalFee("NA");
      } else {
        let local = programs[0].localCost;
        setLocalFee(
          !currency ? local : convertCurrency(local, convertedCurrency)
        );

        let international = programs[0].internationalCost;
        setInternationalFee(
          !currency
            ? international
            : convertCurrency(international, convertedCurrency)
        );
      }
    } else {
      if (!programs || programs?.length === 0) {
        setTutionFee("NA");
      } else {
        let tution = programs[0].tutionFee
          ? programs[0].tutionFee
          : programs[0].localCost;

        setTutionFee(
          !currency ? tution : convertCurrency(tution, convertedCurrency)
        );
      }
    }
  }, [convertedCurrency]);

  useEffect(() => {
    let currencyValue1 = "NA";
    if (!currency) {
      if (programs?.length !== 0) {
        if (!programs[0].localCost.includes("NA")) {
          currencyValue1 = programs[0].localCost.split(" ")[0];
        } else if (!programs[0].tutionFee.includes("NA")) {
          currencyValue1 = programs[0].tutionFee.split(" ")[0];
        } else {
          if (
            !studentLife?.onCampus?.averageMonthlyAccommodationCost.includes(
              "NA"
            )
          ) {
            currencyValue1 =
              studentLife?.onCampus?.averageMonthlyAccommodationCost.split(
                " "
              )[0];
          } else if (
            !studentLife?.offCampus?.averageMonthlyAccommodationCost.includes(
              "NA"
            )
          ) {
            currencyValue1 =
              studentLife?.offCampus?.averageMonthlyAccommodationCost.split(
                " "
              )[0];
          } else {
            currencyValue1 = "NA";
          }
        }
        setCurrencyValue(currencyValue1);
        setProgram(programs.length !== 0 ? programs[0].name : "NA");
      }
    } else {
      setCurrencyValue(convertedCurrency);
      setProgram(programs.length !== 0 ? programs[0].name : "NA");
    }
  }, [convertedCurrency]);

  const handleEligibility = (record) => {
    setEligibilityCriteria(record.eligibilityCriteria);
    setScholarshipTitle(record.title);
    setShowModal(true);
  };

  const handleFee = (programName) => {
    if (location.includes("Malaysia") || location.includes("malaysia")) {
      programs?.map((program) => {
        if (program.name === programName) {
          let local = program.localCost;

          let international = program.internationalCost;

          setLocalFee(
            !currency ? local : convertCurrency(local, convertedCurrency)
          );
          setInternationalFee(
            !currency
              ? international
              : convertCurrency(international, convertedCurrency)
          );

          setProgram(programName);
        }
      });
    } else {
      programs?.map((program) => {
        if (program.name === programName) {
          let tution = program.tutionFee
            ? program.tutionFee
            : program.localCost;

          setTutionFee(
            !currency ? tution : convertCurrency(tution, convertedCurrency)
          );

          setProgram(programName);
        }
      });
    }
  };

  const malaysiaChart = [
    {
      name: "Tution Cost",
      value: convertToNumber(internationalFee),
    },
    {
      name: "Housing Cost",
      value: isOnCampus
        ? convertToNumber(internationalHousingCost) * 12
        : convertToNumber(offCampusInternationalHousingCost) * 12,
    },
    {
      name: "Other Costs",
      value: isOnCampus
        ? convertToNumber(internationalOtherCost) * 12
        : convertToNumber(offCampusInternationalOtherCost) * 12,
    },
  ];

  const otherChart = [
    {
      name: "Tution Cost",
      value: convertToNumber(tutionFee),
    },
    {
      name: "Housing Cost",
      value: isOnCampus
        ? convertToNumber(internationalHousingCost) * 12
        : convertToNumber(offCampusInternationalHousingCost) * 12,
    },
    {
      name: "Other Costs",
      value: isOnCampus
        ? convertToNumber(internationalOtherCost) * 12
        : convertToNumber(offCampusInternationalOtherCost) * 12,
    },
  ];

  const costsTitle = [
    { id: 1, image: Images.Loan, title: "Tution Fee Per Year" },
    { id: 2, image: Images.HousingCost, title: "Housing Cost Per Month" },
    { id: 3, image: Images.Others, title: "Other Cost Per Month" },
  ];

  const malaysiaLocalCosts = [
    { id: 1, value: localFee },
    { id: 2, value: isOnCampus ? localHousingCost : offCampusLocalHousingCost },
    { id: 3, value: isOnCampus ? localOtherCost : offCampusLocalOtherCost },
  ];

  const malaysiaInternationalCosts = [
    { id: 1, value: internationalFee },
    {
      id: 2,
      value: isOnCampus
        ? internationalHousingCost
        : offCampusInternationalHousingCost,
    },
    {
      id: 3,
      value: isOnCampus
        ? internationalOtherCost
        : offCampusInternationalOtherCost,
    },
  ];

  const otherUniCosts = [
    { id: 1, value: tutionFee },
    { id: 2, value: isOnCampus ? localHousingCost : offCampusLocalHousingCost },
    { id: 3, value: isOnCampus ? localOtherCost : offCampusLocalOtherCost },
  ];

  const chartValues = [
    {
      id: 1,
      value: isMalaysia ? (
        <>
          {isOnCampus
            ? (
                (convertToNumber(internationalFee) /
                  (convertToNumber(internationalFee) +
                    convertToNumber(internationalHousingCost) * 12 +
                    convertToNumber(internationalOtherCost) * 12)) *
                100
              ).toFixed(2)
            : (
                (convertToNumber(internationalFee) /
                  (convertToNumber(internationalFee) +
                    convertToNumber(offCampusInternationalHousingCost) * 12 +
                    convertToNumber(offCampusInternationalOtherCost) * 12)) *
                100
              ).toFixed(2)}
          %
        </>
      ) : (
        <>
          {isOnCampus
            ? (
                (convertToNumber(tutionFee) /
                  (convertToNumber(tutionFee) +
                    convertToNumber(internationalHousingCost) * 12 +
                    convertToNumber(internationalOtherCost) * 12)) *
                100
              ).toFixed(2)
            : (
                (convertToNumber(tutionFee) /
                  (convertToNumber(tutionFee) +
                    convertToNumber(offCampusInternationalHousingCost) * 12 +
                    convertToNumber(offCampusInternationalOtherCost) * 12)) *
                100
              ).toFixed(2)}
          %
        </>
      ),
      color: Colors.Primary,
      title: "Tution Costs",
    },
    {
      id: 2,
      value: isMalaysia ? (
        <>
          {isOnCampus
            ? (
                ((convertToNumber(internationalHousingCost) * 12) /
                  (convertToNumber(internationalFee) +
                    convertToNumber(internationalHousingCost) * 12 +
                    convertToNumber(internationalOtherCost) * 12)) *
                100
              ).toFixed(2)
            : (
                ((convertToNumber(offCampusInternationalHousingCost) * 12) /
                  (convertToNumber(internationalFee) +
                    convertToNumber(offCampusInternationalHousingCost) * 12 +
                    convertToNumber(offCampusInternationalOtherCost) * 12)) *
                100
              ).toFixed(2)}
          %
        </>
      ) : (
        <>
          {isOnCampus
            ? (
                ((convertToNumber(internationalHousingCost) * 12) /
                  (convertToNumber(tutionFee) +
                    convertToNumber(internationalHousingCost) * 12 +
                    convertToNumber(internationalOtherCost) * 12)) *
                100
              ).toFixed(2)
            : (
                ((convertToNumber(offCampusInternationalHousingCost) * 12) /
                  (convertToNumber(tutionFee) +
                    convertToNumber(offCampusInternationalHousingCost) * 12 +
                    convertToNumber(offCampusInternationalOtherCost) * 12)) *
                100
              ).toFixed(2)}
          %
        </>
      ),
      color: "#214854",
      title: "Housing Costs",
    },
    {
      id: 3,
      value: isMalaysia ? (
        <>
          {isOnCampus
            ? (
                ((convertToNumber(internationalOtherCost) * 12) /
                  (convertToNumber(internationalFee) +
                    convertToNumber(internationalHousingCost) * 12 +
                    convertToNumber(internationalOtherCost) * 12)) *
                100
              ).toFixed(2)
            : (
                ((convertToNumber(offCampusInternationalOtherCost) * 12) /
                  (convertToNumber(internationalFee) +
                    convertToNumber(offCampusInternationalHousingCost) * 12 +
                    convertToNumber(offCampusInternationalOtherCost) * 12)) *
                100
              ).toFixed(2)}
          %
        </>
      ) : (
        <>
          {isOnCampus
            ? (
                ((convertToNumber(internationalOtherCost) * 12) /
                  (convertToNumber(tutionFee) +
                    convertToNumber(internationalHousingCost) * 12 +
                    convertToNumber(internationalOtherCost) * 12)) *
                100
              ).toFixed(2)
            : (
                ((convertToNumber(offCampusInternationalOtherCost) * 12) /
                  (convertToNumber(tutionFee) +
                    convertToNumber(offCampusInternationalHousingCost) * 12 +
                    convertToNumber(offCampusInternationalOtherCost) * 12)) *
                100
              ).toFixed(2)}
          %
        </>
      ),
      color: "#66c6d5",
      title: "Other Costs",
    },
  ];

  return (
    <div style={{ position: "relative" }}>
      <p style={styles.uniDetailsHeader}>Calculate Your Cost of Living</p>
      <div style={{ display: "flex" }}>
        <div style={styles.step1Container}>
          <div style={styles.stepTitle}>Step 1:</div>
          <p style={styles.stepSubtitle}>Select the program of your choice</p>
          <div className="finances-select">
            <Select
              style={styles.programSelect}
              onChange={(val) => {
                handleFee(val);
              }}
              showSearch
              defaultValue={program}
              value={program}
            >
              {programs || programs?.length > 0 ? (
                programs.map((item) => (
                  <Select.Option value={item.name} key={item._id}>
                    {item.name}
                  </Select.Option>
                ))
              ) : (
                <Select.Option value="No Data" key={"nodata"}>
                  No Data
                </Select.Option>
              )}
            </Select>
          </div>
        </div>

        <div style={styles.step2Container}>
          <div style={styles.stepTitle}>Step 2:</div>
          <p style={styles.stepSubtitle}>
            Select where you would prefer to reside
          </p>
          <div style={{ display: "flex" }}>
            <div
              style={{
                color: isOnCampus ? "white" : "black",
                background: isOnCampus ? Colors.Primary : "white",
                border: isOnCampus ? "" : `1px solid ${Colors.Primary}`,
                ...styles.campusBox,
              }}
              onClick={() => setIsOnCampus(true)}
            >
              On Campus
            </div>
            <div
              style={{
                color: !isOnCampus ? "white" : "black",
                background: !isOnCampus ? Colors.Primary : "white",
                border: !isOnCampus ? "" : `1px solid ${Colors.Primary}`,
                ...styles.campusBox,
              }}
              onClick={() => setIsOnCampus(false)}
            >
              Off Campus
            </div>
          </div>
        </div>
      </div>
      <Layout.Spacer height={10} />

      <div style={styles.stepTitle}>Step 3:</div>

      <div style={styles.flexRow}>
        {isMalaysia ? (
          <div style={styles.resultsContainer}>
            <div style={{ marginBottom: "20px" }}>
              <p style={{ ...styles.uniDetailsHeader, width: "320px" }}>
                Results (Local Students) (Per Year)
              </p>
              <div style={styles.resultsLeftPartContainer}>
                <div style={styles.resultsLeftPart}>
                  <img src={Images.Calculator} height={56} width={56} alt="" />
                  <div style={styles.resultsLeftPartValue}>
                    {currencyValue}{" "}
                    {isOnCampus
                      ? (
                          convertToNumber(localHousingCost) * 12 +
                          convertToNumber(localOtherCost) * 12 +
                          convertToNumber(localFee)
                        ).toLocaleString()
                      : (
                          convertToNumber(offCampusLocalHousingCost) * 12 +
                          convertToNumber(offCampusLocalOtherCost) * 12 +
                          convertToNumber(localFee)
                        ).toLocaleString()}
                  </div>
                </div>
                <div style={styles.flexFullWidth}>
                  <div style={styles.financesCostsTitleContainer}>
                    {costsTitle?.map((item) => (
                      <div
                        style={{
                          marginBottom: item.id === 3 ? "0px" : "10px",
                          ...styles.flexRowCenter,
                        }}
                        key={item.id}
                      >
                        <span>
                          <img src={item.image} height={20} width={20} alt="" />
                        </span>
                        <span style={styles.financesCostsTitle}>
                          {item.title}
                        </span>
                      </div>
                    ))}
                  </div>
                  <div style={styles.financesValuesContainer}>
                    {malaysiaLocalCosts?.map((item) => (
                      <div
                        style={{
                          marginBottom: item.id === 3 ? "0px" : "10px",
                          fontWeight: "700",
                          fontSize: "14px",
                        }}
                        key={item.id}
                      >
                        {item.value}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div style={blurData && styles.blurrScreen}>
              <div style={{ marginBottom: "20px" }}>
                <p style={{ ...styles.uniDetailsHeader, width: "360px" }}>
                  Results (International Students) (Per Year)
                </p>
                <div style={styles.resultsLeftPartContainer}>
                  <div style={styles.resultsLeftPart}>
                    <img
                      src={Images.Calculator}
                      height={56}
                      width={56}
                      alt=""
                    />
                    <div style={styles.resultsLeftPartValue}>
                      {currencyValue}{" "}
                      {isOnCampus
                        ? (
                            convertToNumber(internationalHousingCost) * 12 +
                            convertToNumber(internationalOtherCost) * 12 +
                            convertToNumber(internationalFee)
                          ).toLocaleString()
                        : (
                            convertToNumber(offCampusInternationalHousingCost) *
                              12 +
                            convertToNumber(offCampusInternationalOtherCost) *
                              12 +
                            convertToNumber(internationalFee)
                          ).toLocaleString()}
                    </div>
                  </div>
                  <div style={styles.flexFullWidth}>
                    <div style={styles.financesCostsTitleContainer}>
                      {costsTitle?.map((item) => (
                        <div
                          style={{
                            marginBottom: item.id === 3 ? "0px" : "10px",
                            ...styles.flexRowCenter,
                          }}
                          key={item.id}
                        >
                          <span>
                            <img
                              src={item.image}
                              height={20}
                              width={20}
                              alt=""
                            />
                          </span>
                          <span style={styles.financesCostsTitle}>
                            {item.title}
                          </span>
                        </div>
                      ))}
                    </div>
                    <div style={styles.financesValuesContainer}>
                      {malaysiaInternationalCosts?.map((item) => (
                        <div
                          style={{
                            marginBottom: item.id === 3 ? "0px" : "10px",
                            fontWeight: "700",
                            fontSize: "14px",
                          }}
                          key={item.id}
                        >
                          {item.value}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div style={styles.resultsContainer}>
            <div style={{ marginBottom: "20px" }}>
              <p style={styles.uniDetailsHeader}>Results (Per Year)</p>
              <div style={styles.resultsLeftPartContainer}>
                <div style={styles.resultsLeftPart}>
                  <img src={Images.Calculator} height={56} width={56} alt="" />
                  <div style={styles.resultsLeftPartValue}>
                    {currencyValue}{" "}
                    {isOnCampus
                      ? (
                          convertToNumber(localHousingCost) * 12 +
                          convertToNumber(localOtherCost) * 12 +
                          convertToNumber(tutionFee)
                        ).toLocaleString()
                      : (
                          convertToNumber(offCampusLocalHousingCost) * 12 +
                          convertToNumber(offCampusLocalOtherCost) * 12 +
                          convertToNumber(tutionFee)
                        ).toLocaleString()}
                  </div>
                </div>
                <div style={styles.flexFullWidth}>
                  <div style={styles.financesCostsTitleContainer}>
                    {costsTitle?.map((item) => (
                      <div
                        style={{
                          marginBottom: item.id === 3 ? "0px" : "10px",
                          ...styles.flexRowCenter,
                        }}
                        key={item.id}
                      >
                        <span>
                          <img src={item.image} height={20} width={20} alt="" />
                        </span>
                        <span style={styles.financesCostsTitle}>
                          {item.title}
                        </span>
                      </div>
                    ))}
                  </div>
                  <div style={styles.financesValuesContainer}>
                    {otherUniCosts?.map((item) => (
                      <div
                        style={{
                          marginBottom: item.id === 3 ? "0px" : "10px",
                          fontWeight: "700",
                          fontSize: "14px",
                        }}
                        key={item.id}
                      >
                        {item.value}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div style={{ width: "50%" }}>
          <div style={blurData && styles.blurrScreen}>
            <div style={styles.chartPartContainer}>
              {isMalaysia && (
                <div style={{ marginTop: "10px", fontSize: "12px" }}>
                  International Students
                </div>
              )}

              <Charts.PieChart
                height={isMalaysia ? 200 : 150}
                width={isMalaysia ? 200 : 150}
                data={isMalaysia ? malaysiaChart : otherChart}
              />
              <div style={styles.chartValuesPartContainer}>
                {chartValues?.map((item) => (
                  <div style={styles.chartValuesPart} key={item.id}>
                    <div style={styles.chartValues}>{item.value}</div>
                    <div
                      style={{
                        background: item.color,
                        ...styles.chartValuesBox,
                      }}
                    ></div>

                    <div style={styles.chartValuesTitle}>{item.title}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Layout.Spacer height={30} />
      <div style={blurData && styles.blurrScreen}>
        <p style={styles.uniDetailsHeader}>Financial Aid Scholarships</p>
        <div>
          <Table.AntDesign
            rowKey={(record) => record._id}
            columns={[
              {
                title: "Sr No",
                dataIndex: "srNo",
                align: "center",
                width: 50,
                render: (text, record, index) => (
                  <Text.Heading
                    fontSize={"10px"}
                    fontWeight={"600"}
                    color={Colors.Text}
                    text={index + 1}
                  />
                ),
              },
              {
                title: "Scholarship Title",
                dataIndex: "title",
                align: "center",
                width: 150,
                render: (text, record) => (
                  <div
                    onClick={() =>
                      record.link
                        ? window.open(record.link, "_blank")
                        : window.open("#", "_blank")
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <Text.Heading
                      color={Colors.Primary}
                      fontSize={"10px"}
                      fontWeight={"600"}
                      text={text}
                    />
                  </div>
                ),
              },
              {
                title: "Amount",
                dataIndex: "amount",
                align: "center",
                width: 200,
                render: (record) => (
                  <Text.Heading
                    fontSize={"10px"}
                    fontWeight={"600"}
                    color={Colors.Text}
                    text={
                      !currency
                        ? record.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        : convertCurrency(
                            record.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                            convertedCurrency
                          )
                    }
                  />
                ),
              },
              {
                title: "Award Type",
                dataIndex: "duration",
                align: "center",
                width: 100,
                render: (record) => (
                  <Text.Heading
                    fontSize={"10px"}
                    fontWeight={"600"}
                    color={Colors.Text}
                    text={record}
                  />
                ),
              },
              {
                title: "Requirement",
                dataIndex: "eligibilityCriteria",
                align: "center",
                width: 80,
                render: (text, record) => (
                  <Text.Heading
                    fontSize={"10px"}
                    fontWeight={"600"}
                    color={Colors.Primary}
                    style={styles.cursor}
                    text={"See Details"}
                    onClick={() => handleEligibility(record)}
                  />
                ),
              },
            ]}
            data={data.scholarships}
            pagination={false}
          />
        </div>
      </div>
      {showModal && (
        <Modal.Basic
          isModalVisible={showModal}
          centered={true}
          handleCancel={() => setShowModal(false)}
          closable={false}
          style={{ padding: "20px" }}
        >
          <Layout.Spacer height={10} />
          <Text.Heading
            fontSize={"18px"}
            fontWeight={"600"}
            color={Colors.Primary}
            text={scholarshipTitle}
          />
          <Layout.Spacer height={15} />
          <Text.Heading
            fontSize={"16px"}
            fontWeight={"400"}
            color={Colors.Black}
            text={eligibilityCriteria}
          />
          <Layout.Spacer height={10} />
        </Modal.Basic>
      )}

      {blurData ? (
        <div style={styles.premiumModal}>
          <img src={premiumContent} />
          <p>
            To view more <b>Subscribe to our Pro Package</b>
          </p>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default Finances;
