import { Colors } from "../../config";

const styles = {
  flexSpaceBetween: { display: "flex", justifyContent: "space-between" },
  markAllAsRead: { color: "black", fontSize: "12px", cursor: "pointer" },
  notificationRow: {
    color: "black",
    backgroundColor: Colors.LightGray,
    margin: "10px 0px",
    padding: "10px 10px",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
  },
  iconContainer: { margin: "20px", width: "5%" },
  notificationText: { fontSize: "14px", fontWeight: "600" },
  notificationDate: {
    fontSize: "14px",
    fontWeight: "600",
    color: Colors.Gray,
  },
  unread: {
    backgroundColor: Colors.Primary,
    height: "10px",
    width: "10px",
    borderRadius: "100%",
    cursor: "pointer",
  },
  read: {
    backgroundColor: "#bbbbbb",
    height: "10px",
    width: "10px",
    borderRadius: "100%",
  },
};

export default styles;
