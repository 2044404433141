import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Select } from "antd";

import { UniversityAction } from "../../store/actions";

import { Layout, Text, Loader } from "../../components";
import { Colors } from "../../config";
import Countries from "../../config/mockData/countries";

import CircleContainer from "./circleContainer";

import styles from "./styles";

function RecommendersPreferences() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [acceptance, setAcceptance] = useState("0-100");
  const [duration, setDuration] = useState("all");
  const [countriesChoice, setCountriesChoice] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [locationType, setLocationType] = useState("all");
  const [budget, setBudget] = useState("0-100000000");
  const [scholarship, setScholarship] = useState("yes");
  const [aid, setAid] = useState("yes");
  const [preferredSubject, setPreferredSubject] = useState("All");
  const [halalFood, setHalalFood] = useState("all");
  const [error, setError] = useState("");

  const universityLoading = useSelector((state) => state.university);
  const universityNames = useSelector(
    (state) => state.university.allUniversities
  );

  const handleNext = () => {
    if (!budget || countriesChoice?.length < 3) {
      setError("Fill all the required fields.");
    } else {
      navigate("/recommender/results", {
        state: {
          qualifications: location.state,
          preferences: {
            acceptance,
            duration,
            country1: countriesChoice[0] ?? "",
            country2: countriesChoice[1] ?? "",
            country3: countriesChoice[2] ?? "",
            locationType,
            budget,
            scholarship,
            aid,
            preferredSubject,
            halalFood,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (universityNames?.length === 0) {
      dispatch(UniversityAction.getAllUniversities());
    }
  }, []);

  // useEffect(() => {
  //   let universities = [];

  //   universityNames.forEach((university) => {
  //     const location = university.location.split(",");
  //     const country = location[location.length - 1]
  //       .trim()
  //       .replace(/\u200E/g, "");

  //     if (!universities.includes(country)) {
  //       universities.push(country);
  //     }
  //   });
  //   universities.sort((a, b) => a.localeCompare(b));

  //   setCountryList(universities);
  // }, [universityNames, universityLoading]);

  const handleFilterCountry = (input, option) => {
    return option.children.toLowerCase().startsWith(input.toLowerCase());
  };

  return (
    <div style={styles.flexColumn}>
      <CircleContainer page="preferences" />
      <Layout.Spacer height={20} />
      {universityLoading?.isLoading ? (
        <Loader.Circular />
      ) : (
        <div>
          <Text.Heading
            fontSize={"18px"}
            fontWeight={"600"}
            color={Colors.Primary}
            text="Preferences"
          />
          <div style={styles.inputFieldContainer}>
            <div>
              <div style={styles.boxHeaders}>Acceptance Rate*</div>
              <div style={styles.boxSubtitles}>
                Your Target university Should be Between what Range
              </div>
              <div className="university-form-1">
                <Select
                  defaultValue="0-20"
                  style={{ width: "100%" }}
                  value={acceptance}
                  onChange={(e) => {
                    setAcceptance(e);
                  }}
                >
                  <Select.Option value="0-100">All</Select.Option>
                  <Select.Option value="0-20">0-20 %</Select.Option>
                  <Select.Option value="21-40">21-40 %</Select.Option>
                  <Select.Option value="41-60">41-60 %</Select.Option>
                  <Select.Option value="61-80">61-80 %</Select.Option>
                  <Select.Option value="81-100">81-100 %</Select.Option>
                </Select>
              </div>
            </div>
            <div style={{ width: "100%" }}>
              <div style={styles.boxHeaders}>Duration of Degree *</div>
              <div style={styles.boxSubtitles}>
                How Long Do You Want Your University Degree to be
              </div>
              <div className="university-form-1">
                <Select
                  defaultValue="all"
                  style={{ width: "100%" }}
                  value={duration}
                  onChange={(e) => {
                    setDuration(e);
                  }}
                >
                  <Select.Option value="all">All</Select.Option>
                  <Select.Option value="3 years">3 Years</Select.Option>
                  <Select.Option value="4 years">4 Years</Select.Option>
                </Select>
              </div>
            </div>
            <div style={{ width: "100%" }}>
              <div style={styles.boxHeaders}>Top 3 Countries of Choice *</div>
              <div style={styles.boxSubtitles}>
                What are Your Top 3 Countries of Choice
              </div>

              <div className="top-countries">
                <Select
                  style={styles.countryInput}
                  onChange={(universityJSON) => {
                    setCountriesChoice(universityJSON);
                  }}
                  showSearch
                  placeholder="Select Your top 3 countries"
                  filterOption={handleFilterCountry}
                  mode="multiple"
                  maxCount={3}
                >
                  {Countries?.map((dropdown) => {
                    return (
                      <Select.Option value={dropdown} key={dropdown}>
                        {dropdown}
                      </Select.Option>
                    );
                  })}
                </Select>
              </div>
            </div>
            <div style={{ width: "100%" }}>
              <div style={styles.boxHeaders}>Location Type *</div>
              <div style={styles.boxSubtitles}>
                Would You Prefer to Live in an Urban or Rural Campus
              </div>
              <div className="university-form-1">
                <Select
                  defaultValue="all"
                  style={{ width: "100%" }}
                  value={locationType}
                  onChange={(e) => {
                    setLocationType(e);
                  }}
                >
                  <Select.Option value="all">All</Select.Option>
                  <Select.Option value="urban">Urban</Select.Option>
                  <Select.Option value="rural">Rural</Select.Option>
                </Select>
              </div>
            </div>
            <div>
              <div style={styles.boxHeaders}>Budget *</div>
              <div style={styles.boxSubtitles}>
                How Much are You Looking to Allocate to Your University Fee
              </div>
              <div className="university-form-1">
                <Select
                  defaultValue="USD"
                  value={budget}
                  onChange={(e) => setBudget(e)}
                  style={{ width: "100%" }}
                >
                  <Select.Option value="0-100000000">All</Select.Option>
                  <Select.Option value="0-20000">0 - 20,000 $</Select.Option>
                  <Select.Option value="20000-40000">
                    20,000 - 40,000 $
                  </Select.Option>
                  <Select.Option value="40000-60000">
                    40,000 - 60,000 $
                  </Select.Option>
                  <Select.Option value="60000-80000">
                    60,000 - 80,000 $
                  </Select.Option>
                  <Select.Option value="80000-100000000">
                    {"> 80,000 $"}
                  </Select.Option>
                </Select>
              </div>
            </div>
            <div>
              <div style={styles.boxHeaders}>Scholarship *</div>
              <div style={styles.boxSubtitles}>
                Is Scholarship a Criteria for University Selection?
              </div>
              <div className="university-form-1">
                <Select
                  defaultValue="yes"
                  value={scholarship}
                  onChange={(e) => setScholarship(e)}
                  style={{ width: "100%" }}
                >
                  <Select.Option value="yes">Yes</Select.Option>
                  <Select.Option value="no">No</Select.Option>
                </Select>
              </div>
            </div>
            <div>
              <div style={styles.boxHeaders}>Financial Aid *</div>
              <div style={styles.boxSubtitles}>
                Is Financial Aid a Criteria for University Selection?
              </div>
              <div className="university-form-1">
                <Select
                  defaultValue="yes"
                  value={aid}
                  onChange={(e) => setAid(e)}
                  style={{ width: "100%" }}
                >
                  <Select.Option value="yes">Yes</Select.Option>
                  <Select.Option value="no">No</Select.Option>
                </Select>
              </div>
            </div>
            <div style={{ width: "100%" }}>
              <div style={styles.boxHeaders}>Preferred Subject *</div>
              <div style={styles.boxSubtitles}>
                What Majors are You Looking to Study at University?
              </div>
              <div className="university-form-1">
                <Select
                  defaultValue="All"
                  style={{ width: "100%" }}
                  value={preferredSubject}
                  onChange={(e) => {
                    setPreferredSubject(e);
                  }}
                >
                  <Select.Option value="All">All</Select.Option>
                  <Select.Option value="Business">Business</Select.Option>
                  <Select.Option value="Law">Law</Select.Option>
                  <Select.Option value="Social Sciences">
                    Social Sciences
                  </Select.Option>
                  <Select.Option value="Sciences">Sciences</Select.Option>
                  <Select.Option value="Medical">Medical</Select.Option>
                </Select>
              </div>
            </div>
            <div style={{ width: "100%" }}>
              <div style={styles.boxHeaders}>Halal Food Availibility *</div>
              <div style={styles.boxSubtitles}>
                Is Nearby Halal Food a Criteria for University Selection?
              </div>
              <div className="university-form-1">
                <Select
                  defaultValue="all"
                  style={{ width: "100%" }}
                  value={halalFood}
                  onChange={(e) => {
                    setHalalFood(e);
                  }}
                >
                  <Select.Option value="all">Any Availibility</Select.Option>
                  <Select.Option value="easy">Easy Availibility</Select.Option>
                </Select>
              </div>
            </div>
          </div>
          <Layout.Spacer height={20} />
        </div>
      )}
      <Layout.Spacer height={20} />
      {error !== "" && <div style={styles.errorText}>{error}</div>}
      <div style={styles.justifyEnd}>
        <div
          style={styles.continueButton}
          onClick={() => {
            handleNext();
          }}
        >
          Continue
        </div>
      </div>
    </div>
  );
}

export default RecommendersPreferences;
