import {
  GET_ETEXTBOOKS,
  GET_ETEXTBOOKS_SUCCESS,
  GET_ETEXTBOOKS_FAILURE,
} from "../constants";

const initialState = {
  books: {},
  isLoading: false,
  error: null,
};

export default function ETextbookReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ETEXTBOOKS:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;

    case GET_ETEXTBOOKS_SUCCESS:
      state = {
        ...state,
        books: action.payload,
        isLoading: false,
      };
      break;

    case GET_ETEXTBOOKS_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    default:
      break;
  }
  return state;
}
