const Colors = {
  PlaceHolder: (opacity = "0.5") => `rgba(235, 235, 235, ${opacity})`,
  BlackOpacity: (opacity = "0.5") => `rgba(0, 0, 0, ${opacity})`,
  WhiteOpacity: (opacity = "0.5") => `rgba(255, 255, 255, ${opacity})`,
  Black: "#000000",
  Transparent: "transparent",
  White: "#ffffff",
  Gray: "#707070",
  LightGray: "#f5f5f5",
  Primary: "#387F75",
  Secondary: "#6DC6C3",
  Secondary2: "#214854",
  LightSecondary: "#97BAB5",
  GrayText: "#474747",
  LightGrayText: "#8F8F8F",
  BlackText: "#081C15",
  BlackText2: "#001614",
  DarkRed: "#8C1515",
  Red: "#E00000",
  Red1: "#B01820",
  Red2: "#C61018",
  Red3: "#AE1A22",
  Red4: "#A31F34",
  DarkBlue: "#002A70",
  DarkBlue2: "#002173",
  DarkBlue3: "#1A408A",
  Yellow: "#ECD000",
  Success: "#67D669",
  Danger: "#E8533F",
  Blue: "#1BA5F3",
};

export default Colors;
