import { useState } from "react";
import { useNavigate, useLocation } from "react-router";
import { message } from "antd";
import ReactCodeInput from "react-code-input";

import { Button, Layout, Text } from "../../components";

import styles from "./styles";

const Flow2OTP = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [otpCode, setOtpCode] = useState("");
  const [otpCodeErrMsg, setOtpCodeErrMsg] = useState("");

  const handleVerifyCode = () => {
    if (otpCode.length === 0) {
      setOtpCodeErrMsg("Please enter code");
    } else if (otpCode.length < 4 && otpCode.length > 0) {
      setOtpCodeErrMsg("Incomplete Code");
    } else {
      setOtpCodeErrMsg("");

      if (parseInt(otpCode) === location.state.otp) {
        message.success("OTP verified Succesfully");

        navigate("/signup/passwordForm", {
          state: {
            email: location.state.email,
            details: location.state,
          },
        });
      } else {
        message.error("Invalid OTP");
      }
    }
  };

  const handleChange = (val) => {
    setOtpCode(val);
  };

  return (
    <Layout.LoginLayout backLink={"/signup/email"}>
      <Layout.HeaderSection
        title="Verify OTP"
        subtitle="Please enter the 4-digit OTP you received in your email"
      />
      <Layout.Spacer height={50} />
      <div style={styles.otpFormContainer}>
        <div style={styles.otpCodeContainer}>
          <ReactCodeInput
            type="number"
            fields={4}
            onChange={handleChange}
            value={otpCode}
            inputStyle={styles.codeInput}
            onClick={(e) => setOtpCode(e.target.value)}
          />
          <Layout.Spacer height={20} />
          {otpCodeErrMsg && (
            <Text.ErrorText style={styles.otpErrorText} text={otpCodeErrMsg} />
          )}
        </div>
        <Layout.Spacer height={10} />

        <Button.Primary style={styles.buttonStyle} onClick={handleVerifyCode}>
          Continue
        </Button.Primary>

        <Layout.Spacer height={10} />
        <p style={{ fontSize: "10px", color: "black", textAlign: "center" }}>
          *Make sure to check your spam/junk folder if you can't find the email
        </p>
        <Layout.Spacer height={20} />
      </div>
    </Layout.LoginLayout>
  );
};

export default Flow2OTP;
