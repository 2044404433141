import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Images, Colors } from "../../config";
import { Layout, convertCurrency } from "../../components";

import premiumContent from "../../assets/images/premiunContent.svg";

import styles from "./styles";

function PostGraduation({ data, secondaryQs }) {
  const currency = useSelector((state) => state.converter.converted);
  const convertedCurrency = useSelector((state) => state.converter.convertTo);

  const [blurData, setBlurData] = useState(undefined);
  const studentDetail = useSelector((state) => state.auth.user.user);

  useEffect(() => {
    const currentDate = new Date();
    const validUntilDate = new Date(studentDetail.validUntil);

    if (
      validUntilDate < currentDate &&
      studentDetail.studentType === "individualStudent" &&
      studentDetail.subscriptionPlan === "Free" &&
      secondaryQs > 10
    ) {
      setBlurData(true);
    }
  }, []);

  const postGrad = [
    {
      id: 1,
      image: Images.Placement,
      value: data?.placementRate !== "NA" ? `${data?.placementRate}%` : "NA",
      title: "Placement Rate",
    },
    {
      id: 2,
      image: Images.PostGradSalary,
      value: !currency
        ? data?.graduateSalary
          ? data?.graduateSalary
          : "NA"
        : convertCurrency(
            data?.graduateSalary ? data?.graduateSalary : "NA",
            convertedCurrency
          ),
      title: "Average Annual Fresh Graduate Salary",
    },
  ];

  const pathwayUpperPart = [
    {
      value: (
        <div style={styles.studentPathwayUpperPart}>
          <p style={styles.studentPathwayUpperPartText}>Education</p>
        </div>
      ),
    },
    {
      value: (
        <div style={styles.visaProcessingValue}>
          <img src={Images.Graduation} height={40} width={40} />
        </div>
      ),
    },
    {
      value: (
        <div style={styles.studentPathwayUpperPart}>
          <p style={styles.studentPathwayUpperPartText}>Work</p>
        </div>
      ),
    },
  ];
  const pathwayLowerPart = [
    {
      value: (
        <div style={styles.visaProcessingValue}>
          {data?.graduation ? `(${data?.graduation})` : "NA"}
        </div>
      ),
    },
    {
      value: <div style={styles.visaProcessingValue}>Graduation</div>,
    },
    {
      value: (
        <div style={styles.visaProcessingValue}>
          {data?.work ? `(${data?.work})` : "NA"}
        </div>
      ),
    },
  ];

  return (
    <div style={{ position: "relative" }}>
      <p style={styles.uniDetailsHeader}>Post Graduation</p>
      <div style={styles.flexSpaceBetween}>
        {postGrad.map((item) => (
          <div
            style={{
              ...styles.postGraduation,
              marginRight: item.id === 2 ? "0px" : "50px",
            }}
            key={item.id}
          >
            <div style={styles.flexSpaceBetween}>
              <div style={styles.visaDurationImageContainer}>
                <img src={item.image} height={65} width={65} />
              </div>
              <div style={styles.visaDurationTextContainer}>
                <p
                  style={{
                    ...styles.uniDetailsValue,
                    marginBottom: "0px",
                    fontWeight: "700",
                  }}
                >
                  {item.value}
                </p>
                <p style={styles.visaDurationText}>{item.title}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <Layout.Spacer height={30} />

      <div style={blurData && styles.blurrScreen}>
        <p style={{ ...styles.uniDetailsHeader, width: "400px" }}>
          Student Pathway after Graduation
        </p>
        <div style={styles.studentPathwayContainer}>
          <div style={{ display: "flex" }}>
            <div style={styles.visaProcessingLeftPart}></div>
            <div style={styles.visaProcessingMiddlePart}>
              <div style={styles.visaProcessingMiddleUpperPart}>
                {pathwayUpperPart?.map((item, index) => (
                  <div key={index} style={{ width: "100%" }}>
                    {item.value}
                  </div>
                ))}
              </div>
              <div style={styles.studentPathwayMiddleLowerPart}>
                {pathwayLowerPart?.map((item, index) => (
                  <div key={index} style={{ width: "100%" }}>
                    {item.value}
                  </div>
                ))}
              </div>
            </div>
            <div style={styles.visaProcessingRightPart}>
              <img src={Images.Visa} height={60} width={60} />
            </div>
          </div>
          <div
            style={{
              ...styles.flexSpaceBetween,
              marginTop: "20px",
            }}
          >
            <div
              style={{
                ...styles.visaProcessingBottomDivText,
                paddingLeft: "5%",
              }}
            >
              Admission
            </div>
            <div
              style={{
                ...styles.visaProcessingBottomDivText,
                paddingRight: "5%",
                color: Colors.Primary,
              }}
            >
              Citizensip
            </div>
          </div>
        </div>{" "}
      </div>

      {blurData ? (
        <div style={styles.premiumModal}>
          <img src={premiumContent} />
          <p>
            To view more <b>Subscribe to our Pro Package</b>
          </p>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default PostGraduation;
