import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { ChatbotAction } from "../../store/actions";

import { Images } from "../../config";

import styles from "./styles";

import styled from "styled-components";

export default function Contacts({ contacts, changeChat }) {
  const dispatch = useDispatch();

  const loginUser = useSelector((state) => state.profile.user);
  const unread = useSelector((state) => state.chatbot.unreadMessages);

  const [currentUserName, setCurrentUserName] = useState(undefined);
  const [currentUserImage, setCurrentUserImage] = useState(undefined);
  const [currentSelected, setCurrentSelected] = useState(undefined);

  useEffect(() => {
    const payload = {
      from: loginUser._id,
    };
    dispatch(ChatbotAction.getUnreadMessages(payload));
  }, [currentSelected]);

  useEffect(() => {
    setCurrentUserName(loginUser.fullName);
    setCurrentUserImage(loginUser.profileImage);
  }, []);

  const changeCurrentChat = (index, contact) => {
    setCurrentSelected(index);
    changeChat(contact);
  };

  return (
    <>
      {currentUserImage && currentUserImage && (
        <Container>
          <div className="contacts">
            {contacts.map((contact, index) => {
              return (
                <div
                  key={contact.userId}
                  className={`contact ${
                    index === currentSelected ? "selected" : ""
                  }`}
                  onClick={() => changeCurrentChat(index, contact)}
                >
                  <div className="avatar">
                    <img
                      src={
                        contact?.imageUrl
                          ? contact?.imageUrl
                          : Images.DummyImage
                      }
                      alt=""
                    />
                  </div>
                  <div className="username">{contact.name}</div>
                  {unread?.some((item) => item.sender === contact.userId) && (
                    <div style={styles.userInfoContainer}></div>
                  )}
                </div>
              );
            })}
          </div>
          <div className="current-user">
            <div className="avatar">
              <img src={loginUser.profileImage} alt="avatar" />
            </div>
            <div className="username">{currentUserName}</div>
          </div>
        </Container>
      )}
    </>
  );
}
const Container = styled.div`
  display: grid;
  grid-template-rows: 85% 15%;
  overflow: hidden;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  .contacts {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    gap: 0.8rem;
    background-color: #eff2f5;
    padding-top: 15px;

    .contact {
      background-color: #ffffff5e;
      min-height: 5rem;
      cursor: pointer;
      width: 90%;
      border-radius: 0.2rem;
      padding: 0.4rem;
      display: flex;
      gap: 1rem;
      align-items: center;
      transition: 0.5s ease-in-out;
      border-radius: 20px;
      .avatar {
        img {
          height: 30px;
          width: 30px;
          border-radius: 100%;
          object-fit: contain;
          background-color: white;
        }
      }
      .username {
        color: black;
        text-transform: capitalize;
      }
    }
    .contact:hover {
      background-color: #387f768a;
    }
    .selected {
      color: white;
      font-weight: 500;
      background-color: #387f75;
      border-radius: 20px;
      .username {
        color: white;
        text-transform: capitalize;
      }
    }
  }

  .current-user {
    background-color: #387f75;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    .avatar {
      img {
        height: 30px;
        width: 30px;
        border-radius: 100%;
        object-fit: contain;
        background-color: white;
      }
    }
    .username {
      color: white;
      font-size: 20px;
      text-transform: capitalize;
    }
  }
`;
