import {
  GET_DEADLINES,
  GET_DEADLINES_FAILURE,
  GET_DEADLINES_SUCCESS,
} from "../constants";

const initialState = {
  deadlines: [],
  isLoading: false,
  error: null,
};

export default function DeadlineReducer(state = initialState, action) {
  switch (action.type) {
    case GET_DEADLINES:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;
    case GET_DEADLINES_SUCCESS:
      state = {
        ...state,
        deadlines: action?.payload,
        isLoading: false,
      };
      break;
    case GET_DEADLINES_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      break;
    default:
      break;
  }
  return state;
}
