import { put } from "redux-saga/effects";

import { ApiCaller } from "../../config";
import { CounselorAction } from "../actions";

export default class CounselorMiddleware {
  static *getCounselors({ payload, cb }) {
    try {
      const res = yield ApiCaller.Get(`/school/get-counselors/${payload.id}`);
      if (res.status === 200) {
        yield put(CounselorAction.getCounselorsSuccess(res.data.data));
      } else {
        yield put(CounselorAction.getCounselorsSuccess([]));
      }
      if (cb) {
        cb(res.data.data);
      }
    } catch (err) {
      yield put(CounselorAction.getCounselorsFailure());
    }
  }
}
