import { put } from "redux-saga/effects";
import { message } from "antd";
import { ApiCaller } from "../../config";
import { ContactAction } from "../actions";

export default class ContactMiddleware {
  static *addContact({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post(`/miscellaneous/add-contact`, payload);
      if (res.status === 200) {
        yield put(ContactAction.addContactSuccess(res.data.data));
        message.success("Your message has been submitted");
      } else {
        yield put(ContactAction.addContactFailure());
        message.error(res.data.message);
      }
      if (cb) {
        cb();
      }
    } catch (err) {
      yield put(ContactAction.addContactFailure());
    }
  }
}
