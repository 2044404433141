import { Colors } from "../../config";

const styles = {
  customTextLabel: {
    textAlign: "left",
    color: Colors.BlackText,
    fontWeight: "400",
    fontSize: "16px",
  },
};

export default styles;
