import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { BiCalendar } from "react-icons/bi";

import { NotificationsAction } from "../../store/actions";

import { Text, dateFormatter } from "../../components";
import { Colors } from "../../config";

import styles from "./styles";

export default function Notifications() {
  const dispatch = useDispatch();

  const loginUser = useSelector((state) => state.auth.user.user);
  const notifications = useSelector((state) => state.notifications);

  useEffect(() => {
    const payload = {
      limit: "",
      offset: "",
      userId: loginUser._id ? loginUser._id : "",
    };
    dispatch(NotificationsAction.getNotifications(payload));
  }, []);

  const notificationPayload = {
    limit: "",
    offset: "",
    userId: loginUser._id || loginUser._id,
  };

  const handleMarkAll = () => {
    const payload = {
      userId: loginUser._id,
      update: "all",
    };
    dispatch(
      NotificationsAction.changeNotificationsStatus(payload, () => {
        dispatch(NotificationsAction.getNotifications(notificationPayload));
      })
    );
  };

  const handleMarkSingle = (notification) => {
    const value = notification._id;
    const payload = {
      id: value,
      userId: loginUser._id,
      update: "single",
    };
    dispatch(
      NotificationsAction.changeNotificationsStatus(payload, () => {
        dispatch(NotificationsAction.getNotifications(notificationPayload));
      })
    );
  };

  return (
    <div>
      <div style={styles.flexSpaceBetween}>
        <Text.Heading
          fontSize={"18px"}
          fontWeight={"600"}
          color={Colors.Primary}
          text="Notifications"
        />
        <div style={styles.markAllAsRead} onClick={handleMarkAll}>
          Mark All as Read
        </div>
      </div>
      <div>
        {notifications.notifications.map((notification) => {
          return (
            <div style={styles.notificationRow}>
              <div style={styles.iconContainer}>
                <BiCalendar fontSize={24} color={Colors.Primary} />
              </div>

              <div style={{ width: "100%" }}>
                <div style={styles.notificationText}>{notification.body}</div>
                <div style={styles.notificationDate}>
                  {dateFormatter(notification.date)}
                </div>
              </div>
              <div style={{ width: "5%" }}>
                {notification.read === false ? (
                  <div
                    onClick={() => handleMarkSingle(notification)}
                    style={styles.unread}
                  ></div>
                ) : (
                  <div style={styles.read}></div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
