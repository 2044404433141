import { put } from "redux-saga/effects";

import { ApiCaller, Utils } from "../../config";
import { StudentAction } from "../actions";

export default class StudentMiddleware {
  static *getStudentDetails({ id, cb }) {
    try {
      const res = yield ApiCaller.Get(
        `/school/get-student-detail?studentId=${id}`
      );
      if (res.status === 200) {
        yield put(StudentAction.getStudentDetailsSuccess(res.data.data));
      } else {
        yield put(StudentAction.getStudentDetailsFailure());
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(StudentAction.getStudentDetailsFailure());
    }
  }
  static *getSchools({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post("/school/getSchoolList", payload);
      if (res.status === 200) {
        yield put(StudentAction.getSchoolsSuccess(res.data.data));
      } else {
        yield put(StudentAction.getSchoolsFailure());
      }
      if (cb) {
        cb(res.data.data);
      }
    } catch (err) {
      yield put(StudentAction.getStudentDetailsFailure());
    }
  }
}
