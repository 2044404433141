import React, { useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import { Select } from "antd";
import { Form } from "react-bootstrap";

import { Button, Layout } from "../../components";
import { Colors } from "../../config";

import styles from "./styles";
import "react-phone-input-2/lib/style.css";

function PersonalInfo() {
  const navigate = useNavigate();

  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  const [searchParams, setSearchParams] = useSearchParams();

  const [referral, setReferral] = useState(searchParams.get("referral"));
  const [isMobile, setIsMobile] = useState(
    /android/i.test(userAgent) ||
      (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream)
  );
  const [state, setState] = useState({
    fullname: "",
    location: "",
    gender: "",
    error: "",
  });

  const handleNext = () => {
    if (!state.fullname || !state.location || !state.gender) {
      setState({
        ...state,
        error: "Please fill all the fields.",
      });
    } else {
      setState({
        ...state,
        error: "",
      });

      navigate("/signup/contactInfo", {
        state: {
          fullname: state.fullname,
          gender: state.gender,
          role: "IndividualStudent",
          referral: referral,
          location: state.location,
        },
      });
    }
  };

  return (
    <Layout.LoginLayout backLink={"/login"}>
      <Layout.HeaderSection
        title="Sign Up"
        subtitle={
          /android/i.test(userAgent) ||
          (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream)
            ? null
            : "Enter Your Details To Sign Up On The Univision Student Portal"
        }
      />
      <Layout.Spacer height={10} />
      {isMobile && searchParams.get("referral") ? (
        <div
          onClick={() => {
            const appLink = `https://student.univision.hk/signup/personalInfo-mobile?type=individual&referral=${referral}`;
            const fallbackLink = `https://student.univision.hk/signup/personalInfo-mobile?type=individual&referral=${referral}`;

            const timeout = setTimeout(() => {
              window.location.replace(fallbackLink);
            }, 1000);

            window.location.replace(appLink);

            window.addEventListener("blur", () => clearTimeout(timeout));
          }}
          style={{
            color: "white",
            padding: "10px",
            borderRadius: "6px",
            background: Colors.Primary,
            textAlign: "center",
          }}
        >
          Continue to Mobile App
        </div>
      ) : (
        <>
          <div style={styles.botomTitleStyle}>Personal Information</div>
          <Layout.Spacer height={10} />
          <div style={styles.container}>
            <label style={styles.labelStyle}>
              Select Country <span style={{ color: "red" }}>*</span>
            </label>
            <Layout.Spacer height={5} />
            <div className="signup-form-2">
              <Select
                placeholder="Your country name"
                value={state.location || undefined}
                onChange={(e) => {
                  setState({
                    ...state,
                    location: e,
                    error: "",
                  });
                }}
                style={{
                  width: "100%",
                  height: "53px",
                }}
                showSearch
              >
                <Select.Option value="Pakistan">Pakistan</Select.Option>
                <Select.Option value="Malaysia">Malaysia</Select.Option>
                <Select.Option value="UAE">UAE</Select.Option>
                <Select.Option value="Others">Others</Select.Option>
              </Select>
            </div>
            <Layout.Spacer height={15} />

            <label style={styles.labelStyle}>
              Full Name <span style={{ color: "red" }}>*</span>
            </label>
            <Layout.Spacer height={5} />
            <input
              placeholder="Enter your full name"
              onChange={(e) => {
                setState({
                  ...state,
                  fullname: e.target.value,
                  error: "",
                });
              }}
              className="signup-input"
              style={styles.inputFieldBorder}
              value={state.fullname}
            />
            <Layout.Spacer height={15} />

            <label style={styles.labelStyle}>
              Gender <span style={{ color: "red" }}>*</span>
            </label>
            <Layout.Spacer height={5} />
            <div className="signup-form-2">
              <Select
                value={state.gender || undefined}
                placeholder="Select your gender"
                onChange={(e) => {
                  setState({
                    ...state,
                    gender: e,
                    error: "",
                  });
                }}
                style={{
                  width: "100%",
                  height: "53px",
                }}
              >
                <Select.Option value="Male">Male</Select.Option>
                <Select.Option value="Female">Female</Select.Option>
                <Select.Option value="Not Specified">
                  Not Specified
                </Select.Option>
              </Select>
            </div>

            <Layout.Spacer height={20} />

            {state.error && (
              <div style={{ textAlign: "center" }}>
                <Form.Text className="text-danger">{state.error}</Form.Text>
              </div>
            )}

            <Button.Primary style={styles.buttonStyle} onClick={handleNext}>
              Next
            </Button.Primary>

            <Layout.Spacer height={20} />
            <div style={styles.signinContainer}>
              <span style={styles.signinText}>Already have an account?</span>
              <Link to="/login" style={styles.signinLink}>
                Sign In
              </Link>
            </div>
          </div>
        </>
      )}
    </Layout.LoginLayout>
  );
}

export default PersonalInfo;
