import { Colors } from "../../config";
const styles = {
  justifyAround: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-around",
  },
  chatbotButton: {
    height: "50px",
    width: "50px",
    borderRadius: "100%",
    backgroundColor: Colors.Primary,
    position: "absolute",
    bottom: "30px",
    right: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  chatbotContainer: {
    height: "200px",
    width: "200px",
    borderRadius: "10px",
    backgroundColor: Colors.White,
    boxShadow: "0px 2px 7px #00000029",
    position: "absolute",
    bottom: "70px",
    right: "70px",
    display: "flex",
    padding: "20px",
    alignItems: "center",
    flexDirection: "column",
    animation: "slide-down 0.3s ease",
  },
  chatbotImage: {
    height: "30px",
    width: "30px",
    marginBottom: "10px",
  },
  chatbotHeaderText: {
    color: Colors.Primary,
    fontSize: "10px",
    fontWeight: "700",
    textTransform: "uppercase",
    marginBottom: "20px",
  },
  chatbotOptionContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  chatbotOption: {
    height: "70px",
    width: "70px",
    borderRadius: "100%",
    backgroundColor: Colors.Primary,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  chatbotOptionText: { fontSize: "10px", fontWeight: "700" },
};

export default styles;
