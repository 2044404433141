import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import styled from "styled-components";

import {
  MdLocationPin,
  MdOutlineCheckBox,
  MdOutlineCheckBoxOutlineBlank,
} from "react-icons/md";
import { BsBookmarkStarFill } from "react-icons/bs";
import { AiFillRightCircle } from "react-icons/ai";

import {
  RecommenderAction,
  WatchlistAction,
  UniversityAction,
} from "../../store/actions";

import { Layout, Text, Loader, Table, convertCurrency } from "../../components";
import { Colors } from "../../config";
import premiumContent from "../../assets/images/premiunContent.svg";

import CircleContainer from "./circleContainer";
import styles from "./styles";

function RecommendersResults() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const currency = useSelector((state) => state.converter.converted);
  const convertedCurrency = useSelector((state) => state.converter.convertTo);
  const recommender = useSelector((state) => state.recommender);
  const watchlistState = useSelector((state) => state.watchlist);
  const studentDetail = useSelector((state) => state.auth.user.user);

  const [blurData, setBlurData] = useState(undefined);

  const [selectedUni, setSelectedUni] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const currentDate = new Date();
    const validUntilDate = new Date(studentDetail.validUntil);
    if (
      validUntilDate < currentDate &&
      studentDetail.studentType === "individualStudent" &&
      studentDetail.subscriptionPlan === "Free"
    ) {
      setBlurData(true);
    }
  }, []);

  useEffect(() => {
    const payload = {
      studentId: studentDetail.studentId,
      data: location.state,
    };
    dispatch(RecommenderAction.getRecommender(payload));
  }, []);

  const uniCompareAPICall = () => {
    var body = {
      uniIds: selectedUni,
    };
    dispatch(
      UniversityAction.compareUniverities(body, () => {
        navigate("/universities/compare");
      })
    );
  };

  const handleUniversityWishlist = (id) => {
    dispatch(
      WatchlistAction.addOrRemoveToWishlist(id, () => {
        const payload = {
          limit: "",
          offset: "",
        };
        dispatch(WatchlistAction.getWatchlist(payload));
      })
    );
  };

  const handleSelected = (uni) => {
    if (selectedUni.includes(uni)) {
      setSelectedUni(selectedUni.filter((i) => i !== uni));
    } else {
      setSelectedUni([...selectedUni, uni]);
    }
  };
  const handleAll = () => {
    if (allSelected === false) {
      setSelectedUni([]);
      let uniArray = [];
      recommender.recommender.aspirational.forEach((uni) => {
        uniArray.push(uni._id);
      });
      recommender.recommender.solid.forEach((uni) => {
        uniArray.push(uni._id);
      });
      recommender.recommender.safe.forEach((uni) => {
        uniArray.push(uni._id);
      });

      setSelectedUni(uniArray);
      setAllSelected(true);
    } else {
      setSelectedUni([]);
      setAllSelected(false);
    }
  };

  const column = [
    {
      title: "Name",
      dataIndex: "name",
      width: 350,
      onCell: (record, index) => ({
        onClick: () => {
          if (blurData) {
            if (index === 0) {
              navigate(`/universities/${record._id}/general`);
            } else {
            }
          } else {
            navigate(`/universities/${record._id}/general`);
          }
        },
      }),
      render: (text, record, index) => (
        <ColumnDiv
          style={{ filter: blurData && (index > 0 ? "blur(12px)" : "none") }}
        >
          <img
            src={record?.profilePic}
            alt="img"
            style={{ backgroundColor: "white" }}
          />
          <span>
            <Text.Heading style={styles.resultTableName} text={record.name} />
            <span>
              <MdLocationPin
                color={Colors.GrayText}
                style={{ marginRight: "5px" }}
              />
              <Text.Heading
                style={styles.resultTableLocation}
                text={record.location}
              />
            </span>
          </span>
        </ColumnDiv>
      ),
    },
    {
      title: "QS World Ranking",
      dataIndex: "QSWorldRanking",
      align: "center",
      width: 200,
      onCell: (record, index) => ({
        onClick: () => {
          if (blurData) {
            if (index === 0) {
              navigate(`/universities/${record._id}/general`);
            } else {
            }
          } else {
            navigate(`/universities/${record._id}/general`);
          }
        },
      }),
      render: (text, record, index) => (
        <span
          style={{
            ...styles.resultTableText,
            filter: blurData && (index > 0 ? "blur(12px)" : "none"),
          }}
        >
          {record?.summary?.qsWorldRanking}
        </span>
      ),
    },
    {
      title: !currency ? "Average Fee" : `Average Fee (${convertedCurrency})`,
      dataIndex: "averageFee",
      align: "center",
      width: 300,
      onCell: (record, index) => ({
        onClick: () => {
          if (blurData) {
            if (index === 0) {
              navigate(`/universities/${record._id}/general`);
            } else {
            }
          } else {
            navigate(`/universities/${record._id}/general`);
          }
        },
      }),
      render: (text, record, index) => {
        return (
          <div
            style={{
              ...styles.resultTableText,
              filter: blurData && (index > 0 ? "blur(12px)" : "none"),
            }}
          >
            {!currency
              ? record?.summary.fee_structure
              : convertCurrency(
                  record?.summary.fee_structure,
                  convertedCurrency
                )}
          </div>
        );
      },
    },
    {
      title: " ",
      dataIndex: "action",
      width: 150,
      render: (text, record, index) => {
        const containsValue = watchlistState.watchlists.some((obj) => {
          return Object.values(obj.universityId).includes(record._id);
        });

        return (
          <div
            style={{
              ...styles.resultTableActionContainer,
              filter: blurData && (index > 0 ? "blur(12px)" : "none"),
            }}
          >
            <div style={styles.resutlTableActionDiv}>
              {selectedUni.includes(record._id) ? (
                <MdOutlineCheckBox
                  style={styles.checkBox}
                  onClick={() => {
                    if (blurData) {
                      if (index === 0) {
                        handleSelected(record?._id);
                      } else {
                      }
                    } else {
                      handleSelected(record?._id);
                    }
                  }}
                />
              ) : (
                <MdOutlineCheckBoxOutlineBlank
                  style={styles.checkBox}
                  onClick={() => {
                    if (blurData) {
                      if (index === 0) {
                        handleSelected(record?._id);
                      } else {
                      }
                    } else {
                      handleSelected(record?._id);
                    }
                  }}
                />
              )}
              <BsBookmarkStarFill
                fontSize={"26px"}
                color={containsValue ? Colors.Primary : "#387f7662"}
                cursor={"pointer"}
                onClick={() => {
                  if (blurData) {
                    if (index === 0) {
                      handleUniversityWishlist(record?._id);
                    } else {
                    }
                  } else {
                    handleUniversityWishlist(record?._id);
                  }
                }}
                style={styles.mr5}
              />
              <AiFillRightCircle
                fontSize={"30px"}
                cursor={"pointer"}
                color={Colors.Primary}
                onClick={() => {
                  if (blurData) {
                    if (index === 0) {
                      navigate(`/universities/${record._id}/general`);
                    } else {
                    }
                  } else {
                    navigate(`/universities/${record._id}/general`);
                  }
                }}
              />
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div style={styles.flexColumn}>
      <CircleContainer page="results" />
      <Layout.Spacer height={20} />
      <div>
        <Text.Heading style={styles.headlines} text="Results" />
      </div>
      <div>
        <Text.Heading
          style={styles.resultLoadingText}
          text={
            recommender?.isLoading
              ? "Hold tight, recommender is finding universities that matches your qualifications and preferences."
              : "Based on your entered details & your interests, the Recommender has found following universities for you."
          }
        />
      </div>
      <Layout.Spacer height={20} />
      {recommender?.isLoading ? (
        <Loader.Circular />
      ) : (
        <div style={styles.airesultspro}>
          <div>
            <div style={styles.justifyBetweenItemsCenter}>
              <Text.Heading
                style={styles.headlines}
                text="Aspirational Options"
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {allSelected ? (
                  <MdOutlineCheckBox
                    style={styles.checkBox}
                    onClick={() => handleAll()}
                  />
                ) : (
                  <MdOutlineCheckBoxOutlineBlank
                    style={styles.checkBox}
                    onClick={() => handleAll()}
                  />
                )}

                <span style={{ color: "black" }}>Select All</span>
              </div>
            </div>
            <Layout.Spacer height={10} />
            <Table.AntDesign
              rowKey={(record) => record._id}
              columns={column}
              data={recommender.recommender.aspirational}
              loading={recommender.isLoading}
              pagination={false}
            />
          </div>
          <Layout.Spacer height={20} />
          <div style={blurData && styles.blurrScreen}>
            <div>
              <Text.Heading style={styles.headlines} text="Solid Options" />
              <Layout.Spacer height={10} />
              <Table.AntDesign
                rowKey={(record) => record._id}
                columns={column}
                data={recommender.recommender.solid}
                loading={recommender.isLoading}
                pagination={false}
              />
            </div>
            <Layout.Spacer height={20} />
            <div>
              <Text.Heading style={styles.headlines} text="Safe Options" />
              <Layout.Spacer height={10} />
              <Table.AntDesign
                rowKey={(record) => record._id}
                columns={column}
                data={recommender.recommender.safe}
                loading={recommender.isLoading}
                pagination={false}
              />
            </div>
          </div>
          {blurData ? (
            <div style={styles.premimumContent}>
              <img src={premiumContent} />
              <div style={styles.premimumContentData}>Pro Feature Only</div>
            </div>
          ) : (
            ""
          )}
        </div>
      )}

      <Layout.Spacer height={20} />
      {error !== "" && <div style={styles.errorText}>{error}</div>}
      <Layout.Spacer height={10} />
      <div style={styles.justifyEnd}>
        <div
          style={{
            ...styles.continueButton,
            cursor: blurData ? "not-allowed" : "pointer",
            opacity: blurData ? ".6" : "1",
          }}
          onClick={() => {
            if (blurData) {
            } else {
              if (selectedUni.length < 2) {
                setError("Select atleast two universities to compare.");
              } else {
                uniCompareAPICall();
              }
            }
          }}
        >
          Compare
        </div>
      </div>
    </div>
  );
}

export default RecommendersResults;

const ColumnDiv = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-start;
  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: contain;
    margin-right: 10px;
  }
  span {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    span:last-of-type {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
`;
