import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import Lottie from "react-lottie-player";

import { FaCheckCircle } from "react-icons/fa";

import { AuthAction } from "../../store/actions";

import { Button, Layout, Text, Modal } from "../../components";
import { Colors, Images } from "../../config";

import styles from "./styles";

import Done from "../../assets/images/57767-done.json";

function SubscriptionSelectFormFlow2() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [currency, setCurrency] = useState("USD");
  const [amount, setAmount] = useState("22.99");
  const [monthlyAmount, setMonthlyAmount] = useState("8.99");
  const [successModal, setSuccessModal] = useState(false);

  useEffect(() => {
    if (location.state.location) {
      if (location.state.location === "Malaysia") {
        setCurrency("MYR");
        setAmount("24.99");
        setMonthlyAmount("9.99");
      } else if (location.state.location === "Pakistan") {
        setCurrency("PKR");
        setAmount("899");
        setMonthlyAmount("299");
      } else if (
        location.state.location === "UAE" ||
        location.state.location === "United Arab Emirates"
      ) {
        setCurrency("AED");
        setAmount("24.99");
        setMonthlyAmount("9.99");
      } else {
        setCurrency("USD");
        setAmount("49.99");
        setMonthlyAmount("19.99");
      }
    } else {
      setCurrency("USD");
      setAmount("22.99");
      setMonthlyAmount("8.99");
    }
  }, []);

  const handleFreeSubscription = () => {
    const body = {
      ...location.state,
      subscriptionPlan: "Free",
    };
    dispatch(
      AuthAction.studentSignup(body, (res) => {
        if (res?.status === 201) {
          setSuccessModal(true);
        }
      })
    );
  };

  const handlePaidSubscription = (paymentType) => {
    const body = {
      ...location.state,
      amount: paymentType === "Monthly" ? monthlyAmount : amount,
      currency,
      subscriptionPlan: "Pro",
      paymentType,
    };

    navigate("/signup/paymentSelectForm", {
      state: body,
    });
  };

  const rightSide = [
    {
      id: 1,
      icon: <FaCheckCircle style={styles.greenIcon} />,
      text: "Unlimited University Search",
    },
    {
      id: 2,
      icon: <FaCheckCircle style={styles.greenIcon} />,
      text: "Live Counseller Availibility",
    },
    {
      id: 3,
      icon: <FaCheckCircle style={styles.greenIcon} />,
      text: "Compare Different Universities",
    },
    {
      id: 4,
      icon: <FaCheckCircle style={styles.greenIcon} />,
      text: "AI Recommender",
    },
    {
      id: 5,
      icon: <FaCheckCircle style={styles.greenIcon} />,
      text: "Automated Essay Grading",
    },
    {
      id: 6,
      icon: <FaCheckCircle style={styles.greenIcon} />,
      text: "Unlimited Online Courses",
    },
  ];

  return (
    <Layout.LoginLayout backLink={"/signup/email"} page={"1st"}>
      <Layout.HeaderSection />
      <div
        style={{
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Text.Heading
          fontSize={"18px"}
          fontWeight={"600"}
          color={Colors.Black}
          text="Select Your Subscription Plan"
        />
        <Text.Heading
          fontSize={"14px"}
          fontWeight={"500"}
          color={Colors.GrayText}
          text="Select this plan to start using"
        />
        <Text.Heading
          fontSize={"14px"}
          fontWeight={"500"}
          color={Colors.GrayText}
          text="UniVision Pro Platform now"
        />
        <Text.Heading
          fontSize={"14px"}
          fontWeight={"500"}
          color={Colors.GrayText}
          text={
            <span style={{ textDecoration: "underline", cursor: "pointer" }}>
              Continue using <b>Free Trial</b> only
            </span>
          }
          onClick={() => handleFreeSubscription()}
        />
      </div>
      <Layout.Spacer height={10} />
      <div style={styles.subscriptionDivContainer}>
        <div style={{ position: "relative" }}>
          <div style={styles.subscriptionTitle}>
            <b>Monthly Payment</b>
          </div>
          <Layout.Spacer height={10} />
          <div style={styles.proDiv}>
            <img src={Images.ProIcon} height={20} />
            Pro
          </div>
          <div style={styles.subscriptionDiv}>
            <div style={{ ...styles.comingSoon, width: "200px", top: "15px" }}>
              <div style={styles.flexRowCenter}>
                <span style={{ color: "black", fontSize: "14px" }}>
                  {currency} {monthlyAmount}
                </span>
              </div>
            </div>

            <div style={styles.uniVisionText}>UniVision</div>
            <div style={styles.subscriptionInnerDiv}>
              <Layout.Spacer height={20} />
              {rightSide?.map((item) => (
                <div key={item.id}>
                  <div style={styles.innerDivValuesContainer}>
                    <div style={{ margin: "5px" }}>{item.icon}</div>
                    <div style={styles.innerDivText}>{item.text}</div>
                  </div>
                </div>
              ))}
              <div
                style={{ color: "black", fontSize: "10px", fontWeight: "600" }}
              >
                & Access to 25+ More Features
              </div>
              <Layout.Spacer height={5} />
              <Button.Primary
                style={styles.subscriptionButtonStyle}
                onClick={() => {
                  const paymentType = "Monthly";
                  handlePaidSubscription(paymentType);
                }}
              >
                Select
              </Button.Primary>
            </div>
          </div>
        </div>
        <div style={{ position: "relative" }}>
          <div style={styles.subscriptionTitle}>
            <b>One Time Payment</b>
          </div>
          <Layout.Spacer height={10} />
          <div style={styles.proDiv}>
            <img src={Images.ProIcon} height={20} />
            Pro
          </div>
          <div style={styles.subscriptionDiv}>
            <div style={{ ...styles.comingSoon, width: "200px", top: "15px" }}>
              <div style={styles.flexRowCenter}>
                <span style={{ color: "black", fontSize: "14px" }}>
                  {currency} {amount}
                </span>
              </div>
            </div>

            <div style={styles.uniVisionText}>UniVision</div>
            <div style={styles.subscriptionInnerDiv}>
              <Layout.Spacer height={20} />
              {rightSide?.map((item) => (
                <div key={item.id}>
                  <div style={styles.innerDivValuesContainer}>
                    <div style={{ margin: "5px" }}>{item.icon}</div>
                    <div style={styles.innerDivText}>{item.text}</div>
                  </div>
                </div>
              ))}
              <div
                style={{ color: "black", fontSize: "10px", fontWeight: "600" }}
              >
                & Access to 25+ More Features
              </div>
              <Layout.Spacer height={5} />
              <Button.Primary
                style={styles.subscriptionButtonStyle}
                onClick={() => {
                  const paymentType = "Onetime";
                  handlePaidSubscription(paymentType);
                }}
              >
                Select
              </Button.Primary>
            </div>
          </div>
        </div>
      </div>
      {successModal && (
        <Modal.Basic
          isModalVisible={successModal}
          centered={true}
          handleCancel={() => {
            setSuccessModal(false);
            navigate("/login");
          }}
          closable={false}
        >
          <div style={styles.flexColumnCenter}>
            <Lottie
              loop
              animationData={Done}
              play
              style={{ width: "68px", height: "68px" }}
            ></Lottie>

            <div style={styles.successText}>Successful!</div>
            <div style={styles.modalDetails}>
              You have signed up successfully, please sign in to start using the
              UniVision platform.
            </div>
            <Button.Primary
              style={styles.modalButton}
              onClick={() => {
                setSuccessModal(false);
                navigate("/login");
              }}
            >
              Ok
            </Button.Primary>
          </div>
        </Modal.Basic>
      )}
    </Layout.LoginLayout>
  );
}

export default SubscriptionSelectFormFlow2;
