//--------------login flow
export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";

export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

export const VERIFY_OTP = "VERIFY_OTP";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const VERIFY_OTP_FAILURE = "VERIFY_OTP_FAILURE";

export const SIGNUP = "SIGNUP";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAILURE = "SIGNUP_FAILURE";

export const SIGNUPSTUDENT = "SIGNUPSTUDENT";
export const SIGNUPSTUDENT_SUCCESS = "SIGNUPSTUDENT_SUCCESS";
export const SIGNUPSTUDENT_FAILURE = "SIGNUPSTUDENT_FAILURE";

export const CHECK_EMAIL = "CHECK_EMAIL";
export const CHECK_EMAIL_SUCCESS = "CHECK_EMAIL_SUCCESS";
export const CHECK_EMAIL_FAILURE = "CHECK_EMAIL_FAILURE";

export const VERIFY_OTP_SIGNUP = "VERIFY_OTP_SIGNUP";
export const VERIFY_OTP_SIGNUP_SUCCESS = "VERIFY_OTP_SIGNUP_SUCCESS";
export const VERIFY_OTP_SIGNUP_FAILURE = "VERIFY_OTP_SIGNUP_FAILURE";

export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const LOADER_TRUE = "LOADER_TRUE";
export const LOADER_FALSE = "LOADER_FALSE";
export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";

//-------------universities
export const GET_UNIVERSITIES = "GET_UNIVERSITIES";
export const GET_UNIVERSITIES_SUCCESS = "GET_UNIVERSITIES_SUCCESS";
export const GET_UNIVERSITIES_FAILURE = "GET_UNIVERSITIES_FAILURE";

export const GET_ALL_UNIVERSITIES = "GET_ALL_UNIVERSITIES";
export const GET_ALL_UNIVERSITIES_SUCCESS = "GET_ALL_UNIVERSITIES_SUCCESS";
export const GET_ALL_UNIVERSITIES_FAILURE = "GET_ALL_UNIVERSITIES_FAILURE";

export const GET_UNIVERSITY_DETAILS = "GET_UNIVERSITY_DETAILS";
export const GET_UNIVERSITY_DETAILS_SUCCESS = "GET_UNIVERSITY_DETAILS_SUCCESS";
export const GET_UNIVERSITY_DETAILS_FAILURE = "GET_UNIVERSITY_DETAILS_FAILURE";

export const ADD_OR_REMOVE_FROM_WISHLIST = "ADD_OR_REMOVE_FROM_WISHLIST";
export const ADD_OR_REMOVE_FROM_WISHLIST_SUCCESS =
  "ADD_OR_REMOVE_FROM_WISHLIST_SUCCESS";
export const ADD_OR_REMOVE_FROM_WISHLIST_FAILURE =
  "ADD_OR_REMOVE_FROM_WISHLIST_FAILURE";

export const COMPARE_UNIVERSITIES = "COMPARE_UNIVERISTIES";
export const COMPARE_UNIVERISTIES_SUCCESS = "COMPARE_UNIVERISTIES_SUCCESS";
export const COMPARE_UNIVERISTIES_FAILURE = "COMPARE_UNIVERISTIES_FAILURE";

//students

export const GET_STUDENT_DETAILS = "GET_STUDENT_DETAILS";
export const GET_STUDENT_DETAILS_SUCCESS = "GET_STUDENT_DETAILS_SUCCESS";
export const GET_STUDENT_DETAILS_FAILURE = "GET_STUDENT_DETAILS_FAILURE";

export const GET_STUDENT_WISHLIST = "GET_STUDENT_WISHLIST";
export const GET_STUDENT_WISHLIST_SUCCESS = "GET_STUDENT_WISHLIST_SUCCESS";
export const GET_STUDENT_WISHLIST_FAILURE = "GET_STUDENT_WISHLIST_FAILURE";

export const SEND_STUDENT_EMAIL = "SEND_STUDENT_EMAIL";
export const SEND_STUDENT_EMAIL_SUCCESS = "SEND_STUDENT_EMAIL_SUCCESS";
export const SEND_STUDENT_EMAIL_FAILURE = "SEND_STUDENT_EMAIL_FAILURE";

//profile
export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_FAILURE = "GET_PROFILE_FAILURE";

export const EDIT_PROFILE = "EDIT_PROFILE";
export const EDIT_PROFILE_SUCCESS = "EDIT_PROFILE_SUCCESS";
export const EDIT_PROFILE_FAILURE = "EDIT_PROFILE_FAILURE";

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";

//miscellaneous
export const ADD_CONTACT = "ADD_CONTACT";
export const ADD_CONTACT_SUCCESS = "ADD_CONTACT_SUCCESS";
export const ADD_CONTACT_FAILURE = "ADD_CONTACT_FAILURE";

//Staff
export const GET_COUNSELORS = "GET_COUNSELORS";
export const GET_COUNSELORS_SUCCESS = "GET_COUNSELORS_SUCCESS";
export const GET_COUNSELORS_FAILURE = "GET_COUNSELORS_FAILURE";

//Announcements
export const GET_ANNOUNCEMENTS = "GET_ANNOUNCEMENTS";
export const GET_ANNOUNCEMENTS_SUCCESS = "GET_ANNOUNCEMENTS_SUCCESS";
export const GET_ANNOUNCEMENTS_FAILURE = "GET_ANNOUNCEMENTS_FAILURE";

export const CHANGE_ANNOUNCEMENT_STATUS = "CHANGE_ANNOUNCEMENT_STATUS";
export const CHANGE_ANNOUNCEMENT_STATUS_SUCCESS =
  "CHANGE_ANNOUNCEMENT_STATUS_SUCCESS";
export const CHANGE_ANNOUNCEMENT_STATUS_FAILURE =
  "CHANGE_ANNOUNCEMENT_STATUS_FAILURE";

export const GET_INDIVIDUAL_ANNOUNCEMENTS = "GET_INDIVIDUAL_ANNOUNCEMENTS";
export const GET_INDIVIDUAL_ANNOUNCEMENTS_SUCCESS =
  "GET_INDIVIDUAL_ANNOUNCEMENTS_SUCCESS";
export const GET_INDIVIDUAL_ANNOUNCEMENTS_FAILURE =
  "GET_INDIVIDUAL_ANNOUNCEMENTS_FAILURE";

export const CHANGE_INDIVIDUAL_ANNOUNCEMENT_STATUS =
  "CHANGE_INDIVIDUAL_ANNOUNCEMENT_STATUS";
export const CHANGE_INDIVIDUAL_ANNOUNCEMENT_STATUS_SUCCESS =
  "CHANGE_INDIVIDUAL_ANNOUNCEMENT_STATUS_SUCCESS";
export const CHANGE_INDIVIDUAL_ANNOUNCEMENT_STATUS_FAILURE =
  "CHANGE_INDIVIDUAL_ANNOUNCEMENT_STATUS_FAILURE";

//meetings
export const ADD_MEETING = "ADD_MEETING";
export const ADD_MEETING_SUCCESS = "ADD_MEETING_SUCCESS";
export const ADD_MEETING_FAILURE = "ADD_MEETING_FAILURE";

export const GET_MEETINGS = "GET_MEETINGS";
export const GET_MEETINGS_SUCCESS = "GET_MEETINGS_SUCCESS";
export const GET_MEETINGS_FAILURE = "GET_MEETINGS_FAILURE";

export const GET_MEETINGS_INVITES_AND_REQUESTS =
  "GET_MEETINGS_INVITES_AND_REQUESTS";
export const GET_MEETINGS_INVITES_AND_REQUESTS_SUCCESS =
  "GET_MEETINGS_INVITES_AND_REQUESTS_SUCCESS";
export const GET_MEETINGS_INVITES_AND_REQUESTS_FAILURE =
  "GET_MEETINGS_INVITES_AND_REQUESTS_FAILURE";

export const GET_ALL_MEETINGS_AND_COUNT = "GET_ALL_MEETINGS_AND_COUNT";
export const GET_ALL_MEETINGS_AND_COUNT_SUCCESS =
  "GET_ALL_MEETINGS_AND_COUNT_SUCCESS";
export const GET_ALL_MEETINGS_AND_COUNT_FAILURE =
  "GET_ALL_MEETINGS_AND_COUNT_FAILURE";

export const GET_MEETINGS_BASED_ON_DATE = "GET_MEETINGS_BASED_ON_DATE";
export const GET_MEETINGS_BASED_ON_DATE_SUCCESS =
  "GET_MEETINGS_BASED_ON_DATE_SUCCESS";
export const GET_MEETINGS_BASED_ON_DATE_FAILURE =
  "GET_MEETINGS_BASED_ON_DATE_FAILURE";

export const CHANGE_MEETING_STATUS = "CHANGE_MEETING_STATUS";
export const CHANGE_MEETING_STATUS_SUCCESS = "CHANGE_MEETING_STATUS_SUCCESS";
export const CHANGE_MEETING_STATUS_FAILURE = "CHANGE_MEETING_STATUS_FAILURE";

// deadlines
export const GET_DEADLINES = "GET_DEADLINES";
export const GET_DEADLINES_SUCCESS = "GET_DEADLINES_SUCCESS";
export const GET_DEADLINES_FAILURE = "GET_DEADLINES_FAILURE";

//applications
export const GET_APPLICATIONS = "GET_APPLICATIONS";
export const GET_APPLICATIONS_SUCCESS = "GET_APPLICATIONS_SUCCESS";
export const GET_APPLICATIONS_FAILURE = "GET_APPLICATIONS_FAILURE";

export const GET_APPLICATIONS_STUDENT = "GET_APPLICATIONS_STUDENT";
export const GET_APPLICATIONS_SUCCESS_STUDENT =
  "GET_APPLICATIONS_SUCCESS_STUDENT";

export const ADD_APPLICATIONS = "ADD_APPLICATIONS";
export const ADD_APPLICATIONS_SUCCESS = "ADD_APPLICATIONS_SUCCESS";
export const ADD_APPLICATIONS_FAILURE = "ADD_APPLICATIONS_FAILURE";

//notifications
export const ADD_NOTIFICATIONS = "ADD_NOTIFICATIONS";
export const ADD_NOTIFICATIONS_SUCCESS = "ADD_NOTIFICATIONS_SUCCESS";
export const ADD_NOTIFICATIONS_FAILURE = "ADD_NOTIFICATIONS_FAILURE";

export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS";
export const GET_NOTIFICATIONS_FAILURE = "GET_NOTIFICATIONS_FAILURE";

export const CHANGE_NOTIFICATIONS_STATUS = "CHANGE_NOTIFICATIONS_STATUS";
export const CHANGE_NOTIFICATIONS_STATUS_SUCCESS =
  "CHANGE_NOTIFICATIONS_STATUS_SUCCESS";
export const CHANGE_NOTIFICATIONS_STATUS_FAILURE =
  "CHANGE_NOTIFICATIONS_STATUS_FAILURE";

//recommender
export const GET_RECOMMENDER = "GET_RECOMMENDER";
export const GET_RECOMMENDER_SUCCESS = "GET_RECOMMENDER_SUCCESS";
export const GET_RECOMMENDER_FAILURE = "GET_RECOMMENDER_FAILURE";

//marketplace products
export const ADD_PRODUCT = "ADD_PRODUCT";
export const ADD_PRODUCT_SUCCESS = "ADD_PRODUCT_SUCCESS";
export const ADD_PRODUCT_FAILURE = "ADD_PRODUCT_FAILURE";

export const GET_PRODUCT = "GET_PRODUCT";
export const GET_PRODUCT_SUCCESS = "GET_PRODUCT_SUCCESS";
export const GET_PRODUCT_FAILURE = "GET_PRODUCT_FAILURE";

export const GET_STUDENT_PRODUCT = "GET_STUDENT_PRODUCT";
export const GET_STUDENT_PRODUCT_SUCCESS = "GET_STUDENT_PRODUCT_SUCCESS";
export const GET_STUDENT_PRODUCT_FAILURE = "GET_STUDENT_PRODUCT_FAILURE";

export const GET_ALL_PRODUCT = "GET_ALL_PRODUCT";
export const GET_ALL_PRODUCT_SUCCESS = "GET_ALL_PRODUCT_SUCCESS";
export const GET_ALL_PRODUCT_FAILURE = "GET_ALL_PRODUCT_FAILURE";

export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT_FAILURE = "UPDATE_PRODUCT_FAILURE";

export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_FAILURE = "DELETE_PRODUCT_FAILURE";

// tracker

export const GET_ALL_TRACKER = "GET_ALL_TRACKER";
export const GET_ALL_TRACKER_SUCCESS = "GET_ALL_TRACKER_SUCCESS";
export const GET_ALL_TRACKER_FAILURE = "GET_ALL_TRACKER_FAILURE";

export const GET_TRACKER = "GET_TRACKER";
export const GET_TRACKER_SUCCESS = "GET_TRACKER_SUCCESS";
export const GET_TRACKER_FAILURE = "GET_TRACKER_FAILURE";

// converter
export const GET_CONVERTER = "GET_CONVERTER";
export const GET_CONVERTER_SUCCESS = "GET_CONVERTER_SUCCESS";
export const GET_CONVERTER_FAILURE = "GET_CONVERTER_FAILURE";

export const UPDATE_CONVERTER = "UPDATE_CONVERTER";
export const UPDATE_CONVERTERG_SUCCESS = "UPDATE_CONVERTER_SUCCESS";
export const UPDATE_CONVERTER_FAILURE = "UPDATE_CONVERTER_FAILURE";

// chatbot
export const GET_MESSAGES = "GET_MESSAGES";
export const GET_MESSAGES_SUCCESS = "GET_MESSAGES_SUCCESS";
export const GET_MESSAGES_FAILURE = "GET_MESSAGES_FAILURE";

export const GET_UNREAD_MESSAGES = "GET_UNREAD_MESSAGES";
export const GET_UNREAD_MESSAGES_SUCCESS = "GET_UNREAD_MESSAGES_SUCCESS";
export const GET_UNREAD_MESSAGES_FAILURE = "GET_UNREAD_MESSAGES_FAILURE";

export const ADD_MESSAGE = "ADD_MESSAGE";
export const ADD_MESSAGE_SUCCESS = "ADD_MESSAGE_SUCCESS";
export const ADD_MESSAGE_FAILURE = "ADD_MESSAGE_FAILURE";

export const GET_USERS = "GET_USERS";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAILURE = "GET_USERS_FAILURE";

// interactive learning
export const GET_UDEMY_COURSES = "GET_UDEMY_COURSES";
export const GET_UDEMY_COURSES_SUCCESS = "GET_UDEMY_COURSES_SUCCESS";
export const GET_UDEMY_COURSES_FAILURE = "GET_UDEMY_COURSES_FAILURE";

export const GET_KHAN_ACADEMY_COURSES = "GET_KHAN_ACADEMY_COURSES";
export const GET_KHAN_ACADEMY_COURSES_SUCCESS =
  "GET_KHAN_ACADEMY_COURSES_SUCCESS";
export const GET_KHAN_ACADEMY_COURSES_FAILURE =
  "GET_KHAN_ACADEMY_COURSES_FAILURE";

// etextbooks
export const GET_ETEXTBOOKS = "GET_ETEXTBOOKS";
export const GET_ETEXTBOOKS_SUCCESS = "GET_ETEXTBOOKS_SUCCESS";
export const GET_ETEXTBOOKS_FAILURE = "GET_ETEXTBOOKS_FAILURE";

// school
export const GET_SCHOOLS = "GET_SCHOOLS";
export const GET_SCHOOLS_SUCCESS = "GET_SCHOOLS_SUCCESS";
export const GET_SCHOOLS_FAILURE = "GET_SCHOOLS_FAILURE";

// individual student
export const UPDATE_INDIVIDUAL_STUDENT = "UPDATE_INDIVIDUAL_STUDENT";
export const UPDATE_INDIVIDUAL_STUDENT_SUCCESS =
  "UPDATE_INDIVIDUAL_STUDENT_SUCCESS";
export const UPDATE_INDIVIDUAL_STUDENT_FAILURE =
  "UPDATE_INDIVIDUAL_STUDENT_FAILURE";

export const ALERT_INDIVIDUAL_STUDENT = "ALERT_INDIVIDUAL_STUDENT";
export const ALERT_INDIVIDUAL_STUDENT_SUCCESS =
  "ALERT_INDIVIDUAL_STUDENT_SUCCESS";
export const ALERT_INDIVIDUAL_STUDENT_FAILURE =
  "ALERT_INDIVIDUAL_STUDENT_FAILURE";

export const UPLOAD_PROOF = "UPLOAD_PROOF";
export const UPLOAD_PROOF_SUCCESS = "UPLOAD_PROOF_SUCCESS";
export const UPLOAD_PROOF_FAILURE = "UPLOAD_PROOF_FAILURE";

export const SET_TRIAL = "SET_TRIAL";
export const SET_TRIAL_SUCCESS = "SET_TRIAL_SUCCESS";
export const SET_TRIAL_FAILURE = "SET_TRIAL_FAILURE";

export const SEND_EMAIL_ADMIN = "SEND_EMAIL_ADMIN";
export const SEND_EMAIL_ADMIN_SUCCESS = "SEND_EMAIL_ADMIN_SUCCESS";
export const SEND_EMAIL_ADMIN_FAILURE = "SEND_EMAIL_ADMIN_FAILURE";

export const CREATE_CHECKOUT_SESSION = "CREATE_CHECKOUT_SESSION";
export const CREATE_CHECKOUT_SESSION_SUCCESS =
  "CREATE_CHECKOUT_SESSION_SUCCESS";
export const CREATE_CHECKOUT_SESSION_FAILURE =
  "CREATE_CHECKOUT_SESSION_FAILURE";

export const CREATE_CHECKOUT_SESSION_PRO = "CREATE_CHECKOUT_SESSION_PRO";
export const CREATE_CHECKOUT_SESSION_PRO_SUCCESS =
  "CREATE_CHECKOUT_SESSION_PRO_SUCCESS";
export const CREATE_CHECKOUT_SESSION_PRO_FAILURE =
  "CREATE_CHECKOUT_SESSION_PRO_FAILURE";

export const CREATE_SUBSCRIPTION = "CREATE_SUBSCRIPTION";
export const CREATE_SUBSCRIPTION_SUCCESS = "CREATE_SUBSCRIPTION_SUCCESS";
export const CREATE_SUBSCRIPTION_FAILURE = "CREATE_SUBSCRIPTION_FAILURE";

export const GET_SUBSCRIPTION = "GET_SUBSCRIPTION";
export const GET_SUBSCRIPTION_SUCCESS = "GET_SUBSCRIPTION_SUCCESS";
export const GET_SUBSCRIPTION_FAILURE = "GET_SUBSCRIPTION_FAILURE";

export const CANCEL_SUBSCRIPTION = "CANCEL_SUBSCRIPTION";
export const CANCEL_SUBSCRIPTION_SUCCESS = "CANCEL_SUBSCRIPTION_SUCCESS";
export const CANCEL_SUBSCRIPTION_FAILURE = "CANCEL_SUBSCRIPTION_FAILURE";
