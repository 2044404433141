import { Colors } from "../../config";

const styles = {
  header: { color: "black", fontSize: "12px", fontWeight: "500" },
  uniDropdown: {
    color: Colors.Primary,
    width: "100%",
    borderColor: Colors.Primary,
    borderRadius: "10px",
    padding: "10px",
    textTransform: "capitalize",
  },
  submitButton: {
    backgroundColor: Colors.Primary,
    height: "35px",
    width: "150px",
    fontSize: "14px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "5px",
    cursor: "pointer",
  },
  trackerValuesTitle: {
    fontSize: "18px",
    fontWeight: "600",
    color: Colors.Primary,
  },
  trackerValues: {
    fontSize: "18px",
    fontWeight: "400",
    color: Colors.GrayText,
    textTransform: "capitalize",
  },
};

export default styles;
