import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as Icon from "react-icons";
import Lottie from "react-lottie-player";

import { Button, Layout, Modal, Text } from "../../components";
import { Colors } from "../../config";

import ComingSoon from "../../assets/images/113096-coming-soon-optimized.json";

function Tour() {
  return (
    <div>
      <Text.Heading
        fontSize={"18px"}
        fontWeight={"600"}
        color={Colors.Primary}
        text="University Virtual Tour"
      />

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "100px",
        }}
      >
        <Lottie
          loop
          animationData={ComingSoon}
          play
          style={{ width: "600px", height: "auto" }}
        ></Lottie>
      </div>
    </div>
  );
}

export default Tour;
