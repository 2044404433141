import { useState } from "react";
import { useDispatch } from "react-redux";
import { Checkbox } from "antd";
import { Link } from "react-router-dom";

import { HiOutlineEyeOff, HiOutlineEye } from "react-icons/hi";
import { AuthAction } from "../../store/actions";
import { Button, Layout } from "../../components";
import { Utils } from "../../config";

import styles from "./styles";

const LoginForm = () => {
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);
  const [state, setState] = useState({
    email: "",
    password: "",
    emailErrMsg: "",
    passErrMsg: "",
    validEmail: true,
    validPass: true,
  });
  const [checked, setChecked] = useState(false);

  const onChange = (e) => {
    setChecked(e.target.checked);
  };

  const handleLogin = (e) => {
    e.preventDefault();
    if (state.email === "") {
      setState({
        ...state,
        emailErrMsg: "Email can not be left empty.",
        validEmail: false,
      });
    } else if (Utils.emailRegex.test(state.email) === false) {
      setState({
        ...state,
        emailErrMsg: "Please enter a valid email address.",
        validEmail: false,
      });
    } else if (state.password === "") {
      setState({
        ...state,
        emailErrMsg: "",
        validEmail: true,
        passErrMsg: "Password can not be left empty.",
        validPass: false,
      });
    } else if (Utils.passwordRegex.test(state.password) === false) {
      setState({
        ...state,
        emailErrMsg: "",
        validEmail: true,
        passErrMsg: "Please enter a valid password.",
        validPass: false,
      });
    } else {
      setState({
        ...state,
        emailErrMsg: "",
        validEmail: true,
        passErrMsg: "",
        validPass: true,
      });
      const body = {
        email: state.email,
        password: state.password,
        role: "student",
      };
      dispatch(AuthAction.SignIn(body));
    }
  };

  return (
    <form style={styles.loginFormContainer} onSubmit={handleLogin}>
      <input
        type="email"
        placeholder="Enter your email"
        onChange={(e) =>
          setState({
            ...state,
            email: e.target.value,
          })
        }
        style={styles.inputFieldBorder}
      />

      {state.emailErrMsg && (
        <div className="text-danger">{state.emailErrMsg}</div>
      )}

      <Layout.Spacer height={20} />

      <div style={styles.inputFieldBorder}>
        <input
          type={showPassword ? "text" : "password"}
          placeholder="Enter your password"
          onChange={(e) => {
            setState({
              ...state,
              password: e.target.value,
            });
          }}
          style={{
            outline: "none",
            background: "transparent",
            border: "none",
            width: "93%",
          }}
        />
        <div>
          {showPassword ? (
            <HiOutlineEye
              style={styles.passwordIcon}
              onClick={() => setShowPassword(false)}
            />
          ) : (
            <HiOutlineEyeOff
              style={styles.passwordIcon}
              onClick={() => setShowPassword(true)}
            />
          )}
        </div>
      </div>
      {state.passErrMsg && (
        <div className="text-danger">{state.passErrMsg}</div>
      )}

      <Layout.Spacer height={10} />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Checkbox checked={checked} onChange={onChange}>
          <span style={{ fontSize: "14px" }}>Remember me</span>
        </Checkbox>

        <Link to="/forgot-password" style={styles.forgotPasswordText}>
          Forgot Password?
        </Link>
      </div>
      <Layout.Spacer height={30} />

      <Button.Primary
        style={styles.buttonStyle}
        onClick={handleLogin}
        type="submit"
      >
        Login
      </Button.Primary>

      <Layout.Spacer height={20} />
      <div style={styles.signupContainer}>
        <span style={styles.signupText}>Don't have an account?</span>
        <Link to="/signup/personalInfo" style={styles.signupLink}>
          Sign Up
        </Link>
      </div>
    </form>
  );
};

export default LoginForm;
