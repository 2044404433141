import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { createLogger } from "redux-logger";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import RootReducer from "./reducers";
import { Sagas } from "./sagas/Sagas";

const sagaMiddleware = createSagaMiddleware();

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"],
};

const persistedReducer = persistReducer(persistConfig, RootReducer);

const store = createStore(
  persistedReducer,
  {},
  process.env.REACT_APP_ENV === "local"
    ? applyMiddleware(createLogger(), sagaMiddleware)
    : applyMiddleware(sagaMiddleware)
);

let persistor = persistStore(store);

sagaMiddleware.run(Sagas);

export { store, persistor };
