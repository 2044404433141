import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styles from "../../containers/Dashboard/styles";

const Clock = () => {
  const studentDetail = useSelector((state) => state.auth.user.user);

  const [days, setDays] = useState();
  const [minutes, setMinutes] = useState();
  const [hours, setHours] = useState();
  const [seconds, setSeconds] = useState();

  useEffect(() => {
    const dest = new Date(studentDetail.validUntil);
    const inputDate = new Date().getTime() + 5 * 24 * 60 * 60 * 1000; // 5 days in milliseconds

    const intervalId = setInterval(function () {
      let now = new Date().getTime();
      let diff = dest - now;

      let days = Math.max(Math.floor(diff / (1000 * 60 * 60 * 24)), 0);
      setDays(days);

      let hours = Math.max(
        Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
        0
      );
      setHours(hours);

      let minutes = Math.max(
        Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60)),
        0
      );
      setMinutes(minutes);

      let seconds = Math.max(Math.floor((diff % (1000 * 60)) / 1000), 0);
      setSeconds(seconds);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [studentDetail.validUntil]);

  return (
    <>
      <div style={styles.timerRightPartMain}>
        <div style={styles.timerRightPart1}>{days}</div>
        <div style={styles.timerRightPart2}>days</div>
      </div>
      <div style={styles.timerRightPartMain}>
        <div style={styles.timerRightPart1}>{hours}</div>
        <div style={styles.timerRightPart2}>hours</div>
      </div>
      <div style={styles.timerRightPartMain}>
        <div style={styles.timerRightPart1}>{minutes}</div>
        <div style={styles.timerRightPart2}>mins</div>
      </div>
      <div style={styles.timerRightPartMain}>
        <div style={styles.timerRightPart1}>{seconds}</div>
        <div style={styles.timerRightPart2}>secs</div>
      </div>
    </>
  );
};

export default Clock;
