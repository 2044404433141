import {
  UPDATE_CONVERTER,
  UPDATE_CONVERTERG_SUCCESS,
  UPDATE_CONVERTER_FAILURE,
} from "../constants";

const initialState = {
  converted: false,
  convertTo: "",
  isLoading: false,
  error: null,
};

export default function ConverterReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_CONVERTER:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;
    case UPDATE_CONVERTERG_SUCCESS:
      state = {
        ...state,
        converted: action?.payload?.converted,
        convertTo: action?.payload?.convertTo,
        isLoading: false,
      };
      break;

    case UPDATE_CONVERTER_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      break;
    default:
      break;
  }
  return state;
}
