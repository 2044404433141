import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { message } from "antd";
import Lottie from "react-lottie-player";

import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";

import { IndividualStudentAction, AuthAction } from "../../store/actions";

import { Layout, Button, Modal } from "../../components";

import styles from "./styles";

import Done from "../../assets/images/57767-done.json";

const CardFormFlow2 = ({ othervalues }) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isDisabled, setDisabled] = useState(false);
  const [successModal, setSuccessModal] = useState(false);

  const handleNext = async () => {
    setDisabled(true);
    const paymentMethod = await stripe.createPaymentMethod({
      card: elements.getElement("card"),
      type: "card",
    });

    const body = {
      ...othervalues,
      payment: paymentMethod.paymentMethod.id,
    };

    dispatch(
      IndividualStudentAction.createSubscription(body, (res) => {
        if (res.status === 201) {
          const payload = {
            ...body,
            ...res.data,
          };

          dispatch(
            AuthAction.studentSignup(payload, async (res) => {
              if (res.status === 201) {
                setSuccessModal(true);
              } else {
                message.error(res.message);
                setDisabled(false);
              }
            })
          );
        } else {
          message.error(res.message);
          setDisabled(false);
        }
      })
    );
  };

  return (
    <div>
      <div style={styles.stripeInput}>
        <CardElement />
      </div>
      <Layout.Spacer height={20} />
      <Button.Primary
        style={styles.buttonStyle}
        onClick={handleNext}
        disabled={isDisabled}
      >
        Continue
      </Button.Primary>
      {successModal && (
        <Modal.Basic
          isModalVisible={successModal}
          centered={true}
          handleCancel={() => {
            setSuccessModal(false);
            navigate("/login");
          }}
          closable={false}
        >
          <div style={styles.flexColumnCenter}>
            <Lottie
              loop
              animationData={Done}
              play
              style={{ width: "68px", height: "68px" }}
            ></Lottie>

            <div style={styles.successText}>Successful!</div>
            <div style={styles.modalDetails}>
              Thank you for your payment! Please sign in to start using the
              UniVision Pro platform.
            </div>
            <Button.Primary
              style={styles.modalButton}
              onClick={() => {
                setSuccessModal(false);
                navigate("/login");
              }}
            >
              Ok
            </Button.Primary>
          </div>
        </Modal.Basic>
      )}
    </div>
  );
};

export default CardFormFlow2;
