import React from "react";
import { useLocation } from "react-router";

import { Text, Layout } from "../../components";
import { Colors } from "../../config";

function Message() {
  const { state } = useLocation();

  return (
    <div>
      <Text.Heading
        fontSize={"18px"}
        fontWeight={"600"}
        color={Colors.Primary}
        text="Message"
        textAlign="left"
      />
      <Layout.Spacer height={10} />
      <Text.Heading
        fontSize={"16px"}
        fontWeight={"400"}
        color={Colors.BlackText}
        text={state?.data?.message}
        textAlign="left"
      />
    </div>
  );
}

export default Message;
