import React from "react";
import { useNavigate } from "react-router";

import { Layout, Text } from "../../components";
import { Colors } from "../../config";

import styles from "./styles";

function CircleContainer({ page }) {
  const navigate = useNavigate();

  return (
    <>
      <div style={styles.justifyBetweenItemsCenter}>
        <Text.Heading
          fontSize={"18px"}
          fontWeight={"600"}
          color={Colors.Primary}
          text="AI Recommender"
        />

        {page === "results" && (
          <div
            style={styles.continueButton}
            onClick={() => {
              navigate("/recommender");
            }}
          >
            Main Menu
          </div>
        )}
      </div>
      <Layout.Spacer height={20} />
      <div style={{ display: "flex" }}>
        {page === "qualifications" ? (
          <>
            <div style={styles.headerAndCircleContainer}>
              <div style={styles.activeCircleHeader}>Qualifications</div>
              <div style={styles.activeCircle}></div>
            </div>
            <div style={styles.veticalLine}>
              <hr />
            </div>
            <div style={styles.headerAndCircleContainer}>
              <div style={styles.grayCircleHeader}>Preferences</div>
              <div style={styles.grayCircle}></div>
            </div>
            <div style={styles.veticalLine}>
              <hr />
            </div>
            <div style={styles.headerAndCircleContainer}>
              <div style={styles.grayCircleHeader}>Results</div>
              <div style={styles.grayCircle}></div>
            </div>
          </>
        ) : page === "preferences" ? (
          <>
            <div style={styles.headerAndCircleContainer}>
              <div style={styles.activeCircleHeader}>Qualifications</div>
              <div style={styles.activeCircle}></div>
            </div>
            <div style={styles.veticalLine}>
              <hr />
            </div>
            <div style={styles.headerAndCircleContainer}>
              <div style={styles.activeCircleHeader}>Preferences</div>
              <div style={styles.activeCircle}></div>
            </div>
            <div style={styles.veticalLine}>
              <hr />
            </div>
            <div style={styles.headerAndCircleContainer}>
              <div style={styles.grayCircleHeader}>Results</div>
              <div style={styles.grayCircle}></div>
            </div>
          </>
        ) : (
          <>
            <div style={styles.headerAndCircleContainer}>
              <div style={styles.activeCircleHeader}>Qualifications</div>
              <div style={styles.activeCircle}></div>
            </div>
            <div style={styles.veticalLine}>
              <hr />
            </div>
            <div style={styles.headerAndCircleContainer}>
              <div style={styles.activeCircleHeader}>Preferences</div>
              <div style={styles.activeCircle}></div>
            </div>
            <div style={styles.veticalLine}>
              <hr />
            </div>
            <div style={styles.headerAndCircleContainer}>
              <div style={styles.activeCircleHeader}>Results</div>
              <div style={styles.activeCircle}></div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default CircleContainer;
