const Currencies = [
  { currency: "AED", amount: 0.27, code: "AE" },
  { currency: "AMD", amount: 0.0026, code: "AM" },
  { currency: "ANG", amount: 0.56, code: "AN" },
  { currency: "ARS", amount: 0.0029, code: "AR" },
  { currency: "AUD", amount: 0.66, code: "AU" },
  { currency: "AZN", amount: 0.59, code: "AZ" },
  { currency: "BAM", amount: 0.55, code: "BA" },
  { currency: "BDT", amount: 0.0091, code: "BD" },
  { currency: "BHD", amount: 2.68, code: "BH" },
  { currency: "BND", amount: 0.75, code: "BN" },
  { currency: "BRL", amount: 0.21, code: "BR" },
  { currency: "CAD", amount: 0.75, code: "CA" },
  { currency: "CHF", amount: 1.15, code: "CH" },
  { currency: "CLP", amount: 0.001, code: "CL" },
  { currency: "CNY", amount: 0.14, code: "CN" },
  { currency: "COP", amount: 0.00026, code: "CO" },
  { currency: "CRC", amount: 0.002, code: "CR" },
  { currency: "CUC", amount: 1, code: "CU" },
  { currency: "CZK", amount: 0.045, code: "CZ" },
  { currency: "DEM", amount: 0.547099, code: "DE" },
  { currency: "DHS", amount: 0.27, code: "AE" },
  { currency: "DKK", amount: 0.15, code: "DK" },
  { currency: "DOP", amount: 0.017, code: "DO" },
  { currency: "EEK", amount: 0.069, code: "EE" },
  { currency: "EGP", amount: 0.032, code: "EG" },
  { currency: "EUR", amount: 1.1, code: "EU" },
  { currency: "GBP", amount: 1.27, code: "GB" },
  { currency: "HKD", amount: 0.13, code: "HK" },
  { currency: "IDR", amount: 0.000065, code: "ID" },
  { currency: "IEP", amount: 1.35742, code: "IE" },
  { currency: "ILS", amount: 0.27, code: "IL" },
  { currency: "INR", amount: 0.012, code: "IN" },
  { currency: "IQD", amount: 0.00077, code: "IQ" },
  { currency: "IRR", amount: 0.000024, code: "IR" },
  { currency: "ITL", amount: 0.00055, code: "IT" },
  { currency: "JOD", amount: 1.41, code: "JO" },
  { currency: "JPY", amount: 0.0068, code: "JP" },
  { currency: "KES", amount: 0.0076, code: "KE" },
  { currency: "KRW", amount: 0.00075, code: "KR" },
  { currency: "KWD", amount: 3.28, code: "KW" },
  { currency: "KZT", amount: 0.0022, code: "KZ" },
  { currency: "LBP", amount: 0.000067, code: "LB" },
  { currency: "LE", amount: 0.032, code: "EG" },
  { currency: "LKR", amount: 0.0033, code: "LK" },
  { currency: "MOP", amount: 0.12, code: "MO" },
  { currency: "MXN", amount: 0.059, code: "MX" },
  { currency: "MYR", amount: 0.22, code: "MY" },
  { currency: "NTD", amount: 0.031, code: "TW" },
  { currency: "NZD", amount: 0.59, code: "NZ" },
  { currency: "OMR", amount: 2.61, code: "OM" },
  { currency: "PEN", amount: 0.27, code: "PE" },
  { currency: "PHP", amount: 0.018, code: "PH" },
  { currency: "PKR", amount: 0.0036, code: "PK" },
  { currency: "PLN", amount: 0.25, code: "PL" },
  { currency: "QAR", amount: 0.27, code: "QA" },
  { currency: "RD", amount: 0.017, code: "DO" },
  { currency: "RM", amount: 0.22, code: "MY" },
  { currency: "RMB", amount: 0.14, code: "CN" },
  { currency: "RON", amount: 0.22, code: "RO" },
  { currency: "RUB", amount: 0.01, code: "RU" },
  { currency: "SAR", amount: 0.27, code: "SA" },
  { currency: "SDG", amount: 0.0017, code: "SD" },
  { currency: "SEK", amount: 0.094, code: "SE" },
  { currency: "SGD", amount: 0.75, code: "SG" },
  { currency: "THB", amount: 0.028, code: "TH" },
  { currency: "TRY", amount: 0.037, code: "TR" },
  { currency: "TWD", amount: 0.031, code: "TW" },
  { currency: "UGX", amount: 0.00026, code: "UG" },
  { currency: "USD", amount: 1, code: "US" },
  { currency: "VEF", amount: 0.00000027, code: "VE" },
  { currency: "VES", amount: 0.02753, code: "VE" },
  { currency: "VND", amount: 0.00004, code: "VN" },
  { currency: "ZAR", amount: 0.054, code: "ZA" },
];

module.exports = Currencies;
