import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import PhoneInput from "react-phone-input-2";

import { AuthAction } from "../../store/actions";

import { Button, Layout } from "../../components";
import { Utils } from "../../config";

import styles from "./styles";

import "react-phone-input-2/lib/bootstrap.css";

function ContactInfoForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [state, setState] = useState({
    email: "",
    phoneNumber: "92",
  });

  const handleNext = () => {
    if (!state.email || !state.phoneNumber) {
      setState({
        ...state,
        error: "Please fill all the fields.",
      });
    } else if (Utils.emailRegex.test(state.email) === false) {
      setState({
        ...state,
        emailError: "Please enter a valid email address",
      });
    } else if (Utils.phoneRegex.test(`+${state.phoneNumber}`) === false) {
      setState({
        ...state,
        phoneError: "Please enter a valid phone number",
      });
    } else {
      setState({
        ...state,
        error: "",
      });
      const body = {
        email: state.email,
        name: location?.state?.fullname,
        phoneNumber: `+${state.phoneNumber}`,
        role: location?.state?.role,
      };
      dispatch(
        AuthAction.CheckEmail(body, (res) => {
          if (res.status === 200) {
            navigate("/signup/otpform", {
              state: {
                email: state.email,
                phoneNumber: `+${state.phoneNumber}`,
                otp: res.data.code,
                ...location.state,
              },
            });
          }
        })
      );
    }
  };

  return (
    <Layout.LoginLayout backLink={"/signup/personalInfo"}>
      <Layout.HeaderSection title="Sign Up" subtitle="Enter email to get OTP" />
      <Layout.Spacer height={10} />
      <div style={styles.botomTitleStyle}>Contact Information</div>
      <Layout.Spacer height={10} />
      <div style={styles.container}>
        <label style={styles.labelStyle}>
          Email <span style={{ color: "red" }}>*</span>
        </label>
        <Layout.Spacer height={5} />
        <input
          type="email"
          placeholder="xyz@emailprovider.com"
          value={state.email}
          onChange={(e) => {
            setState({
              ...state,
              email: e.target.value,
            });
          }}
          className="signup-input"
          style={styles.inputFieldBorder}
        />
        {state.emailError && (
          <div className="text-danger">{state.emailError}</div>
        )}
        <Layout.Spacer height={10} />
        <label style={styles.labelStyle}>
          Phone Number <span style={{ color: "red" }}>*</span>
        </label>
        <Layout.Spacer height={5} />
        <div className="signup-form-2">
          <PhoneInput
            country={"pk"}
            onChange={(e) => {
              setState({
                ...state,
                phoneNumber: e,
              });
            }}
            containerClass="phone-container"
            inputClass="phone-input"
            className="signup-form-2"
            countryCodeEditable={true}
            value={state.phoneNumber}
          />
        </div>
        {state.phoneError && (
          <div className="text-danger">{state.phoneError}</div>
        )}
      </div>
      <Layout.Spacer height={20} />

      {state.error && (
        <div style={{ textAlign: "center" }}>
          <div className="text-danger">{state.error}</div>
        </div>
      )}

      <Button.Primary style={styles.buttonStyle} onClick={handleNext}>
        Continue
      </Button.Primary>

      <Layout.Spacer height={30} />
      <div style={styles.signinContainer}>
        <span style={styles.signinText}>Already have an account?</span>
        <Link to="/login" style={styles.signinLink}>
          Sign In
        </Link>
      </div>
    </Layout.LoginLayout>
  );
}

export default ContactInfoForm;
