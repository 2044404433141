import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PhoneInput from "react-phone-input-2";
import { Form } from "react-bootstrap";
import { Select } from "antd";

import { AiFillCamera } from "react-icons/ai";

import { ProfileAction } from "../../store/actions";

import { Button, Layout, Text, TextField } from "../../components";
import { Colors, Images } from "../../config";

import styles from "./style";
import "react-phone-input-2/lib/style.css";

const imageMimeType = /image\/(png|jpg|jpeg)/i;

function Profile() {
  const dispatch = useDispatch();
  const profileImgRef = useRef(null);

  const profile = useSelector((state) => state.profile);

  const [buttonText, setButtonText] = useState("Confirm");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [error, setError] = useState("");
  const [state, setState] = useState({
    email: profile?.user?.email,
    name: profile?.user?.fullName || "NA",
    nationality: profile?.user?.studentId?.nationality || "NA",
    location: profile?.user?.studentId?.location || "NA",
    phoneNumber: profile?.user?.phoneNumber || "NA",
    linkedIn: profile?.user?.studentId?.linkedIn || "NA",
    grade: profile?.user?.studentId?.grade || "NA",
    age: profile?.user?.studentId?.age || "NA",
    gender: profile?.user?.studentId?.gender || "NA",
    about: profile?.user?.studentId?.about || "NA",
  });
  const [file, setFile] = useState(null);
  const [fileDataURL, setFileDataURL] = useState(
    profile?.user?.profileImage
      ? profile?.user?.profileImage
      : Images.DummyImage
  );

  useEffect(() => {
    dispatch(ProfileAction.getUserProfile());
  }, []);

  useEffect(() => {
    let fileReader,
      isCancel = false;
    if (file) {
      fileReader = new FileReader();
      fileReader.onload = (e) => {
        const { result } = e.target;
        if (result && !isCancel) {
          setFileDataURL(result);
        }
      };
      fileReader.readAsDataURL(file);
    }
    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    };
  }, [file]);

  const defaultState = () => {
    setButtonDisabled(false);
    setButtonText("Confirm");
  };

  const handleEdit = () => {
    setButtonDisabled(true);
    setButtonText("Saving...");
    if (
      !state.email ||
      !state.name ||
      !state.nationality ||
      !state.location ||
      !state.phoneNumber ||
      !state.linkedIn ||
      !state.grade ||
      !state.age ||
      !state.gender ||
      !state.about
    ) {
      defaultState();
      setError("Please enter all fields");
    } else {
      setError("");

      let data = new FormData();
      data.append("email", state.email);
      data.append("name", state.name);
      data.append("nationality", state.nationality);
      data.append("location", state.location);
      data.append("phoneNumber", state.phoneNumber);
      data.append("linkedIn", state.linkedIn);
      data.append("grade", state.grade);
      data.append("age", state.age);
      data.append("gender", state.gender);
      data.append("about", state.about);
      data.append("image", file ? file : fileDataURL);
      dispatch(
        ProfileAction.editUserProfile(data, () => {
          defaultState();
          dispatch(ProfileAction.getUserProfile());
        })
      );
    }
  };

  const changeHandler = (e) => {
    const file = e.target.files[0];
    if (!file.type.match(imageMimeType)) {
      alert("Image mime type is not valid");
      return;
    }
    setFile(file);
  };

  return (
    <>
      <Text.Heading
        fontSize={"18px"}
        fontWeight={"600"}
        color={Colors.Primary}
        text="User Profile"
      />
      <Layout.Spacer height={60} />
      <div style={styles.inputContainer}>
        <div
          style={{
            borderRight: `1px solid ${Colors.Primary}`,
          }}
        >
          <Layout.Spacer height={20} />
          <div style={styles.flexRowCenter}>
            <div style={styles.positionRelative}>
              <img src={fileDataURL} style={styles.profileImg} alt="profile" />
              <div
                style={styles.imageUploadDiv}
                onClick={() => profileImgRef.current.click()}
              >
                <AiFillCamera color={Colors.Primary} fontSize={20} />
              </div>
            </div>
            <input
              type={"file"}
              ref={profileImgRef}
              accept=".png, .jpg, .jpeg"
              onChange={changeHandler}
              style={styles.inputDisplayNone}
            />
          </div>
          <Layout.Spacer height={20} />
          <div style={styles.emailText}>{state.email}</div>
        </div>
        <div style={styles.leftSideContainer}>
          <div>
            <TextField.CustomInput
              placeholder="Enter your name"
              value={state.name}
              onChange={(e) =>
                setState({
                  ...state,
                  name: e.target.value,
                })
              }
              label="Name"
              style={styles.textfieldStyle}
            />
          </div>
          <div>
            <TextField.CustomInput
              placeholder="Enter your nationality"
              value={state.nationality}
              onChange={(e) =>
                setState({
                  ...state,
                  nationality: e.target.value,
                })
              }
              label="Nationality"
              style={styles.textfieldStyle}
            />
          </div>
          <div>
            <TextField.CustomInput
              placeholder="Enter your location"
              value={state.location}
              onChange={(e) =>
                setState({
                  ...state,
                  location: e.target.value,
                })
              }
              label="Location"
              style={styles.textfieldStyle}
            />
          </div>
          <div>
            <label style={styles.labelStyle}>Phone number</label>
            <PhoneInput
              country={"my"}
              value={state.phoneNumber}
              onChange={(phone) =>
                setState({
                  ...state,
                  phoneNumber: phone,
                })
              }
            />
          </div>
          <div>
            <TextField.CustomInput
              placeholder="Enter your LinkedIn URL"
              value={state.linkedIn}
              onChange={(e) =>
                setState({
                  ...state,
                  linkedIn: e.target.value,
                })
              }
              label="LinkedIn"
              style={styles.textfieldStyle}
            />
          </div>
          <div>
            <div style={{ color: "black" }}>Grade / Class</div>
            <div className="profile-form-1">
              <Select
                value={state.grade}
                onChange={(e) => {
                  setState({
                    ...state,
                    grade: e,
                  });
                }}
                style={{
                  width: "100%",
                }}
              >
                <Select.Option value="10 (E.G. O-level First Year)">
                  10 (E.G. O-level First Year)
                </Select.Option>
                <Select.Option value="11 (E.G. O-level Second Year)">
                  11 (E.G. O-level Second Year)
                </Select.Option>
                <Select.Option value="12 (E.G. A-level First Year)">
                  12 (E.G. A-level First Year)
                </Select.Option>
                <Select.Option value="13 (E.G. A-level Second Year)">
                  13 (E.G. A-level Second Year)
                </Select.Option>
              </Select>
            </div>
          </div>
          <div>
            <TextField.CustomInput
              placeholder="Enter Age"
              value={state.age}
              onChange={(e) =>
                setState({
                  ...state,
                  age: e.target.value,
                })
              }
              label="Age"
              style={styles.textfieldStyle}
            />
          </div>
          <div>
            <TextField.CustomInput
              placeholder="Gender"
              value={state.gender}
              onChange={(e) =>
                setState({
                  ...state,
                  gender: e.target.value,
                })
              }
              label="Gender"
              style={styles.textfieldStyle}
            />
          </div>
        </div>
      </div>
      <Layout.Spacer height={20} />
      <Text.Heading
        fontSize={"16px"}
        fontWeight={"500"}
        color={Colors.Primary}
        text="About me"
      />
      <TextField.CustomTextArea
        rows={3}
        name="about"
        value={state.about}
        onChange={(e) =>
          setState({
            ...state,
            about: e.target.value,
          })
        }
        placeholder="About"
        style={styles.textfieldStyle}
      />
      <Layout.Spacer height={20} />
      {error && (
        <>
          <Layout.Spacer height={20} />
          <div style={styles.flexRowCenter}>
            <Text.Heading
              fontSize={"16px"}
              fontWeight={"400"}
              color={"red"}
              text={error}
            />
          </div>
        </>
      )}
      <div style={styles.justifyRight}>
        <Button.Primary
          style={styles.buttonStyle}
          onClick={handleEdit}
          disabled={buttonDisabled}
        >
          {buttonText}
        </Button.Primary>
      </div>
    </>
  );
}

export default Profile;
