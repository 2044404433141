import {
  GET_STUDENT_DETAILS,
  GET_STUDENT_DETAILS_FAILURE,
  GET_STUDENT_DETAILS_SUCCESS,
  GET_SCHOOLS,
  GET_SCHOOLS_FAILURE,
  GET_SCHOOLS_SUCCESS,
} from "../constants";

export default class StudentAction {
  static getStudentDetails(id, cb) {
    return {
      type: GET_STUDENT_DETAILS,
      id,
      cb,
    };
  }
  static getStudentDetailsSuccess(payload) {
    return {
      type: GET_STUDENT_DETAILS_SUCCESS,
      payload,
    };
  }
  static getStudentDetailsFailure(payload) {
    return {
      type: GET_STUDENT_DETAILS_FAILURE,
      payload,
    };
  }
  static getSchools(payload, cb) {
    return {
      type: GET_SCHOOLS,
      payload,
      cb,
    };
  }
  static getSchoolsSuccess(payload) {
    return {
      type: GET_SCHOOLS_SUCCESS,
      payload,
    };
  }
  static getSchoolsFailure(payload) {
    return {
      type: GET_SCHOOLS_FAILURE,
      payload,
    };
  }
}
