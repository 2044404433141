import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import { MarketplaceAction } from "../../store/actions";

import { Button, Layout, Text, Loader } from "../../components";
import { Colors, Images } from "../../config";

import styles from "./styles";

function Marketplace() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [books, setBooks] = useState([]);
  const [papers, setPapers] = useState([]);
  const [assignments, setAssignments] = useState([]);
  const [stationery, setStationery] = useState([]);

  const studentId = useSelector((state) => state.auth.user.user.studentId);
  const schoolId = useSelector((state) => state.auth.user.user.schoolId);
  const marketplaceLoading = useSelector((state) => state.marketplace);

  useEffect(() => {
    const payload = {
      studentId,
      schoolId,
      type: "all",
    };
    dispatch(
      MarketplaceAction.getAllProduct(payload, (res) => handleValues(res))
    );
  }, []);

  const handleValues = (res) => {
    const books = [];
    const papers = [];
    const assignments = [];
    const stationery = [];
    res?.allProduct?.map((product) => {
      if (product.itemType === "book") {
        books.push(product);
      }
      if (product.itemType === "pastpaper") {
        papers.push(product);
      }
      if (product.itemType === "pastassignment") {
        assignments.push(product);
      }
      if (product.itemType === "stationery") {
        stationery.push(product);
      }
    });

    setBooks(books);
    setPapers(papers);
    setAssignments(assignments);
    setStationery(stationery);
  };

  const values = [
    { id: 1, title: "Books", route: "/more/marketplace/books", value: books },
    {
      id: 2,
      title: "Past Papers",
      route: "/more/marketplace/papers",
      value: papers,
    },
    {
      id: 3,
      title: "Past Assignments",
      route: "/more/marketplace/assignments",
      value: assignments,
    },
    {
      id: 4,
      title: "Stationery",
      route: "/more/marketplace/stationery",
      value: stationery,
    },
  ];
  return (
    <div>
      <div style={styles.justifyBetweenAlignCenter}>
        <div>
          <Text.Heading
            fontSize={"18px"}
            fontWeight={"600"}
            color={Colors.Primary}
            text="Marketplace"
          />
        </div>
        <div style={styles.justifyBetweenAlignCenter}>
          <div
            style={styles.ownProduct}
            onClick={() => navigate("/more/marketplace/yourProducts/")}
          >
            Your Products
          </div>
          <Button.Primary
            style={styles.addProductButton}
            onClick={() => navigate("/more/marketplace/addproduct")}
          >
            Sell Product
          </Button.Primary>
        </div>
      </div>
      <Layout.Spacer height={20} />
      {marketplaceLoading?.isLoading ? (
        <Loader.Circular />
      ) : (
        <div>
          {values?.map((item) => (
            <div key={item.id}>
              <div style={styles.justifyBetweenAlignCenter}>
                <div style={styles.productTypeLabel}>{item.title}</div>
                <div style={styles.seeAll} onClick={() => navigate(item.route)}>
                  See All
                </div>
              </div>
              <Layout.Spacer height={10} />
              {item?.value?.length === 0 ? (
                <div style={styles.noDataContainer}>
                  <img src={Images.NoData} height={60} width={60} alt="" />
                  <div style={styles.noData}>No Data</div>
                </div>
              ) : (
                <div style={styles.productContainer}>
                  {item?.value?.slice(0, 5)?.map((product) => {
                    return (
                      <div
                        style={styles.constainerStyle}
                        onClick={() =>
                          navigate(`/more/marketplace/product/${product._id}`)
                        }
                        key={product._id}
                      >
                        <div style={styles.imageContainerStyle}>
                          <img
                            src={product.itemPhoto}
                            height={62}
                            width={62}
                            style={{ borderRadius: "5px" }}
                          />
                        </div>
                        <div style={styles.textFieldContainer}>
                          <div style={styles.itemNameStyle}>
                            {product?.itemName?.length > 30
                              ? product?.itemName.slice(0, 30) + "..."
                              : product?.itemName}
                          </div>
                          <div style={styles.itemPriceStyle}>
                            {product?.price.split(" ")[0]}{" "}
                            {parseFloat(
                              product?.price.split(" ")[1]
                            ).toLocaleString()}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
              <Layout.Spacer height={20} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default Marketplace;
