import { useNavigate } from "react-router";

import { AiOutlineRight } from "react-icons/ai";

import { Colors } from "../../config";
import { Layout, Text } from "../../components";

import styles from "./styles";

function StepsSection({ heading, stepsList, children }) {
  const navigate = useNavigate();

  return (
    <div>
      <Text.Heading
        fontSize={"18px"}
        fontWeight={"600"}
        color={Colors.Primary}
        text={heading}
      />
      <Layout.Spacer height={20} />
      <div style={styles.sectionStyle}>
        <div style={{ width: "20%" }}>
          {stepsList.map((step, index) => (
            <div
              style={styles.sidebarItem}
              onClick={() => navigate(step.route)}
              key={index}
            >
              <div style={styles.flexColumn}>
                <Text.Heading
                  fontSize={"16px"}
                  fontWeight={"500"}
                  color={Colors.Primary}
                  textAlign={"left"}
                  text={step.heading}
                />
                <Layout.Spacer height={5} />
                <Text.Heading
                  fontSize={"12px"}
                  fontWeight={"500"}
                  color={Colors.BlackText2}
                  textAlign={"left"}
                  text={step.subHeading}
                />
              </div>
              <AiOutlineRight fontSize={"16px"} color={Colors.Primary} />
            </div>
          ))}
        </div>

        <div style={styles.line}></div>

        <div style={{ width: "100%" }}>{children}</div>
      </div>
    </div>
  );
}

export default StepsSection;
