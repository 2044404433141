import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { message } from "antd";
import Lottie from "react-lottie-player";

import { AuthAction, IndividualStudentAction } from "../../store/actions";

import { Layout, Modal, Button } from "../../components";
import { Images } from "../../config";

import styles from "./styles";

import Done from "../../assets/images/57767-done.json";

function PaymentSelectFlow2() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [successModalOnetime, setSuccessModalOnetime] = useState(false);

  const items = [
    {
      id: 1,
      icon: Images.Card,
      comingSoon: false,
      text: "Credit / Debit Card",
    },
    {
      id: 4,
      icon: Images.CashOnDelivery,
      comingSoon: location.state?.paymentType === "Onetime" ? false : true,
      text: "Cash Collection on Delivery",
    },
    {
      id: 2,
      icon: Images.JazzCash,
      comingSoon: true,
      text: "Jazz Cash",
    },
    {
      id: 3,
      icon: Images.EasyPaisa,
      comingSoon: true,
      text: "Easy Paisa",
    },
  ];

  const filteredItems = items.filter((item) => {
    if (location?.state?.currency === "PKR") {
      return true;
    }
    return item.id !== 2 && item.id !== 3;
  });

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      const storedItem = localStorage.getItem("checkout");
      const body = JSON.parse(storedItem);

      dispatch(
        AuthAction.studentSignup(body, async (res) => {
          if (res.status === 201) {
            localStorage.removeItem("checkout");
            setSuccessModalOnetime(true);
          } else {
            message.error(res.message);
          }
        })
      );
    }

    if (query.get("canceled")) {
      message.error("Payment failed.");
    }
  }, []);

  const handleSelection = (type) => {
    if (type === "Cash Collection on Delivery") {
      const body = {
        ...location.state,
        paymentMethod: "CashOnDelivery",
      };
      navigate("/signup/cashOnDeliveryForm", {
        state: body,
      });
    }
    if (type === "Credit / Debit Card") {
      const body = {
        ...location.state,
        paymentMethod: "Card",
        url: window.location.href,
      };
      if (location.state?.paymentType === "Onetime") {
        dispatch(
          IndividualStudentAction.createCheckout(body, async (res) => {
            localStorage.setItem("checkout", JSON.stringify(body));
            if (res.status === 200) {
              window.location.href = res.data.url;
            } else {
              message.error(res.message);
            }
          })
        );
      } else {
        navigate("/signup/cardPaymentForm", {
          state: body,
        });
      }
    }
  };
  return (
    <Layout.LoginLayout backLink={"/signup/email"}>
      <Layout.HeaderSection
        title="Select Payment Method"
        subtitle="Select the payment method you prefer"
      />
      <Layout.Spacer height={20} />
      {filteredItems?.map((item, index) => (
        <div
          style={{
            ...styles.paymentMethodRows,
            position: "relative",
            pointerEvents: item?.comingSoon ? "none" : "",
          }}
          key={index}
          onClick={() => handleSelection(item.text)}
        >
          {item?.comingSoon && (
            <div style={styles.comingSoon2}>Coming Soon</div>
          )}
          <div style={{ width: "50%", textAlign: "center" }}>
            <img
              src={item.icon}
              style={{
                ...styles.paymentMethodIcon,
                opacity: item?.comingSoon ? "0.6" : "1",
              }}
            />
          </div>
          <div
            style={{
              color: "black",
              width: "100%",
              opacity: item?.comingSoon ? "0.6" : "1",
            }}
          >
            {item.text}
          </div>
        </div>
      ))}
      {successModalOnetime && (
        <Modal.Basic
          isModalVisible={successModalOnetime}
          centered={true}
          handleCancel={() => {
            setSuccessModalOnetime(false);
            navigate("/login");
          }}
          closable={false}
        >
          <div style={styles.flexColumnCenter}>
            <Lottie
              loop
              animationData={Done}
              play
              style={{ width: "68px", height: "68px" }}
            ></Lottie>

            <div style={styles.successText}>Successful!</div>
            <div style={styles.modalDetails}>
              Thank you for your payment! Please sign in to start using the
              UniVision Pro platform.
            </div>
            <Button.Primary
              style={styles.modalButton}
              onClick={() => {
                setSuccessModalOnetime(false);
                navigate("/login");
              }}
            >
              Ok
            </Button.Primary>
          </div>
        </Modal.Basic>
      )}
    </Layout.LoginLayout>
  );
}

export default PaymentSelectFlow2;
