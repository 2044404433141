import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Select } from "antd";

import { Layout, Table, Text, convertCurrency } from "../../components";
import { Colors } from "../../config";

import premiumContent from "../../assets/images/premiunContent.svg";

import styles from "./styles";

function Programs({ data, location, secondaryQs }) {
  const currency = useSelector((state) => state.converter.converted);
  const convertedCurrency = useSelector((state) => state.converter.convertTo);
  const studentDetail = useSelector((state) => state.auth.user.user);

  const [programsArr, setProgramsArr] = useState(data);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [program, setProgram] = useState("All");

  const [blurData, setBlurData] = useState(undefined);

  useEffect(() => {
    const currentDate = new Date();
    const validUntilDate = new Date(studentDetail.validUntil);

    if (
      validUntilDate < currentDate &&
      studentDetail.studentType === "individualStudent" &&
      studentDetail.subscriptionPlan === "Free" &&
      secondaryQs > 10
    ) {
      setBlurData(true);
    }
  }, []);

  // get only degrees from the array of programs
  let programsData = data.map((program) => {
    return {
      item: program.degree,
      value: program.degree,
    };
  });

  // insert All in the array
  programsData.unshift({
    item: "All",
    value: "All",
  });

  // remove duplicates
  const programs = programsData.filter(
    (v, i, a) => a.findIndex((v2) => v2.item === v.item) === i
  );

  const handleProgramChange = (value) => {
    if (value === "All") {
      setProgramsArr(data);
      setProgram(value);
    } else {
      const newArr = data.filter((item) => item.degree === value);
      setProgramsArr(newArr);
      setProgram(value);
    }
  };

  const handleTableChange = (page, newPageSize) => {
    setCurrentPage(page);
    setPageSize(newPageSize);
  };

  const columns = [
    {
      title: "Sr No",
      dataIndex: "srNo",
      align: "center",
      width: 80,
      render: (text, record, index) => {
        if (index > 1 && blurData) {
          return (
            <Text.Heading
              fontSize={"10px"}
              fontWeight={"600"}
              style={{ filter: "blur(7px)" }}
              text={`${(currentPage - 1) * pageSize + (index + 1)}.`}
            />
          );
        } else {
          return (
            <Text.Heading
              fontSize={"10px"}
              fontWeight={"600"}
              text={`${(currentPage - 1) * pageSize + (index + 1)}.`}
            />
          );
        }
      },
    },
    {
      title: "Subject",
      dataIndex: "program",
      align: "center",
      width: 180,
      render: (text, record, index) => {
        if (index > 1 && blurData) {
          return (
            <Text.Heading
              style={{ filter: "blur(7px)" }}
              fontSize={"10px"}
              fontWeight={"600"}
              text={text}
            />
          );
        } else {
          return (
            <Text.Heading fontSize={"10px"} fontWeight={"600"} text={text} />
          );
        }
      },
    },
    {
      title: "Type",
      dataIndex: "degree",
      align: "center",
      width: 200,
      render: (text, record, index) => {
        if (index > 1 && blurData) {
          return (
            <Text.Heading
              style={{ cursor: "pointer", filter: "blur(7px)" }}
              fontSize={"10px"}
              fontWeight={"600"}
              color={Colors.Primary}
              text={text}
            />
          );
        } else {
          return (
            <div
              onClick={() =>
                record.programLink
                  ? window.open(record.programLink, "_blank")
                  : window.open("#", "_blank")
              }
              style={{ cursor: "pointer" }}
            >
              <Text.Heading
                fontSize={"10px"}
                fontWeight={"600"}
                color={Colors.Primary}
                text={text}
              />
            </div>
          );
        }
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      align: "center",
      width: 300,
      render: (text, record, index) => {
        if (index > 1 && blurData) {
          return (
            <Text.Heading
              style={{ cursor: "pointer", filter: "blur(7px)" }}
              fontSize={"10px"}
              fontWeight={"600"}
              color={Colors.Primary}
              text={text}
            />
          );
        } else {
          return (
            <div
              onClick={() =>
                record.programLink
                  ? window.open(record.programLink, "_blank")
                  : window.open("#", "_blank")
              }
            >
              <Text.Heading
                fontSize={"10px"}
                fontWeight={"600"}
                color={Colors.Primary}
                text={text}
              />
            </div>
          );
        }
      },
    },
    {
      title: "Duration",
      dataIndex: "duration",
      width: 200,
      align: "center",
      render: (text, record, index) => {
        if (index > 1 && blurData) {
          return (
            <Text.Heading
              style={{ cursor: "pointer", filter: "blur(7px)" }}
              fontSize={"10px"}
              fontWeight={"600"}
              textTransform={"capitalize"}
              text={text}
            />
          );
        } else {
          return (
            <Text.Heading
              fontSize={"10px"}
              fontWeight={"600"}
              textTransform={"capitalize"}
              text={text}
            />
          );
        }
      },
    },
  ];

  return (
    <div style={{ position: "relative" }}>
      <Layout.Spacer height={10} />
      <p style={styles.uniDetailsHeader}>Programs</p>
      <Text.Heading
        fontSize={"14px"}
        fontWeight={"500"}
        color={Colors.Black}
        text={"Select any program to see the respective courses offered."}
      />
      <Layout.Spacer height={10} />
      <div>
        <div className="university-form-1">
          <Select
            style={{
              width: "300px",
            }}
            defaultValue={program}
            value={program}
            onChange={(val) => handleProgramChange(val)}
            showSearch
          >
            {programs.map((item, index) => (
              <Select.Option value={item.value} key={index}>
                {item.item}
              </Select.Option>
            ))}
          </Select>
        </div>
      </div>
      <Layout.Spacer height={20} />

      <div>
        <Table.AntDesign
          rowKey={(record) => record._id}
          columns={
            location.toLowerCase().includes("malaysia")
              ? [
                  ...columns,
                  {
                    title: "Tution Fee - Local\n(Per Year)",
                    dataIndex: "localCost",
                    align: "center",
                    width: 200,
                    render: (text, record, index) => {
                      if (index > 1 && blurData) {
                        return (
                          <Text.Heading
                            fontSize={"10px"}
                            fontWeight={"600"}
                            textTransform={"capitalize"}
                            style={{ cursor: "pointer", filter: "blur(7px)" }}
                            text={
                              text !== "NA"
                                ? !currency
                                  ? `${text.split(" ")[0]} ${String(
                                      text.split(" ")[1]
                                    ).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                                  : convertCurrency(text, convertedCurrency)
                                : "NA"
                            }
                          />
                        );
                      } else {
                        return (
                          <Text.Heading
                            fontSize={"10px"}
                            fontWeight={"600"}
                            textTransform={"capitalize"}
                            text={
                              text !== "NA"
                                ? !currency
                                  ? `${text.split(" ")[0]} ${String(
                                      text.split(" ")[1]
                                    ).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                                  : convertCurrency(text, convertedCurrency)
                                : "NA"
                            }
                          />
                        );
                      }
                    },
                  },
                  {
                    title: "Tution Fee - International\n(Per Year)",
                    dataIndex: "internationalCost",
                    align: "center",
                    width: 200,
                    render: (text, record, index) => {
                      if (index > 1 && blurData) {
                        return (
                          <Text.Heading
                            fontSize={"10px"}
                            fontWeight={"600"}
                            textTransform={"capitalize"}
                            style={{ cursor: "pointer", filter: "blur(7px)" }}
                            text={
                              text !== "NA"
                                ? !currency
                                  ? `${text.split(" ")[0]} ${String(
                                      text.split(" ")[1]
                                    ).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                                  : convertCurrency(text, convertedCurrency)
                                : "NA"
                            }
                          />
                        );
                      } else {
                        return (
                          <Text.Heading
                            fontSize={"10px"}
                            fontWeight={"600"}
                            textTransform={"capitalize"}
                            text={
                              text !== "NA"
                                ? !currency
                                  ? `${text.split(" ")[0]} ${String(
                                      text.split(" ")[1]
                                    ).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                                  : convertCurrency(text, convertedCurrency)
                                : "NA"
                            }
                          />
                        );
                      }
                    },
                  },
                ]
              : [
                  ...columns,
                  {
                    title: "Tution Fee (Per Year)",
                    dataIndex: "tutionFee",
                    align: "center",
                    width: 200,
                    render: (text, record, index) => {
                      if (index > 1 && blurData) {
                        return (
                          <Text.Heading
                            fontSize={"10px"}
                            fontWeight={"600"}
                            textTransform={"capitalize"}
                            style={{ cursor: "pointer", filter: "blur(7px)" }}
                            text={
                              text !== "NA"
                                ? !currency
                                  ? `${text.split(" ")[0]} ${String(
                                      text.split(" ")[1]
                                    ).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                                  : convertCurrency(text, convertedCurrency)
                                : "NA"
                            }
                          />
                        );
                      } else {
                        return (
                          <Text.Heading
                            fontSize={"10px"}
                            fontWeight={"600"}
                            textTransform={"capitalize"}
                            text={
                              text !== "NA"
                                ? !currency
                                  ? `${text.split(" ")[0]} ${String(
                                      text.split(" ")[1]
                                    ).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                                  : convertCurrency(text, convertedCurrency)
                                : "NA"
                            }
                          />
                        );
                      }
                    },
                  },
                ]
          }
          data={programsArr}
          pagination={{ defaultPageSize: 100, onChange: handleTableChange }}
        />
      </div>

      {blurData && (
        <div style={styles.premiumModal}>
          <img src={premiumContent} />
          <p>
            To view more <b>Subscribe to our Pro Package</b>
          </p>
        </div>
      )}
    </div>
  );
}

export default Programs;
