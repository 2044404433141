import { Colors } from "../../config";

const styles = {
  resetFormContainer: {
    width: "100%",
    margin: "0 auto",
  },
  inputFieldBorder: { borderColor: Colors.Primary },
  passwordContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    position: "relative",
  },
  passwordIcon: {
    position: "absolute",
    right: "10",
    top: "10",
    fontSize: "20px",
    cursor: "pointer",
    color: Colors.Primary,
  },

  buttonStyle: {
    width: "100%",
    backgroundColor: Colors.Primary,
    borderRadius: "15px",
    fontFamily: "Montserrat",
  },
};

export default styles;
