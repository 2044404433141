import React, { useEffect, useState } from "react";

const MobileLogin = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  const [isMobile, setIsMobile] = useState(
    /android/i.test(userAgent) ||
      (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream)
  );

  useEffect(() => {
    if (isMobile) {
      const appLink = "https://student.univision.hk/login";
      const fallbackLink = "https://student.univision.hk/login";

      const timeout = setTimeout(() => {
        window.location.replace(fallbackLink);
      }, 1000);

      window.location.replace(appLink);

      window.addEventListener("blur", () => clearTimeout(timeout));
    }
  }, [isMobile]);
  return <div>mobileLogin</div>;
};

export default MobileLogin;
