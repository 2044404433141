import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Dropdown, Select, Popover, ConfigProvider } from "antd";
import ReactCountryFlag from "react-country-flag";

import { MdCancel } from "react-icons/md";
import { GoBell, GoBellFill } from "react-icons/go";
import { CiCircleQuestion } from "react-icons/ci";
import { FaAngleDown } from "react-icons/fa6";
import { AiOutlineUser } from "react-icons/ai";

import {
  NotificationsAction,
  ProfileAction,
  ConverterAction,
  StudentAction,
} from "../../store/actions";

import { Colors, Images, MockData } from "../../config";
import { ProfileDropDown, NotificationDropDown } from "./ProfileDropDown";

import styles from "./styles";

const NavHeader = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loginUser = useSelector((state) => state.auth.user.user);
  const notifications = useSelector((state) => state.notifications);
  const profile = useSelector((state) => state.profile);
  const currency = useSelector((state) => state.converter);
  const studentType = useSelector((state) => state.auth.user.user?.studentType);
  const studentId = useSelector((state) => state.auth.user.user?.studentId);
  const validUntil = useSelector(
    (state) =>
      state?.student?.student?.studentDetails?.individualStudentId?.validUntil
  );
  const subscriptionPlan = useSelector(
    (state) =>
      state?.student?.student?.studentDetails?.individualStudentId
        ?.subscriptionPlan
  );

  const path = location.pathname;

  const [countryCode, setCountryCode] = useState("");
  const [showConverter, setShowConverter] = useState(false);
  useEffect(() => {
    dispatch(StudentAction.getStudentDetails(studentId));
    dispatch(ProfileAction.getUserProfile());
  }, []);

  useEffect(() => {
    const payload = {
      limit: "",
      offset: "",
      userId: loginUser._id || "",
    };
    dispatch(NotificationsAction.getNotifications(payload));
  }, [path]);

  const handleCurrency = (e) => {
    if (!e) {
      handleCurrencyClear();
    } else {
      const values = JSON.parse(e);
      setCountryCode(values.code);
      const payload = {
        converted: true,
        convertTo: values?.currency,
      };
      dispatch(ConverterAction.updateConverter(payload));
    }
  };

  const handleCurrencyClear = () => {
    setCountryCode("");
    const payload = {
      converted: false,
      convertTo: "",
    };
    dispatch(ConverterAction.updateConverter(payload));
  };

  return (
    <div style={styles.navbarContainer}>
      {!path.includes("/home") ? (
        <div
          onClick={() => {
            window.history.back();
          }}
          style={{ display: "flex" }}
        >
          <img src={Images.LeftArrowCircle} style={styles.backButton} />
        </div>
      ) : (
        <div></div>
      )}

      <div style={styles.navbarRightPart}>
        {studentType === "individualStudent" && subscriptionPlan === "Free" && (
          <div
            style={{
              ...styles.itemCenterjustifyCenter,
              marginRight: "10px",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/getPro");
            }}
          >
            <img src={Images.ProIcon} style={styles.proIconNavbar} />
            <span style={styles.univisionPro}>Get UniVision Pro</span>
          </div>
        )}
        {studentType === "individualStudent" &&
          subscriptionPlan === "Pro" &&
          new Date(validUntil) > new Date() && (
            <div
              style={{
                ...styles.itemCenterjustifyCenter,
                marginRight: "10px",
              }}
            >
              <img src={Images.ProIcon} style={styles.proIconNavbar} />
              <span style={styles.univisionProMember}>PRO MEMBER</span>
            </div>
          )}

        <div
          style={styles.currencytext}
          onClick={() => setShowConverter(!showConverter)}
        >
          Currency
        </div>
        {showConverter && (
          <>
            <div
              style={{
                background: "transparent",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "170px",
                border: `1px solid ${Colors.Primary}`,
                borderRadius: "6px",
                paddingLeft: "10px",
                marginRight: "10px",
              }}
              className="converter"
            >
              {countryCode && (
                <ReactCountryFlag
                  style={{ ...styles.flagStyle, marginRight: "0px" }}
                  countryCode={countryCode}
                  svg
                  title={countryCode}
                />
              )}
              <Select
                value={currency?.convertTo || undefined}
                placeholder="Select currency"
                onChange={(e) => handleCurrency(e)}
                style={{
                  width: "100%",
                  padding: "0px",
                }}
                showSearch
                suffixIcon={
                  currency?.convertTo ? null : (
                    <FaAngleDown color={Colors?.Primary} fontSize={18} />
                  )
                }
                allowClear={{
                  clearIcon: <MdCancel color={Colors?.Red} fontSize={14} />,
                }}
                className="currency-converter"
              >
                {MockData?.Currencies?.map((currency, index) => (
                  <Select.Option key={index} value={JSON.stringify(currency)}>
                    <span>
                      <ReactCountryFlag
                        style={styles.flagStyle}
                        countryCode={currency?.code}
                        svg
                        title={currency?.code}
                      />
                    </span>
                    <span>{currency?.currency}</span>
                  </Select.Option>
                ))}
              </Select>
            </div>

            <ConfigProvider
              theme={{
                token: {
                  colorBgElevated: Colors.Primary,
                  colorText: "white",
                },
              }}
            >
              <Popover
                content={<div>Convert Currency</div>}
                style={{ background: Colors.Primary }}
              >
                <CiCircleQuestion color={Colors?.GrayText} fontSize={18} />
              </Popover>
            </ConfigProvider>
          </>
        )}
        <Dropdown
          trigger="click"
          overlay={
            <NotificationDropDown notifications={notifications.notifications} />
          }
          placement="bottom"
        >
          {notifications.unreadCount > 0 ? (
            <div style={{ display: "flex", position: "relative" }}>
              <GoBellFill style={styles.notificationBell} />
              <div
                style={{
                  position: "absolute",
                  height: "18px",
                  width: "18px",
                  background: "red",
                  borderRadius: "100%",
                  color: "white",
                  right: "5px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "10px",
                }}
              >
                {notifications.unreadCount}
              </div>
            </div>
          ) : (
            <div style={{ display: "flex" }}>
              <GoBell style={styles.notificationBell} />
            </div>
          )}
        </Dropdown>

        <Dropdown
          trigger="click"
          overlay={<ProfileDropDown />}
          placement="bottom"
        >
          {profile?.user?.profileImage ? (
            <img
              src={profile?.user?.profileImage}
              alt="user"
              style={styles.profileImage}
            />
          ) : (
            <AiOutlineUser style={styles.defaultImage} />
          )}
        </Dropdown>

        <div style={styles.profileContainer}>
          <div style={styles.profileName}>{profile?.user?.fullName}</div>
          <div style={styles.profileRole}>{loginUser?.role}</div>
        </div>
      </div>
    </div>
  );
};
export default NavHeader;
