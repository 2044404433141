import { useNavigate } from "react-router";
import ReactPlayer from "react-player";

import { Layout, Text } from "../../components";
import { Colors } from "../../config";

import styles from "./styles";

function Recommenders() {
  const navigate = useNavigate();

  return (
    <div style={styles.flexColumn}>
      <div>
        <Text.Heading
          fontSize={"18px"}
          fontWeight={"600"}
          color={Colors.Primary}
          text="AI Recommender"
        />
      </div>
      <Layout.Spacer height={20} />
      <div style={styles.recommednerTitle}>
        Are you facing difficulties choosing universities?
      </div>
      <Layout.Spacer height={10} />
      <div style={styles.recommednerSubTitle}>
        Use our Recommender to help you find the best university for you, based
        on your performance. Take a peek at the video below to see how it works!
      </div>
      <Layout.Spacer height={30} />
      <div style={styles.videoContainer}>
        <ReactPlayer
          url={"https://youtu.be/lrjixk6fmbQ?si=SDDIGUPBnJBRvZep"}
          height="350px"
          width="623px"
          controls={true}
        />
        <Layout.Spacer height={20} />
        <div style={styles.underVideoTitle}>
          Ready to start? click the button below to start now.
        </div>
        <Layout.Spacer height={20} />
        <div
          style={styles.startButton}
          onClick={() => navigate("/recommender/qualifications")}
        >
          Start
        </div>
      </div>
    </div>
  );
}

export default Recommenders;
