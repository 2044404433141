import React from "react";
import { useLocation } from "react-router-dom";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import { Layout } from "../../components";

import CardForm from "./CardForm";

const stripeKey =
  process.env.REACT_APP_ENV === "development" ||
  process.env.REACT_APP_ENV === "local"
    ? process.env.REACT_APP_STRIPE_KEY_STAGING
    : process.env.REACT_APP_STRIPE_KEY_PROD;

const stripePromise = loadStripe(stripeKey);

const CardPayment = () => {
  const location = useLocation();

  return (
    <Layout.LoginLayout backLink={"/signup/email"}>
      <Layout.HeaderSection
        title="Credit / Debit Card"
        subtitle="Please enter your details"
      />
      <Layout.Spacer height={20} />
      <Elements stripe={stripePromise}>
        <CardForm othervalues={location.state} />
      </Elements>
      <Layout.Spacer height={20} />
    </Layout.LoginLayout>
  );
};

export default CardPayment;
