import { Colors } from "../../config";

const styles = {
  forgotFormContainer: {
    width: "100%",
    margin: "0 auto",
  },
  inputFieldBorder: {
    border: "1px solid #DFDFDD",
    borderRadius: "100px",
    padding: "14px",
    width: "100%",
    backgroundColor: "#F7F6F2",
    outline: "none",
  },

  buttonStyle: {
    width: "100%",
    backgroundColor: Colors.Primary,
    borderRadius: "100px",
    fontWeight: "600",
  },
};

export default styles;
