import { useState } from "react";
import { Layout } from "../../components";
import LoginForm from "./LoginForm";

import { Colors } from "../../config";

const SignIn = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  const [isMobile, setIsMobile] = useState(
    /android/i.test(userAgent) ||
      (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream)
  );

  return (
    <Layout.LoginLayout>
      <Layout.HeaderSection
        title={isMobile ? null : "Welcome Back"}
        subtitle={
          isMobile ? (
            <div
              onClick={() => {
                const appLink = "https://student.univision.hk/mobile-login";
                const fallbackLink =
                  "https://student.univision.hk/mobile-login";

                const timeout = setTimeout(() => {
                  window.location.replace(fallbackLink);
                }, 1000);

                window.location.replace(appLink);

                window.addEventListener("blur", () => clearTimeout(timeout));
              }}
              style={{
                color: "white",
                padding: "10px",
                borderRadius: "6px",
                background: Colors.Primary,
              }}
            >
              Continue to Mobile App
            </div>
          ) : (
            "Log in to Continue"
          )
        }
      />
      <Layout.Spacer height={30} />
      {isMobile ? null : <LoginForm />}
    </Layout.LoginLayout>
  );
};

export default SignIn;
