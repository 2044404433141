import {
  GET_TRACKER,
  GET_TRACKER_SUCCESS,
  GET_TRACKER_FAILURE,
  GET_ALL_TRACKER,
  GET_ALL_TRACKER_SUCCESS,
  GET_ALL_TRACKER_FAILURE,
} from "../constants";

export default class TrackerAction {
  static getTracker(payload, cb) {
    return {
      type: GET_TRACKER,
      payload,
      cb,
    };
  }
  static getTrackersSuccess(payload) {
    return {
      type: GET_TRACKER_SUCCESS,
      payload,
    };
  }
  static getTrackerFailure(payload) {
    return {
      type: GET_TRACKER_FAILURE,
      payload,
    };
  }
  static getAllTracker(payload, cb) {
    return {
      type: GET_ALL_TRACKER,
      payload,
      cb,
    };
  }
  static getAllTrackersSuccess(payload) {
    return {
      type: GET_ALL_TRACKER_SUCCESS,
      payload,
    };
  }
  static getAllTrackerFailure(payload) {
    return {
      type: GET_ALL_TRACKER_FAILURE,
      payload,
    };
  }
}
