import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import { MdDelete } from "react-icons/md";

import { MarketplaceAction } from "../../store/actions";

import { Layout, Loader, Text } from "../../components";
import { Colors, Images } from "../../config";

import styles from "./styles";

function OwnProduct() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const studentId = useSelector((state) => state.auth.user.user.studentId);
  const marketplaceLoading = useSelector((state) => state.marketplace);

  const [books, setBooks] = useState([]);
  const [papers, setPapers] = useState([]);
  const [assignments, setAssignments] = useState([]);
  const [stationery, setStationery] = useState([]);

  useEffect(() => {
    const payload = {
      studentId,
    };
    dispatch(
      MarketplaceAction.getStudentProduct(payload, (res) => handleValues(res))
    );
  }, []);

  const updateProduct = (status, id) => {
    const payload = {
      status,
      id,
    };
    dispatch(
      MarketplaceAction.updateProduct(payload, (res) => {
        const payload = {
          studentId,
        };
        dispatch(
          MarketplaceAction.getStudentProduct(payload, (res) =>
            handleValues(res)
          )
        );
      })
    );
  };
  const deleteProduct = (id) => {
    const payload = {
      id,
    };
    dispatch(
      MarketplaceAction.deleteProduct(payload, (res) => {
        const payload = {
          studentId,
        };
        dispatch(
          MarketplaceAction.getStudentProduct(payload, (res) =>
            handleValues(res)
          )
        );
      })
    );
  };

  const handleValues = (res) => {
    const books = [];
    const papers = [];
    const assignments = [];
    const stationery = [];
    res?.product?.map((product) => {
      if (product.itemType === "book") {
        books.push(product);
      }
      if (product.itemType === "pastpaper") {
        papers.push(product);
      }
      if (product.itemType === "pastassignment") {
        assignments.push(product);
      }
      if (product.itemType === "stationery") {
        stationery.push(product);
      }
    });

    setBooks(books);
    setPapers(papers);
    setAssignments(assignments);
    setStationery(stationery);
  };

  const values = [
    { id: 1, title: "Books", value: books },
    { id: 2, title: "Past Papers", value: papers },
    { id: 3, title: "Past Assignments", value: assignments },
    { id: 4, title: "Stationery", value: stationery },
  ];

  return (
    <div>
      <Text.Heading
        fontSize={"18px"}
        fontWeight={"600"}
        color={Colors.Primary}
        text="Your Products"
      />
      <Layout.Spacer height={20} />
      {marketplaceLoading?.isLoading ? (
        <Loader.Circular />
      ) : (
        <div>
          {values?.map((item) => (
            <div key={item.id}>
              <div style={styles.justifyBetweenAlignCenter}>
                <div style={styles.productTypeLabel}>{item.title}</div>
              </div>
              <Layout.Spacer height={10} />
              {item?.value?.length === 0 ? (
                <div style={styles.noDataContainer}>
                  <img src={Images.NoData} height={60} width={60} alt="" />
                  <div style={styles.noData}>No Data</div>
                </div>
              ) : (
                <div style={styles.productContainer}>
                  {item?.value?.map((item) => {
                    return (
                      <div style={styles.constainerStyle3} key={item._id}>
                        <div
                          style={{ display: "flex", cursor: "pointer" }}
                          onClick={() =>
                            navigate(`/more/marketplace/product/${item._id}`)
                          }
                        >
                          <div style={styles.imageContainerStyle}>
                            <img
                              src={item.itemPhoto}
                              height={62}
                              width={62}
                              style={{ borderRadius: "5px" }}
                            />
                          </div>
                          <div style={styles.textFieldContainer}>
                            <div style={styles.itemNameStyle}>
                              {item?.itemName?.length > 30
                                ? item?.itemName.slice(0, 30) + "..."
                                : item?.itemName}
                            </div>
                            <div style={styles.itemPriceStyle}>
                              {item?.price.split(" ")[0]}{" "}
                              {parseFloat(
                                item?.price.split(" ")[1]
                              ).toLocaleString()}
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            ...styles.justifyBetweenAlignCenter,
                            padding: "10px",
                          }}
                        >
                          <div style={{ color: "black", width: "100%" }}>
                            <select
                              style={styles.updateDropdown}
                              onChange={(e) => {
                                updateProduct(e.target.value, item._id);
                              }}
                              value={item.status}
                            >
                              <option value="sold">Sold</option>
                              <option value="unsold">Unsold</option>
                            </select>
                          </div>
                          <div style={styles.deleteIconContainer}>
                            <MdDelete
                              style={styles.deleteIcon}
                              onClick={() => deleteProduct(item._id)}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
              <Layout.Spacer height={20} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default OwnProduct;
