import {
  ADD_PRODUCT,
  ADD_PRODUCT_SUCCESS,
  ADD_PRODUCT_FAILURE,
  GET_PRODUCT,
  GET_PRODUCT_SUCCESS,
  GET_PRODUCT_FAILURE,
  GET_ALL_PRODUCT,
  GET_ALL_PRODUCT_SUCCESS,
  GET_ALL_PRODUCT_FAILURE,
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAILURE,
  DELETE_PRODUCT,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAILURE,
  GET_STUDENT_PRODUCT,
  GET_STUDENT_PRODUCT_SUCCESS,
  GET_STUDENT_PRODUCT_FAILURE,
} from "../constants";

export default class MarketplaceAction {
  static addProduct(payload, cb) {
    return {
      type: ADD_PRODUCT,
      payload,
      cb,
    };
  }
  static addProductSuccess(payload) {
    return {
      type: ADD_PRODUCT_SUCCESS,
      payload,
    };
  }
  static addProductFailure(payload) {
    return {
      type: ADD_PRODUCT_FAILURE,
      payload,
    };
  }
  static getProduct(payload, cb) {
    return {
      type: GET_PRODUCT,
      payload,
      cb,
    };
  }
  static getProductSuccess(payload) {
    return {
      type: GET_PRODUCT_SUCCESS,
      payload,
    };
  }
  static getProductFailure(payload) {
    return {
      type: GET_PRODUCT_FAILURE,
      payload,
    };
  }
  static getStudentProduct(payload, cb) {
    return {
      type: GET_STUDENT_PRODUCT,
      payload,
      cb,
    };
  }
  static getStudentProductSuccess(payload) {
    return {
      type: GET_STUDENT_PRODUCT_SUCCESS,
      payload,
    };
  }
  static getStudentProductFailure(payload) {
    return {
      type: GET_STUDENT_PRODUCT_FAILURE,
      payload,
    };
  }
  static getAllProduct(payload, cb) {
    return {
      type: GET_ALL_PRODUCT,
      payload,
      cb,
    };
  }
  static getAllProductSuccess(payload) {
    return {
      type: GET_ALL_PRODUCT_SUCCESS,
      payload,
    };
  }
  static getAllProductFailure(payload) {
    return {
      type: GET_ALL_PRODUCT_FAILURE,
      payload,
    };
  }
  static updateProduct(payload, cb) {
    return {
      type: UPDATE_PRODUCT,
      payload,
      cb,
    };
  }
  static updateProductSuccess(payload) {
    return {
      type: UPDATE_PRODUCT_SUCCESS,
      payload,
    };
  }
  static updateProductFailure(payload) {
    return {
      type: UPDATE_PRODUCT_FAILURE,
      payload,
    };
  }
  static deleteProduct(payload, cb) {
    return {
      type: DELETE_PRODUCT,
      payload,
      cb,
    };
  }
  static deleteProductSuccess(payload) {
    return {
      type: DELETE_PRODUCT_SUCCESS,
      payload,
    };
  }
  static deleteProductFailure(payload) {
    return {
      type: DELETE_PRODUCT_FAILURE,
      payload,
    };
  }
}
