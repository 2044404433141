import { Colors } from "../../config";

const styles = {
  referfriend: {
    height: "100%",
    overFlow: "scroll",
  },
  referfriend1LeftCopy: {
    display: "flex",
    gap: "10px",
    height: "42px",
  },
  referfriendMain: {
    display: "flex",
    gap: "26px",
    height: "52%",
  },
  referfriend1: {
    width: "100%",
  },
  referfriend1Input: {
    width: "65%",
    height: "100%",
    border: "1px solid #BDBDBD",
    borderRadius: "7px",
    color: Colors.GrayText,
    display: "flex",
    alignItems: "center",
    paddingLeft: "10px",
    fontSize: "14px",
    fontWeight: "500",
  },
  referfriend1CopyButton: {
    width: "6.2rem",
    height: "100%",
    borderRadius: "7px",
    backgroundColor: Colors.Primary,
    color: Colors.White,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "5px",
    cursor: "pointer",
  },
  referfriend1SocialIcons: {
    display: "flex",
    gap: "8px",
  },
  referfriend1Icons: {
    margin: "12px 0 4px 0",
    width: "48px",
    height: "46px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "10px",
    border: "1px solid #BDBDBD",
    cursor: "pointer",
  },
  referfriend1IconsImg: {
    width: "22px",
    height: "22px",
  },
  referfriend2: {
    width: "60%",
    height: "100%",
    border: "1px solid #BDBDBD",
    borderRadius: "7px",
    display: "flex",
    flexDirection: "column",
    padding: "10px 24px",
  },
  referfriend2main: {
    display: "flex",
    alignItems: "center",
    height: "32%",
  },
  referfriend2first: {
    display: "flex",
    gap: "8px",
  },
  friend2reflinkIcon: {
    width: "28px",
    height: "28px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#EDF2F1",
  },
  friend2reflinkContent: {
    width: "98%",
  },
  reflinkContent: {
    color: "#474747",
    fontSize: ".72rem",
    fontWeight: "500",
    // textAlign: 'justify'
  },
  referfriendwork: { height: "44%", paddingTop: "18px" },
  referfriendworkmain: {
    display: "flex",
    gap: "14px",
    height: "70%",
  },
  referworkmain1coins: {
    color: Colors.Primary,
    fontSize: "9px",
    fontWeight: "800",
    width: "95px",
    height: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "16px",
    backgroundColor: "#EDF2F1",
  },
  veticalLine: {
    width: "100%",
    color: Colors.GrayText,
    margin: "-5px 0",
    padding: "0px",
  },
  referfriendworkmain1: {
    width: "32.2%",
    padding: "12px 15px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    border: "1px solid #BDBDBD",
    borderRadius: "8px",
  },
  referfriendworkmain1img: {
    width: "24px",
    height: "22px",
  },
  referworkmain1heading: {
    display: "flex",
    alignItems: "flex-start",
    textAlign: "left",
  },
  referfriendworkmain1row: {
    display: "flex",
    justifyContent: "space-between",
  },
};

export default styles;
