import React from "react";
import { useLocation } from "react-router-dom";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import { Layout, Text } from "../../components";
import { Colors } from "../../config";

import styles from "./styles";

import CardInputForm from "./CardInputForm";

const stripeKey =
  process.env.REACT_APP_ENV === "development" ||
  process.env.REACT_APP_ENV === "local"
    ? process.env.REACT_APP_STRIPE_KEY_STAGING
    : process.env.REACT_APP_STRIPE_KEY_PROD;

const stripePromise = loadStripe(stripeKey);

const CardPaymentForm = () => {
  const location = useLocation();

  return (
    <div>
      <Text.Heading
        fontSize={"18px"}
        fontWeight={"600"}
        color={Colors.Primary}
        text="Credit / Debit Card"
      />
      <div style={styles.subscriptionTitle}>Please enter your details</div>
      <Layout.Spacer height={20} />
      <div style={{ width: "500px" }}>
        <Elements stripe={stripePromise}>
          <CardInputForm othervalues={location.state} />
        </Elements>
        <Layout.Spacer height={20} />
      </div>
    </div>
  );
};

export default CardPaymentForm;
