import { useNavigate, Link } from "react-router-dom";

import { Menu } from "antd";

import { BiCalendar } from "react-icons/bi";

import { Colors } from "../../config";
import styles from "./styles";

// ProfileDropDown
export const ProfileDropDown = () => {
  return (
    <Menu style={styles.dropdownContainer}>
      <Menu.Item key={"1"}>
        <Link to="/profile">Profile</Link>
      </Menu.Item>
      <hr style={styles.dropdownDivider} />
      <Menu.Item key={"2"}>
        <Link to="/settings">Settings</Link>
      </Menu.Item>
      <hr style={styles.dropdownDivider} />
      <Menu.Item key={"3"}>
        <Link to="/support">Contact Us</Link>
      </Menu.Item>
    </Menu>
  );
};

// Notifications DropDown
export const NotificationDropDown = ({ notifications }) => {
  const navigate = useNavigate();
  return (
    <Menu style={{ width: "400px" }}>
      <div style={styles.notificationTitle}>Notifications</div>
      {notifications.slice(0, 6).map((notification) => (
        <Menu.Item key={notification._id}>
          <div style={{ borderBottom: `1px solid ${Colors.Primary}` }}>
            <div style={styles.itemCenter}>
              <div>
                <BiCalendar style={styles.notificationBodyIcon} />
              </div>
              <div style={styles.notificationBody}>
                <b>Reminder: </b>
                {notification.body}
              </div>
            </div>
          </div>
        </Menu.Item>
      ))}
      <div
        onClick={() => navigate("/notifications")}
        style={styles.allNotificationsContainer}
      >
        See All Notifications
      </div>
    </Menu>
  );
};
