import {
  GET_ANNOUNCEMENTS,
  GET_ANNOUNCEMENTS_FAILURE,
  GET_ANNOUNCEMENTS_SUCCESS,
  CHANGE_ANNOUNCEMENT_STATUS,
  CHANGE_ANNOUNCEMENT_STATUS_FAILURE,
  CHANGE_ANNOUNCEMENT_STATUS_SUCCESS,
  GET_INDIVIDUAL_ANNOUNCEMENTS,
  GET_INDIVIDUAL_ANNOUNCEMENTS_SUCCESS,
  GET_INDIVIDUAL_ANNOUNCEMENTS_FAILURE,
  CHANGE_INDIVIDUAL_ANNOUNCEMENT_STATUS,
  CHANGE_INDIVIDUAL_ANNOUNCEMENT_STATUS_SUCCESS,
  CHANGE_INDIVIDUAL_ANNOUNCEMENT_STATUS_FAILURE,
} from "../constants";

const initialState = {
  count: 0,
  announcements: [],
  announcement: {},
  isLoading: false,
  error: null,
};

export default function AnnouncementReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ANNOUNCEMENTS:
    case CHANGE_ANNOUNCEMENT_STATUS:
    case GET_INDIVIDUAL_ANNOUNCEMENTS:
    case CHANGE_INDIVIDUAL_ANNOUNCEMENT_STATUS:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;

    case CHANGE_ANNOUNCEMENT_STATUS_SUCCESS:
    case CHANGE_INDIVIDUAL_ANNOUNCEMENT_STATUS_SUCCESS:
      state = {
        ...state,
        isLoading: false,
      };
      break;
    case GET_ANNOUNCEMENTS_SUCCESS:
    case GET_INDIVIDUAL_ANNOUNCEMENTS_SUCCESS:
      state = {
        ...state,
        count: action.payload.count,
        announcements: action.payload.data,
        isLoading: false,
      };
      break;

    case GET_ANNOUNCEMENTS_FAILURE:
    case CHANGE_ANNOUNCEMENT_STATUS_FAILURE:
    case GET_INDIVIDUAL_ANNOUNCEMENTS_FAILURE:
    case CHANGE_INDIVIDUAL_ANNOUNCEMENT_STATUS_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      break;
    default:
      break;
  }
  return state;
}
