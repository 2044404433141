import {
  GET_STUDENT_DETAILS,
  GET_STUDENT_DETAILS_FAILURE,
  GET_STUDENT_DETAILS_SUCCESS,
  GET_SCHOOLS,
  GET_SCHOOLS_FAILURE,
  GET_SCHOOLS_SUCCESS,
} from "../constants";

const initialState = {
  count: 0,
  students: [],
  schools: [],
  cities: [],
  countries: [],
  student: {},
  isLoading: true,
  error: null,
};

export default function StudentReducer(state = initialState, action) {
  switch (action.type) {
    case GET_STUDENT_DETAILS:
    case GET_SCHOOLS:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;

    case GET_STUDENT_DETAILS_SUCCESS:
      state = {
        ...state,
        student: action.payload,
        isLoading: false,
      };
      break;
    case GET_SCHOOLS_SUCCESS:
      state = {
        ...state,
        schools: action.payload?.findSchools,
        cities: action.payload?.cityList,
        countries: action.payload?.countryList,
        isLoading: false,
      };
      break;

    case GET_STUDENT_DETAILS_FAILURE:
    case GET_SCHOOLS_FAILURE:
      state = {
        ...state,
        isLoading: false,
      };
      break;

    default:
      break;
  }
  return state;
}
