const Spacer = (props) => {
  let { height, width, space } = props;
  if (space) {
    return <div style={{ width: space, height: width }}></div>;
  }
  if (!height) height = 0;
  if (!width) width = 0;
  return <div style={{ height, width }}></div>;
};

export default Spacer;
