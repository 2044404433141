import { BrowserRouter as Router } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { createRoot } from "react-dom/client";

import App from "./App";

import { persistor, store } from "./store";

import "antd/dist/reset.css";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";

if (window.location.hostname === "student-prod-univision.azurewebsites.net") {
  window.location.href =
    "https://student.univision.hk" + window.location.pathname;
} else {
  const root = createRoot(document.getElementById("root"));

  root.render(
    <Router basename="/">
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </Router>
  );
}
