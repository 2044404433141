import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Select, message } from "antd";

import { CiLocationOn } from "react-icons/ci";
import { AiOutlineClose } from "react-icons/ai";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { FaTelegramPlane } from "react-icons/fa";

import { UniversityAction, IndividualStudentAction } from "../../store/actions";

import { Button, Layout, Text, Modal, Loader } from "../../components";
import { Colors, Images, Utils } from "../../config";

import styles from "./styles";
import premiumContent from "../../assets/images/premiunContent.svg";

function Compare() {
  const dispatch = useDispatch();

  const studentType = useSelector((state) => state.auth.user.user.studentType);
  const subscriptionPlan = useSelector(
    (state) =>
      state?.student?.student?.studentDetails?.individualStudentId
        ?.subscriptionPlan
  );
  const validUntil = useSelector(
    (state) =>
      state?.student?.student?.studentDetails?.individualStudentId?.validUntil
  );
  const universityLoading = useSelector((state) => state.university);
  const universityState = useSelector(
    (state) => state.university.allUniversities
  );

  const [email, setEmail] = useState("");
  const [freeUser, setFreeUser] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showSubModal, setShowSubModal] = useState(false);
  const [uniIdArray, setUniversityIDArray] = useState("");
  const [compareUniversity, setCompareUniversity] = useState(
    useSelector((state) => state?.university?.compareUniversity)
  );

  useEffect(() => {
    if (
      studentType === "individualStudent" &&
      subscriptionPlan === "Free" &&
      new Date(validUntil) < new Date()
    ) {
      setFreeUser(true);
    }
  }, []);

  useEffect(() => {
    if (universityState?.length === 0) {
      dispatch(UniversityAction.getAllUniversities());
    }
  }, []);

  useEffect(() => {
    let uniIds = [];
    compareUniversity?.map((uni) => {
      if (!uniIds.includes(uni.id)) {
        uniIds.push(uni.id);
      }
    });
    setUniversityIDArray(uniIds);
  }, []);

  const DeleteFunction = (single) => {
    const compareUniversity2 = compareUniversity.filter(
      (name) => name.name !== single.name
    );
    setCompareUniversity(compareUniversity2);
  };

  const uniCompareAPICall = () => {
    setLoading(true);
    const body = {
      uniIds: uniIdArray,
    };
    dispatch(
      UniversityAction.compareUniverities(body, (res) => {
        setCompareUniversity(res);
        setLoading(false);
      })
    );
  };

  const handleEmailSubmit = () => {
    if (Utils.emailRegex.test(email) === false) {
      message.error("Please enter a valid email");
    } else {
      const payload = {
        email,
      };

      dispatch(
        IndividualStudentAction.sendEmailToAdmin(payload, (res) => {
          if (res.status === 200) {
            message.success("Email Sent");
          } else {
            message.error("Something went wrong");
          }
          setShowSubModal(false);
        })
      );
    }
  };
  const tableHeader = [
    {
      id: 2,
      title: "Location",
      image: <CiLocationOn style={styles.compareLabelImage} />,
    },
    {
      id: 3,
      title: "Campus",
      image: (
        <img
          src={Images.UniversityCampusLabel}
          style={styles.compareLabelImage}
        />
      ),
    },
    {
      id: 4,
      title: "QS World Ranking",
      image: <img src={Images.QsLabel} style={styles.compareLabelImage} />,
    },
    {
      id: 5,
      title: "Faculty / Student Ratio",
      image: <img src={Images.RatioLabel} style={styles.compareLabelImage} />,
    },
    {
      id: 6,
      title: "Total Students",
      image: (
        <img src={Images.StudentsLabel} style={styles.compareLabelImage} />
      ),
    },
    {
      id: 7,
      title: "Sector",
      image: <img src={Images.StatusLabel} style={styles.compareLabelImage} />,
    },
    {
      id: 8,
      title: "Research Output",
      image: (
        <img
          src={Images.ResearchOutputLabel}
          style={styles.compareLabelImage}
        />
      ),
    },
    {
      id: 9,
      title: "Number of Programs",
      image: (
        <img src={Images.ProgramsLabel} style={styles.compareLabelImage} />
      ),
    },
    {
      id: 10,
      title: "Scholarship Availibility",
      image: (
        <img src={Images.ScholarshipLabel} style={styles.compareLabelImage} />
      ),
    },
    {
      id: 11,
      title: "Courses Duration",
      image: (
        <img
          src={Images.CoursesDurationLabel}
          style={styles.compareLabelImage}
        />
      ),
    },
    {
      id: 12,
      title: "Average Tution Fee",
      image: <img src={Images.TutionLabel} style={styles.compareLabelImage} />,
    },
  ];

  const compareValues = (single) => {
    return [
      { id: 2, value: single?.location },
      { id: 3, value: single?.campus },
      { id: 4, value: single.qs_ranking },
      { id: 5, value: single?.facultyToStudentRatio },
      { id: 6, value: single?.total_students },
      { id: 7, value: single?.status },
      {
        id: 8,
        value:
          single?.research_output === "veryHigh"
            ? "Very High"
            : single?.research_output === "veryLow"
            ? "Very Low"
            : single?.research_output,
      },
      { id: 9, value: single?.programs },
      { id: 10, value: single?.scholarship_availability },
      { id: 11, value: single?.courses_duration },
      { id: 12, value: single?.tuition_fee },
    ];
  };

  return (
    <div style={styles.compareContainer}>
      <div style={styles.compareLeftPart}>
        <div style={styles.compareHeader}>
          <Text.Heading
            fontSize={"18px"}
            fontWeight={"600"}
            textAlign={"center"}
            color={Colors.Primary}
            text="Compare Universitites"
          />
          <Layout.Spacer height={10} />
          <Text.Heading
            fontSize={"10px"}
            fontWeight={"500"}
            textAlign={"center"}
            color={Colors.Black}
            text="This section shows overall summary of the universitites"
          />
        </div>
        <Layout.Spacer height={20} />
        <div style={styles.comparelabelsContainer}>
          {tableHeader.map((item, index) => (
            <div
              key={item.id}
              style={{
                ...styles.compareLabelDiv,
                filter: freeUser && index > 4 ? "blur(10px)" : "",
              }}
            >
              <div style={styles.compareLabelImageContainer}>{item.image}</div>
              <div style={styles.compareLabel}>{item.title}</div>
            </div>
          ))}
        </div>
      </div>
      <div style={styles.compareRightPart}>
        {loading ? (
          <div style={{ marginTop: "300px" }}>
            <Loader.Circular />
          </div>
        ) : (
          <div style={styles.flex}>
            {compareUniversity?.map((single, index) => {
              const values = compareValues(single);
              return (
                <div style={styles.compareDetailsContainer} key={single._id}>
                  <div style={styles.compareImageBox}>
                    <button
                      style={styles.deleteButton}
                      onClick={() => DeleteFunction(single)}
                    >
                      <AiOutlineClose color="white" />
                    </button>
                    <div style={styles.compareImageDiv}>
                      <img
                        src={single?.profilePic}
                        alt="Icon"
                        style={styles.compareImage}
                      />
                    </div>
                    <div style={styles.uniNameAfterImage}>{single?.name}</div>
                  </div>
                  <Layout.Spacer height={20} />
                  <div style={styles.compareValuesContainer}>
                    {values?.map((item, index) => (
                      <div
                        style={{
                          ...styles.compareLabelDiv,
                          filter: freeUser && index > 4 ? "blur(10px)" : "",
                        }}
                        key={item.id}
                      >
                        <div style={styles.compareValuesText}>{item.value}</div>
                      </div>
                    ))}
                  </div>
                </div>
              );
            })}
            <div
              style={styles.compareDetailsContainerEmpty}
              onClick={() => {
                if (!freeUser || (freeUser && uniIdArray?.length < 2)) {
                  setShowModal(true);
                } else {
                  setShowSubModal(true);
                }
              }}
            >
              <div style={styles.compareImageBox}>
                <div style={styles.compareImageDiv}>
                  <AiOutlinePlusCircle style={styles.addMoreIcon} />
                  <div style={styles.addButtonText}>Add University</div>
                </div>
              </div>
            </div>
          </div>
        )}
        <Layout.Spacer height={20} />
      </div>
      {showModal && (
        <Modal.Basic
          isModalVisible={showModal}
          centered={true}
          handleCancel={() => setShowModal(false)}
          closable={false}
        >
          <div style={styles.flexRowStart}>
            <Text.Heading
              fontSize={"16px"}
              fontWeight={"400"}
              color={Colors.GrayText}
              text="Select the University you want to compare"
            />
          </div>

          <Layout.Spacer height={20} />
          {universityLoading?.isLoading ? (
            <Loader.Circular />
          ) : (
            <div className="dashboard-select">
              <Select
                style={styles.compareDropdownSelect}
                onChange={(universityJSON) => {
                  var university = JSON.parse(universityJSON);

                  setUniversityIDArray((oldArray) => [
                    ...oldArray,
                    university._id,
                  ]);
                }}
                showSearch
                placeholder="Select a University to compare"
                optionFilterProp="children"
              >
                {universityState.map((dropdown) => {
                  return (
                    <Select.Option
                      value={JSON.stringify(dropdown)}
                      style={styles.compareDropdownValues}
                      key={dropdown._id}
                    >
                      {dropdown.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </div>
          )}

          <Layout.Spacer height={20} />

          <div style={styles.flexCenter}>
            <Button.Primary
              style={styles.closeButton}
              onClick={() => {
                setShowModal(false);
                uniCompareAPICall();
              }}
            >
              Done
            </Button.Primary>
          </div>

          <Layout.Spacer height={10} />
        </Modal.Basic>
      )}
      {showSubModal && (
        <Modal.Basic
          isModalVisible={showSubModal}
          centered={true}
          handleCancel={() => setShowSubModal(false)}
          closable={false}
        >
          <div style={styles.flexCenter}>
            <img src={Images.FullLogo} alt="" style={{ height: "50px" }} />
          </div>

          <Layout.Spacer height={20} />
          <div style={{ fontSize: "30px", fontWeight: "700" }}>Subscribe</div>
          <Layout.Spacer height={20} />
          <div style={{ fontSize: "18px", fontWeight: "500" }}>
            To view more subscribe to our Pro Package, <br />
            Enter your email address we will send details
          </div>
          <Layout.Spacer height={10} />
          <fieldset class="fieldInput my-4">
            <input
              class="form-input"
              type="email"
              placeholder="Enter your email address here"
              onChange={(e) => setEmail(e.target.value)}
            />
            <button
              type="submit"
              className="form-submit"
              onClick={handleEmailSubmit}
            >
              <FaTelegramPlane
                color={Colors.White}
                style={{ width: "25.19px", height: "25.19px" }}
              />
            </button>
          </fieldset>
        </Modal.Basic>
      )}
      {freeUser && (
        <div style={styles.premiumModal}>
          <img src={premiumContent} />
          <p style={{ color: "black" }}>
            To view more <b>Subscribe to our Pro Package</b>
          </p>
        </div>
      )}
    </div>
  );
}

export default Compare;
