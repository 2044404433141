import React, { useState } from "react";
import { useNavigate } from "react-router";
import { Select } from "antd";

import { IoMdCloseCircle } from "react-icons/io";
import { MdCancel } from "react-icons/md";
import { FaAngleDown } from "react-icons/fa6";
import { Layout, Text, TextField } from "../../components";
import { Colors } from "../../config";

import CircleContainer from "./circleContainer";

import styles from "./styles";

function RecommendersQualifications() {
  const navigate = useNavigate();

  const [qualification, setQualification] = useState("aLevels");
  const [expectedGrade, setExpectedGrade] = useState({
    expectedGrade1: "",
    expectedGrade2: "",
    expectedGrade3: "",
    expectedGrade4: "",
  });
  const [toefl, setToefl] = useState("");
  const [ielts, setIelts] = useState("");
  const [satAll, setSatAll] = useState("");
  const [satEnglish, setSatEnglish] = useState("");
  const [satMath, setSatMath] = useState("");
  const [showSatMathEnglish, setShowSatMathEnglish] = useState(false);
  const [state, setState] = useState({
    grades: [{ subject: "", grade: "" }],
  });
  const [error, setError] = useState("");
  const [error2, setError2] = useState("");

  const handleNext = () => {
    if (
      (qualification === "aLevels" || qualification === "stpm") &&
      (!expectedGrade.expectedGrade1 ||
        !expectedGrade.expectedGrade2 ||
        !expectedGrade.expectedGrade3)
    ) {
      setError2("Please fill all the required fields");
    } else if (
      (qualification === "ibDiploma" || qualification === "fsc") &&
      !expectedGrade.expectedGrade1
    ) {
      setError2("Please fill all the required fields");
    } else if (
      (satEnglish || satMath || satAll) &&
      parseInt(satEnglish) + parseInt(satMath) !== parseInt(satAll)
    ) {
      setError2("Your total SAT score does not match the total SAT score");
    } else {
      navigate("/recommender/preferences", {
        state: {
          qualification,
          expectedGrade: expectedGrade,
          grades: state.grades,
          toefl: toefl ? toefl : "0",
          ielts: ielts ? ielts : "0",
          satAll: satAll ? satAll : "0",
          satEnglish: satEnglish ? satEnglish : "0",
          satMath: satMath ? satMath : "0",
        },
      });
    }
  };

  const handleAddMoregrades = () => {
    let checkFieldsValidation = checkFields();
    if (checkFieldsValidation) {
      setError("Input atleast one grade before adding another.");
    } else {
      setError("");
      setState({
        ...state,
        grades: [...state.grades, { subject: "", grade: "" }],
      });
    }
  };

  const handleRemovegrades = (index) => {
    var array = [...state.grades];
    array.splice(index, 1);
    setState({
      ...state,
      grades: array,
    });
  };

  const checkFields = () => {
    let flag = false;
    state.grades.forEach((item) => {
      if (!item.subject || !item.grade) {
        flag = true;
      }
    });
    return flag;
  };

  return (
    <div style={styles.flexColumn}>
      <CircleContainer page="qualifications" />
      <Layout.Spacer height={20} />

      <div>
        <Text.Heading
          fontSize={"18px"}
          fontWeight={"600"}
          color={Colors.Primary}
          text="General Qualifications"
        />

        <div style={styles.inputFieldContainer}>
          <div>
            <div style={styles.boxHeaders}>Current Qualification</div>
            <div className="university-form-1">
              <Select
                defaultValue="underGraduate"
                style={{ width: "100%" }}
                value={qualification}
                onChange={(e) => {
                  setQualification(e);
                }}
              >
                <Select.Option value="aLevels">A-Levels</Select.Option>
                <Select.Option value="ibDiploma">IB Diploma</Select.Option>
                <Select.Option value="stpm">STPM</Select.Option>
                <Select.Option value="fsc">FSC</Select.Option>
              </Select>
            </div>
          </div>
          {qualification === "aLevels" || qualification === "stpm" ? (
            <>
              <div>
                <div style={styles.boxHeaders}>Expected Grade *</div>
                <div className="university-form-1">
                  <Select
                    placeholder="A*"
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      setExpectedGrade({ ...expectedGrade, expectedGrade1: e });
                    }}
                    suffixIcon={
                      expectedGrade?.expectedGrade1 ? null : (
                        <FaAngleDown color={Colors?.Primary} fontSize={18} />
                      )
                    }
                    allowClear={{
                      clearIcon: <MdCancel color={Colors?.Red} fontSize={14} />,
                    }}
                  >
                    <Select.Option value="A*">A*</Select.Option>
                    <Select.Option value="A">A</Select.Option>
                    <Select.Option value="B">B</Select.Option>
                    <Select.Option value="C">C</Select.Option>
                    <Select.Option value="D">D</Select.Option>
                    <Select.Option value="E">E</Select.Option>
                    <Select.Option value="U">U</Select.Option>
                  </Select>
                </div>
              </div>
              <div>
                <div style={styles.boxHeaders}>Expected Grade *</div>
                <div className="university-form-1">
                  <Select
                    placeholder="A*"
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      setExpectedGrade({ ...expectedGrade, expectedGrade2: e });
                    }}
                    suffixIcon={
                      expectedGrade?.expectedGrade2 ? null : (
                        <FaAngleDown color={Colors?.Primary} fontSize={18} />
                      )
                    }
                    allowClear={{
                      clearIcon: <MdCancel color={Colors?.Red} fontSize={14} />,
                    }}
                  >
                    <Select.Option value="A*">A*</Select.Option>
                    <Select.Option value="A">A</Select.Option>
                    <Select.Option value="B">B</Select.Option>
                    <Select.Option value="C">C</Select.Option>
                    <Select.Option value="D">D</Select.Option>
                    <Select.Option value="E">E</Select.Option>
                    <Select.Option value="U">U</Select.Option>
                  </Select>
                </div>
              </div>
              <div>
                <div style={styles.boxHeaders}>Expected Grade *</div>
                <div className="university-form-1">
                  <Select
                    placeholder="A*"
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      setExpectedGrade({ ...expectedGrade, expectedGrade3: e });
                    }}
                    suffixIcon={
                      expectedGrade?.expectedGrade3 ? null : (
                        <FaAngleDown color={Colors?.Primary} fontSize={18} />
                      )
                    }
                    allowClear={{
                      clearIcon: <MdCancel color={Colors?.Red} fontSize={14} />,
                    }}
                  >
                    <Select.Option value="A*">A*</Select.Option>
                    <Select.Option value="A">A</Select.Option>
                    <Select.Option value="B">B</Select.Option>
                    <Select.Option value="C">C</Select.Option>
                    <Select.Option value="D">D</Select.Option>
                    <Select.Option value="E">E</Select.Option>
                    <Select.Option value="U">U</Select.Option>
                  </Select>
                </div>
              </div>
              <div>
                <div style={styles.boxHeaders}>Expected Grade</div>
                <div className="university-form-1">
                  <Select
                    placeholder="A*"
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      setExpectedGrade({ ...expectedGrade, expectedGrade4: e });
                    }}
                    suffixIcon={
                      expectedGrade?.expectedGrade4 ? null : (
                        <FaAngleDown color={Colors?.Primary} fontSize={18} />
                      )
                    }
                    allowClear={{
                      clearIcon: <MdCancel color={Colors?.Red} fontSize={14} />,
                    }}
                  >
                    <Select.Option value="A*">A*</Select.Option>
                    <Select.Option value="A">A</Select.Option>
                    <Select.Option value="B">B</Select.Option>
                    <Select.Option value="C">C</Select.Option>
                    <Select.Option value="D">D</Select.Option>
                    <Select.Option value="E">E</Select.Option>
                    <Select.Option value="U">U</Select.Option>
                  </Select>
                </div>
              </div>
            </>
          ) : null}
          {qualification === "ibDiploma" ? (
            <div>
              <div style={styles.boxHeaders}>Expected Grade *</div>
              <div>
                <TextField.CustomInput
                  placeholder="0-45"
                  name="expectedGrade"
                  style={styles.scoresInput}
                  type="number"
                  onChange={(e) =>
                    setExpectedGrade({
                      expectedGrade1: e.target.value,
                      expectedGrade2: "",
                      expectedGrade3: "",
                      expectedGrade4: "",
                    })
                  }
                />
              </div>
            </div>
          ) : null}
          {qualification === "fsc" ? (
            <div>
              <div style={styles.boxHeaders}>Percentage *</div>
              <div>
                <TextField.CustomInput
                  placeholder="0-100%"
                  name="expectedGrade"
                  style={styles.scoresInput}
                  type="number"
                  onChange={(e) =>
                    setExpectedGrade({
                      expectedGrade1: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          ) : null}
        </div>

        <Layout.Spacer height={20} />

        <div style={styles.gradeSectionContainer}>
          <div>
            <Text.Heading
              fontSize={"18px"}
              fontWeight={"600"}
              color={Colors.Primary}
              text="Grades"
            />
          </div>
          <div style={styles.addGradeButtonContainer}>
            <button style={styles.addGradeButton} onClick={handleAddMoregrades}>
              Add Subject and Grade
            </button>
          </div>
        </div>
        <Layout.Spacer height={10} />
        <div style={styles.gradeContainer}>
          {state.grades.map((item, index) => (
            <div style={{ display: "flex" }} key={index}>
              <div style={styles.gradeInputContainer}>
                <div style={styles.gradeInputContainer2}>
                  <TextField.CustomInput
                    placeholder="English"
                    label="Subject"
                    value={item.subject}
                    onChange={(e) => {
                      let newValue = [...state.grades];

                      newValue[index].subject = e.target.value;
                      setState({
                        grades: newValue,
                      });
                    }}
                    style={styles.gradeInputBox}
                  />
                </div>
                <Layout.Spacer width={50} />
                <div style={styles.gradeInputContainer2}>
                  <TextField.CustomInput
                    placeholder="A* / 80%"
                    label="Grades"
                    value={item.grade}
                    onChange={(e) => {
                      let newValue = [...state.grades];

                      newValue[index].grade = e.target.value;
                      setState({
                        grades: newValue,
                      });
                    }}
                    style={styles.gradeInputBox}
                  />
                </div>
              </div>
              {state.grades.length > 0 && index !== 0 && (
                <IoMdCloseCircle
                  color={Colors.Danger}
                  fontSize={30}
                  cursor={"pointer"}
                  style={{ marginTop: "25px" }}
                  onClick={() => handleRemovegrades(index)}
                />
              )}
            </div>
          ))}
        </div>
      </div>

      <Layout.Spacer height={20} />
      {error !== "" && <div style={styles.errorText}>{error}</div>}

      <div style={styles.inputFieldContainer}>
        <div>
          <div style={styles.boxHeaders}>TOEFL Score</div>
          <div>
            <TextField.CustomInput
              placeholder="0-120"
              name="ToeflScore"
              value={toefl}
              onChange={(e) => setToefl(e.target.value)}
              style={styles.scoresInput}
              type="number"
            />
          </div>
        </div>
        <div>
          <div style={styles.boxHeaders}>IELTS Score</div>
          <div>
            <TextField.CustomInput
              placeholder="1-9"
              name="IeltsScore"
              value={ielts}
              onChange={(e) => setIelts(e.target.value)}
              style={styles.scoresInput}
              type="number"
            />
          </div>
        </div>
        <div>
          <div style={styles.boxHeaders}>SAT Score</div>
          <div>
            <TextField.CustomInput
              placeholder="0-1600"
              name="SatScore"
              value={satAll}
              onChange={(e) => setSatAll(e.target.value)}
              onClick={() => setShowSatMathEnglish(true)}
              style={styles.scoresInput}
              type="number"
            />
          </div>
        </div>
        {showSatMathEnglish && (
          <>
            <div>
              <div style={styles.boxHeaders}>SAT (English)</div>
              <div>
                <TextField.CustomInput
                  placeholder="0 - 800"
                  name="SATEnglish"
                  value={satEnglish}
                  onChange={(e) => setSatEnglish(e.target.value)}
                  style={styles.scoresInput}
                  type="number"
                />
              </div>
            </div>
            <div>
              <div style={styles.boxHeaders}>SAT (Math)</div>
              <div>
                <TextField.CustomInput
                  placeholder="0 - 800"
                  name="SATmath"
                  value={satMath}
                  onChange={(e) => setSatMath(e.target.value)}
                  style={styles.scoresInput}
                  type="number"
                />
              </div>
            </div>
          </>
        )}
      </div>
      {error2 !== "" && <div style={styles.errorText}>{error2}</div>}
      <div style={styles.justifyEnd}>
        <div style={styles.continueButton} onClick={() => handleNext()}>
          Continue
        </div>
      </div>
    </div>
  );
}

export default RecommendersQualifications;
