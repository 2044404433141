import { Colors } from "../../config";

const styles = {
  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  flexSpaceBetween: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  searchButton: {
    width: "150px",
    padding: "6px",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "center",
    margin: "0px auto",
    alignItems: "center",
    backgroundColor: Colors.Primary,
    cursor: "pointer",
  },
  customTextfieldStyle: {
    borderRadius: "10px",
    border: `1px solid ${Colors.Primary}`,
    padding: "10px",
    width: "100%",
    fontSize: "16px",
    fontWeight: "500",
    color: "#081C15",
  },
  courseDivContainer: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
    gridGap: "20px",
  },
  courseDiv: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    height: "100%",
    background: "#F5F5F5",
    borderRadius: "10px",
    boxShadow: "0px 6px 18px -4px rgba(0,0,0,0.4)",
  },
  courseImage: {
    width: "auto",
    height: "200px",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
  },
  coursePrice: {
    color: Colors.White,
    background: Colors.Primary,
    padding: "5px 10px",
    fontSize: "12px",
    fontWeight: "600",
    width: "100%",
    height: "25px",
  },
  courseInstructor: {
    color: "black",
    padding: "5px",
    fontSize: "12px",
    fontWeight: "400",
    width: "100%",
    padding: "5px 10px",
  },
  courseTitle: {
    color: "black",
    padding: "5px",
    fontSize: "12px",
    fontWeight: "600",
    width: "100%",
    padding: "0px 10px",
  },
  courseHeadline: {
    color: Colors.GrayText,
    padding: "10px",
    fontSize: "10px",
    fontWeight: "400",
    width: "100%",
    height: "60px",
  },
  boxStyle: {
    position: "absolute",
    top: "45%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "43%",
    bgcolor: "background.paper",
    boxShadow: 4,
    p: 4,
    borderRadius: "20px",
  },

  filterButton: {
    width: "45px",
    height: "45px",
    padding: "6px",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Colors.Primary,
  },
  filterDropdown: {
    position: "absolute",
    zIndex: "100",
    backgroundColor: Colors.Primary,
    color: "white",
    fontSize: "12px",
    height: "80px",
    width: "150px",
    borderRadius: "5px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
    marginTop: "5px",
    left: "-55px",
    animation: "slide-down 0.3s ease",
  },
  filterDropdownValue: {
    cursor: "pointer",
    fontSize: "14px",
    width: "100%",
    textAlign: "center",
  },
};

export default styles;
