import { Colors } from "../../config";
const styles = {
  flexColumnCenter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  flexColumnCenter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  modalButton: {
    backgroundColor: Colors.Primary,
    fontWeight: "700",
    fontSize: "16px",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "15px",
    width: "120px",
  },
  modalButtonRed: {
    backgroundColor: Colors.Red,
    fontWeight: "700",
    fontSize: "16px",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "15px",
    width: "120px",
  },
  redIcon: {
    color: "red",
    height: "68px",
    width: "68px",
    padding: "10px",
  },

  valuesContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "30px",
    color: "black",
  },
  valueTitle: { fontSize: "14px", fontWeight: "500" },
  valueBox: {
    padding: "10px",
    border: `1px solid ${Colors.Primary}`,
    borderRadius: "6px",
  },
};

export default styles;
