import { Colors } from "../../config";
const styles = {
  flexRow: {
    display: "flex",
    flexDirection: "row",
  },
  heading: {
    fontSize: "12px",
    fontWeight: "600",
    fontFamily: "Montserrat",
    color: "#081C15",
    textTransform: "capitalize",
    textAlign: "left",
  },
  subHeading: {
    fontSize: "10px",
    fontWeight: "400",
    fontFamily: "Montserrat",
    color: Colors.GrayText,
    textTransform: "capitalize",
    textAlign: "left",
  },
  iconDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "7px",
    borderRadius: "50px",
    marginRight: "5px",
    backgroundColor: Colors.Primary,
  },
};

export default styles;
