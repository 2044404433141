import Accommodation from "../../assets/images/accommodation.svg";
import AnnouncementIcon from "../../assets/images/announcementLogo.svg";
import ApplicationIcon from "../../assets/images/application.png";
import Approval from "../../assets/images/approval.svg";
import Applied from "../../assets/images/applied.svg";
import Book from "../../assets/images/book.svg";
import Bus2 from "../../assets/images/bus.svg";
import Calculator from "../../assets/images/calculator.svg";
import Campus from "../../assets/images/university-campus.svg";
import CashOnDelivery from "../../assets/images/cashOnDelivery.png";
import Card from "../../assets/images/card.png";
import Chatbot from "../../assets/images/chatbot.svg";
import ChatbotWhite from "../../assets/images/chatbotWhite.svg";
import Converter from "../../assets/images/converter.svg";
import ClassSize from "../../assets/images/presentation.svg";
import Clipboard from "../../assets/images/clipboard.svg";
import CoursesDurationLabel from "../../assets/images/coursesDurationLabel.svg";
import DateRange from "../../assets/images/calendar.svg";
import DefaultRestaurant from "../../assets/images/defaultRestaurant.png";
import Duration from "../../assets/images/homework.svg";
import DummyImage from "../../assets/images/dummyUser.jpg";
import EasyPaisa from "../../assets/images/easyPaisa.png";
import Education from "../../assets/images/education.svg";
import Essay from "../../assets/images/essay.svg";
import Etextbook from "../../assets/images/open-book.svg";
import Exam from "../../assets/images/exam.svg";
import Exchange1 from "../../assets/images/world.svg";
import Facebook from "../../assets/images/facebk.svg";
import Facilities from "../../assets/images/school.svg";
import FacultyStudentRatio from "../../assets/images/facultyStudentRatio.svg";
import Food from "../../assets/images/fast-food.svg";
import FullLogo from "../../assets/images/univisionLoginText.png";
import GraduationCap from "../../assets/images/graduationCap.png";
import Graduation from "../../assets/images/graduation-hat.svg";
import HomePhoto1 from "../../assets/images/homePhoto1.png";
import HousingCost from "../../assets/images/housingCost.png";
import InteractiveLearning from "../../assets/images/interactiveLearning.svg";
import Interview from "../../assets/images/interview.svg";
import Instagram from "../../assets/images/instagram.svg";
import JazzCash from "../../assets/images/jazzcash.png";
import Journey1 from "../../assets/images/journey1.svg";
import Journey2 from "../../assets/images/journey2.svg";
import Journey3 from "../../assets/images/journey3.svg";
import Journey4 from "../../assets/images/journey4.svg";
import LinkedIn from "../../assets/images/linkedin.svg";
import LeftArrowCircle from "../../assets/images/arrowLeftCircle.svg";
import Loan from "../../assets/images/loan.svg";
import Logo from "../../assets/images/Logo.svg";
import Location from "../../assets/images/location.svg";
import LoginBackground from "../../assets/images/studentPortalBackground.jpg";
import MaleToFemaleRatio from "../../assets/images/graduates.svg";
import Marketplace from "../../assets/images/marketplace.svg";
import MeetingDetails from "../../assets/images/meetingDetails.png";
import MeetingIcon from "../../assets/images/meetingIcon.svg";
import Medal from "../../assets/images/medal.svg";
import MeetingIconGreen from "../../assets/images/meetingIconGreen.svg";
import MeetingLogo from "../../assets/images/meetingLogo.png";
import Medical from "../../assets/images/cardiogram.svg";
import Metro from "../../assets/images/train.svg";
import Misc from "../../assets/images/bill.svg";
import MoneyBag from "../../assets/images/budget1.svg";
import NoData from "../../assets/images/no-data.svg";
import Others from "../../assets/images/pending.svg";
import PeopleIcon from "../../assets/images/people.png";
import PostGradSalary from "../../assets/images/postgradSalary.png";
import Placement from "../../assets/images/graduate.svg";
import Promotion from "../../assets/images/promotion.png";
import Processing from "../../assets/images/work-process.svg";
import ProgramsLabel from "../../assets/images/programsLabel.svg";
import Product from "../../assets/images/product.svg";
import ProIcon from "../../assets/images/premium.svg";
import QsLabel from "../../assets/images/qsLabel.svg";
import RatioLabel from "../../assets/images/ratioLabel.svg";
import Ranking from "../../assets/images/ranking.svg";
import ReferAFriend from "../../assets/images/opinions.svg";
import RecommenderIcon from "../../assets/images/recommender.svg";
import Research from "../../assets/images/research.svg";
import ResearchOutputLabel from "../../assets/images/researchOutputLabel.svg";
import ScholarshipLabel from "../../assets/images/scholarshipLabel.svg";
import Sort from "../../assets/images/sort.png";
import Shortlist from "../../assets/images/shortlist.svg";
import StatusLabel from "../../assets/images/statusLabel.svg";
import StudentsLabel from "../../assets/images/studentsLabel.svg";
import Tracker from "../../assets/images/tracker.svg";
import Time from "../../assets/images/sand-clock.svg";
import Thumb from "../../assets/images/thumbs-up.svg";
import TutionLabel from "../../assets/images/tutionLabel.svg";
import Uni1 from "../../assets/images/uni-1.png";
import UniversityLabel from "../../assets/images/universityLabel.svg";
import UniversityCampusLabel from "../../assets/images/universityCampusLabel.svg";
import UnivisionPopup from "../../assets/images/UnivisionLogo.svg";
import Univision from "../../assets/images/univision.png";
import Visa from "../../assets/images/success.svg";
import Whatsapp from "../../assets/images/whatsapp.svg";

const Images = {
  Accommodation,
  AnnouncementIcon,
  ApplicationIcon,
  Applied,
  Approval,
  Book,
  Bus2,
  Calculator,
  Campus,
  CashOnDelivery,
  Card,
  ChatbotWhite,
  Chatbot,
  Converter,
  ClassSize,
  Clipboard,
  CoursesDurationLabel,
  DateRange,
  DefaultRestaurant,
  Duration,
  DummyImage,
  EasyPaisa,
  Education,
  UnivisionPopup,
  Essay,
  Etextbook,
  Exam,
  Exchange1,
  Facebook,
  Facilities,
  FacultyStudentRatio,
  Food,
  FullLogo,
  GraduationCap,
  Graduation,
  HomePhoto1,
  HousingCost,
  InteractiveLearning,
  Interview,
  Instagram,
  JazzCash,
  Journey1,
  Journey2,
  Journey3,
  Journey4,
  LinkedIn,
  LeftArrowCircle,
  Loan,
  Logo,
  Location,
  LoginBackground,
  MaleToFemaleRatio,
  Marketplace,
  MeetingDetails,
  MeetingIcon,
  Medal,
  MeetingIconGreen,
  MeetingLogo,
  Medical,
  Metro,
  Misc,
  MoneyBag,
  NoData,
  Others,
  PeopleIcon,
  PostGradSalary,
  Placement,
  Promotion,
  Processing,
  ProgramsLabel,
  Product,
  ProIcon,
  QsLabel,
  RatioLabel,
  Ranking,
  ReferAFriend,
  RecommenderIcon,
  Research,
  ResearchOutputLabel,
  ScholarshipLabel,
  Sort,
  Shortlist,
  StatusLabel,
  StudentsLabel,
  Tracker,
  Time,
  Thumb,
  TutionLabel,
  Uni1,
  UniversityLabel,
  UniversityCampusLabel,
  Univision,
  Visa,
  Whatsapp,
};

export default Images;
