import {
  ADD_MEETING,
  ADD_MEETING_FAILURE,
  ADD_MEETING_SUCCESS,
  CHANGE_MEETING_STATUS,
  CHANGE_MEETING_STATUS_FAILURE,
  CHANGE_MEETING_STATUS_SUCCESS,
  GET_MEETINGS_INVITES_AND_REQUESTS,
  GET_MEETINGS_INVITES_AND_REQUESTS_SUCCESS,
  GET_MEETINGS_INVITES_AND_REQUESTS_FAILURE,
  GET_ALL_MEETINGS_AND_COUNT,
  GET_ALL_MEETINGS_AND_COUNT_SUCCESS,
  GET_ALL_MEETINGS_AND_COUNT_FAILURE,
  GET_MEETINGS_BASED_ON_DATE,
  GET_MEETINGS_BASED_ON_DATE_SUCCESS,
  GET_MEETINGS_BASED_ON_DATE_FAILURE,
} from "../constants";

const initialState = {
  meetingsAll: [],
  meetingInvites: [],
  meetingSent: [],
  meetingBasedOnDate: [],
  isLoading: true,
  isAllMeetingLoading: true,
  isMeetingInvitesLoading: true,
  isMeetingBasedOnDateLoading: true,
  error: null,
};

export default function MeetingReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_MEETING:
    case CHANGE_MEETING_STATUS:
    case GET_MEETINGS_INVITES_AND_REQUESTS:
    case GET_ALL_MEETINGS_AND_COUNT:
    case GET_MEETINGS_BASED_ON_DATE:
      state = {
        ...state,
        isLoading: true,
        isAllMeetingLoading: true,
        isMeetingInvitesLoading: true,
        isMeetingBasedOnDateLoading: true,
        error: null,
      };
      break;
    case ADD_MEETING_SUCCESS:
    case CHANGE_MEETING_STATUS_SUCCESS:
      state = {
        ...state,
        isLoading: false,
      };
      break;
    case GET_MEETINGS_INVITES_AND_REQUESTS_SUCCESS:
      state = {
        ...state,
        meetingInvites: action.payload.meetingInvites,
        meetingSent: action.payload.meetingSent,
        isMeetingInvitesLoading: false,
      };
      break;
    case GET_ALL_MEETINGS_AND_COUNT_SUCCESS:
      state = {
        ...state,
        meetingsAll: action.payload,
        isAllMeetingLoading: false,
      };
      break;
    case GET_MEETINGS_BASED_ON_DATE_SUCCESS:
      state = {
        ...state,
        meetingBasedOnDate: action.payload,
        isMeetingBasedOnDateLoading: false,
      };
      break;
    case ADD_MEETING_FAILURE:
    case CHANGE_MEETING_STATUS_FAILURE:
    case GET_MEETINGS_INVITES_AND_REQUESTS_FAILURE:
    case GET_ALL_MEETINGS_AND_COUNT_FAILURE:
    case GET_MEETINGS_BASED_ON_DATE_FAILURE:
      state = {
        ...state,
        isLoading: false,
        isAllMeetingLoading: false,
        isMeetingInvitesLoading: false,
        isMeetingBasedOnDateLoading: false,
        error: action.payload,
      };
      break;
    default:
      break;
  }
  return state;
}
