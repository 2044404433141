import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import { Tabs } from "antd";
import { IoCallOutline } from "react-icons/io5";
import { IoGlobeOutline } from "react-icons/io5";
import { MdLocationPin, MdMailOutline } from "react-icons/md";

import { Layout, Loader, Text, Button } from "../../components";
import { Colors, Images } from "../../config";
import { UniversityAction, WatchlistAction } from "../../store/actions";

import ApplicationJourney from "./ApplicationJourney";
import Finances from "./Finances";
import General from "./General";
import PostGraduation from "./PostGraduation";
import Programs from "./Programs";
import StudentDetails from "./StudentDetails";
import Summary from "./Summary";
import VisaInformation from "./VisaInformation";

import styles from "./styles";

const { TabPane } = Tabs;

function UniversityDetails() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const id = location.pathname.split("/")[2];

  const university = useSelector((state) => state?.university?.university);
  const watchlistState = useSelector((state) => state?.watchlist);
  const universityState = useSelector((state) => state?.university);

  const universityArray = [university?._id];
  const [showFull, setShowFull] = useState(false);
  const [watchlistText, setWatchlistText] = useState("");

  const tabs = [
    "general",
    "student-life",
    "visa",
    "programs",
    "finances",
    "post-graduation",
    "application-journey",
    "summary",
    "compare",
  ];
  const tabName = location.pathname.split("/")[3];
  const tabKey = String(tabs.indexOf(tabName) + 1);

  useEffect(() => {
    const containsValue = watchlistState.watchlists.some((obj) => {
      return Object.values(obj.universityId).includes(university?._id);
    });

    if (containsValue) {
      setWatchlistText("Remove from Watchlist");
    } else {
      setWatchlistText("Add to Watchlist");
    }
  }, [watchlistState]);

  useEffect(() => {
    dispatch(UniversityAction.getUniversityDetails(id));
    const payload2 = {
      limit: "",
      offset: 0,
    };
    dispatch(WatchlistAction.getWatchlist(payload2));
  }, []);

  const uniCompareAPICall = () => {
    var body = {
      uniIds: universityArray,
    };
    dispatch(
      UniversityAction.compareUniverities(body, () => {
        navigate("/universities/compare");
      })
    );
  };

  const handleUniversityWishlist = (id) => {
    dispatch(
      WatchlistAction.addOrRemoveToWishlist(id, () => {
        dispatch(UniversityAction.getUniversityDetails(id));
        const payload = {
          limit: "",
          offset: 0,
        };
        dispatch(WatchlistAction.getWatchlist(payload));
      })
    );
  };

  const sortedPrograms = university?.programs?.sort((a, b) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();

    if (nameA < nameB) {
      return -1;
    } else if (nameA > nameB) {
      return 1;
    } else {
      return 0;
    }
  });

  return (
    <div>
      {universityState?.isLoading ? (
        <Loader.Circular />
      ) : (
        <div style={{ marginTop: "40px" }}>
          <div style={styles.rowStyle}>
            <div style={styles.UniversityProfileDiv}>
              <img
                src={
                  university?.profilePic ? university?.profilePic : Images.Uni1
                }
                alt="uniImg"
                style={styles.UniversityProfileImg}
              />
            </div>
            <div style={{ display: "flex" }}>
              <div>
                <div style={{ ...styles.cardDiv, paddingBottom: "0px" }}>
                  <Text.Heading
                    fontSize={"22px"}
                    fontWeight={"700"}
                    color={Colors.Primary}
                    text={
                      university?.summary?.qsWorldRanking === "NA"
                        ? "NA"
                        : `#${university?.summary?.qsWorldRanking}`
                    }
                  />
                  <Text.Heading
                    fontSize={"14px"}
                    fontWeight={"600"}
                    color={Colors.Black}
                    style={{ opacity: "0.8" }}
                    text={"QS World Ranking"}
                  />
                  <Text.Heading
                    fontSize={"10px"}
                    fontWeight={"300"}
                    color={Colors.GrayText}
                    style={styles.mb10}
                    text={"2023"}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div style={{ ...styles.universityDetailsCard }}>
                  <div
                    style={{
                      ...styles.flexRowCenter,
                      ...styles.mb10,
                      width: "200px",
                    }}
                  >
                    <IoGlobeOutline style={styles.rightBoxIcons} />
                    <Text.Heading
                      fontSize={"10px"}
                      fontWeight={"400"}
                      color={Colors.GrayText}
                      style={{
                        ...styles.ml20,
                        ...styles.cursor,
                      }}
                      textAlign="left"
                      onClick={() => {
                        window.open(university?.websiteLink, "_blank");
                      }}
                      text={
                        university?.websiteLink?.length > 25
                          ? `${university?.websiteLink?.slice(0, 25)}... `
                          : university?.websiteLink
                      }
                    />
                  </div>
                  <div
                    style={{
                      ...styles.flexRowCenter,
                      ...styles.mb10,
                    }}
                  >
                    <IoCallOutline style={styles.rightBoxIcons} />
                    <Text.Heading
                      fontSize={"10px"}
                      fontWeight={"400"}
                      color={Colors.GrayText}
                      style={styles.ml20}
                      textAlign="left"
                      text={`+${university?.phoneNumber}`}
                    />
                  </div>
                  <div
                    style={{
                      ...styles.flexRowCenter,
                      ...styles.mb10,
                    }}
                  >
                    <MdMailOutline style={styles.rightBoxIcons} />
                    <Text.Heading
                      fontSize={"10px"}
                      fontWeight={"400"}
                      color={Colors.GrayText}
                      style={{
                        ...styles.ml20,
                        ...styles.cursor,
                      }}
                      textAlign="left"
                      onClick={() =>
                        university?.email !== "NA" &&
                        (window.location.href = `mailto:${university?.email}`)
                      }
                      text={
                        university?.email?.length > 20
                          ? `${university?.email?.slice(0, 17)}... `
                          : university?.email
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Layout.Spacer height={20} />

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <div style={styles.flexColumn}>
                <Text.Heading
                  fontSize={"20px"}
                  fontWeight={"600"}
                  color={Colors.Primary}
                  textTransform="capitalize"
                  text={university?.name}
                  textAlign="left"
                />
                <div style={styles.flexRowCenter}>
                  <MdLocationPin color={Colors.BlackText2} style={styles.mr5} />
                  <Text.Heading
                    fontSize={"14px"}
                    fontWeight={"400"}
                    color={Colors.GrayText}
                    textAlign="left"
                    text={university?.location}
                  />
                </div>

                <Layout.Spacer height={20} />
              </div>
            </div>

            <div style={{ display: "flex" }}>
              <Button.Primary
                style={styles.indexButton}
                onClick={() => {
                  window.open(university?.applicationLink, "_blank");
                }}
              >
                Apply now
              </Button.Primary>
              <Layout.Spacer height={10} />
              <Button.Primary
                style={styles.indexButton}
                onClick={uniCompareAPICall}
              >
                Compare
              </Button.Primary>
              <Layout.Spacer height={10} />

              <Button.Primary
                style={{ ...styles.indexButton, marginRight: "0px" }}
                onClick={() => handleUniversityWishlist(university?._id)}
              >
                {watchlistText}
              </Button.Primary>
            </div>
          </div>
          <div>
            {showFull ? (
              <Text.Heading
                fontSize={"12px"}
                fontWeight={"500"}
                color={Colors.BlackText}
                textAlign="left"
                text={university?.details}
                onClick={() => setShowFull(!showFull)}
              />
            ) : (
              <Text.Heading
                fontSize={"12px"}
                fontWeight={"500"}
                color={Colors.BlackText}
                textAlign="left"
                onClick={() => setShowFull(!showFull)}
                text={
                  university?.details
                    ? `${university?.details.slice(0, 200)} See more...`
                    : ""
                }
              />
            )}
          </div>

          <Layout.Spacer height={40} />
          <Tabs
            type="card"
            className="university-details-tabs"
            moreIcon={false}
            animated={true}
            key={tabKey}
            defaultActiveKey={tabKey}
            onChange={(value) => {
              switch (value) {
                case "1":
                  navigate(`/universities/${id}/general`);
                  break;
                case "2":
                  navigate(`/universities/${id}/student-life`);
                  break;
                case "3":
                  navigate(`/universities/${id}/visa`);
                  break;
                case "4":
                  navigate(`/universities/${id}/programs`);
                  break;
                case "5":
                  navigate(`/universities/${id}/finances`);
                  break;
                case "6":
                  navigate(`/universities/${id}/post-graduation`);
                  break;
                case "7":
                  navigate(`/universities/${id}/application-journey`);
                  break;
                case "8":
                  navigate(`/universities/${id}/summary`);
                  break;
                default:
                  navigate(`/universities/${id}/general`);
              }
            }}
          >
            <TabPane tab="General" key="1">
              <General data={university} secondaryQs={university.secondaryQs} />
            </TabPane>
            <TabPane tab="Student Life" key="2">
              <StudentDetails
                data={university?.studentLife}
                secondaryQs={university.secondaryQs}
              />
            </TabPane>
            <TabPane tab="Visa Information" key="3">
              <VisaInformation
                data={university?.visaInformation}
                secondaryQs={university.secondaryQs}
              />
            </TabPane>
            <TabPane tab="Programs" key="4">
              <Programs
                location={university?.location}
                data={sortedPrograms}
                secondaryQs={university.secondaryQs}
              />
            </TabPane>
            <TabPane tab="Finances" key="5">
              <Finances
                location={university?.location}
                data={university?.finances}
                programs={
                  university?.programs && university?.programs.length !== 0
                    ? university?.programs
                    : []
                }
                studentLife={university?.studentLife}
                secondaryQs={university.secondaryQs}
              />
            </TabPane>
            <TabPane tab="Post Graduation" key="6">
              <PostGraduation
                data={university?.postGraduation}
                secondaryQs={university.secondaryQs}
              />
            </TabPane>
            <TabPane tab="Application Journey" key="7">
              <ApplicationJourney
                data={university?.applicationJourney}
                secondaryQs={university.secondaryQs}
              />
            </TabPane>
            <TabPane tab="Summary" key="8">
              <Summary
                data={university?.summary}
                secondaryQs={university.secondaryQs}
              />
            </TabPane>
          </Tabs>
        </div>
      )}
    </div>
  );
}

export default UniversityDetails;
