import {
  GET_STUDENT_WISHLIST,
  GET_STUDENT_WISHLIST_FAILURE,
  GET_STUDENT_WISHLIST_SUCCESS,
  ADD_OR_REMOVE_FROM_WISHLIST,
  ADD_OR_REMOVE_FROM_WISHLIST_FAILURE,
  ADD_OR_REMOVE_FROM_WISHLIST_SUCCESS,
} from "../constants";

export default class WatchlistAction {
  static getWatchlist(payload, cb) {
    return {
      type: GET_STUDENT_WISHLIST,
      payload,
      cb,
    };
  }
  static getWatchlistSuccess(payload) {
    return {
      type: GET_STUDENT_WISHLIST_SUCCESS,
      payload,
    };
  }
  static getWatchlistFailure(payload) {
    return {
      type: GET_STUDENT_WISHLIST_FAILURE,
      payload,
    };
  }
  static addOrRemoveToWishlist(id, cb) {
    return {
      type: ADD_OR_REMOVE_FROM_WISHLIST,
      id,
      cb,
    };
  }
  static addOrRemoveToWishlistSuccess(payload) {
    return {
      type: ADD_OR_REMOVE_FROM_WISHLIST_SUCCESS,
      payload,
    };
  }
  static addOrRemoveToWishlistFailure(payload) {
    return {
      type: ADD_OR_REMOVE_FROM_WISHLIST_FAILURE,
      payload,
    };
  }
}
