import {
  ADD_APPLICATIONS,
  ADD_APPLICATIONS_FAILURE,
  ADD_APPLICATIONS_SUCCESS,
  GET_APPLICATIONS_STUDENT,
  GET_APPLICATIONS_SUCCESS_STUDENT,
  GET_APPLICATIONS_FAILURE,
} from "../constants";

const initialState = {
  totalApplicationsCountStudent: 0,
  approvedApplications: [],
  pendingApplications: [],
  rejectedApplications: [],
  application: {},
  isLoading: false,
  error: null,
};

export default function ApplicationReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_APPLICATIONS:
    case GET_APPLICATIONS_STUDENT:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;
    case GET_APPLICATIONS_SUCCESS_STUDENT:
      state = {
        approvedApplications: action.payload.approvedApplications,
        pendingApplications: action.payload.pendingApplications,
        rejectedApplications: action.payload.rejectedApplications,
        totalApplicationsCountStudent:
          action.payload.totalApplicationsCountStudent,

        isLoading: false,
      };
      break;
    case ADD_APPLICATIONS_SUCCESS:
      state = {
        ...state,
        isLoading: false,
      };
    case ADD_APPLICATIONS_FAILURE:
    case GET_APPLICATIONS_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      break;
    default:
      break;
  }
  return state;
}
