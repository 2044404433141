import { useState } from "react";
import { useNavigate, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import { FaRegUserCircle, FaRegUser } from "react-icons/fa";

import { ChatbotAction } from "../../store/actions";

import { Colors, Images } from "../../config";

import styles from "./styles";

const ChatbotButton = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const title = (location || {}).pathname || {};
  const schoolIdState = useSelector((state) => state.auth.user?.user?.schoolId);

  const [isOpen, setIsOpen] = useState(false);

  const handleChatClick = (user) => {
    setIsOpen(false);
    const payload = {
      schoolId: schoolIdState,
    };
    dispatch(
      ChatbotAction.getUsers(payload, (res) => {
        if (user === "counselor") {
          navigate("/more/chatbot/counselors", {
            state: { counselors: res.counselors },
          });
        }
        if (user === "admin") {
          navigate("/more/chatbot/admins", {
            state: { admins: res.admins, staffAdmins: res.staffAdmins },
          });
        }
      })
    );
  };

  return (
    <>
      {process.env.REACT_APP_ENV !== "production" && (
        <>
          {!title.includes("chatbot") && (
            <div
              style={styles.chatbotButton}
              onClick={() => setIsOpen(!isOpen)}
            >
              <img src={Images.ChatbotWhite} height={30} width={30} />
            </div>
          )}
          {isOpen && (
            <div style={styles.chatbotContainer}>
              <img src={Images.Chatbot} style={styles.chatbotImage} />

              <div style={styles.chatbotHeaderText}>You want to chat with?</div>
              <div style={styles.justifyAround}>
                <div style={styles.chatbotOptionContainer}>
                  <div
                    style={styles.chatbotOption}
                    onClick={() => {
                      const user = "counselor";
                      handleChatClick(user);
                    }}
                  >
                    <FaRegUserCircle
                      fontSize={"20px"}
                      cursor={"pointer"}
                      color={Colors.White}
                    />
                  </div>
                  <div style={styles.chatbotOptionText}>Counselor</div>
                </div>
                <div style={styles.chatbotOptionContainer}>
                  <div
                    style={styles.chatbotOption}
                    onClick={() => {
                      const user = "admin";
                      handleChatClick(user);
                    }}
                  >
                    <FaRegUser
                      fontSize={"18px"}
                      cursor={"pointer"}
                      color={Colors.White}
                    />
                  </div>
                  <div style={styles.chatbotOptionText}>Admin</div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ChatbotButton;
