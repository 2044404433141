import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import { MarketplaceAction } from "../../store/actions";

import { Layout, Loader, Text } from "../../components";
import { Colors, Images } from "../../config";

import styles from "./styles";

function Books() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const studentId = useSelector((state) => state.auth.user.user.studentId);
  const schoolId = useSelector((state) => state.auth.user.user.schoolId);
  const marketplaceLoading = useSelector((state) => state.marketplace);
  const marketplace = useSelector(
    (state) => state.marketplace.marketplace.allProduct
  );

  useEffect(() => {
    const payload = {
      studentId,
      schoolId,
      type: "book",
    };
    dispatch(MarketplaceAction.getAllProduct(payload));
  }, []);

  return (
    <div>
      <Text.Heading
        fontSize={"18px"}
        fontWeight={"600"}
        color={Colors.Primary}
        text="Books"
      />
      <Layout.Spacer height={20} />
      {marketplaceLoading?.isLoading ? (
        <Loader.Circular />
      ) : (
        <>
          {marketplace?.length === 0 ? (
            <div style={styles.noDataContainer}>
              <img src={Images.NoData} height={60} width={60} alt="" />
              <div style={styles.noData}>No Data</div>
            </div>
          ) : (
            <div style={styles.specificTypeProductContainer}>
              {marketplace?.map((books) => {
                return (
                  <div
                    style={styles.constainerStyle2}
                    onClick={() =>
                      navigate(`/more/marketplace/product/${books._id}`)
                    }
                    key={books._id}
                  >
                    <div style={styles.imageContainerStyle}>
                      <img
                        src={books.itemPhoto}
                        height={62}
                        width={62}
                        style={{ borderRadius: "5px" }}
                      />
                    </div>
                    <div style={styles.textFieldContainer}>
                      <div style={styles.itemNameStyle}>
                        {books?.itemName?.length > 30
                          ? books?.itemName.slice(0, 30) + "..."
                          : books?.itemName}
                      </div>
                      <div style={styles.itemPriceStyle}>
                        {books?.price.split(" ")[0]}{" "}
                        {parseFloat(
                          books?.price.split(" ")[1]
                        ).toLocaleString()}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default Books;
