import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import { DatePicker, Select, Upload } from "antd";

import Lottie from "react-lottie-player";

import { BsUpload } from "react-icons/bs";
import { AiFillCloseCircle } from "react-icons/ai";

import { Button, Layout, Modal, Text, Loader } from "../../components";
import { Colors, Images } from "../../config";

import { UniversityAction, ApplicationAction } from "../../store/actions";

import Done from "../../assets/images/57767-done.json";

import styles from "./styles";

const AddApplication = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const studentId = useSelector((state) => state.auth.user.user.studentId);
  const studentType = useSelector((state) => state.auth.user.user.studentType);
  const universityLoading = useSelector((state) => state.university);
  const universityNames = useSelector(
    (state) => state.university.allUniversities
  );

  const [fileList, setFileList] = useState([]);
  const [file, setFile] = useState(null);
  const [university, setUniversity] = useState();
  const [program, setProgram] = useState();
  const [date, setDate] = useState();
  const [fileDataURL, setFileDataURL] = useState(Images.UniversityDefault);

  const [error, setError] = useState("");
  const [successModal, setSuccessModal] = useState(false);
  const [failModal, setFailModal] = useState(false);
  const [duplicateModal, setDuplicateModal] = useState(false);
  const [buttonText, setButtonText] = useState("Submit");
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    if (universityNames?.length === 0) {
      dispatch(UniversityAction.getAllUniversities());
    }
  }, []);

  useEffect(() => {
    let fileReader,
      isCancel = false;
    if (file) {
      fileReader = new FileReader();
      fileReader.onload = (e) => {
        const { result } = e.target;
        if (result && !isCancel) {
          setFileDataURL(result);
        }
      };
      fileReader.readAsDataURL(file);
    }
    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    };
  }, [file]);

  const defaultStates = () => {
    setIsDisabled(false);
    setButtonText("Submit");
  };

  const AddApplicationAPICall = () => {
    setButtonText("Submitting...");
    setIsDisabled(true);

    if (!university || !date || !program || !fileList[0]) {
      setError("Please fill all fields");
      defaultStates();
    } else {
      setError("");
      let data = new FormData();

      data.append("instituteName", university);
      data.append("dateOfApplication", date);
      data.append("proofOfApplication", "lorem ipsum");
      data.append("studentId", studentId);
      data.append("studentType", studentType);
      data.append("preferredProgram", program);
      data.append("status", "pending");
      data.append("file", fileList[0]);

      dispatch(
        ApplicationAction.addApplications(data, (res) => {
          if (res.status === 409) {
            setDuplicateModal(true);
          } else if (res.status === 200) {
            setSuccessModal(true);
          } else {
            setFailModal(true);
          }
          defaultStates();
        })
      );
    }
  };

  const props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      return false;
    },
    fileList,
  };

  return (
    <>
      <Text.Heading
        fontSize={"18px"}
        fontWeight={"600"}
        color={Colors.Primary}
        text="Add Application"
      />
      <Layout.Spacer height={10} />
      <Text.Heading
        fontSize={"14px"}
        fontWeight={"500"}
        color={Colors.Black}
        text="If you have applied to any university, kindly provide the application details below"
      />
      <Layout.Spacer height={30} />
      <Text.Heading
        fontSize={"14px"}
        fontWeight={"500"}
        color={Colors.Black}
        text="University Name"
      />
      <Layout.Spacer height={10} />
      {universityLoading?.isLoading ? (
        <Loader.Circular />
      ) : (
        <div className="university-form-1">
          <Select
            style={styles.universityDropdown}
            onChange={(universityJSON) => {
              setUniversity(universityJSON);
            }}
            showSearch
            placeholder="Select a University"
            optionFilterProp="children"
          >
            {universityNames.map((dropdown) => {
              return (
                <Select.Option
                  style={styles.universityDropdownSelect}
                  value={dropdown._id}
                  key={dropdown._id}
                >
                  {dropdown.name}
                </Select.Option>
              );
            })}
          </Select>
        </div>
      )}
      <Layout.Spacer height={20} />
      <Text.Heading
        fontSize={"14px"}
        fontWeight={"500"}
        color={Colors.Black}
        text="Date of application"
      />

      <Layout.Spacer height={10} />

      <DatePicker
        style={styles.applicationDatepicker}
        onChange={(date, dateString) => {
          setDate(dateString);
        }}
      />

      <Layout.Spacer height={20} />
      <Text.Heading
        fontSize={"14px"}
        fontWeight={"500"}
        color={Colors.Black}
        text="Preferred Program Type"
      />
      <Layout.Spacer height={10} />

      <div className="university-form-1">
        <Select
          style={styles.universityDropdown}
          onChange={(program) => {
            setProgram(program);
          }}
          placeholder="Select a Program"
          optionFilterProp="children"
          showSearch
        >
          <Select.Option value="Business">Business</Select.Option>
          <Select.Option value="Law">Law</Select.Option>
          <Select.Option value="Social Sciences">Social Sciences</Select.Option>
          <Select.Option value="Sciences">Sciences</Select.Option>
          <Select.Option value="Medical">Medical</Select.Option>
        </Select>
      </div>

      <Layout.Spacer height={20} />
      <Text.Heading
        fontSize={"14px"}
        fontWeight={"500"}
        color={Colors.Black}
        text="Proof of Application"
      />
      <Layout.Spacer height={10} />
      <div style={styles.applicationProofContainer} className="applicationFile">
        <Upload {...props}>
          <div style={styles.applicationProof}>
            <Layout.Spacer height={30} />
            <div style={{ textAlign: "center" }}>
              <BsUpload style={styles.applicationProofIcon} />
            </div>
            <Text.Heading
              fontSize={"15px"}
              fontWeight={"200"}
              color={Colors.Black}
              text="Upload Document"
            />
            <Layout.Spacer height={30} />
          </div>
        </Upload>
      </div>
      <Layout.Spacer height={50} />
      {error !== "" && <div style={styles.errortext}>{error}</div>}
      <div style={styles.flexRowCenter}>
        <Button.Primary
          onClick={AddApplicationAPICall}
          style={styles.addApplicationButton}
          disabled={isDisabled}
        >
          {buttonText}
        </Button.Primary>
      </div>

      {successModal && (
        <Modal.Basic
          isModalVisible={successModal}
          centered={true}
          handleCancel={() => setSuccessModal(false)}
          closable={false}
        >
          <div style={styles.flexColumnCenter}>
            <Lottie
              loop
              animationData={Done}
              play
              style={{ width: "68px", height: "68px" }}
            ></Lottie>

            <div style={styles.successText}>Successful!</div>
            <div style={styles.modalDetails}>
              Your Data Has Been Submitted Successfully
            </div>
            <Button.Primary
              style={styles.modalButton}
              onClick={() => {
                setSuccessModal(false);
                navigate("/applications");
              }}
            >
              Done
            </Button.Primary>
          </div>
        </Modal.Basic>
      )}
      {failModal && (
        <Modal.Basic
          isModalVisible={failModal}
          centered={true}
          handleCancel={() => setFailModal(false)}
          closable={false}
        >
          <div style={styles.flexColumnCenter}>
            <AiFillCloseCircle style={styles.failIcon} />

            <div style={styles.failText}>Unsuccessful!</div>
            <div style={styles.modalDetails}>
              We have encountered an error. Please check if you have filled all
              the required fields.
            </div>

            <Button.Primary
              style={styles.modalButton}
              onClick={() => {
                setFailModal(false);
              }}
            >
              Done
            </Button.Primary>
          </div>
        </Modal.Basic>
      )}
      {duplicateModal && (
        <Modal.Basic
          isModalVisible={duplicateModal}
          centered={true}
          handleCancel={() => setDuplicateModal(false)}
          closable={false}
        >
          <div style={styles.flexColumnCenter}>
            <AiFillCloseCircle style={styles.failIcon} />

            <div style={styles.failText}>Unsuccessful!</div>
            <div style={styles.modalDetails}>
              You have already applied to this university.
            </div>

            <Button.Primary
              style={styles.modalButton}
              onClick={() => {
                setDuplicateModal(false);
              }}
            >
              Done
            </Button.Primary>
          </div>
        </Modal.Basic>
      )}
    </>
  );
};

export default AddApplication;
