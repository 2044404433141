import { useState } from "react";
import { useNavigate, useLocation } from "react-router";
import { useDispatch } from "react-redux";

import ReactCodeInput from "react-code-input";

import { AuthAction } from "../../store/actions";

import { Button, Layout, Text } from "../../components";

import styles from "./styles";

const VerifyOTP = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [otpCode, setOtpCode] = useState("");
  const [otpCodeErrMsg, setOtpCodeErrMsg] = useState("");
  // const [counter, setCounter] = useState(60);

  const handleVerifyCode = () => {
    if (otpCode.length === 0) {
      setOtpCodeErrMsg("Please enter code");
    } else if (otpCode.length < 4 && otpCode.length > 0) {
      setOtpCodeErrMsg("Incomplete Code");
    } else {
      setOtpCodeErrMsg("");
      const body = {
        OTPCode: otpCode,
        email: location.state.email,
      };
      dispatch(
        AuthAction.VerifyOtp(body, () => {
          navigate("/reset-password", {
            state: {
              email: location.state.email,
            },
          });
        })
      );
    }
  };

  const handleChange = (val) => {
    setOtpCode(val);
  };

  // const handleResendOtp = () => {
  //   const body = {
  //     email: location.state.email,
  //   };
  //   dispatch(
  //     AuthAction.ForgotPassword(body, () => {
  //       setCounter(60);
  //     })
  //   );
  // };

  // useEffect(() => {
  //   const timer =
  //     counter > 0 &&
  //     setInterval(() => {
  //       setCounter(counter - 1);
  //     }, 1000);
  //   return () => clearInterval(timer);
  // }, [counter]);

  return (
    <Layout.LoginLayout backLink={"/forgot-password"}>
      <Layout.HeaderSection
        title="Verify OTP"
        subtitle="Please enter the 4-digit OTP"
      />
      <Layout.Spacer height={50} />
      <div style={styles.otpFormContainer}>
        <div style={styles.otpCodeContainer}>
          <ReactCodeInput
            type="number"
            fields={4}
            onChange={handleChange}
            value={otpCode}
            inputStyle={styles.codeInput}
            onClick={(e) => setOtpCode(e.target.value)}
          />
          <Layout.Spacer height={20} />
          {otpCodeErrMsg && (
            <Text.ErrorText style={styles.otpErrorText} text={otpCodeErrMsg} />
          )}
        </div>
        <Layout.Spacer height={10} />

        <Button.Primary style={styles.buttonStyle} onClick={handleVerifyCode}>
          Continue
        </Button.Primary>

        <Layout.Spacer height={20} />
        {/* <div>
          {counter > 0 ? (
            <div style={styles.resendContainer}>
              <span style={styles.resendLabel}>
                Resend OTP in{" "}
                <span style={styles.counterText}>
                  0:{counter < 10 ? `0${counter}` : counter}
                </span>
              </span>
            </div>
          ) : (
            <div style={styles.resendContainer}>
              <span style={styles.resendLabel}>Haven't received OTP?</span>
              <span style={styles.resendText} onClick={handleResendOtp}>
                Resend
              </span>
            </div>
          )}
        </div>
        <Layout.Spacer height={20} /> */}
      </div>
    </Layout.LoginLayout>
  );
};

export default VerifyOTP;
