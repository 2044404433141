import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { Select, DatePicker, message } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import timerbg from "../../assets/images/timerBg.png";

import { Swiper, SwiperSlide } from "swiper/react";

import {
  AnnouncementAction,
  MeetingAction,
  WatchlistAction,
  ApplicationAction,
  ProfileAction,
  StudentAction,
  UniversityAction,
  DeadlineAction,
  InteractiveLearningAction,
  IndividualStudentAction,
} from "../../store/actions";

import {
  Layout,
  Loader,
  Text,
  TextField,
  CustomCalendar,
  dateFormatter,
  Modal,
  Button,
} from "../../components";
import { Colors, Images, MockData } from "../../config";

import styled from "styled-components";
import Clock from "../../components/Layout/Clock";
import styles from "./styles";

import "react-phone-input-2/lib/style.css";
import "swiper/css";

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const watchlist = useSelector((state) => state.watchlist);
  const meetings = useSelector((state) => state.meeting);
  const allMeetings = useSelector(
    (state) => state.meeting?.meetingsAll?.meetingDataAll
  );
  const meetingBasedOnDate = useSelector(
    (state) => state.meeting?.meetingBasedOnDate
  );
  const announcements = useSelector((state) => state.announcement);
  const student = useSelector((state) => state.student);
  const studentId = useSelector((state) => state.auth.user.user?.studentId);
  const studentType = useSelector(
    (state) => state.auth?.user?.user?.studentType
  );
  const studentDetail = useSelector((state) => state.auth.user.user);
  const universityLoading = useSelector((state) => state.university);
  const universityState = useSelector(
    (state) => state.university.allUniversities
  );
  const schoolIdState = useSelector((state) => state.auth.user.user.schoolId);
  const studentIdState = useSelector((state) => state.auth.user.user.studentId);
  const profile = useSelector((state) => state.profile);
  const deadlines = useSelector((state) => state?.deadlines?.deadlines);
  const udemyLoading = useSelector(
    (state) => state?.interactiveLearning?.isUdemyLoading
  );
  const udemy = useSelector((state) => state?.interactiveLearning?.udemy);

  const [showRange, setShowRange] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [state, setState] = useState({
    nationality: "",
    location: "",
    grade: "",
    qualification: "",
    schoolName: "",
    error: "",
  });
  const [showTimer, setShowTimer] = useState(false);

  useEffect(() => {
    if (
      studentDetail?.studentType === "individualStudent" &&
      studentDetail?.subscriptionPlan === "Free" &&
      studentDetail?.trial === true &&
      new Date(studentDetail?.validUntil) > new Date()
    ) {
      setShowTimer(true);
    }
  }, [studentDetail]);

  useEffect(() => {
    if (universityState?.length === 0) {
      dispatch(UniversityAction.getAllUniversities());
    }
  }, []);

  useEffect(() => {
    dispatch(ProfileAction.getUserProfile());
  }, []);

  useEffect(() => {
    dispatch(StudentAction.getStudentDetails(studentId));
  }, []);

  useEffect(() => {
    const currentDate = new Date();
    const validUntilDate = new Date(studentDetail.validUntil);

    if (
      validUntilDate < currentDate &&
      studentDetail?.studentType === "individualStudent" &&
      studentDetail?.subscriptionPlan === "Free"
    ) {
      setState({
        ...state,
        location:
          student?.student?.studentDetails?.location === "NA"
            ? ""
            : student?.student?.studentDetails?.location,
        nationality:
          student?.student?.studentDetails?.nationality === "NA"
            ? ""
            : student?.student?.studentDetails?.nationality,
        grade:
          student?.student?.studentDetails?.grade === "NA"
            ? ""
            : student?.student?.studentDetails?.grade,
        schoolName:
          student?.student?.studentDetails?.schoolName === "NA"
            ? ""
            : student?.student?.studentDetails?.schoolName,
      });
      setShowModal(true);
    }
  }, []);

  useEffect(() => {
    if (studentType && studentType !== "individualStudent") {
      const meetingPayload = {
        organizer: "Student",
        studentId: studentId,
        schoolId: schoolIdState,
      };
      dispatch(MeetingAction.getAllMeetingsAndCount(meetingPayload));
      dispatch(MeetingAction.getMeetingsBasedOnDate(meetingPayload));
    }
  }, []);

  useEffect(() => {
    const payload = {
      limit: 10,
      offset: 0,
    };
    dispatch(WatchlistAction.getWatchlist(payload));
  }, []);

  useEffect(() => {
    const payload = {
      studentId,
    };
    dispatch(DeadlineAction.getDeadlines(payload));
  }, []);

  useEffect(() => {
    const payload = {
      limit: 10,
      offset: 0,
      studentId: studentId,
    };
    dispatch(ApplicationAction.getApplicationsStudent(payload));
  }, []);

  useEffect(() => {
    if (studentType && studentType !== "individualStudent") {
      const payload = {
        limit: 10,
        offset: 0,
        schoolId: schoolIdState,
        studentId: studentIdState,
      };
      dispatch(AnnouncementAction.getAnnouncements(payload));
    } else {
      const payload = {
        limit: 10,
        offset: 0,
        studentId: studentIdState,
      };
      dispatch(AnnouncementAction.getIndividualAnnouncements(payload));
    }
  }, []);

  useEffect(() => {
    if (studentType && studentType === "individualStudent") {
      const payload = {
        pageNo: 1,
      };
      dispatch(InteractiveLearningAction.getUdemyCourses(payload));
    }
  }, []);

  const handleFilterUni = (input, option) => {
    return option.children.toLowerCase().startsWith(input.toLowerCase());
  };

  const handleMeetings = () => {
    const meetingPayload = {
      organizer: "Student",
      fromDate: fromDate,
      toDate: toDate,
      studentId: studentId,
      schoolId: schoolIdState,
    };
    dispatch(MeetingAction.getMeetingsBasedOnDate(meetingPayload));
    dispatch(MeetingAction.getAllMeetingsAndCount(meetingPayload));
  };

  const handleModalDetails = () => {
    if (
      !state.nationality ||
      state.nationality === "NA" ||
      !state.location ||
      state.location === "NA" ||
      !state.grade ||
      state.grade === "NA" ||
      !state.qualification ||
      state.qualification === "NA" ||
      !state.schoolName ||
      state.schoolName === "NA"
    ) {
      setState({ ...state, error: "please fill all the fields" });
    } else {
      let data = new FormData();
      data.append("studentId", studentId);
      data.append("nationality", state.nationality);
      data.append("location", state.location);
      data.append("grade", state.grade);
      data.append("qualification", state.qualification);
      data.append("schoolName", state.schoolName);
      dispatch(
        IndividualStudentAction.setTrial(data, (res) => {
          if (res.status === 200) {
            dispatch(
              StudentAction.getStudentDetails(studentId, () => {
                navigate("/getPro");
              })
            );
          } else {
            message.error("Something went wrong");
          }
        })
      );
    }
  };

  const analytics = [
    {
      heading: "Number of Universities Applied",
      text: student.student.uniqueApplications
        ? student.student.uniqueApplications
        : "0",
      image: Images.Applied,
    },
    {
      heading: "Number of Universities Shortlisted",
      text: watchlist.count ? watchlist.count : "0",
      image: Images.Shortlist,
    },
  ];

  return (
    <div style={styles.dashboard}>
      <div style={styles.dashboardLeftPart}>
        <div style={styles.nameContainer}>
          <div>
            <Text.Heading
              fontSize={"14px"}
              fontWeight={"300"}
              color={Colors.Primary}
              text="This is your student dashboard!"
            />
          </div>
          <div>
            <Text.Heading
              fontSize={"24px"}
              fontWeight={"900"}
              color={Colors.Primary}
              textTransform="capitalize"
              text={
                profile?.user?.fullName
                  ? `Welcome ${profile?.user?.fullName},`
                  : "Welcome,"
              }
            />
          </div>

          {showTimer && (
            <>
              <Layout.Spacer height={12} />
              <div style={{ ...styles.timer, background: `url(${timerbg})` }}>
                <div style={styles.timerLeftPart}>
                  <div>
                    <Text.Heading
                      fontSize={"22px"}
                      fontWeight={"700"}
                      color={Colors.White}
                      textTransform="capitalize"
                      text="5 DAYS FREE TRIAL"
                    />
                  </div>
                  <div>
                    <Text.Heading
                      fontSize={"22px"}
                      fontWeight={"700"}
                      color={Colors.White}
                      textTransform="capitalize"
                      text="ENDING SOON"
                    />
                  </div>
                </div>
                <div style={styles.timerRightPart}>
                  <Clock />
                </div>
              </div>
            </>
          )}
        </div>
        <Layout.Spacer height={18} />
        {universityLoading?.isLoading ? (
          <Loader.Circular />
        ) : (
          <div className="dashboard-select">
            <Select
              style={styles.universityDropdown}
              onChange={(universityJSON) => {
                const university = JSON.parse(universityJSON);
                navigate(`/universities/${university._id}/general`);
              }}
              showSearch
              placeholder="Search University"
              optionFilterProp="children"
              suffixIcon={<SearchOutlined />}
              filterOption={handleFilterUni}
            >
              {universityState.map((dropdown) => {
                return (
                  <Select.Option
                    value={JSON.stringify(dropdown)}
                    style={{ textTransform: "capitalize" }}
                    key={dropdown._id}
                  >
                    {dropdown.name}
                  </Select.Option>
                );
              })}
            </Select>
          </div>
        )}
        <Layout.Spacer height={10} />

        <div>
          <Swiper
            spaceBetween={0}
            slidesPerView={2.2}
            style={{
              width: "100%",
              margin: "0px",
            }}
          >
            <SwiperSlide>
              <img
                src={Images.HomePhoto1}
                alt=""
                style={styles.promotionalPhoto}
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={Images.HomePhoto1}
                alt=""
                style={styles.promotionalPhoto}
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={Images.HomePhoto1}
                alt=""
                style={styles.promotionalPhoto}
              />
            </SwiperSlide>
          </Swiper>
        </div>
        <Layout.Spacer height={28} />
        <div>
          <Text.Heading
            fontSize={"18px"}
            fontWeight={"600"}
            color={Colors.Primary}
            text="Analytics"
          />
          <Layout.Spacer height={10} />
          <div style={styles.announcementDiv} className="announcement-div">
            {analytics.map((analytic, index) => {
              return (
                <div
                  style={{
                    ...styles.analyticsContainer,
                    marginRight: index === 0 ? "30px" : "0px",
                  }}
                  key={index}
                >
                  <div style={styles.analyticsBox}>
                    <div style={styles.analyticsTextDiv}>
                      <Text.Heading
                        fontSize={"14px"}
                        fontWeight={"600"}
                        color={Colors.Primary}
                        textAlign="left"
                        text={analytic.heading}
                      />
                      {student?.isLoading ? (
                        <Loader.Circular />
                      ) : (
                        <Text.Heading
                          fontSize={"26px"}
                          fontWeight={"800"}
                          color={Colors.BlackText}
                          textAlign="left"
                          text={analytic.text}
                        />
                      )}
                    </div>
                    <div style={styles.analyticsImageDiv}>
                      <img
                        src={analytic.image}
                        height="70px"
                        width="140px"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div
            style={styles.announcementDivHeader}
            className="announcement-div"
          >
            <Text.Heading
              fontSize={"18px"}
              fontWeight={"600"}
              color={Colors.Primary}
              text="Announcements"
            />
            <Text.Heading
              fontSize={"12px"}
              fontWeight={"600"}
              color={Colors.GrayText}
              style={styles.cursor}
              text="See all"
              onClick={() => navigate("/announcements")}
            />
          </div>
          {announcements?.isLoading ? (
            <Loader.Circular />
          ) : (
            <div style={styles.announcementDiv} className="announcement-div">
              {announcements?.announcements?.length === 0 ? (
                <div style={{ color: Colors?.Primary, fontWeight: "600" }}>
                  No Annoucnements
                </div>
              ) : (
                <Swiper
                  spaceBetween={15}
                  slidesPerView={3}
                  style={{
                    width: "100%",
                    margin: "0px",
                  }}
                >
                  {announcements?.announcements?.map((item, index) => (
                    <SwiperSlide>
                      <div
                        style={{
                          ...styles.announcementContainer,
                          background:
                            item.recepient === "all"
                              ? Colors.LightGray
                              : "#DFF5F2",
                        }}
                        key={index}
                      >
                        <Text.Heading
                          fontSize={"14px"}
                          fontWeight={"700"}
                          color={Colors.BlackText}
                          textAlign="left"
                          text={
                            item?.subject.length > 20
                              ? `${item?.subject.slice(0, 20)}...`
                              : item?.subject
                          }
                        />
                        <Layout.Spacer height={5} />
                        <Text.Heading
                          fontSize={"12px"}
                          fontWeight={"400"}
                          color={Colors.GrayText}
                          textAlign="left"
                          text={
                            item?.message.length > 50
                              ? `${item?.message.slice(0, 50)}...`
                              : item?.message
                          }
                        />
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              )}
            </div>
          )}
          <Layout.Spacer height={30} />
        </div>
      </div>

      <div style={styles.dashboardRightPart}>
        <div>
          <Layout.Spacer height={20} />
          <Text.Heading
            fontSize={"18px"}
            fontWeight={"600"}
            color={Colors.Primary}
            text="Calendar"
            textAlign={"left"}
          />
          <Layout.Spacer height={15} />

          <CustomCalendar allMeetings={allMeetings} deadlines={deadlines} />
        </div>

        <Layout.Spacer height={20} />

        {studentType && studentType !== "individualStudent" ? (
          <div style={{ width: "100%" }}>
            <div style={styles.flexBetweenAlignCenter}>
              <Text.Heading
                fontSize={"18px"}
                fontWeight={"600"}
                color={Colors.Primary}
                text="Meetings"
                textAlign={"left"}
              />
              <div
                style={styles.dateRangeButton}
                onClick={() => setShowRange(!showRange)}
              >
                Date Range <img src={Images.DateRange} height={24}></img>
              </div>
            </div>
            {showRange && (
              <div style={styles.meetingDatePickerDiv}>
                <div
                  style={{
                    marginTop: "10px",
                    ...styles.flexRowCenter,
                  }}
                >
                  <div
                    style={{
                      ...styles.meetingDatePickerText,
                      width: "120px",
                    }}
                  >
                    From Date
                  </div>
                  <div>
                    <DatePicker
                      style={styles.meetingDatePicker}
                      onChange={(date, dateString) => {
                        setFromDate(dateString);
                      }}
                    />
                  </div>
                  <div
                    style={{
                      ...styles.meetingDatePickerText,
                      width: "110px",
                    }}
                  >
                    To Date
                  </div>
                  <div>
                    <DatePicker
                      style={styles.meetingDatePicker}
                      onChange={(date, dateString) => {
                        setToDate(dateString);
                      }}
                    />
                  </div>
                </div>
                <Layout.Spacer height={10} />
                <div
                  onClick={handleMeetings}
                  style={styles.meetingSubmitButton}
                >
                  Submit
                </div>
              </div>
            )}
            <Layout.Spacer height={10} />
            {meetings?.isMeetingBasedOnDateLoading ? (
              <Loader.Circular />
            ) : (
              <div>
                {meetingBasedOnDate?.length === 0 ? (
                  <div style={styles.noMeeting}>No Meetings This Month</div>
                ) : (
                  <div style={styles.meetingResultsContainer}>
                    {meetingBasedOnDate?.map((item, index) => (
                      <RowDiv
                        key={index}
                        onClick={() => {
                          navigate("/meetings/meetingdetails", {
                            state: { data: item, sent: "true" },
                          });
                        }}
                      >
                        <span>
                          <Text.Heading
                            fontSize={"14px"}
                            fontWeight={"900"}
                            color={Colors.BlackText}
                            textALign={"center"}
                            text={item.subject}
                            textTransform={"capitalize"}
                          />
                          <Text.Heading
                            fontSize={"11px"}
                            fontWeight={"400"}
                            color={Colors.GrayText}
                            textALign={"left"}
                            text={`with ${item?.counselor?.fullName}`}
                          />
                          <Text.Heading
                            fontSize={"11px"}
                            fontWeight={"600"}
                            color={Colors.Primary}
                            textALign={"left"}
                            text={dateFormatter(item.meetingDate)}
                          />
                        </span>
                        <span>
                          <img
                            src={Images.MeetingLogo}
                            height="48px"
                            width="48px"
                            alt=""
                          ></img>
                        </span>
                      </RowDiv>
                    ))}
                  </div>
                )}
              </div>
            )}
            <Layout.Spacer height={10} />
            <div style={styles.flexRowCenter}>
              <Text.Heading
                fontSize={"14px"}
                fontWeight={"500"}
                color={Colors.GrayText}
                style={styles.cursor}
                textAlign="center"
                text="See all Meetings"
                onClick={() => navigate("/meetings")}
              />
            </div>
          </div>
        ) : (
          <div style={{ width: "100%" }}>
            <Text.Heading
              fontSize={"18px"}
              fontWeight={"600"}
              color={Colors.Primary}
              text="Interactive Learning Courses"
              textAlign={"left"}
            />
            <Layout.Spacer height={10} />
            <div>
              {udemyLoading ? (
                <Loader.Circular />
              ) : (
                <div style={styles.courseDivContainer}>
                  {udemy?.results?.slice(0, 5)?.map((course) => {
                    return (
                      <div
                        style={styles.courseDiv}
                        onClick={() => {}}
                        key={course.title}
                      >
                        <div>
                          <img
                            src={course.image_480x270}
                            style={styles.courseImage}
                          />
                        </div>
                        <div style={styles.coursePrice}>{course.price}</div>

                        <div style={styles.courseTitle}>
                          {course.title.length > 50
                            ? `${course.title.slice(0, 50)}...`
                            : course.title}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <Layout.Spacer height={20} />
      {showModal && (
        <Modal.Basic
          isModalVisible={showModal}
          centered={true}
          handleCancel={() => setShowModal(false)}
          closable={true}
        >
          <div>
            <img src={Images.ProIcon} height={60} />
            <div style={{ fontSize: "26px", fontWeight: "700" }}>
              Unlock Pro Features
            </div>
            <div style={{ fontSize: "16px", fontWeight: "500" }}>
              To continue using <b>UniVision Pro</b>,<br></br>
              please fill in the following information
            </div>
          </div>
          <Layout.Spacer height={20} />
          <div style={styles.modalInputContainer}>
            <div>
              <label style={styles.labelStyle}>Nationality</label>
              <Layout.Spacer height={10} />
              <div className="more-info-modal">
                <Select
                  value={state.nationality || undefined}
                  onChange={(e) => {
                    setState({
                      ...state,
                      nationality: e,
                      error: "",
                    });
                  }}
                  style={{
                    width: "100%",
                    height: "36px",
                  }}
                  placeholder="Select nationality"
                  showSearch
                >
                  {MockData?.Countries?.map((country) => {
                    return (
                      <Select.Option value={country} key={country}>
                        {country}
                      </Select.Option>
                    );
                  })}
                </Select>
              </div>
            </div>
            <div>
              <label style={styles.labelStyle}>Current Location</label>
              <Layout.Spacer height={10} />
              <div className="more-info-modal">
                <Select
                  value={state.location || undefined}
                  onChange={(e) => {
                    setState({
                      ...state,
                      location: e,
                      error: "",
                    });
                  }}
                  style={{
                    width: "100%",
                    height: "36px",
                  }}
                  placeholder="Select current location"
                  showSearch
                >
                  {MockData?.Countries?.map((country) => {
                    return (
                      <Select.Option value={country} key={country}>
                        {country}
                      </Select.Option>
                    );
                  })}
                </Select>
              </div>
            </div>

            <div>
              <label style={styles.labelStyle}>Grade / Class</label>
              <Layout.Spacer height={10} />
              <div className="more-info-modal">
                <Select
                  value={state.grade || undefined}
                  onChange={(e) => {
                    setState({
                      ...state,
                      grade: e,
                    });
                  }}
                  style={{
                    width: "100%",
                    height: "36px",
                  }}
                  placeholder="Select Grade / Class"
                >
                  <Select.Option value="10 (E.G. O-level First Year)">
                    10 (E.G. O-level First Year)
                  </Select.Option>
                  <Select.Option value="11 (E.G. O-level Second Year)">
                    11 (E.G. O-level Second Year)
                  </Select.Option>
                  <Select.Option value="12 (E.G. A-level First Year)">
                    12 (E.G. A-level First Year)
                  </Select.Option>
                  <Select.Option value="13 (E.G. A-level Second Year)">
                    13 (E.G. A-level Second Year)
                  </Select.Option>
                </Select>
              </div>
            </div>
            <div>
              <label style={styles.labelStyle}>Educational Qualification</label>
              <Layout.Spacer height={10} />
              <div className="more-info-modal">
                <Select
                  value={state.qualification || undefined}
                  onChange={(e) => {
                    setState({
                      ...state,
                      qualification: e,
                      error: "",
                    });
                  }}
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select Qualification"
                >
                  <Select.Option value="O-Levels">O-Levels</Select.Option>
                  <Select.Option value="A-Levels">A-Levels</Select.Option>
                  <Select.Option value="IB Diploma">IB Diploma</Select.Option>
                  <Select.Option value="STPM">STPM</Select.Option>
                  <Select.Option value="FSC">FSC</Select.Option>
                </Select>
              </div>
            </div>
            <div style={{ gridColumn: "1 / span 2" }}>
              <label style={styles.labelStyle}>Insititute Name</label>
              <Layout.Spacer height={10} />
              <TextField.CustomInput
                placeholder="Enter institute name"
                value={state.schoolName}
                onChange={(e) =>
                  setState({
                    ...state,
                    schoolName: e.target.value,
                    error: "",
                  })
                }
                style={styles.textfieldStyle}
              />
            </div>
          </div>

          <Layout.Spacer height={20} />
          {state.error && (
            <div style={{ color: "red", marginBottom: "20px" }}>
              {state.error}
            </div>
          )}

          <div style={styles.flexRowCenter}>
            <Button.Primary
              style={styles.modalButton}
              onClick={() => {
                handleModalDetails();
              }}
            >
              DONE
            </Button.Primary>
          </div>
          <Layout.Spacer height={20} />
          <div style={styles.flexRowCenter}>
            <div
              onClick={() => {
                setShowModal(false);
              }}
              style={styles.freeTrialText}
            >
              Continue Using Free Trial Only
            </div>
          </div>
        </Modal.Basic>
      )}
    </div>
  );
};

export default Dashboard;

const RowDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 99%;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 10px;
  cursor: pointer;
  span:first-of-type {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    width: 80%;
  }
  span:last-of-type {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    span:first-of-type {
      width: 27px;
      height: 27px;
    }
  }
`;
