import { put } from "redux-saga/effects";
import { message } from "antd";
import { ApiCaller } from "../../config";
import { WatchlistAction } from "../actions";

export default class WatchlistMiddleware {
  static *getWatchlist({ payload, cb }) {
    try {
      const { limit, offset } = payload;
      const res = yield ApiCaller.Get(
        `/university/fetch-wishlist?limit=${limit}&offset=${offset}`
      );
      if (res.status === 200) {
        yield put(WatchlistAction.getWatchlistSuccess(res.data.data));
      } else {
        yield put(WatchlistAction.getWatchlistSuccess([]));
      }
      if (cb) {
        cb();
      }
    } catch (err) {
      yield put(WatchlistAction.getWatchlistFailure());
    }
  }

  static *addOrRemoveWishlist({ id, cb }) {
    try {
      const res = yield ApiCaller.Post(`/university/add-to-wishlist?id=${id}`);
      if (res.status === 200) {
        yield put(WatchlistAction.addOrRemoveToWishlistSuccess(res.data.data));
        message.success(res.data.message);
      } else {
        yield put(WatchlistAction.addOrRemoveToWishlistFailure());
      }
      if (cb) {
        cb();
      }
    } catch (err) {
      yield put(WatchlistAction.addOrRemoveToWishlistFailure());
    }
  }
}
