import { Cell, Pie, PieChart } from "recharts";
import propTypes from "prop-types";
import React from "react";

class renderPieChart extends React.PureComponent {
  render() {
    var obj, numId;
    if (this.props.data[0]) obj = Object.keys(this.props.data[0]);

    for (let i = 0; i < this.props.data.length; i++) {
      numId = i;
    }

    const COLORS = ["#387F75", "#004953", "#66c6d5"];

    return (
      <PieChart
        width={this.props.width ? this.props.width : 170}
        height={this.props.height ? this.props.height : 170}
      >
        <Pie
          data={this.props.data}
          dataKey={obj[1]}
          nameKey={"name"}
          legendType={this.props.legendType}
          Legend
          labelLine={false}
        >
          {obj && !this.props.loading
            ? this.props.data.map((val, index) => (
                <Cell
                  key={`cell-${obj[7]}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))
            : null}
        </Pie>

        <label position="center" />
      </PieChart>
    );
  }
}

renderPieChart.defaultProps = {
  width: 150,
  height: 150,
  margin: { top: 0, right: 0, left: 0, bottom: 0 },
  isTooltip: false,
  isLegend: false,
  size: null,
  label: false,
  legendType: "circle",
};

renderPieChart.propTypes = {
  data: propTypes.array.isRequired,
};

export default renderPieChart;
