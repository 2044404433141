import { Colors } from "../../config";

const styles = {
  justifyBetweenAlignCenter: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  justifyCenterAlignCenterColumn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  justifyCenterAlignCenterRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  ownProduct: {
    color: Colors.Primary,
    fontWeight: "600",
    marginRight: "20px",
    cursor: "pointer",
  },
  addProductButton: {
    width: "200px",
    height: "25px",
    backgroundColor: Colors.Primary,
    fontFamily: "Montserrat",
    fontWeight: "500",
    fontSize: "15px",
  },
  productTypeLabel: { color: "black", fontWeight: "700", fontSize: "14px" },
  seeAll: { color: "black", fontSize: "14px", cursor: "pointer" },
  productContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
    gridColumnGap: "20px",
    gridRowGap: "20px",
  },
  constainerStyle: {
    height: "100px",
    borderRadius: "10px",
    boxShadow: "0px 4px 8px -1px rgba(0,0,0,0.3)",
    display: "flex",
    cursor: "pointer",
  },
  constainerStyle2: {
    height: "100px",
    width: "100%",
    borderRadius: "10px",
    boxShadow: "0px 4px 8px -1px rgba(0,0,0,0.3)",
    display: "flex",
    marginRight: "20px",
    cursor: "pointer",
  },
  constainerStyle3: {
    height: "120px",
    borderRadius: "10px",
    boxShadow: "0px 4px 8px -1px rgba(0,0,0,0.3)",
    padding: "10px",
  },
  specificTypeProductContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
    gridColumnGap: "20px",
    gridRowGap: "20px",
  },
  textFieldContainer: {
    width: "100%",
    color: Colors.Primary,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
  },
  itemNameStyle: {
    fontSize: "12px",
    fontWeight: "700",
    paddingRight: "5px",
    textTransform: "capitalize",
  },
  itemPriceStyle: { fontSize: "16px", fontWeight: "700" },
  imageContainerStyle: {
    width: "70%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  noDataContainer: {
    paddingTop: "10px",
    paddingBottom: "10px",
    backgroundColor: Colors.LightGray,
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  noData: {
    fontWeight: "800",
    fontSize: "16px",
    color: "black",
  },

  textfieldStyle: {
    borderRadius: "6px",
    border: `1px solid ${Colors.Primary}`,
    padding: "5px",
    width: "300px",
    fontSize: "14px",
    fontWeight: "400",
    color: Colors.BlackText2,
  },

  addProductInputLabel: { fontSize: "14px", color: "black", fontWeight: "500" },
  phoneInput: {
    fontSize: "14px",
    borderRadius: "5px",
    outline: "none",
    height: "30px",
  },
  itemTypeInput: {
    border: `1px solid ${Colors.Primary}`,
    color: Colors.Primary,
    width: "300px",
    outline: "none",
    padding: "7px",
    borderRadius: "6px",
    fontSize: "14px",
  },
  fileInput: {
    width: "300px",
    border: `1px solid ${Colors.Primary}`,
    borderRadius: "6px",
    padding: "6px",
    color: "black",
    fontSize: "14px",
    display: "flex",
    justifyContent: "space-between",
  },
  uploadButton: {
    width: "200px",
    fontSize: "14px",
    height: "20px",
  },

  modalIcon: { width: "68px", height: "68px" },
  successText: {
    fontSize: "24px",
    fontWeight: "700",
    color: Colors.Primary,
    marginBottom: "10px",
  },
  failText: {
    fontSize: "24px",
    fontWeight: "700",
    color: "red",
    marginBottom: "10px",
  },
  modalDetails: {
    fontSize: "16px",
    fontWeight: "400",
    color: "black",
    marginBottom: "10px",
  },
  modalButton: {
    width: "100px",
    height: "30px",
    borderRadius: "30px",
    border: `1px solid ${Colors.Primary}`,
    fontSize: "18px",
    fontWeight: "700",
  },

  specificItemPhoto: {
    height: "250px",
    width: "250px",
    borderRadius: "20px",
  },
  specificItemLabelContainer: {
    display: "flex",
    flexDirection: "column",
    color: Colors.Primary,
    marginRight: "60px",
    marginLeft: "60px",
    fontSize: "20px",
    fontWeight: "600",
  },
  specificItemValueContainer: {
    display: "flex",
    flexDirection: "column",
    color: Colors.Black,
    fontSize: "20px",
    textTransform: "capitalize",
  },
  contactButton: {
    background: Colors.Primary,
    padding: "10px",
    width: "200px",
    height: "35px",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  contactModalLabelContainer: {
    display: "flex",
    flexDirection: "column",
    color: Colors.Primary,
    marginRight: "60px",
    alignItems: "flex-start",
    fontSize: "18px",
    fontWeight: "600",
  },
  contactModalValueContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    color: Colors.Black,
    fontSize: "18px",
  },
  updateDropdown: {
    width: "100%",
    height: "100%",
    border: `1px solid ${Colors.Primary}`,
    borderRadius: "5px",
    outline: "none",
  },
  deleteIconContainer: {
    width: "20%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  deleteIcon: {
    color: Colors.Red,
    cursor: "pointer",
    fontSize: "18px",
  },
};

export default styles;
