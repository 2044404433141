import {
  GET_UDEMY_COURSES,
  GET_UDEMY_COURSES_SUCCESS,
  GET_UDEMY_COURSES_FAILURE,
  GET_KHAN_ACADEMY_COURSES,
  GET_KHAN_ACADEMY_COURSES_SUCCESS,
  GET_KHAN_ACADEMY_COURSES_FAILURE,
} from "../constants";

const initialState = {
  udemy: {},
  khanAcademy: {},
  isUdemyLoading: false,
  isKhanAcademyLoading: false,
  error: null,
};

export default function InteractiveLearningReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_UDEMY_COURSES:
      state = {
        ...state,
        isUdemyLoading: true,
        error: null,
      };
      break;
    case GET_KHAN_ACADEMY_COURSES:
      state = {
        ...state,
        isKhanAcademyLoading: false,
        error: null,
      };
      break;

    case GET_UDEMY_COURSES_SUCCESS:
      state = {
        ...state,
        udemy: action.payload,
        isUdemyLoading: false,
      };
      break;
    case GET_KHAN_ACADEMY_COURSES_SUCCESS:
      state = {
        ...state,
        khanAcademy: action.payload,
        isKhanAcademyLoading: false,
      };
      break;

    case GET_UDEMY_COURSES_FAILURE:
      state = {
        ...state,
        isUdemyLoading: false,
        error: action.payload,
      };

    case GET_KHAN_ACADEMY_COURSES_FAILURE:
      state = {
        ...state,
        isKhanAcademyLoading: false,
        error: action.payload,
      };

    default:
      break;
  }
  return state;
}
