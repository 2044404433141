import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Support from ".";

import { ContactAction } from "../../store/actions";

import { Button, Layout, Text, TextField } from "../../components";
import { Colors } from "../../config";

import styles from "./styles";

function ContactUs() {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.auth.user.user._id);

  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [buttonText, setButtonText] = useState("Submit");
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const defaultStates = () => {
    setButtonText("Submit");
    setButtonDisabled(false);
  };

  const handleSubmit = () => {
    setButtonText("Submitting...");
    setButtonDisabled(true);
    if (!subject || !message) {
      setError("Please fill all the fields.");
      defaultStates();
    } else {
      setError("");
      const payload = {
        subject,
        message,
        userId,
      };

      dispatch(
        ContactAction.addContact(payload, () => {
          defaultStates();
          setSubject("");
          setMessage("");
        })
      );
    }
  };
  return (
    <>
      <Support>
        <Layout.Spacer height={5} />
        <Text.Heading
          fontSize="20px"
          fontWeight="500"
          color={Colors.BlackText2}
          text="Contact Us"
        />
        <Layout.Spacer height={20} />
        <Text.Heading
          fontSize="18px"
          fontWeight="500"
          color={Colors.Primary}
          text="Are you facing any problem?"
        />
        <Layout.Spacer height={5} />
        <Text.Heading
          fontSize="16px"
          fontWeight="400"
          color={Colors.GrayText}
          text="Send us your query or complain and our support team will get back to you soon."
        />
        <Layout.Spacer height={30} />
        <div style={{ ...styles.flexColumn, width: "650px" }}>
          <TextField.CustomInput
            placeholder="What problem are you facing?"
            label="Subject"
            style={styles.textfieldStyle}
            onChange={(e) => setSubject(e.target.value)}
            value={subject}
          />
        </div>
        <Layout.Spacer height={20} />
        <div style={{ ...styles.flexColumn, width: "650px" }}>
          <label style={styles.labelStyle}>Message</label>
          <TextField.CustomTextArea
            rows={6}
            placeholder="Tell us more about your problem"
            style={styles.textArea}
            onChange={(e) => setMessage(e.target.value)}
            value={message}
          />
        </div>
        {error && (
          <>
            <Layout.Spacer height={30} />
            <Text.Heading
              fontSize={"16px"}
              fontWeight={"500"}
              color={Colors.Red}
              text={error}
            />
          </>
        )}
        <Layout.Spacer height={30} />
        <Button.Primary
          style={styles.buttonStyle}
          onClick={handleSubmit}
          disabled={buttonDisabled}
        >
          {buttonText}
        </Button.Primary>
      </Support>
    </>
  );
}

export default ContactUs;
