import React from "react";
import { Route, Routes } from "react-router-dom";

import { PROTECTED_ROUTES } from "./siteMap";

import StudentDashboardLayout from "../../components/Layout/StudentDashboardLayout";

const AppRoutes = () => {
  return (
    <Routes>
      <Route element={<StudentDashboardLayout />}>
        {PROTECTED_ROUTES.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={route.component}
            exact={route.exact}
          />
        ))}
      </Route>
    </Routes>
  );
};

export default AppRoutes;
