import { ProfileDropDown } from "./ProfileDropDown";
import LoginLayout, { HeaderSection } from "./LoginLayout";
import Spacer from "./Spacer";
import StepsSection from "./Steps";
import StudentDashboardLayout from "./StudentDashboardLayout";

export default {
  StudentDashboardLayout,
  ProfileDropDown,
  Spacer,
  LoginLayout,
  HeaderSection,
  StepsSection,
};
