import {
  ADD_PRODUCT,
  ADD_PRODUCT_SUCCESS,
  ADD_PRODUCT_FAILURE,
  GET_PRODUCT,
  GET_PRODUCT_SUCCESS,
  GET_PRODUCT_FAILURE,
  GET_ALL_PRODUCT,
  GET_ALL_PRODUCT_SUCCESS,
  GET_ALL_PRODUCT_FAILURE,
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAILURE,
  DELETE_PRODUCT,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAILURE,
  GET_STUDENT_PRODUCT,
  GET_STUDENT_PRODUCT_SUCCESS,
  GET_STUDENT_PRODUCT_FAILURE,
} from "../constants";

const initialState = {
  marketplace: [],
  product: [],
  studentProducts: [],
  isLoading: true,
  error: null,
};

export default function MarketplaceReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_PRODUCT:
    case GET_PRODUCT:
    case GET_ALL_PRODUCT:
    case UPDATE_PRODUCT:
    case DELETE_PRODUCT:
    case GET_STUDENT_PRODUCT:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;
    case ADD_PRODUCT_SUCCESS:
    case UPDATE_PRODUCT_SUCCESS:
    case DELETE_PRODUCT_SUCCESS:
      state = {
        ...state,
        isLoading: false,
      };
      break;
    case GET_ALL_PRODUCT_SUCCESS:
      state = {
        ...state,
        marketplace: action.payload,
        isLoading: false,
      };
      break;
    case GET_PRODUCT_SUCCESS:
      state = {
        ...state,
        product: action.payload,
        isLoading: false,
      };
      break;
    case GET_STUDENT_PRODUCT_SUCCESS:
      state = {
        ...state,
        studentProducts: action.payload,
        isLoading: false,
      };
      break;
    case ADD_PRODUCT_FAILURE:
    case GET_PRODUCT_FAILURE:
    case GET_ALL_PRODUCT_FAILURE:
    case UPDATE_PRODUCT_FAILURE:
    case DELETE_PRODUCT_FAILURE:
    case GET_STUDENT_PRODUCT_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      break;
    default:
      break;
  }
  return state;
}
