import {
  UPDATE_CONVERTER,
  UPDATE_CONVERTERG_SUCCESS,
  UPDATE_CONVERTER_FAILURE,
} from "../constants";

export default class ConverterAction {
  static updateConverter(payload, cb) {
    return {
      type: UPDATE_CONVERTER,
      payload,
      cb,
    };
  }
  static updateConverterSuccess(payload) {
    return {
      type: UPDATE_CONVERTERG_SUCCESS,
      payload,
    };
  }
  static updateConverterFailure(payload) {
    return {
      type: UPDATE_CONVERTER_FAILURE,
      payload,
    };
  }
}
