import { put } from "redux-saga/effects";

import { ApiCaller } from "../../config";
import { InteractiveLearningAction } from "../actions";

export default class InteractiveLearningMiddleware {
  static *getUdemyCourses({ payload, cb }) {
    try {
      const res = payload.search
        ? yield ApiCaller.Get(
            `/courses/getUdemyCourses?pageNo=${payload.pageNo}&filter=${payload.filter}&search=${payload.search}`
          )
        : yield ApiCaller.Get(
            `/courses/getUdemyCourses?pageNo=${payload.pageNo}&filter=${payload.filter}`
          );

      if (res.status === 201) {
        yield put(
          InteractiveLearningAction.getUdemyCoursesSuccess(res.data.data.data)
        );
      } else {
        yield put(InteractiveLearningAction.getUdemyCoursesFailure());
      }
      if (cb) {
        cb(res.data.data.data);
      }
    } catch (err) {
      yield put(InteractiveLearningAction.getUdemyCoursesFailure());
    }
  }
  static *getKhanAcademyCourses({ payload, cb }) {
    try {
      const res = payload.search
        ? yield ApiCaller.Post(
            `/courses/getKhanAcademyCourses?pageNo=${payload.pageNo}&search=${payload.search}`,
            payload
          )
        : yield ApiCaller.Post(
            `/courses/getKhanAcademyCourses?pageNo=${payload.pageNo}`,
            payload
          );
      if (res.status === 201) {
        yield put(
          InteractiveLearningAction.getKhanAcademyCoursesSuccess(res.data.data)
        );
      } else {
        yield put(InteractiveLearningAction.getKhanAcademyCoursesFailure());
      }
      if (cb) {
        cb(res.data.data);
      }
    } catch (err) {
      yield put(InteractiveLearningAction.getKhanAcademyCoursesFailure());
    }
  }
}
