import { useNavigate } from "react-router";

import { Layout, Text } from "..";
import { Colors, Images } from "../../config";

import styled from "styled-components";
import styles from "./styles";

import useScreenSize from "../UseScreenSize/useScreenSize";

export const HeaderSection = ({ title, subtitle }) => {
  return (
    <TextArea>
      <img
        src={Images.FullLogo}
        alt="Icon"
        style={styles.alignCenter}
        height={50}
      />
      <Layout.Spacer height={20} />
      <Text.Heading
        fontSize={"30px"}
        fontWeight={"500"}
        color={Colors.Primary}
        text={title}
        textAlign="center"
      />
      {subtitle ? (
        <Text.Heading
          fontSize={"16px"}
          fontWeight={"500"}
          color={Colors.BlackText}
          text={subtitle}
          textAlign="center"
        />
      ) : null}
    </TextArea>
  );
};

const LoginLayout = ({ children, backLink, page }) => {
  const navigate = useNavigate();
  const screensize = useScreenSize();

  const pageNo = page || "2nd";

  const handleClick = () => {
    navigate(backLink);
  };
  return (
    <div style={styles.background}>
      <img style={styles.loginBackgroundImage} src={Images.LoginBackground} />
      <div style={styles.itemCenterjustifyCenter}>
        <div style={styles.backgroundOpacity}></div>
        <div
          style={{
            ...styles.card,
            width:
              pageNo === "1st"
                ? "900px"
                : screensize.width <= 600
                ? "350px"
                : "600px",
            padding: screensize.width <= 600 ? "20px" : "30px 90px",
            borderRadius: screensize.width <= 600 ? "50px" : "92px",
          }}
        >
          {backLink && (
            <span
              style={{
                ...styles.backButtonLogin,
                left: screensize.width <= 600 ? 20 : 50,
              }}
            >
              <img
                src={Images.LeftArrowCircle}
                onClick={handleClick}
                alt="arrow"
                height={30}
                width={30}
              />
            </span>
          )}
          <div style={styles.carChildernContainer}>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default LoginLayout;

const TextArea = styled.div`
  font-size: 2.2rem;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
`;
