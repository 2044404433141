import {
  GET_DEADLINES,
  GET_DEADLINES_FAILURE,
  GET_DEADLINES_SUCCESS,
} from "../constants";

export default class DeadlineAction {
  static getDeadlines(payload, cb) {
    return {
      type: GET_DEADLINES,
      payload,
      cb,
    };
  }
  static getDeadlinesSuccess(payload) {
    return {
      type: GET_DEADLINES_SUCCESS,
      payload,
    };
  }
  static getDeadlinesFailure(payload) {
    return {
      type: GET_DEADLINES_FAILURE,
      payload,
    };
  }
}
