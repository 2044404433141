import {
  ADD_CONTACT,
  ADD_CONTACT_FAILURE,
  ADD_CONTACT_SUCCESS,
} from '../constants';

export default class ContactAction {
  static addContact(payload, cb) {
    return {
      type: ADD_CONTACT,
      payload,
      cb,
    };
  }
  static addContactSuccess(payload) {
    return {
      type: ADD_CONTACT_SUCCESS,
      payload,
    };
  }
  static addContactFailure(payload) {
    return {
      type: ADD_CONTACT_FAILURE,
      payload,
    };
  }
}
