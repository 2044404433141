import { Colors } from "../../config";

const styles = {
  flexColumn: {
    display: "flex",
    flexDirection: "column",
  },
  justifyEnd: { display: "flex", justifyContent: "flex-end" },
  justifyBetweenItemsCenter: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  mr5: {
    marginRight: "5px",
  },
  recommednerTitle: {
    fontSize: "16px",
    fontWeight: "500",
    color: Colors.Primary,
  },
  recommednerSubTitle: {
    fontSize: "14px",
    fontWeight: "400",
    color: Colors.Black,
  },
  underVideoTitle: {
    fontSize: "12px",
    fontWeight: "500",
    color: Colors.Black,
  },
  videoContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  startButton: {
    backgroundColor: Colors.Primary,
    height: "35px",
    width: "150px",
    fontSize: "14px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "5px",
    cursor: "pointer",
  },
  grayCircle: {
    backgroundColor: "#cfcfcf",
    height: "20px",
    width: "20px",
    borderRadius: "100%",
  },
  grayCircleHeader: {
    color: "black",
    fontSize: "12px",
    fontWeight: "500",
    marginBottom: "5px",
  },
  activeCircle: {
    backgroundColor: Colors.Primary,
    height: "20px",
    width: "20px",
    borderRadius: "100%",
  },
  activeCircleHeader: {
    color: "black",
    fontSize: "12px",
    fontWeight: "600",
    marginBottom: "5px",
  },
  veticalLine: { width: "100%", color: Colors.GrayText, paddingTop: "20px" },
  headerAndCircleContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "40%",
  },

  addGradeButtonContainer: {
    border: `1px solid ${Colors.Primary}`,
    borderRadius: "6px",
  },
  scoresInput: {
    border: `1px solid ${Colors.Primary}`,
    borderRadius: "5px",
    padding: "5px",
  },
  gradeSectionContainer: {
    margin: "0px 20px",
    display: "flex",
    justifyContent: "space-between",
  },
  addGradeButton: {
    width: "200px",
    height: "30px",
    borderRadius: "6px",
    fontSize: "14px",
    fontWeight: "600",
    color: Colors.Primary,
    backgroundColor: "#ffffff",
  },
  gradeContainer: {
    display: "grid",
    gridTemplateColumns: "1fr ",
    columnGap: "60px",
    rowGap: "20px",
    margin: "0px 20px",
  },
  gradeInputContainer: {
    width: "66%",
    display: "flex",
    alignItems: "center",
  },
  gradeInputContainer2: {
    display: "flex",
    flexDirection: "column",
    width: "50%",
  },
  gradeInputBox: {
    border: `1px solid ${Colors.Primary}`,
    borderRadius: "5px",
    padding: "5px",
    width: "100%",
  },
  boxHeaders: { color: "black", fontSize: "12px", fontWeight: "500" },
  boxSubtitles: {
    color: Colors.GrayText,
    fontSize: "10px",
    fontWeight: "500",
    opacity: "0.7",
  },
  inputFieldContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    columnGap: "40px",
    rowGap: "20px",
    margin: "20px",
  },
  continueButton: {
    backgroundColor: Colors.Primary,
    height: "35px",
    width: "150px",
    fontSize: "14px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "5px",
    cursor: "pointer",
  },
  errorText: { color: "red", display: "flex", justifyContent: "center" },
  countryInput: {
    border: "none",
    textTransform: "capitalize",
    outline: "none",
    width: "100%",
  },
  countryInputContainer: {
    color: Colors.Primary,
    height: "35px",
    border: `1px solid ${Colors.Primary}`,
    borderRadius: "5px",
    textTransform: "capitalize",
    outline: "none",
    fontSize: "12px",
  },
  resultTableName: {
    fontSize: "12px",
    fontWeight: "600",
    color: "#081C15",
    textTransform: "capitalize",
    textAlign: "left",
  },
  resultTableLocation: {
    fontSize: "10px",
    fontWeight: "400",
    color: Colors.GrayText,
    textTransform: "capitalize",
    textAlign: "left",
  },
  resultTableText: {
    fontSize: "12px",
    fontWeight: "500",
    color: Colors.BlackText,
  },
  resultTableActionContainer: { display: "flex", justifyContent: "center" },
  resutlTableActionDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  headlines: {
    fontSize: "18px",
    fontWeight: "600",
    color: Colors.Primary,
    marginRight: "10px",
  },
  resultLoadingText: {
    fontSize: "14px",
    fontWeight: "400",
    color: Colors.Black,
    marginRight: "10px",
  },
  checkBox: {
    fontSize: "26px",
    cursor: "pointer",
    color: Colors.Primary,
    marginRight: "5px",
  },
  blurrScreen: {
    backgroundColor: "white",
    zIndex: "4",
    filter: "blur(12px)",
  },
  airesultspro: {
    position: "relative",
  },
  premimumContent: {
    position: "absolute",
    top: "40%",
    left: "34%",
    filter: "blur(0px)",
    width: "34%",
    display: "flex",
    flexDirection: "column",
    gap: "14px",
    alignItems: "center",
    justifyContent: "center",
  },
  premimumContentData: {
    fontSize: "28px",
    fontWeight: "600",
    color: Colors.Black,
  },
};

export default styles;
