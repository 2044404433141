import { delay, put } from "redux-saga/effects";
import { message } from "antd";
import { ApiCaller } from "../../config";
import { AuthAction } from "../actions";

export default class AuthMiddleware {
  static *SignIn({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post("/auth/login", payload);
      if (res.data.status === 404) {
        yield put(AuthAction.SignInFailure());
        message.error(res.data.message);
      } else if (res.data.status === 400) {
        yield put(AuthAction.SignInFailure());
        message.error(res.data.message);
      } else if (res.data.status === 403) {
        yield put(AuthAction.SignInFailure());
        message.error(res.data.message);
      } else if (res.data.status === 401) {
        yield put(AuthAction.SignInFailure());
        message.error(res.data.message);
      } else {
        yield put(AuthAction.SignInSuccess(res.data.data));
        message.success("Login successful");

        yield localStorage.setItem("authUser", JSON.stringify(res.data.data));
        if (cb) {
          cb();
        }
      }
    } catch (err) {
      yield put(AuthAction.SignInFailure());
    }
  }

  static *studentSignup({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post("/auth/add-student", payload);
      if (res.data.status === 400) {
        yield put(AuthAction.studentSignupFailure());
        message.error(res.data.message);
      } else {
        yield put(AuthAction.studentSignupSuccess(res.data.data));
        message.success(res.data.message);
        yield delay(2000);
      }
      if (cb) {
        cb(res.data);
      }
    } catch (error) {
      yield put(AuthAction.studentSignupFailure());
    }
  }

  static *Logout() {
    try {
      yield delay(1000);
      localStorage.removeItem("authUser");
      yield put(AuthAction.LogoutSuccess());
    } catch (err) {
      console.log(err);
      yield put(AuthAction.LogoutFailure());
    }
  }

  static *ForgotPassword({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post("/auth/forget-password", payload);
      if (res.data.status === 200) {
        yield put(AuthAction.ForgotPasswordSuccess(res.data.data));
        message.success(res.data.message);

        cb();
      } else {
        yield put(AuthAction.ForgotPasswordFailure());
        message.error(res.data.message);
      }
    } catch (err) {
      yield put(AuthAction.ForgotPasswordFailure());
    }
  }
  static *CheckEmail({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post("/auth/check-user-email", payload);
      if (res.data.status === 200) {
        yield put(AuthAction.CheckEmailSuccess(res.data.data));
        message.success(res.data.message);
      } else {
        yield put(AuthAction.CheckEmailFailure());
        message.error(res.data.message);
      }
      cb(res.data);
    } catch (err) {
      yield put(AuthAction.CheckEmailFailure());
    }
  }

  static *VerifyOtp({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post("/auth/verify-code", payload);
      if (res.data.status === 200) {
        yield put(AuthAction.VerifyOtpSuccess(res.data.data));
        message.success(res.data.message);
        cb();
      } else {
        yield put(AuthAction.VerifyOtpFailure());
        message.error(res.data.message);
      }
    } catch (err) {
      yield put(AuthAction.VerifyOtpFailure());
    }
  }
  static *VerifySignupOtp({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post("/auth/verify-signup-otp", payload);
      if (res.data.status === 200) {
        yield put(AuthAction.VerifySignupOtpSuccess(res.data.data));
        message.success(res.data.message);
        cb();
      } else {
        yield put(AuthAction.VerifySignupOtpFailure());
        message.error(res.data.message);
      }
    } catch (err) {
      yield put(AuthAction.VerifySignupOtpFailure());
    }
  }

  static *ResetPassword({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post("/auth/reset-password", payload);
      if (res.data.status === 200) {
        yield put(AuthAction.ResetPasswordSuccess(res.data.data));
        message.success(res.data.message);
        cb();
      } else {
        yield put(AuthAction.ResetPasswordFailure());
        message.error(res.data.message);
      }
    } catch (err) {
      yield put(AuthAction.ResetPasswordFailure());
    }
  }
}
