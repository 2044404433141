import { put } from "redux-saga/effects";
import { message } from "antd";
import { ApiCaller } from "../../config";
import { MeetingAction } from "../actions";

export default class MeetingMiddleware {
  static *addMeeting({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post(`/miscellaneous/add-meeting`, payload);
      if (res.status === 200 || res.status === 201) {
        yield put(MeetingAction.addMeetingSuccess(res.data.data));
        message.success(res.data.message);
      } else {
        yield put(MeetingAction.addMeetingFailure());
        message.error(res.data.message);
      }
      if (cb) {
        cb();
      }
    } catch (err) {
      yield put(MeetingAction.addMeetingFailure());
    }
  }

  static *getMeetingInvitesAndRequests({ payload, cb }) {
    const { organizer, studentId, limit, offset } = payload;
    try {
      const res = yield ApiCaller.Get(
        `/miscellaneous/meetingInvitesAndRequests?organizer=${organizer}&studentId=${studentId}&limit=${limit}&offset=${offset}`
      );
      if (res.status === 200) {
        yield put(
          MeetingAction.getMeetingInvitesAndRequestsSuccess(res.data.data)
        );
      } else {
        yield put(MeetingAction.getMeetingInvitesAndRequestsFailure());
      }
      if (cb) {
        cb(res.data.data);
      }
    } catch (err) {
      yield put(MeetingAction.getMeetingInvitesAndRequestsFailure());
    }
  }
  static *getAllMeetingsAndCount({ payload, cb }) {
    const { organizer, studentId } = payload;
    try {
      const res = yield ApiCaller.Get(
        `/miscellaneous/fetchAllMeetingsAndCount?organizer=${organizer}&studentId=${studentId}`
      );
      if (res.status === 200) {
        yield put(MeetingAction.getAllMeetingsAndCountSuccess(res.data.data));
      } else {
        yield put(MeetingAction.getAllMeetingsAndCountFailure());
      }
      if (cb) {
        cb(res.data.data);
      }
    } catch (err) {
      yield put(MeetingAction.getAllMeetingsAndCountFailure());
    }
  }
  static *getMeetingsBasedOnDate({ payload, cb }) {
    const { organizer, studentId, fromDate, toDate } = payload;
    try {
      const res =
        fromDate && toDate
          ? yield ApiCaller.Get(
              `/miscellaneous/fetchMeetingBasedOnDate?organizer=${organizer}&studentId=${studentId}&fromDate=${fromDate}&toDate=${toDate}`
            )
          : yield ApiCaller.Get(
              `/miscellaneous/fetchMeetingBasedOnDate?organizer=${organizer}&studentId=${studentId}`
            );
      if (res.status === 200) {
        yield put(MeetingAction.getMeetingsBasedOnDateSuccess(res.data.data));
      } else {
        yield put(MeetingAction.getMeetingsBasedOnDateFailure());
      }
      if (cb) {
        cb(res.data.data);
      }
    } catch (err) {
      yield put(MeetingAction.getMeetingsBasedOnDateFailure());
    }
  }

  static *changeMeetingStatus({ id, status, cb }) {
    try {
      const res = yield ApiCaller.Get(
        `/miscellaneous/change-meeting-status/${id}?status=${status}`
      );
      if (res.status === 200) {
        yield put(MeetingAction.changeMeetingStatusSuccess(res.data.data));
      } else {
        yield put(MeetingAction.changeMeetingStatusFailure());
      }
      if (cb) {
        cb();
      }
    } catch (err) {
      yield put(MeetingAction.changeMeetingStatusFailure());
    }
  }
}
