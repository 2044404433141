import { put } from "redux-saga/effects";

import { ApiCaller } from "../../config";
import { ETextbookAction } from "../actions";

export default class ETextbookMiddleware {
  static *getETextbooks({ payload, cb }) {
    try {
      const res = yield ApiCaller.Get(
        `/eTextbooks/getETextbooks?pageNo=${payload.pageNo}&search=${payload.search}&filter=${payload.filter}`
      );
      if (res.status === 201) {
        yield put(ETextbookAction.getETextbooksSuccess(res.data.data.data));
      } else {
        yield put(ETextbookAction.getETextbooksFailure());
      }
      if (cb) {
        cb(res.data.data.data);
      }
    } catch (err) {
      yield put(ETextbookAction.getETextbooksFailure());
    }
  }
}
