import {
  GET_ETEXTBOOKS,
  GET_ETEXTBOOKS_SUCCESS,
  GET_ETEXTBOOKS_FAILURE,
} from "../constants";

export default class ETextbookAction {
  static getETextbooks(payload, cb) {
    return {
      type: GET_ETEXTBOOKS,
      payload,
      cb,
    };
  }
  static getETextbooksSuccess(payload) {
    return {
      type: GET_ETEXTBOOKS_SUCCESS,
      payload,
    };
  }
  static getETextbooksFailure(payload) {
    return {
      type: GET_ETEXTBOOKS_FAILURE,
      payload,
    };
  }
}
