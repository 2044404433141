import { Input } from "antd";

import { Colors } from "../../config";

import styled, { css } from "styled-components";
import styles from "./styles";

const InputWrapper = (props) => {
  return props.password ? (
    <>
      {props.label && (
        <label style={styles.customTextLabel}>{props.label}</label>
      )}
      <StyledInput as={Input.Password} {...props}></StyledInput>
    </>
  ) : (
    <>
      {props.label && (
        <label style={styles.customTextLabel}>{props.label}</label>
      )}
      <StyledInput {...props}></StyledInput>
    </>
  );
};

export default InputWrapper;

const StyledInput = styled(Input).attrs({ className: "inputField" })`
  ${(props) =>
    !props.password
      ? css`
&[type], input {
    background-color: ${props.bgColor ? props.bgColor : Colors.White};
    color: ${props.color ? props.color : "gray"};
    border: none;
    border-radius: 0;
}
& .ant-input-disabled{
  color:black;
}
&[type]:hover, input:hover {
  text-decoration:none
  border: none;
 
 border-color: #387F75 !important;
}
&[type]:focus, input:focus {
  border: none;
  border-color: #387F75 !important;
  box-shadow: none;
}
`
      : css`
          & {
            background-color: ${Colors.White};
            border: none;
            border-bottom: 1px solid #387f75;
            border-radius: 6px;
            align-items: center;
          }
          &:before {
            width: 0;
            visibility: hidden;
            content: "\a0";
            line-height: 0;
          }
          input {
            color: grey;
          }
          &:hover {
            border: none;
          }
          &:focus,
          &.ant-input-affix-wrapper-focused {
            border: none;
            box-shadow: none;
          }
          * {
            color: grey;
          }
        `}
`;
