import {
  GET_TRACKER,
  GET_TRACKER_SUCCESS,
  GET_TRACKER_FAILURE,
  GET_ALL_TRACKER,
  GET_ALL_TRACKER_SUCCESS,
  GET_ALL_TRACKER_FAILURE,
} from "../constants";

const initialState = {
  trackers: [],
  trackerCount: 0,
  specificTracker: {},
  isLoading: true,
  error: null,
};

export default function TrackerReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_TRACKER:
    case GET_TRACKER:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;

    case GET_TRACKER_SUCCESS:
      state = {
        ...state,
        specificTracker: action.payload,
        isLoading: false,
      };
      break;
    case GET_ALL_TRACKER_SUCCESS:
      state = {
        ...state,
        trackers: action.payload.getTrackers,
        trackerCount: action.payload.trackerCount,
        isLoading: false,
      };
      break;

    case GET_ALL_TRACKER_FAILURE:
    case GET_TRACKER_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      break;
    default:
      break;
  }
  return state;
}
