import {
  GET_ANNOUNCEMENTS,
  GET_ANNOUNCEMENTS_FAILURE,
  GET_ANNOUNCEMENTS_SUCCESS,
  CHANGE_ANNOUNCEMENT_STATUS,
  CHANGE_ANNOUNCEMENT_STATUS_FAILURE,
  CHANGE_ANNOUNCEMENT_STATUS_SUCCESS,
  GET_INDIVIDUAL_ANNOUNCEMENTS,
  GET_INDIVIDUAL_ANNOUNCEMENTS_SUCCESS,
  GET_INDIVIDUAL_ANNOUNCEMENTS_FAILURE,
  CHANGE_INDIVIDUAL_ANNOUNCEMENT_STATUS,
  CHANGE_INDIVIDUAL_ANNOUNCEMENT_STATUS_SUCCESS,
  CHANGE_INDIVIDUAL_ANNOUNCEMENT_STATUS_FAILURE,
} from "../constants";

export default class AnnouncementAction {
  static getAnnouncements(payload, cb) {
    return {
      type: GET_ANNOUNCEMENTS,
      payload,
      cb,
    };
  }
  static getAnnouncementsSuccess(payload) {
    return {
      type: GET_ANNOUNCEMENTS_SUCCESS,
      payload,
    };
  }
  static getAnnouncementsFailure(payload) {
    return {
      type: GET_ANNOUNCEMENTS_FAILURE,
      payload,
    };
  }
  static changeAnnouncementStatus(id, status, cb) {
    return {
      type: CHANGE_ANNOUNCEMENT_STATUS,
      id,
      status,
      cb,
    };
  }
  static changeAnnouncementStatusSuccess(payload) {
    return {
      type: CHANGE_ANNOUNCEMENT_STATUS_SUCCESS,
      payload,
    };
  }
  static changeAnnouncementStatusFailure(payload) {
    return {
      type: CHANGE_ANNOUNCEMENT_STATUS_FAILURE,
      payload,
    };
  }
  static getIndividualAnnouncements(payload, cb) {
    return {
      type: GET_INDIVIDUAL_ANNOUNCEMENTS,
      payload,
      cb,
    };
  }
  static getIndividualAnnouncementsSuccess(payload) {
    return {
      type: GET_INDIVIDUAL_ANNOUNCEMENTS_SUCCESS,
      payload,
    };
  }
  static getIndividualAnnouncementsFailure(payload) {
    return {
      type: GET_INDIVIDUAL_ANNOUNCEMENTS_FAILURE,
      payload,
    };
  }
  static changeIndividualAnnouncementStatus(id, status, cb) {
    return {
      type: CHANGE_INDIVIDUAL_ANNOUNCEMENT_STATUS,
      id,
      status,
      cb,
    };
  }
  static changeIndividualAnnouncementStatusSuccess(payload) {
    return {
      type: CHANGE_INDIVIDUAL_ANNOUNCEMENT_STATUS_SUCCESS,
      payload,
    };
  }
  static changeIndividualAnnouncementStatusFailure(payload) {
    return {
      type: CHANGE_INDIVIDUAL_ANNOUNCEMENT_STATUS_FAILURE,
      payload,
    };
  }
}
