import React, { useState, useRef } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Input, Select } from "antd";

import { AiFillCloseCircle } from "react-icons/ai";
import { CgAttachment } from "react-icons/cg";

import PhoneInput from "react-phone-input-2";

import Lottie from "react-lottie-player";

import { MarketplaceAction } from "../../store/actions";

import { Button, Layout, Modal, Text, TextField } from "../../components";
import { Colors, MockData } from "../../config";

import styles from "./styles";
import "react-phone-input-2/lib/style.css";

import Done from "../../assets/images/57767-done.json";

function AddProduct() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fileSelect = useRef();

  const studentId = useSelector((state) => state.auth.user.user.studentId);
  const schoolId = useSelector((state) => state.auth.user.user.schoolId);

  const [selectedFile, setSelectedFile] = useState(null);
  const [sellerName, setSellerName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [itemType, setItemType] = useState("book");
  const [itemName, setItemName] = useState("");
  const [price, setPrice] = useState("");
  const [priceCurrency, setPriceCurrency] = useState("USD");

  const [failModal, setFailModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);

  const [uploadText, setUploadText] = useState("Upload");
  const [uploadDisabled, setUploadDisabled] = useState(false);

  const [error, setError] = useState("");

  const setDefault = () => {
    setUploadText("Upload");
    setUploadDisabled(false);
  };

  const handleSubmit = () => {
    if (
      !selectedFile ||
      !sellerName ||
      !itemType ||
      !itemName ||
      !price ||
      !priceCurrency ||
      (!email && !phone)
    ) {
      setError(
        "Please fill all the required fields (Either email or whatsapp must be provided)"
      );
    } else {
      // const payload = {
      //   schoolId,
      //   studentId,
      //   selectedFile,
      //   sellerName,
      //   email,
      //   phone,
      //   itemType,
      //   itemName,
      //   price: `${priceCurrency} ${price}`,
      // };
      // console.log(payload);
      setUploadText("Uploading...");
      setUploadDisabled(true);

      let data = new FormData();

      data.append("schoolId", schoolId);
      data.append("studentId", studentId);
      data.append("image", selectedFile);
      data.append("sellerName", sellerName);
      data.append("email", email);
      data.append("phone", phone);
      data.append("itemType", itemType);
      data.append("itemName", itemName);
      data.append("price", `${priceCurrency} ${price}`);

      dispatch(
        MarketplaceAction.addProduct(data, (res) => {
          if (res.status === 201) {
            setDefault();
            setSuccessModal(true);
          } else {
            setDefault();
            setFailModal(true);
          }
        })
      );
    }
  };

  return (
    <div>
      <div>
        <Text.Heading
          fontSize={"18px"}
          fontWeight={"600"}
          color={Colors.Primary}
          text="Add Product"
        />
      </div>
      <Layout.Spacer height={20} />
      <div>
        <div>
          <div style={styles.addProductInputLabel}>Seller Name*</div>
          <Layout.Spacer height={5} />
          <TextField.CustomInput
            style={styles.textfieldStyle}
            placeholder="Name"
            onChange={(e) => setSellerName(e.target.value)}
            value={sellerName}
          />
        </div>
        <Layout.Spacer height={10} />
        <div>
          <div style={styles.addProductInputLabel}>Email</div>
          <Layout.Spacer height={5} />
          <TextField.CustomInput
            style={styles.textfieldStyle}
            placeholder="example@xyz.com"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
        </div>
        <Layout.Spacer height={10} />
        <div>
          <div style={styles.addProductInputLabel}>Whatsapp</div>
          <Layout.Spacer height={5} />

          <div style={{ width: "300px" }}>
            <PhoneInput
              inputStyle={styles.phoneInput}
              inputProps={{
                name: "phoneNumber",
                required: true,
              }}
              country={"us"}
              name="phoneNumber"
              placeholder="Phone Number"
              excludeCountries={[]}
              value={phone}
              onChange={(phone) => setPhone(phone)}
              containerClass="phone-container"
              inputClass="phone-input"
              countryCodeEditable={false}
            />
          </div>
        </div>
        <Layout.Spacer height={10} />
        <div>
          <div style={styles.addProductInputLabel}>Item Name*</div>
          <Layout.Spacer height={5} />
          <TextField.CustomInput
            style={styles.textfieldStyle}
            placeholder="Item Name"
            onChange={(e) => setItemName(e.target.value)}
            value={itemName}
          />
        </div>
        <Layout.Spacer height={10} />
        <div>
          <div style={styles.addProductInputLabel}>Item Type*</div>
          <Layout.Spacer height={5} />
          <select
            style={styles.itemTypeInput}
            onChange={(e) => setItemType(e.target.value)}
          >
            <option value="book">Book</option>
            <option value="pastpaper">Past Paper</option>
            <option value="pastassignment">Past Assignment</option>
            <option value="stationery">Stationery</option>
          </select>
        </div>
        <Layout.Spacer height={10} />

        <div>
          <div style={styles.addProductInputLabel}>Item Cost*</div>
          <Layout.Spacer height={5} />
          <div className="input-group">
            <Input.Group compact>
              <Select
                defaultValue="USD"
                name="priceCurrency"
                value={priceCurrency}
                onChange={(value) => setPriceCurrency(value)}
                style={{
                  width: "100px",
                }}
                showSearch
              >
                {MockData.Currency.map((item) => (
                  <Select.Option value={item.value}>{item.name}</Select.Option>
                ))}
              </Select>
              <Input
                style={{
                  width: "200px",
                  padding: "5px",
                }}
                placeholder="Amount"
                name="price"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />
            </Input.Group>
          </div>
        </div>
        <Layout.Spacer height={10} />
        <div>
          <div style={styles.addProductInputLabel}>Upload Picture*</div>
          <Layout.Spacer height={5} />
          <div
            style={styles.fileInput}
            onClick={() => {
              document.getElementById("getFile").click();
            }}
          >
            <input
              style={{ display: "none" }}
              type="file"
              name="image"
              id="getFile"
              ref={fileSelect}
              onChange={(event) => {
                setSelectedFile(event.target.files[0]);
              }}
              accept=".jpg,.jpeg,.png"
            />
            <div>{selectedFile ? selectedFile.name : "Select a file"}</div>
            <div>
              <CgAttachment color={Colors.Primary} />
            </div>
          </div>
        </div>
        <Layout.Spacer height={20} />
        <div style={{ color: "red" }}>{error}</div>
        <Layout.Spacer height={20} />
        <div>
          <Button.Primary
            onClick={handleSubmit}
            style={styles.uploadButton}
            disabled={uploadDisabled}
          >
            {uploadText}
          </Button.Primary>
        </div>
      </div>
      {successModal && (
        <Modal.Basic
          isModalVisible={successModal}
          centered={true}
          handleCancel={() => setSuccessModal(false)}
          closable={false}
        >
          <div style={styles.justifyCenterAlignCenterColumn}>
            <div>
              <Lottie
                loop
                animationData={Done}
                play
                style={styles.modalIcon}
              ></Lottie>
            </div>
            <div style={styles.successText}>Successful!</div>
            <div style={styles.modalDetails}>
              Your Product has been Uploaded Successfully.
            </div>
            <div>
              <Button.Primary
                style={styles.modalButton}
                onClick={() => {
                  setSuccessModal(false);
                  navigate("/more/marketplace");
                }}
              >
                Done
              </Button.Primary>
            </div>
          </div>
        </Modal.Basic>
      )}
      {failModal && (
        <Modal.Basic
          isModalVisible={failModal}
          centered={true}
          handleCancel={() => setFailModal(false)}
          closable={false}
        >
          <div style={styles.justifyCenterAlignCenterColumn}>
            <div>
              <AiFillCloseCircle
                style={{
                  ...styles.modalIcon,
                  color: "red",
                  padding: "10px",
                }}
              />
            </div>
            <div style={styles.failText}>Unsuccessful!</div>
            <div style={styles.modalDetails}>
              We have encountered an error. Please check if you have filled all
              the required fields.
            </div>
            <div>
              <Button.Primary
                style={styles.modalButton}
                onClick={() => {
                  setFailModal(false);
                }}
              >
                Okay
              </Button.Primary>
            </div>
          </div>
        </Modal.Basic>
      )}
    </div>
  );
}

export default AddProduct;
