import {
  ADD_CONTACT,
  ADD_CONTACT_FAILURE,
  ADD_CONTACT_SUCCESS,
} from '../constants';

const initialState = {
  contacts: [],
  contact: {},
  isLoading: false,
  error: null,
};

export default function ContactReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_CONTACT:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;
    case ADD_CONTACT_SUCCESS:
      state = {
        ...state,
        isLoading: false,
      };
      break;
    case ADD_CONTACT_FAILURE:
      state = {
        ...state,
        isLoading: false,
      };
      break;
    default:
      break;
  }
  return state;
}
