import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { TrackerAction } from "../../store/actions";

import { Layout, Text, Loader } from "../../components";
import { Colors } from "../../config";

import styles from "./styles";

function TrackerDetails() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const trackerLoading = useSelector((state) => state?.tracker?.isLoading);
  const tracker = useSelector((state) => state?.tracker?.specificTracker);

  useEffect(() => {
    const payload = {
      universityId: location?.state?.university,
    };
    dispatch(TrackerAction.getTracker(payload));
  }, []);

  const title = [
    {
      id: 1,
      title: "University Name",
      value: tracker?.universityId
        ? tracker?.universityId?.name
        : location?.state?.universityName,
    },
    {
      id: 2,
      title: "Deadline",
      value: tracker?.deadline
        ? moment(tracker?.deadline).format("DD MMMM YYYY")
        : "NA",
    },
    { id: 3, title: "Status", value: tracker?.status ? tracker?.status : "NA" },
    {
      id: 4,
      title: "Result Date",
      value: tracker?.resultDate
        ? moment(tracker?.resultDate).format("DD MMMM YYYY")
        : "NA",
    },
  ];
  return (
    <div>
      <div>
        <Text.Heading
          fontSize={"18px"}
          fontWeight={"600"}
          color={Colors.Primary}
          text="Application Status Tracker"
        />
      </div>
      <Layout.Spacer height={20} />
      <div>
        <Text.Heading
          fontSize={"16px"}
          fontWeight={"500"}
          color={Colors.Primary}
          text="Application Details"
        />
      </div>
      <Layout.Spacer height={30} />
      {trackerLoading ? (
        <Loader.Circular />
      ) : (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ marginRight: "50px" }}>
            {title?.map((item) => (
              <div key={item.id}>
                <div>
                  <Text.Heading
                    style={styles.trackerValuesTitle}
                    text={item.title}
                  />
                </div>
                <Layout.Spacer height={20} />
              </div>
            ))}
          </div>
          <div>
            {title?.map((item) => (
              <div key={item.id}>
                <div>
                  <Text.Heading
                    style={styles.trackerValues}
                    text={item.value}
                  />
                </div>
                <Layout.Spacer height={20} />
              </div>
            ))}
          </div>
        </div>
      )}
      <Layout.Spacer height={20} />
      <div
        style={styles.submitButton}
        onClick={() => navigate("/more/statusTracker")}
      >
        Done
      </div>
    </div>
  );
}

export default TrackerDetails;
