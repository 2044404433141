import {
  ALERT_INDIVIDUAL_STUDENT,
  ALERT_INDIVIDUAL_STUDENT_SUCCESS,
  ALERT_INDIVIDUAL_STUDENT_FAILURE,
  CANCEL_SUBSCRIPTION,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CANCEL_SUBSCRIPTION_FAILURE,
  CREATE_CHECKOUT_SESSION,
  CREATE_CHECKOUT_SESSION_SUCCESS,
  CREATE_CHECKOUT_SESSION_FAILURE,
  CREATE_CHECKOUT_SESSION_PRO,
  CREATE_CHECKOUT_SESSION_PRO_SUCCESS,
  CREATE_CHECKOUT_SESSION_PRO_FAILURE,
  CREATE_SUBSCRIPTION,
  CREATE_SUBSCRIPTION_SUCCESS,
  CREATE_SUBSCRIPTION_FAILURE,
  GET_SUBSCRIPTION,
  GET_SUBSCRIPTION_SUCCESS,
  GET_SUBSCRIPTION_FAILURE,
  SET_TRIAL,
  SET_TRIAL_SUCCESS,
  SET_TRIAL_FAILURE,
  SEND_EMAIL_ADMIN,
  SEND_EMAIL_ADMIN_SUCCESS,
  SEND_EMAIL_ADMIN_FAILURE,
  UPDATE_INDIVIDUAL_STUDENT,
  UPDATE_INDIVIDUAL_STUDENT_SUCCESS,
  UPDATE_INDIVIDUAL_STUDENT_FAILURE,
  UPLOAD_PROOF,
  UPLOAD_PROOF_SUCCESS,
  UPLOAD_PROOF_FAILURE,
} from "../constants";

export default class IndividualStudentAction {
  static alertIndividualStudent(payload, cb) {
    return {
      type: ALERT_INDIVIDUAL_STUDENT,
      payload,
      cb,
    };
  }
  static alertIndividualStudentSuccess(payload) {
    return {
      type: ALERT_INDIVIDUAL_STUDENT_SUCCESS,
      payload,
    };
  }
  static alertIndividualStudentFailure(payload) {
    return {
      type: ALERT_INDIVIDUAL_STUDENT_FAILURE,
      payload,
    };
  }
  static cancelSubscription(payload, cb) {
    return {
      type: CANCEL_SUBSCRIPTION,
      payload,
      cb,
    };
  }
  static cancelSubscriptionSuccess(payload) {
    return {
      type: CANCEL_SUBSCRIPTION_SUCCESS,
      payload,
    };
  }
  static cancelSubscriptionFailure(payload) {
    return {
      type: CANCEL_SUBSCRIPTION_FAILURE,
      payload,
    };
  }
  static createCheckout(payload, cb) {
    return {
      type: CREATE_CHECKOUT_SESSION,
      payload,
      cb,
    };
  }
  static createCheckoutSuccess(payload) {
    return {
      type: CREATE_CHECKOUT_SESSION_SUCCESS,
      payload,
    };
  }
  static createCheckoutFailure(payload) {
    return {
      type: CREATE_CHECKOUT_SESSION_FAILURE,
      payload,
    };
  }
  static createCheckoutPro(payload, cb) {
    return {
      type: CREATE_CHECKOUT_SESSION_PRO,
      payload,
      cb,
    };
  }
  static createCheckoutProSuccess(payload) {
    return {
      type: CREATE_CHECKOUT_SESSION_PRO_SUCCESS,
      payload,
    };
  }
  static createCheckoutProFailure(payload) {
    return {
      type: CREATE_CHECKOUT_SESSION_PRO_FAILURE,
      payload,
    };
  }
  static createSubscription(payload, cb) {
    return {
      type: CREATE_SUBSCRIPTION,
      payload,
      cb,
    };
  }
  static createSubscriptionSuccess(payload) {
    return {
      type: CREATE_SUBSCRIPTION_SUCCESS,
      payload,
    };
  }
  static createSubscriptionFailure(payload) {
    return {
      type: CREATE_SUBSCRIPTION_FAILURE,
      payload,
    };
  }
  static getSubscriptionStatus(payload, cb) {
    return {
      type: GET_SUBSCRIPTION,
      payload,
      cb,
    };
  }
  static getSubscriptionStatusSuccess(payload) {
    return {
      type: GET_SUBSCRIPTION_SUCCESS,
      payload,
    };
  }
  static getSubscriptionStatusFailure(payload) {
    return {
      type: GET_SUBSCRIPTION_FAILURE,
      payload,
    };
  }
  static setTrial(payload, cb) {
    return {
      type: SET_TRIAL,
      payload,
      cb,
    };
  }
  static setTrialSuccess(payload) {
    return {
      type: SET_TRIAL_SUCCESS,
      payload,
    };
  }
  static setTrialFailure(payload) {
    return {
      type: SET_TRIAL_FAILURE,
      payload,
    };
  }
  static sendEmailToAdmin(payload, cb) {
    return {
      type: SEND_EMAIL_ADMIN,
      payload,
      cb,
    };
  }
  static sendEmailToAdminSuccess(payload) {
    return {
      type: SEND_EMAIL_ADMIN_SUCCESS,
      payload,
    };
  }
  static sendEmailToAdminFailure(payload) {
    return {
      type: SEND_EMAIL_ADMIN_FAILURE,
      payload,
    };
  }
  static updateIndividualStudent(payload, cb) {
    return {
      type: UPDATE_INDIVIDUAL_STUDENT,
      payload,
      cb,
    };
  }
  static updateIndividualStudentSuccess(payload) {
    return {
      type: UPDATE_INDIVIDUAL_STUDENT_SUCCESS,
      payload,
    };
  }
  static updateIndividualStudentFailure(payload) {
    return {
      type: UPDATE_INDIVIDUAL_STUDENT_FAILURE,
      payload,
    };
  }
  static uploadProof(payload, cb) {
    return {
      type: UPLOAD_PROOF,
      payload,
      cb,
    };
  }
  static uploadProofSuccess(payload) {
    return {
      type: UPLOAD_PROOF_SUCCESS,
      payload,
    };
  }
  static uploadProofFailure(payload) {
    return {
      type: UPLOAD_PROOF_FAILURE,
      payload,
    };
  }
}
