import { Colors } from "../../config";

const styles = {
  // buttons
  buttonStyle: {
    width: "200px",
    height: "40px",
    borderRadius: "6px",
    border: `1px solid ${Colors.Primary}`,
    fontFamily: "Montserrat",
    fontWeight: "500",
    fontSize: "15px",
    color: Colors.WhiteText,
    backgroundColor: Colors.Primary,
  },
  secondaryButtonStyle: {
    width: "200px",
    height: "40px",
    borderRadius: "6px",
    border: `1px solid ${Colors.Primary}`,
    fontSize: "16px",
    fontFamily: "Montserrat",
    fontWeight: "500",
    color: Colors.Primary,
    backgroundColor: Colors.White,
  },

  // flex styles
  flexColumn: {
    display: "flex",
    flexDirection: "column",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  flexRowCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  textfieldStyle: {
    borderRadius: "6px",
    border: `1px solid ${Colors.Primary}`,
    padding: "5px",
    width: "100%",
    fontSize: "14px",
    fontWeight: "400",
    color: Colors.BlackText,
  },
  labelStyle: {
    textAlign: "left",
    color: Colors.BlackText,
    fontWeight: "400",
    fontSize: "16px",
  },

  // settings

  container: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridGap: "50px",
  },
  settingLeftSide: {
    width: "50%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
  },
  settingsLeftSideText: {
    color: Colors.Primary,
    fontWeight: "500",
    fontSize: "16px",
  },
  arrowIcon: { fontSize: "14px", color: Colors.Primary },
};

export default styles;
