import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { AiOutlineCheckCircle } from "react-icons/ai";

import { Colors, Images } from "../../config";
import { Text } from "../../components";

import premiumContent from "../../assets/images/premiunContent.svg";

import styles from "./styles";

function ApplicationJourney({ data, secondaryQs }) {
  const studentDetail = useSelector((state) => state.auth.user.user);

  const [showDetails, setShowDetails] = useState(false);
  const [itemId, setItemId] = useState(null);
  const [blurData, setBlurData] = useState(undefined);

  useEffect(() => {
    const currentDate = new Date();
    const validUntilDate = new Date(studentDetail.validUntil);

    if (
      validUntilDate < currentDate &&
      studentDetail.studentType === "individualStudent" &&
      studentDetail.subscriptionPlan === "Free" &&
      secondaryQs > 10
    ) {
      setBlurData(true);
    }
  }, []);

  const showDetailsHidden = [
    {
      id: 1,
      image: Images.Journey1,
      title: data?.step1?.title ? data?.step1?.title : "NA",
    },
    {
      id: 2,
      image: Images.Journey2,
      title: data?.step2?.title ? data?.step2?.title : "NA",
    },
    {
      id: 3,
      image: Images.Journey3,
      title: data?.step3?.title ? data?.step3?.title : "NA",
    },
    {
      id: 4,
      image: Images.Journey4,
      title: data?.step4?.title ? data?.step4?.title : "NA",
    },
  ];
  const showDetailsNotHidden = [
    {
      id: 1,
      image: Images.Journey1,
      title: data?.step1?.title ? data?.step1?.title : "NA",
      details: data?.step1?.details ? data?.step1?.details : "NA",
    },
    {
      id: 2,
      image: Images.Journey2,
      title: data?.step2?.title ? data?.step2?.title : "NA",
      details: data?.step2?.details ? data?.step2?.details : "NA",
    },
    {
      id: 3,
      image: Images.Journey3,
      title: data?.step3?.title ? data?.step3?.title : "NA",
      details: data?.step3?.details ? data?.step3?.details : "NA",
    },
    {
      id: 4,
      image: Images.Journey4,
      title: data?.step4?.title ? data?.step4?.title : "NA",
      details: data?.step4?.details ? data?.step4?.details : "NA",
    },
  ];
  return (
    <div>
      <p style={styles.uniDetailsHeader}>Application Journey</p>
      <div style={{ ...styles.flexSpaceBetween, gap: "20px" }}>
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <div style={styles.applicationJourneyContainer}>
            {showDetailsHidden.map((item) => (
              <div
                style={styles.applicationJourneyStepsContainer}
                key={item.id}
              >
                <img src={item.image} width={50} alt="steps" />
                <div style={styles.applicationJourneyStepsTitle}>
                  {item.title}
                </div>
                <div
                  style={styles.applicationJourneySeeMore}
                  onClick={() => {
                    setShowDetails(true);
                    setItemId(item.id);
                  }}
                >
                  See More Details
                </div>
              </div>
            ))}
          </div>

          {showDetails && (
            <div style={styles.applicationJourneyContainerWithDetails}>
              <div style={styles.visaProcessingMiddlePart}>
                <div style={blurData && styles.blurrScreen}>
                  <img
                    src={Images.LeftArrowCircle}
                    height={25}
                    width={25}
                    style={{ marginLeft: "30px", cursor: "pointer" }}
                    alt=""
                    onClick={() => {
                      setShowDetails(false);
                    }}
                  />
                  {showDetailsNotHidden?.map((item) => {
                    if (item.id === itemId) {
                      return (
                        <div
                          style={
                            styles.applicationJourneyContainerWithDetailsSteps
                          }
                          key={item.id}
                        >
                          <div
                            style={
                              styles.applicationJourneyContainerWithDetailsStepsLeftSide
                            }
                          >
                            <img src={item.image} width={50} alt="step1" />
                            <div style={styles.applicationJourneyLeftTitle}>
                              {item.title}
                            </div>
                          </div>
                          <div style={styles.visaProcessingMiddlePart}>
                            <div style={styles.applicationJourneyRightTitle}>
                              {item.title}
                            </div>
                            <div style={styles.applicationJourneyRightDetails}>
                              {item.details}
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
          )}
        </div>
        <div style={styles.checklistContainer}>
          <div style={blurData && styles.blurrScreen}>
            <div style={styles.checklistHeader}>Checklist</div>
            <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
              {data?.checklist.length === 0 ? (
                <div style={styles.noChecklist}>
                  <Text.Heading
                    fontSize={"16px"}
                    fontWeight={"500"}
                    color={Colors.BlackText2}
                    textAlign="center"
                    text={"No checklist available"}
                  />
                </div>
              ) : (
                data?.checklist[0].split(",")?.map((item, index) => (
                  <div
                    style={{ display: "flex", marginBottom: "10px" }}
                    key={index}
                  >
                    <div>
                      <AiOutlineCheckCircle
                        color={Colors.Primary}
                        fontSize={"20px"}
                        style={styles.mr10}
                      />
                    </div>
                    <div>
                      <Text.Heading
                        fontSize={"10px"}
                        fontWeight={"500"}
                        color={Colors.BlackText}
                        textAlign="left"
                        text={item}
                      />
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
      {blurData && (
        <div style={styles.premiumModalAppJourney}>
          <img src={premiumContent} />
          <p>
            To view more <b>Subscribe to our Pro Package</b>
          </p>
        </div>
      )}
    </div>
  );
}

export default ApplicationJourney;
