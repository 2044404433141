import { Colors } from "../../config";

const styles = {
  indexButton: {
    color: Colors.White,
    width: "150px",
    marginRight: "20px",
    backgroundColor: Colors.Primary,
    fontFamily: "Montserrat",
    fontWeight: "400",
    fontSize: "12px",
    height: "20px",
  },

  flexColumn: {
    display: "flex",
    flexDirection: "column",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
  },
  flexRowCenter: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  rowStyle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  generalUni: { position: "relative" },
  studentDetail: { position: "relative" },
  flexSpaceBetween: {
    display: "flex",
    justifyContent: "space-between",
    zIndex: "1",
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  flexColumnJustifyCenter: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  flexFullWidth: { width: "100%", display: "flex" },

  UniversityProfileDiv: {
    width: "120px",
    height: "120px",
    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
    backgroundColor: "#fafafa",
    borderRadius: "100%",
    padding: "10px",
    marginLeft: "20px",
  },
  UniversityProfileImg: {
    width: "100px",
    height: "100px",
    backgroundColor: "white",
    fontSize: "10px",
    objectFit: "contain",
    borderRadius: "100%",
  },
  rightBoxIcons: { fontSize: "16px", color: Colors.Primary },
  UniversityCompareProfileImg: {
    width: "60px",
    height: "60px",
    fontSize: "10px",
    objectFit: "contain",
    borderRadius: "100%",
  },
  cardDiv1: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    background: Colors.LightGray,
    borderRadius: "10px",
    width: "100%",
    padding: "10px 10px",
    height: "250px",
    position: "relative",
  },
  cardDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    background: Colors.LightGray,
    borderRadius: "10px",
    width: "200px",
    padding: "20px 20px",
    marginRight: "20px",
  },
  universityDetailsCard: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    background: Colors.LightGray,
    borderRadius: "10px",
    width: "100%",
    padding: "15px 0px 10px 30px",
  },

  cursor: {
    cursor: "pointer",
  },
  mr5: {
    marginRight: "5px",
  },
  mr10: {
    marginRight: "10px",
  },
  mb10: {
    marginBottom: "10px",
  },

  ml20: {
    marginLeft: "20px",
  },
  uniDetailsHeader: {
    background: "#004953",
    color: "white",
    width: "230px",
    textAlign: "center",
    padding: "5px 10px 5px 10px",
    fontSize: "14px",
    fontWeight: "600",
    textTransform: "capitalize",
    borderRadius: "10px",
  },
  uniDetailsText: {
    fontSize: "12px",
    fontWeight: "500",
    marginBottom: "0px",
  },
  uniDetailsComp: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    textAlign: "center",
  },
  uniDetailsIcon: {
    fontSize: "45px",
    textAlign: "center",
    marginBottom: "20px",
    height: "50px",
    width: "50px",
  },
  uniDetailsValue: {
    fontSize: "22px",
    fontWeight: "700",
    color: Colors.Primary,
    textTransform: "capitalize",
  },

  demoAndCoreContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginRight: "40px",
  },

  infoBox: {
    display: "flex",
    justifyContent: "space-around",
    paddingTop: "30px",
    paddingBottom: "10px",
    backgroundColor: Colors.LightGray,
    borderRadius: "10px",
  },

  promoImage: {
    height: "450px",
    width: "auto",
    marginTop: "40px",
    borderRadius: "20px",
  },

  postGrduation: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    paddingTop: "15px",
    paddingBottom: "15px",
    backgroundColor: Colors.LightGray,
    borderRadius: "10px",
  },
  postGraduationChart: {
    display: "flex",
    flexDirection: "column",
    width: "50%",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    marginLeft: "30px",
  },
  postGraduationValuesContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    justifyContent: "center",
    marginLeft: "30px",
  },
  postGraduationValues: {
    fontSize: "16px",
    fontWeight: "700",
    marginRight: "20px",
  },
  postGraduationBox: {
    width: "60px",
    height: "20px",
    marginRight: "20px",
  },
  postGraduationTitle: {
    fontSize: "12px",
    fontWeight: "500",
  },
  requirementsContainer: {
    display: "flex",
    justifyContent: "space-around",
    paddingTop: "30px",
    paddingBottom: "10px",
    backgroundColor: Colors.LightGray,
    borderRadius: "10px",
  },
  requirementsTitle: {
    fontSize: "22px",
    fontWeight: "700",
    color: Colors.Primary,
  },
  noScoreData: {
    paddingTop: "20px",
    paddingBottom: "20px",
    backgroundColor: Colors.LightGray,
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  noScoresDataText: { fontWeight: "800", fontSize: "20px" },
  scoreTitleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#214854",
    borderRadius: "10px",
    height: "40px",
    color: "white",
    fontWeight: "500",
    fontSize: "12px",
  },
  scoresValueContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "10px",
    height: "40px",
    fontWeight: "700",
    fontSize: "10px",
  },

  // student life
  avgMonthlyCost: {
    display: "flex",
    paddingTop: "15px",
    paddingBottom: "15px",
    backgroundColor: Colors.LightGray,
    borderRadius: "10px",
  },
  costsTitleContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    paddingLeft: "20px",
    borderRight: "1px solid #98989880",
  },
  costsTitle: {
    fontSize: "16px",
    fontWeight: "600",
    marginLeft: "20px",
  },
  costValuesContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    paddingLeft: "20px",
  },
  facilitiesAvailable: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    paddingTop: "20px",
    backgroundColor: Colors.LightGray,
    borderRadius: "10px",
    marginRight: "20px",
    width: "70%",
  },
  facilitiesAvailableTextContainer: {
    textAlign: "center",
    marginTop: "10px",
    marginBottom: "20px",
  },
  moreDetailsButton: {
    textDecoration: "none",
    color: "white",
    background: Colors.Primary,
    padding: "10px 40px",
    borderRadius: "6px",
    fontSize: "14px",
  },
  restaurantSection: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "30px",
    paddingRight: "30px",
    backgroundColor: Colors.LightGray,
    borderRadius: "10px",
    width: "100%",
  },
  restaurantContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  halalFood: {
    width: "300px",
    textAlign: "center",
    padding: "5px",
    fontSize: "14px",
    fontWeight: "700",
    textTransform: "capitalize",
    borderRadius: "10px",
  },
  restaurantDetailsContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    width: "100%",
  },
  restaurantImage: {
    height: "70px",
    width: "95px",
    borderRadius: "10px",
    boxShadow: "0px 5px 13px 0px rgba(0,0,0,0.2)",
  },
  restaurantName: {
    fontWeight: "600",
    fontSize: "12px",
    marginBottom: "0px",
    marginTop: "10px",
    height: "40px",
    display: "flex",
    alignItems: "center",
  },
  restaurantDistance: {
    fontWeight: "600",
    fontSize: "10px",
    marginBottom: "0px",
    color: "grey",
  },
  noRestaurantData: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  transportContainer: {
    display: "flex",
    justifyContent: "space-around",
    paddingTop: "30px",
    paddingBottom: "10px",
    backgroundColor: Colors.LightGray,
    borderRadius: "10px",
  },
  studyAbroadContainer: {
    paddingTop: "5px",
    backgroundColor: Colors.LightGray,
    borderRadius: "10px",
  },
  studyAbroadDetails: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  greenTick: {
    color: "#48d460",
    fontSize: "24px",
    marginRight: "10px",
  },

  // visa info
  visaReqConatiner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    paddingTop: "20px",
    paddingBottom: "10px",
    backgroundColor: Colors.LightGray,
    borderRadius: "10px",
    marginRight: "20px",
    width: "100%",
  },
  visaReqValuesContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    paddingLeft: "20px",
  },
  visaReqValue: {
    fontSize: "15px",
    fontWeight: "600",
    marginBottom: "12px",
  },
  visaDurationContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    paddingLeft: "30px",
    paddingRight: "30px",
    backgroundColor: Colors.LightGray,
    borderRadius: "10px",
    width: "400px",
  },
  visaDurationImageContainer: {
    display: "flex",
    width: "50%",
    justifyContent: "center",
    borderRight: "1px solid #0381748b",
    alignItems: "center",
  },
  visaDurationTextContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    textAlign: "center",
  },
  visaDurationText: {
    fontSize: "15px",
    fontWeight: "800",
    marginBottom: "0px",
  },
  visaProcessingContainer: {
    paddingTop: "20px",
    paddingBottom: "20px",
    backgroundColor: Colors.LightGray,
    borderRadius: "10px",
  },
  visaProcessingLeftPart: {
    width: "10%",
    borderRight: `2px solid ${Colors.Primary}`,
  },
  visaProcessingMiddlePart: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  visaProcessingMiddleUpperPart: {
    borderBottom: `2px solid ${Colors.Primary}`,
    display: "flex",
    justifyContent: "space-around ",
  },
  visaProcessingMiddleLowerPart: {
    display: "flex",
    justifyContent: "space-between",
    textAlign: "center",
    height: "45px",
    paddingTop: "15px",
    fontSize: "14px",
    fontWeight: "700",
  },
  visaProcessingValue: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  visaProcessingRightPart: {
    width: "20%",
    borderLeft: `2px solid ${Colors.Primary}`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  visaProcessingBottomDivText: { fontSize: "14px", fontWeight: "700" },

  // finances
  step1Container: {
    width: "100%",
    borderRight: "1px solid grey",
    paddingRight: "30px",
  },
  step2Container: {
    width: "100%",
    paddingLeft: "150px",
  },
  stepTitle: { fontWeight: "700", marginBottom: "10px" },
  stepSubtitle: { fontSize: "12px", marginBottom: "10px" },
  programSelect: {
    color: Colors.Primary,
    width: "100%",
    fontWeight: "700",
    outline: "none",
  },
  campusBox: {
    borderRadius: "5px",
    padding: "6px 10px",
    marginRight: "15px",
    fontSize: "12px",
    cursor: "pointer",
  },

  resultsContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginRight: "30px",
  },
  resultsLeftPartContainer: {
    display: "flex",
    flexDirection: "row",
    paddingTop: "20px",
    paddingBottom: "20px",
    backgroundColor: Colors.LightGray,
    borderRadius: "10px",
  },
  resultsLeftPart: {
    width: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    borderRight: `1px solid ${Colors.Primary}`,
  },
  resultsLeftPartValue: {
    color: Colors.Primary,
    textTransform: "capitalize",
    marginBottom: "0px",
    marginTop: "10px",
    fontSize: "24px",
    fontWeight: "600",
  },
  financesCostsTitleContainer: {
    borderRight: `1px solid ${Colors.Primary}`,
    width: "100%",
    paddingLeft: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingRight: "20px",
  },
  financesCostsTitle: {
    marginLeft: "10px",
    fontSize: "14px",
    fontWeight: "700",
    color: Colors.Primary,
  },
  financesValuesContainer: {
    width: "70%",
    paddingLeft: "40px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  chartPartContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: "30px",
    backgroundColor: Colors.LightGray,
    borderRadius: "10px",
    marginTop: "48px",
  },
  chartValuesPartContainer: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    width: "100%",
  },
  chartValuesPart: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  chartValues: { fontWeight: "700", fontSize: "12px" },
  chartValuesBox: { width: "60px", height: "20px", marginBottom: "5px" },
  chartValuesTitle: { fontWeight: "700", fontSize: "14px" },

  // post graduation
  postGraduation: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    paddingBottom: "30px",
    paddingTop: "30px",
    paddingLeft: "30px",
    paddingRight: "30px",
    backgroundColor: Colors.LightGray,
    borderRadius: "10px",
    width: "100%",
    marginRight: "50px",
  },
  studentPathwayContainer: {
    paddingTop: "20px",
    paddingBottom: "20px",
    backgroundColor: Colors.LightGray,
    borderRadius: "10px",
  },

  studentPathwayUpperPart: {
    display: "flex",
    justifyContent: "center",
    alignItems: "end",
    width: "100%",
  },
  studentPathwayUpperPartText: {
    fontSize: "12px",
    fontWeight: "700",
    marginBottom: "0px",
  },
  studentPathwayMiddleLowerPart: {
    display: "flex",
    justifyContent: "space-around",
    textAlign: "center",
    height: "40px",
    paddingTop: "10px",
    fontSize: "12px",
    fontWeight: "700",
  },

  // application journey
  checklistContainer: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: "10px",
    backgroundColor: Colors.LightGray,
    borderRadius: "10px",
    marginRight: "20px",
    width: "40%",
    height: "max-content",
  },
  checklistHeader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    background: "#004953",
    width: "100%",
    height: "50px",
    borderRadius: "10px",
    fontWeight: "600",
    marginBottom: "20px",
  },

  applicationJourneyContainer: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    flexDirection: "row",
    paddingTop: "30px",
    paddingBottom: "10px",
    marginBottom: "10px",
    backgroundColor: Colors.LightGray,
    borderRadius: "10px",
    marginRight: "20px",
    width: "100%",
    maxHeight: "200px",
  },
  applicationJourneyStepsContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    flexDirection: "column",
    width: "100%",
  },
  applicationJourneyStepsTitle: {
    color: Colors.Primary,
    fontWeight: "500",
    marginTop: "12px",
    fontSize: "12px",
    height: "40px",
    width: "100%",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  applicationJourneySeeMore: {
    color: "blue",
    fontSize: "10px",
    fontWeight: "500",
    cursor: "pointer",
  },
  applicationJourneyContainerWithDetails: {
    display: "flex",
    paddingTop: "10px",
    paddingBottom: "10px",
    backgroundColor: Colors.LightGray,
    borderRadius: "10px",
    marginRight: "20px",
    width: "100%",
    height: "max-content",
  },
  applicationJourneyContainerWithDetailsSteps: {
    display: "flex",
    marginBottom: "20px",
  },
  applicationJourneyContainerWithDetailsStepsLeftSide: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "25%",
    paddingLeft: "20px",
    marginRight: "10px",
    textAlign: "center",
  },
  applicationJourneyLeftTitle: {
    color: Colors.Primary,
    fontWeight: "700",
    marginTop: "10px",
    fontSize: "12px",
    textAlign: "center",
  },
  applicationJourneyRightTitle: {
    color: Colors.Primary,
    fontWeight: "700",
  },
  applicationJourneyRightDetails: {
    color: "black",
    fontWeight: "500",
    marginTop: "20px",
    border: "1px solid black",
    marginRight: "20px",
    padding: "20px",
    borderRadius: "10px",
    backgroundColor: "white",
    fontSize: "12px",
  },

  // summary
  summaryContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    paddingTop: "30px",
    paddingBottom: "30px",
    backgroundColor: Colors.LightGray,
    borderRadius: "10px",
  },
  summaryPoint: {
    display: "grid",
    gridTemplateColumns: "1fr 6fr",
    gridColumnGap: "20px",
    paddingBottom: "10px",
    margin: "0px 20px",
    height: "70px",
  },

  // compare
  compareCardContainer: {
    marginLeft: "10px",
    marginTop: "10px",
    width: "350px",
    minWidth: "250px",
  },
  compareDelete: {
    position: "absolute",
    top: "10px",
    right: "10px",
  },
  compareSelectDivConatiner: {
    marginLeft: "10px",
    marginTop: "10px",
    width: "250px",
  },
  compareSelectDiv: {
    borderColor: Colors.Primary,
    borderStyle: "dashed",
    minHeight: "250px",
    width: "350px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "10px",
    cursor: "pointer",
  },
  compareDropdownSelect: {
    color: Colors.Primary,
    width: "500px",
    borderColor: Colors.Primary,
    borderRadius: "10px",
    textTransform: "capitalize",
  },
  compareDropdownValues: {
    color: Colors.Primary,
    width: "500px",
    textTransform: "capitalize",
  },
  blurrScreen: {
    backgroundColor: "white",
    zIndex: "4",
    filter: "blur(10px)",
    pointerEvents: "none",
  },
  premiumModal: {
    backgroundColor: "white",
    position: "absolute",
    top: "50%",
    left: "25%",
    padding: "28px 12px",
    zIndex: "8",
    display: "flex",
    flexDirection: "column",
    gap: "1.6rem",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "10px",
    width: "46%",
    boxShadow: "0px 3px 6px #00000029",
  },
  premiumModalAppJourney: {
    backgroundColor: "white",
    position: "absolute",
    top: "250px",
    left: "25%",
    padding: "28px 12px",
    zIndex: "8",
    display: "flex",
    flexDirection: "column",
    gap: "1.6rem",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "10px",
    width: "46%",
    boxShadow: "0px 3px 6px #00000029",
  },
};

export default styles;
