import { put } from "redux-saga/effects";

import { TrackerAction } from "../actions";
import { ApiCaller } from "../../config";

export default class TrackerMiddleware {
  static *getTracker({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post(
        `/applicationStatusTracker/getSpecificTrackerData`,
        payload
      );

      if (res.status === 200) {
        yield put(TrackerAction.getTrackersSuccess(res.data.data));
      } else {
        yield put(TrackerAction.getTrackerFailure());
      }
      if (cb) {
        cb(res.data.data);
      }
    } catch (err) {
      yield put(TrackerAction.getTrackerFailure());
    }
  }

  static *getAllTracker({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post(
        `/applicationStatusTracker/getTrackerData`,
        payload
      );

      if (res.status === 200) {
        yield put(TrackerAction.getAllTrackersSuccess(res.data.data));
      } else {
        yield put(TrackerAction.getAllTrackersSuccess([]));
      }
      if (cb) {
        cb(res.data.data);
      }
    } catch (err) {
      yield put(TrackerAction.getAllTrackerFailure());
    }
  }
}
