import { Colors } from "../../config";

const styles = {
  flexRow: {
    display: "flex",
    flexDirection: "row",
  },
  flexRowCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  positionRelative: {
    position: "relative",
  },

  container: {
    width: "100%",
    margin: "0 auto",
  },
  inputFieldBorder: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #DFDFDD",
    borderRadius: "100px",
    padding: "14px",
    width: "100%",
    backgroundColor: "#F7F6F2",
    outline: "none",
  },
  buttonStyle: {
    width: "100%",
    backgroundColor: Colors.Primary,
    borderRadius: "100px",
    fontWeight: "600",
  },
  subscriptionButtonStyle: {
    width: "75%",
    backgroundColor: Colors.Primary,
    borderRadius: "15px",
    margin: "auto",
  },

  labelStyle: { fontSize: "14px", fontWeight: "600", paddingLeft: "10px" },
  botomTitleStyle: {
    fontSize: "16px",
    color: Colors.Primary,
    textAlign: "center",
    fontWeight: "700",
  },

  // email form
  signinContainer: {
    textAlign: "center",
  },
  signinText: {
    color: Colors.LabelText,
    fontSize: "14px",
    fontWeight: "500",
  },
  signinLink: {
    textDecoration: "none",
    color: Colors.Primary,
    fontSize: "14px",
    marginLeft: "5px",
    fontWeight: "700",
  },

  // OTP form
  otpFormContainer: {
    width: "100%",
    margin: "0 auto",
  },
  otpCodeContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  codeInput: {
    border: `2px solid #ccc`,
    borderRadius: "5px",
    fontSize: "38px",
    color: Colors.Primary,
    fontWeight: "500",
    height: "70px",
    width: "70px",
    margin: "0 10px",
    textAlign: "center",
  },
  otpErrorText: {
    fontSize: "16px",
    color: "red",
    marginBottom: "15px",
    display: "flex",
    justifyContent: "center",
  },

  resendContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "auto",
  },
  resendLabel: { color: Colors.LabelText, fontSize: "18px" },
  counterText: {
    marginLeft: "5px",
    fontSize: "18px",
    color: "red",
  },
  resendText: {
    textDecoration: "none",
    color: Colors.Primary,
    fontSize: "18px",
    marginLeft: "5px",
    cursor: "pointer",
  },

  // password form

  passwordIcon: {
    fontSize: "20px",
    cursor: "pointer",
    color: Colors.Primary,
  },

  passwordCheck: { display: "flex", alignItems: "center", gap: "10px" },

  // details form
  detailsFormContainer: {
    width: "90%",
    margin: "0 auto",
    maxHeight: "450px",
    overFlowX: "scroll",
  },
  datePicker: {
    color: Colors.Primary,
    height: "35px",
    width: "100%",
    borderColor: Colors.Primary,
    borderRadius: "5px",
    padding: "10px",
  },
  profileImg: {
    width: "100px",
    height: "100px",
    objectFit: "cover",
    borderRadius: "50px",
    border: `1px solid ${Colors.Primary}`,
    padding: "0px",
  },
  imageUploadDiv: {
    position: "absolute",
    bottom: "0",
    right: "0",
    border: `1px solid ${Colors.Primary}`,
    borderRadius: "50px",
    padding: "2.5px 5px",
    background: "white",
    cursor: "pointer",
  },
  inputDisplayNone: {
    display: "none",
  },

  // subscription plan
  greenIcon: { fontSize: "16px", color: Colors.Success },
  redIcon: { fontSize: "18px", color: Colors.Red },
  subscriptionDivContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridGap: "20px",
    width: "98%",
    margin: "auto",
  },
  subscriptionTitle: { width: "80%", margin: "auto", textAlign: "center" },
  subscriptionDiv: {
    background: Colors.Primary,
    color: "white",
    position: "relative",
    textAlign: "center",
    height: "380px",
    borderRadius: "40px",
    boxShadow: "0px 1px 10px -3px rgba(0,0,0,0.46)",
    marginBottom: "10px",
    overflow: "hidden",
  },
  uniVisionText: { fontSize: "24px", fontWeight: "700", padding: "10px" },
  subscriptionInnerDiv: {
    background: Colors.White,
    color: "black",
    height: "325px",
    borderRadius: "40px",
  },
  innerDivValuesContainer: {
    display: "flex",
    width: "75%",
    margin: "auto",
    alignItems: "center",
  },
  innerDivText: { fontSize: "14px", fontWeight: "500" },
  comingSoon: {
    position: "absolute",
    top: "25px",
    left: "-65px",
    transform: "rotateY(0deg) rotate(-45deg)",
    padding: "10px",
    background: "orange",
    fontSize: "16px",
    fontWeight: "700",
    width: "220px",
    textAlign: "center",
    opacity: "1",
    color: "black",
  },
  comingSoon2: {
    position: "absolute",
    top: "10px",
    left: "-15px",
    transform: "rotateY(0deg) rotate(-35deg)",
    padding: "5px",
    background: "orange",
    fontSize: "8px",
    fontWeight: "600",
    width: "120px",
    textAlign: "center",
    opacity: "1",
    color: "black",
  },
  proDiv: {
    position: "absolute",
    height: "60px",
    width: "60px",
    background: "#4BAE4F",
    color: "white",
    right: "10px",
    top: "10px",
    zIndex: 100,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    borderRadius: "100%",
    fontWeight: "700",
  },
  tagLeftPart: {
    display: "flex",
    flexDirection: "column",
    fontSize: "8px",
    marginRight: "5px",
    borderRight: "1px solid white",
    paddingRight: "5px",
  },
  tagRightPart: {
    display: "flex",
    flexDirection: "column",
    fontSize: "8px",
  },

  // payment select
  paymentMethodIcon: {
    height: "24px",
    paddingLeft: "30px",
  },
  paymentMethodRows: {
    display: "flex",
    justifyContent: "center",
    border: `1px solid ${Colors.Primary}`,
    borderRadius: "15px",
    padding: "10px",
    margin: "10px auto",
    cursor: "pointer",
    overflow: "hidden",
  },

  // modal
  flexColumnCenter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  successText: {
    fontSize: "20px",
    fontWeight: "700",
    color: "black",
    marginBottom: "10px",
  },
  modalDetails: {
    fontSize: "16px",
    fontWeight: "500",
    color: "black",
    marginBottom: "10px",
  },
  modalButton: {
    width: "150px",
    backgroundColor: Colors.Primary,
    fontFamily: "Montserrat",
    fontWeight: "500",
    fontSize: "15px",
    borderRadius: "20px",
  },

  // stripe
  stripeInput: {
    border: `1px solid ${Colors.Primary}`,
    height: "40px",
    padding: "10px",
    borderRadius: "10px",
  },
};

export default styles;
