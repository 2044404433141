import { Colors } from "../../config";

const styles = {
  justifyCenter: {
    display: "flex",
    justifyContent: "center",
  },
  button: {
    width: "250px",
    backgroundColor: Colors.Primary,
    fontFamily: "Montserrat",
    fontWeight: "500",
    fontSize: "15px",
  },
};

export default styles;
