import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { FaCheckCircle } from "react-icons/fa";

import { Button, Layout, Text } from "../../components";
import { Colors, Images } from "../../config";

import styles from "./styles";

const GetPro = () => {
  const navigate = useNavigate();

  const studentDetail = useSelector(
    (state) => state.student?.student?.studentDetails
  );

  const [currency, setCurrency] = useState("USD");
  const [amount, setAmount] = useState("22.99");
  const [monthlyAmount, setMonthlyAmount] = useState("8.99");

  useEffect(() => {
    if (studentDetail.location) {
      if (studentDetail.location.toLowerCase() === "malaysia") {
        setCurrency("MYR");
        setAmount("24.99");
        setMonthlyAmount("9.99");
      } else if (studentDetail.location.toLowerCase() === "pakistan") {
        setCurrency("PKR");
        setAmount("899");
        setMonthlyAmount("299");
      } else if (
        studentDetail.location.toLowerCase() === "uae" ||
        studentDetail.location.toLowerCase() === "united arab emirates"
      ) {
        setCurrency("AED");
        setAmount("24.99");
        setMonthlyAmount("9.99");
      } else {
        setCurrency("USD");
        setAmount("49.99");
        setMonthlyAmount("19.99");
      }
    } else {
      setCurrency("USD");
      setAmount("49.99");
      setMonthlyAmount("19.99");
    }
  }, []);

  const handlePaidSubscription = (paymentType) => {
    const body = {
      studentId: studentDetail._id,
      email: studentDetail.email,
      name: studentDetail.name,
      amount: paymentType === "Monthly" ? monthlyAmount : amount,
      currency,
      subscriptionPlan: "Pro",
      paymentType,
    };
    navigate("/paymentMethodSelect", {
      state: body,
    });
  };

  const rightSide = [
    {
      id: 1,
      icon: <FaCheckCircle style={styles.greenIcon} />,
      text: "Unlimited University Search",
    },
    {
      id: 2,
      icon: <FaCheckCircle style={styles.greenIcon} />,
      text: "Live Counseller Availibility",
    },
    {
      id: 3,
      icon: <FaCheckCircle style={styles.greenIcon} />,
      text: "Compare Different Universities",
    },
    {
      id: 4,
      icon: <FaCheckCircle style={styles.greenIcon} />,
      text: "AI Recommender",
    },
    {
      id: 5,
      icon: <FaCheckCircle style={styles.greenIcon} />,
      text: "Automated Essay Grading",
    },
    {
      id: 6,
      icon: <FaCheckCircle style={styles.greenIcon} />,
      text: "Unlimited Online Courses",
    },
  ];

  return (
    <div>
      <Text.Heading
        fontSize={"18px"}
        fontWeight={"600"}
        color={Colors.Primary}
        text="Select Your Subscription Plan"
      />
      <div style={styles.subscriptionTitle}>
        Select this plan to start using UniVision Pro platform now
      </div>
      <Layout.Spacer height={20} />
      <div style={{ display: "flex", justifyContent: "center", gap: "20px" }}>
        <div
          style={{
            width: "400px",
            position: "relative",
          }}
        >
          <div style={styles.subscriptionTitle2}>
            <b>Monthly Payment</b>
          </div>
          <Layout.Spacer height={10} />
          <div style={styles.proDiv}>
            <img src={Images.ProIcon} height={20} />
            Pro
          </div>
          <div style={styles.subscriptionDiv}>
            <div style={styles.comingSoon}>
              {currency} {monthlyAmount}
            </div>

            <div style={styles.uniVisionText}>UniVision</div>
            <div style={styles.subscriptionInnerDiv}>
              <Layout.Spacer height={20} />
              {rightSide?.map((item) => (
                <div key={item.id}>
                  <div style={styles.innerDivValuesContainer}>
                    <div style={{ margin: "5px" }}>{item.icon}</div>
                    <div style={styles.innerDivText}>{item.text}</div>
                  </div>
                </div>
              ))}
              <div
                style={{ color: "black", fontSize: "10px", fontWeight: "600" }}
              >
                & Access to 25+ More Features
              </div>
              <Layout.Spacer height={5} />
              <Button.Primary
                style={styles.subscriptionButtonStyle}
                onClick={() => {
                  const paymentType = "Monthly";
                  handlePaidSubscription(paymentType);
                }}
              >
                Select
              </Button.Primary>
            </div>
          </div>
        </div>
        <div
          style={{
            width: "400px",
            position: "relative",
          }}
        >
          <div style={styles.subscriptionTitle2}>
            <b>One Time Payment</b>
          </div>
          <Layout.Spacer height={10} />
          <div style={styles.proDiv}>
            <img src={Images.ProIcon} height={20} />
            Pro
          </div>
          <div style={styles.subscriptionDiv}>
            <div style={styles.comingSoon}>
              {currency} {amount}
            </div>

            <div style={styles.uniVisionText}>UniVision</div>
            <div style={styles.subscriptionInnerDiv}>
              <Layout.Spacer height={20} />
              {rightSide?.map((item) => (
                <div key={item.id}>
                  <div style={styles.innerDivValuesContainer}>
                    <div style={{ margin: "5px" }}>{item.icon}</div>
                    <div style={styles.innerDivText}>{item.text}</div>
                  </div>
                </div>
              ))}
              <div
                style={{ color: "black", fontSize: "10px", fontWeight: "600" }}
              >
                & Access to 25+ More Features
              </div>
              <Layout.Spacer height={5} />
              <Button.Primary
                style={styles.subscriptionButtonStyle}
                onClick={() => {
                  const paymentType = "Onetime";
                  handlePaidSubscription(paymentType);
                }}
              >
                Select
              </Button.Primary>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetPro;
