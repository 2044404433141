import {
  ADD_MEETING,
  ADD_MEETING_FAILURE,
  ADD_MEETING_SUCCESS,
  CHANGE_MEETING_STATUS,
  CHANGE_MEETING_STATUS_FAILURE,
  CHANGE_MEETING_STATUS_SUCCESS,
  GET_MEETINGS_INVITES_AND_REQUESTS,
  GET_MEETINGS_INVITES_AND_REQUESTS_SUCCESS,
  GET_MEETINGS_INVITES_AND_REQUESTS_FAILURE,
  GET_ALL_MEETINGS_AND_COUNT,
  GET_ALL_MEETINGS_AND_COUNT_SUCCESS,
  GET_ALL_MEETINGS_AND_COUNT_FAILURE,
  GET_MEETINGS_BASED_ON_DATE,
  GET_MEETINGS_BASED_ON_DATE_SUCCESS,
  GET_MEETINGS_BASED_ON_DATE_FAILURE,
} from "../constants";

export default class MeetingAction {
  static addMeeting(payload, cb) {
    return {
      type: ADD_MEETING,
      payload,
      cb,
    };
  }
  static addMeetingSuccess(payload) {
    return {
      type: ADD_MEETING_SUCCESS,
      payload,
    };
  }
  static addMeetingFailure(payload) {
    return {
      type: ADD_MEETING_FAILURE,
      payload,
    };
  }
  static getMeetingInvitesAndRequests(payload, cb) {
    return {
      type: GET_MEETINGS_INVITES_AND_REQUESTS,
      payload,
      cb,
    };
  }
  static getMeetingInvitesAndRequestsSuccess(payload) {
    return {
      type: GET_MEETINGS_INVITES_AND_REQUESTS_SUCCESS,
      payload,
    };
  }
  static getMeetingInvitesAndRequestsFailure(payload) {
    return {
      type: GET_MEETINGS_INVITES_AND_REQUESTS_FAILURE,
      payload,
    };
  }
  static getAllMeetingsAndCount(payload, cb) {
    return {
      type: GET_ALL_MEETINGS_AND_COUNT,
      payload,
      cb,
    };
  }
  static getAllMeetingsAndCountSuccess(payload) {
    return {
      type: GET_ALL_MEETINGS_AND_COUNT_SUCCESS,
      payload,
    };
  }
  static getAllMeetingsAndCountFailure(payload) {
    return {
      type: GET_ALL_MEETINGS_AND_COUNT_FAILURE,
      payload,
    };
  }
  static getMeetingsBasedOnDate(payload, cb) {
    return {
      type: GET_MEETINGS_BASED_ON_DATE,
      payload,
      cb,
    };
  }
  static getMeetingsBasedOnDateSuccess(payload) {
    return {
      type: GET_MEETINGS_BASED_ON_DATE_SUCCESS,
      payload,
    };
  }
  static getMeetingsBasedOnDateFailure(payload) {
    return {
      type: GET_MEETINGS_BASED_ON_DATE_FAILURE,
      payload,
    };
  }
  static changeMeetingStatus(id, status, cb) {
    return {
      type: CHANGE_MEETING_STATUS,
      id,
      status,
      cb,
    };
  }
  static changeMeetingStatusSuccess(payload) {
    return {
      type: CHANGE_MEETING_STATUS_SUCCESS,
      payload,
    };
  }
  static changeMeetingStatusFailure(payload) {
    return {
      type: CHANGE_MEETING_STATUS_FAILURE,
      payload,
    };
  }
}
