import { Colors } from "../../config";

const styles = {
  userContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr",
    gridColumnGap: "30px",
  },
  userBox: {
    color: Colors.Primary,
    fontSize: "18px",
    fontWeight: "600",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    height: "130px",
    width: "100%",
    background: "#F5F5F5",
    borderRadius: "30px",
    boxShadow: "0px 6px 18px -4px rgba(0,0,0,0.4)",
    paddingTop: "10px",
    cursor: "pointer",
  },
  userInfoContainer: {
    backgroundColor: Colors.Primary,
    height: "10px",
    width: "10px",
    borderRadius: "100%",
  },
};

export default styles;
