import { useNavigate } from "react-router";
import { useSelector } from "react-redux";

import { FaChevronRight } from "react-icons/fa";

import { Layout, Text } from "../../components";
import { Colors } from "../../config";

import styles from "./styles";

import ChangePassword from "./ChangePassword";

function Settings() {
  const navigate = useNavigate();

  const studentDetail = useSelector((state) => state.auth.user.user);

  return (
    <>
      <Text.Heading
        fontSize={"18px"}
        fontWeight={"600"}
        color={Colors.Primary}
        text="Settings"
      />
      <Layout.Spacer height={40} />
      <div style={styles.container}>
        <div
          style={{
            borderRight: `1px solid ${Colors.Primary}`,
          }}
        >
          <div
            style={styles.settingLeftSide}
            onClick={() => navigate("/privacyPolicy")}
          >
            <div style={styles.settingsLeftSideText}>Privacy Policy</div>
            <FaChevronRight style={styles.arrowIcon} />
          </div>
          <Layout.Spacer height={40} />
          <div
            style={styles.settingLeftSide}
            onClick={() => navigate("/termsAndConditions")}
          >
            <div style={styles.settingsLeftSideText}>Terms & Conditions</div>
            <FaChevronRight style={styles.arrowIcon} />
          </div>
          <Layout.Spacer height={40} />
          {studentDetail?.studentType === "individualStudent" &&
            studentDetail?.subscriptionPlan === "Pro" && (
              <div
                style={styles.settingLeftSide}
                onClick={() => navigate("/subscription")}
              >
                <div style={styles.settingsLeftSideText}>
                  Subscription Settings
                </div>
                <FaChevronRight style={styles.arrowIcon} />
              </div>
            )}
        </div>
        <div>
          <ChangePassword />
        </div>
      </div>
    </>
  );
}

export default Settings;
