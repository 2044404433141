import {
  GET_RECOMMENDER,
  GET_RECOMMENDER_FAILURE,
  GET_RECOMMENDER_SUCCESS,
} from "../constants";

export default class RecommenderAction {
  static getRecommender(payload, cb) {
    return {
      type: GET_RECOMMENDER,
      payload,
      cb,
    };
  }
  static getRecommenderSuccess(payload) {
    return {
      type: GET_RECOMMENDER_SUCCESS,
      payload,
    };
  }
  static getRecommenderFailure(payload) {
    return {
      type: GET_RECOMMENDER_FAILURE,
      payload,
    };
  }
}
