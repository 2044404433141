import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { FaRegUserCircle, FaRegUser } from "react-icons/fa";

import { ChatbotAction } from "../../store/actions";

import { Layout, Text } from "../../components";
import { Colors } from "../../config";

import styles from "./styles";

function Chatbot() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const schoolIdState = useSelector((state) => state.auth.user.user.schoolId);

  const handleChatClick = (user) => {
    const payload = {
      schoolId: schoolIdState,
    };
    dispatch(
      ChatbotAction.getUsers(payload, (res) => {
        if (user === "counselor") {
          navigate("/more/chatbot/counselors", {
            state: { counselors: res.counselors },
          });
        }
        if (user === "admin") {
          navigate("/more/chatbot/admins", {
            state: { admins: res.admins, staffAdmins: res.staffAdmins },
          });
        }
      })
    );
  };

  return (
    <div>
      <div>
        <Text.Heading
          fontSize={"18px"}
          fontWeight={"600"}
          color={Colors.Primary}
          text="Chatbot"
        />
      </div>
      <Layout.Spacer height={20} />
      <div style={styles.userContainer}>
        <div
          style={styles.userBox}
          onClick={() => {
            const user = "admin";
            handleChatClick(user);
          }}
        >
          <div>
            <FaRegUser
              fontSize={"30px"}
              cursor={"pointer"}
              color={Colors.Primary}
            />
          </div>
          <Layout.Spacer height={5} />
          <div>Admins</div>
        </div>
        <div
          style={styles.userBox}
          onClick={() => {
            const user = "counselor";
            handleChatClick(user);
          }}
        >
          <div>
            <FaRegUserCircle
              fontSize={"30px"}
              cursor={"pointer"}
              color={Colors.Primary}
            />
          </div>
          <Layout.Spacer height={5} />
          <div>Counselors</div>
        </div>
      </div>
    </div>
  );
}

export default Chatbot;
