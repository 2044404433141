import { Modal } from "antd";
import React from "react";

const BasicModal = ({
  title,
  isModalVisible,
  handleCancel,
  style,
  centered,
  closeIcon,
  closable,
  children,
}) => {
  return (
    <Modal
      className="ant-modal"
      title={title}
      style={style}
      centered={centered}
      visible={isModalVisible}
      closeIcon={closeIcon}
      onCancel={handleCancel}
      footer={null}
      closable={closable}
    >
      {children}
    </Modal>
  );
};

export default BasicModal;
