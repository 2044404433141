import { Colors } from "../../config";

const styles = {
  calendarDiv: {
    background: Colors.LightGray,
    borderRadius: "10px",
    height: "fit-content",
    padding: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },

  calendarPopup: {
    position: "absolute",
    backgroundColor: Colors.Primary,
    padding: "10px 20px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
    fontSize: "12px",
    borderRadius: "15px",
    borderTopRightRadius: "0px",
    zIndex: "100",
    animation: "slide-down 0.3s ease",
  },
  calendarPopupCancel: { textAlign: "right", cursor: "pointer" },
  calendarChip: { maxHeight: "150px", overflowY: "auto" },
  calendarChipHeader: {
    margin: "0px",
    fontWeight: "600",
    fontSize: "14px",
    textTransform: "capitalize",
  },
  calendarChipCounselor: {
    margin: "0px",
    fontWeight: "500",
    textTransform: "capitalize",
  },
  calendarChipDeadline: {
    margin: "0px",
    fontWeight: "500",
  },
  calendarChipSubject: {
    fontSize: "10px",
    fontWeight: "400",
    textTransform: "capitalize",
  },
};

export default styles;
