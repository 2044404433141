import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import moment from "moment";
import Lottie from "react-lottie-player";

import { AiFillCloseCircle } from "react-icons/ai";

import { IndividualStudentAction, AuthAction } from "../../store/actions";

import { Layout, Text, Modal, Button } from "../../components";
import { Colors } from "../../config";

import Done from "../../assets/images/57767-done.json";

import styles from "./styles";

const Subscription = () => {
  const dispatch = useDispatch();

  const studentDetail = useSelector((state) => state.auth.user.user);

  const [buttonText, setButtonText] = useState("Yes");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);

  const handleCancel = () => {
    setButtonDisabled(true);
    setButtonText("Cancelling...");

    const payload = {
      studentId: studentDetail?.studentId,
    };
    dispatch(
      IndividualStudentAction.cancelSubscription(payload, (res) => {
        setModalOpen(false);
        setButtonDisabled(false);
        setButtonText("Yes");

        if (res.status === 200) {
          setModalOpen(false);
          setConfirmModalOpen(true);
        } else {
          message.error("Something went wrong");
        }
      })
    );
  };

  const items = [
    { title: "Plan", value: studentDetail?.paymentType ?? "NA" },
    {
      title: "Start Date",
      value: studentDetail?.startDate
        ? moment(studentDetail?.startDate).format("DD/MM/YYYY")
        : "NA",
    },
    {
      title: "Plan Renewal Date",
      value: studentDetail?.endDate
        ? moment(studentDetail?.endDate).format("DD/MM/YYYY")
        : "NA",
    },
    {
      title: "Amount",
      value:
        studentDetail?.currency &&
        studentDetail?.amount &&
        studentDetail?.currency !== "NA" &&
        studentDetail?.amount !== "NA"
          ? `${studentDetail?.currency} ${studentDetail?.amount}`
          : "NA",
    },
  ];
  return (
    <div>
      <Text.Heading
        fontSize={"18px"}
        fontWeight={"600"}
        color={Colors.Primary}
        text="Pro Member"
      />

      <Layout.Spacer height={40} />
      <div style={{ width: "50%", margin: "auto" }}>
        <div style={styles.valuesContainer}>
          {items?.map((item, index) => (
            <div key={index}>
              <div style={styles.valueTitle}>{item?.title}</div>
              <Layout.Spacer height={10} />
              <div style={styles.valueBox}>{item?.value}</div>
            </div>
          ))}
        </div>

        <Layout.Spacer height={20} />
        <div style={{ display: "flex", justifyContent: "end" }}>
          {studentDetail?.subscriptionPlan === "Pro" &&
            studentDetail?.paymentType === "Monthly" && (
              <Button.Primary
                style={{ backgroundColor: "red" }}
                onClick={() => setModalOpen(true)}
                disabled={buttonDisabled}
              >
                Cancel Subscription
              </Button.Primary>
            )}
        </div>
      </div>
      {isModalOpen && (
        <Modal.Basic
          isModalVisible={isModalOpen}
          centered={true}
          handleCancel={() => setModalOpen(false)}
          closable={false}
        >
          <div style={styles.flexColumnCenter}>
            <AiFillCloseCircle style={styles.redIcon} />

            <Text.Heading
              fontSize={"18px"}
              fontWeight={"400"}
              color={Colors.Black}
              text="Are you sure you want to cancel your subscription? You'll still be able to use the UniVision platform for free but with limited access."
            />
            <Layout.Spacer height={20} />
            <div
              style={{ display: "flex", gap: "20px", justifyContent: "center" }}
            >
              <Button.Primary
                style={styles.modalButton}
                onClick={() => {
                  setModalOpen(false);
                }}
              >
                No
              </Button.Primary>
              <Button.Primary
                style={styles.modalButtonRed}
                onClick={handleCancel}
                disabled={buttonDisabled}
              >
                {buttonText}
              </Button.Primary>
            </div>
          </div>
        </Modal.Basic>
      )}
      {isConfirmModalOpen && (
        <Modal.Basic
          isModalVisible={isConfirmModalOpen}
          centered={true}
          handleCancel={() => {
            setConfirmModalOpen(false);
            dispatch(AuthAction.Logout());
          }}
          closable={false}
        >
          <div style={styles.flexColumnCenter}>
            <Lottie
              loop
              animationData={Done}
              play
              style={{ width: "68px", height: "68px" }}
            ></Lottie>

            <Text.Heading
              fontSize={"18px"}
              fontWeight={"400"}
              color={Colors.Black}
              text={
                <p>
                  You have successfully unsubscribed and starting now you'll be
                  able to use the <b>UniVision</b> platform for free but with
                  limited access. To get access to the premium features, please
                  resubscribe to the <b>UniVision Pro</b> model
                </p>
              }
            />
            <Layout.Spacer height={20} />
            <div
              style={{ display: "flex", gap: "20px", justifyContent: "center" }}
            >
              <Button.Primary
                style={styles.modalButton}
                onClick={() => {
                  setConfirmModalOpen(false);
                  dispatch(AuthAction.Logout());
                }}
              >
                Okay
              </Button.Primary>
            </div>
          </div>
        </Modal.Basic>
      )}
    </div>
  );
};

export default Subscription;
