import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Outlet } from "react-router-dom";
import { Layout, message } from "antd";
const { Header, Sider } = Layout;

import Lottie from "react-lottie-player";

import { CgAttachment } from "react-icons/cg";

import {
  IndividualStudentAction,
  AuthAction,
  StudentAction,
} from "../../store/actions";

import { Button, Modal } from "../../components";
import { Colors } from "../../config";

import StudentNavHeader from "./StudentNavHeader";
import StudentSidebar from "./StudentSidebar";
import ChatbotButton from "../ChatbotButton/ChatbotButton";

import styles from "./styles";
import "./dashboardLayout.css";

import Done from "../../assets/images/57767-done.json";
import Exclamation from "../../assets/images/exclamation.json";

const StudentDashboardLayout = ({ children }) => {
  const dispatch = useDispatch();
  const fileSelect = useRef();

  const paymentMethod = useSelector(
    (state) => state.auth.user.user?.paymentMethod
  );
  const paymentStatus = useSelector(
    (state) => state.auth.user.user?.paymentStatus
  );
  const studentType = useSelector((state) => state.auth.user.user?.studentType);
  const studentId = useSelector((state) => state.auth.user.user?.studentId);

  const [showModal, setShowModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    dispatch(StudentAction.getStudentDetails(studentId));
  }, []);

  const handleNotify = () => {
    const payload = {
      studentId,
      alert: true,
    };
    dispatch(
      IndividualStudentAction.alertIndividualStudent(payload, () => {
        message.success("Sent Alert Successfully");
        dispatch(AuthAction.Logout());
      })
    );
  };
  const handleUpload = () => {
    if (!selectedFile) return alert("Please select a file");
    let data = new FormData();
    data.append("studentId", studentId);
    data.append("file", selectedFile);
    dispatch(
      IndividualStudentAction.uploadProof(data, (res) => {
        if (res.status === 200) {
          setShowModal(true);
        } else {
          message.error("Something went wrong");
        }
      })
    );
  };

  if (paymentMethod === "CashOnDelivery" && paymentStatus === "unpaid") {
    return (
      <div style={styles.messageContainer}>
        <div style={styles.messagecontainer2}>
          <Lottie
            loop
            animationData={Exclamation}
            play
            style={{ width: "100px", height: "100px" }}
          ></Lottie>
          <div style={styles.successText}>Awaiting Payment Confirmation</div>
          <div style={styles.modalDetails}>
            Has anyone from the UniVision team gotten in touch with you? If not
          </div>
          <Button.Primary
            style={styles.modalButton}
            onClick={() => handleNotify()}
          >
            SEND ALERT
          </Button.Primary>
          <div style={styles.messagetext}>
            OR <br></br>Have you made the paymment already? <br></br> If yes
          </div>
          <div style={styles.uploadText}>upload payment receipt</div>
          <div style={styles.fileInputContainer}>
            <div
              style={styles.meetingFileInput}
              onClick={() => {
                document.getElementById("getFile").click();
              }}
            >
              <input
                style={{ display: "none" }}
                type="file"
                name="csv"
                id="getFile"
                ref={fileSelect}
                onChange={(event) => {
                  setSelectedFile(event.target.files[0]);
                }}
                accept=".pdf, .png, .jpg, .jpeg"
              />
              <div>
                {selectedFile
                  ? selectedFile.name.slice(0, 30) + "..."
                  : "Upload pdf/image"}
              </div>
              <div>
                <CgAttachment color={Colors.Primary} />
              </div>
            </div>
            <Button.Primary style={styles.modalButton} onClick={handleUpload}>
              UPLOAD
            </Button.Primary>
          </div>
        </div>

        {showModal && (
          <Modal.Basic
            isModalVisible={showModal}
            centered={true}
            handleCancel={() => setShowModal(false)}
            closable={false}
          >
            <div style={styles.flexColumnCenter}>
              <Lottie
                loop
                animationData={Done}
                play
                style={{ width: "68px", height: "68px" }}
              ></Lottie>

              <div style={styles.successText}>Successful!</div>
              <div
                style={{ ...styles.modalDetails, textTransform: "capitalize" }}
              >
                You will get an Email within 24hrs to indicate whether your
                payment has been accepted or not
              </div>
              <Button.Primary
                style={styles.modalButton}
                onClick={() => {
                  setShowModal(false);
                  dispatch(AuthAction.Logout());
                }}
              >
                Ok
              </Button.Primary>
            </div>
          </Modal.Basic>
        )}
      </div>
    );
  } else {
    return (
      <Layout className="dashboard-layout">
        <Sider trigger={null} className="dashboard-sidebar" width={265}>
          <StudentSidebar className="siderbar" />
        </Sider>
        <Layout className="dashboard-main">
          <Header className="dashboard-header">
            <StudentNavHeader />
          </Header>
          <div className="dashboard-main-content">
            <Outlet />
            {children}
          </div>
          <div style={styles.bottomTextContainer}>
            {studentType !== "individualStudent" && <ChatbotButton />}
            <div>
              *All facts and figures are based on market research from the most
              updated data sources available
            </div>
          </div>
        </Layout>
      </Layout>
    );
  }
};
export default StudentDashboardLayout;
