import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Layout } from "../../components";
import { Images } from "../../config";

import styled from "styled-components";

export default function Welcome() {
  const loginUser = useSelector((state) => state.profile.user);

  const [userName, setUserName] = useState("");

  useEffect(() => {
    setUserName(loginUser.fullName);
  }, []);

  return (
    <Container>
      <img src={Images.Chatbot} alt="" />
      <Layout.Spacer height={20} />
      <h1 style={{ color: "black" }}>
        Welcome,{" "}
        <span style={{ textTransform: "capitalize" }}>{userName}!</span>
      </h1>
      <h3 style={{ color: "black" }}>
        Please select a chat to start messaging.
      </h3>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;
  img {
    height: 100px;
  }
  span {
    color: #387f75;
  }
`;
