import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";

import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  TypingIndicator,
} from "@chatscope/chat-ui-kit-react";

import { Layout, Text } from "../../components";
import { Colors } from "../../config";

import premiumContent from "../../assets/images/premiunContent.svg";

import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import "./styles.css";

const API_KEY = process.env.REACT_APP_CHATGPT_API_KEY;
const systemMessage = {
  role: "system",
  content:
    "Explain things like you're talking to a school student of grade 11.",
};

function Essay() {
  const navigate = useNavigate();

  const validUntil = useSelector(
    (state) =>
      state?.student?.student?.studentDetails?.individualStudentId?.validUntil
  );
  const subscriptionPlan = useSelector(
    (state) =>
      state?.student?.student?.studentDetails?.individualStudentId
        ?.subscriptionPlan
  );
  const studentType = useSelector((state) => state.auth.user.user.studentType);

  const [open, setOpen] = React.useState(false);
  const [typing, setIsTyping] = useState(false);
  const [messages, setMessages] = useState([
    {
      message: "Please enter your essay.",
      sender: "ChatGPT",
      direction: "incoming",
    },
  ]);

  console.log(messages);

  useEffect(() => {
    if (
      studentType === "individualStudent" &&
      subscriptionPlan === "Free" &&
      new Date(validUntil) < new Date()
    ) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, []);

  const handleSend = async (message) => {
    const newMessage = {
      message: message.replace(/<[^>]+>/g, ""),
      direction: "outgoing",
      sender: "user",
    };

    const newMessages = [...messages, newMessage];

    setMessages(newMessages);

    setIsTyping(true);

    await processMessageToChatGPT(newMessages);
  };

  const processMessageToChatGPT = async (chatMessages) => {
    let apiMessages = chatMessages.map((messageObject) => {
      let role = "";
      if (messageObject.sender === "ChatGPT") {
        role = "assistant";
      } else {
        role = "user";
      }
      return {
        role: role,
        content: `Provide contructive feedback and ways to improve my essay "${messageObject.message}"`,
      };
    });

    const apiRequestBody = {
      model: "gpt-3.5-turbo",
      messages: [systemMessage, ...apiMessages],
    };

    await fetch("https://api.openai.com/v1/chat/completions", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + API_KEY,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(apiRequestBody),
    })
      .then((data) => {
        return data.json();
      })
      .then((data) => {
        setMessages([
          ...chatMessages,
          {
            message: data.error
              ? data.error.message
              : data.choices[0].message.content,
            sender: "ChatGPT",
            direction: "incoming",
          },
        ]);
        setIsTyping(false);
      });
  };

  return (
    <div>
      <div
        style={
          studentType === "individualStudent" &&
          subscriptionPlan === "Free" &&
          new Date(validUntil) < new Date()
            ? { filter: "blur(4px) grayscale(100%)", pointerEvents: "none" }
            : {}
        }
      >
        <div>
          <Text.Heading
            fontSize={"18px"}
            fontWeight={"600"}
            color={Colors.Primary}
            text="Automated Essay Grading"
          />
        </div>
        <Layout.Spacer height={10} />
        <div
          style={{
            height: "75vh",
            width: "100%",
          }}
        >
          <Layout.Spacer height={10} />
          <MainContainer>
            <ChatContainer>
              <MessageList
                typingIndicator={
                  typing ? (
                    <TypingIndicator content="Grading your essay" />
                  ) : null
                }
              >
                {messages.map((message, i) => {
                  return <Message key={i} model={message} />;
                })}
              </MessageList>

              <MessageInput
                placeholder="Enter your essay here"
                onSend={handleSend}
              />
            </ChatContainer>
          </MainContainer>
        </div>
      </div>
      {open && (
        <div
          style={{
            backgroundColor: "white",
            color: "black",
            position: "absolute",
            top: "40%",
            left: "45%",
            padding: "28px 12px",
            zIndex: "8",
            display: "flex",
            flexDirection: "column",
            gap: "1.6rem",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "10px",
            width: "500px",
            boxShadow: "0px 3px 6px #00000029",
          }}
        >
          <img src={premiumContent} />
          <div>
            To view more <b>Subscribe to our Pro Package</b>
          </div>
          <div
            onClick={() => {
              navigate("/getPro");
            }}
          >
            <span
              style={{
                color: "white",
                background: "orange",
                borderRadius: "5px",
                fontSize: "12px",
                height: "25px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "10px",
                cursor: "pointer",
              }}
            >
              Get UniVision Pro
            </span>
          </div>
        </div>
      )}
    </div>
  );
}

export default Essay;
