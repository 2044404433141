import {
  GET_COUNSELORS,
  GET_COUNSELORS_SUCCESS,
  GET_COUNSELORS_FAILURE,
} from "../constants";

const initialState = {
  counselors: [],
  isLoading: false,
  error: null,
};

export default function CounselorReducer(state = initialState, action) {
  switch (action.type) {
    case GET_COUNSELORS:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;
    case GET_COUNSELORS_SUCCESS:
      state = {
        ...state,
        counselors: action?.payload,
        isLoading: false,
      };
      break;
    case GET_COUNSELORS_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      break;
    default:
      break;
  }
  return state;
}
