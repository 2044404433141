import {
  ALERT_INDIVIDUAL_STUDENT,
  ALERT_INDIVIDUAL_STUDENT_SUCCESS,
  ALERT_INDIVIDUAL_STUDENT_FAILURE,
  CANCEL_SUBSCRIPTION,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CANCEL_SUBSCRIPTION_FAILURE,
  CREATE_CHECKOUT_SESSION,
  CREATE_CHECKOUT_SESSION_SUCCESS,
  CREATE_CHECKOUT_SESSION_FAILURE,
  CREATE_CHECKOUT_SESSION_PRO,
  CREATE_CHECKOUT_SESSION_PRO_SUCCESS,
  CREATE_CHECKOUT_SESSION_PRO_FAILURE,
  CREATE_SUBSCRIPTION,
  CREATE_SUBSCRIPTION_SUCCESS,
  CREATE_SUBSCRIPTION_FAILURE,
  GET_SUBSCRIPTION,
  GET_SUBSCRIPTION_SUCCESS,
  GET_SUBSCRIPTION_FAILURE,
  SET_TRIAL,
  SET_TRIAL_SUCCESS,
  SET_TRIAL_FAILURE,
  SEND_EMAIL_ADMIN,
  SEND_EMAIL_ADMIN_SUCCESS,
  SEND_EMAIL_ADMIN_FAILURE,
  UPDATE_INDIVIDUAL_STUDENT,
  UPDATE_INDIVIDUAL_STUDENT_SUCCESS,
  UPDATE_INDIVIDUAL_STUDENT_FAILURE,
  UPLOAD_PROOF,
  UPLOAD_PROOF_SUCCESS,
  UPLOAD_PROOF_FAILURE,
} from "../constants";

const initialState = {
  isLoading: false,
  error: null,
};

export default function IndividualStudentReducer(state = initialState, action) {
  switch (action.type) {
    case ALERT_INDIVIDUAL_STUDENT:
    case CANCEL_SUBSCRIPTION:
    case CREATE_CHECKOUT_SESSION:
    case CREATE_CHECKOUT_SESSION_PRO:
    case CREATE_SUBSCRIPTION:
    case GET_SUBSCRIPTION:
    case SET_TRIAL:
    case SEND_EMAIL_ADMIN:
    case UPDATE_INDIVIDUAL_STUDENT:
    case UPLOAD_PROOF:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;

    case ALERT_INDIVIDUAL_STUDENT_SUCCESS:
    case CANCEL_SUBSCRIPTION_SUCCESS:
    case CREATE_CHECKOUT_SESSION_SUCCESS:
    case CREATE_CHECKOUT_SESSION_PRO_SUCCESS:
    case CREATE_SUBSCRIPTION_SUCCESS:
    case SET_TRIAL_SUCCESS:
    case GET_SUBSCRIPTION_SUCCESS:
    case SEND_EMAIL_ADMIN_SUCCESS:
    case UPDATE_INDIVIDUAL_STUDENT_SUCCESS:
    case UPLOAD_PROOF_SUCCESS:
      state = {
        ...state,
        isLoading: false,
      };
      break;

    case ALERT_INDIVIDUAL_STUDENT_FAILURE:
    case CANCEL_SUBSCRIPTION_FAILURE:
    case CREATE_CHECKOUT_SESSION_FAILURE:
    case CREATE_CHECKOUT_SESSION_PRO_FAILURE:
    case CREATE_SUBSCRIPTION_FAILURE:
    case GET_SUBSCRIPTION_FAILURE:
    case SET_TRIAL_FAILURE:
    case SEND_EMAIL_ADMIN_FAILURE:
    case UPDATE_INDIVIDUAL_STUDENT_FAILURE:
    case UPLOAD_PROOF_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      break;
    default:
      break;
  }
  return state;
}
