import { put } from "redux-saga/effects";

import { NotificationsAction } from "../actions";
import { ApiCaller } from "../../config";

export default class NotificationsMiddleware {
  static *addNotifications({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post(
        `/notifications/createNotifications`,
        payload
      );
      if (res.status === 201) {
        yield put(NotificationsAction.addNotificationsSuccess(res.data.data));
      } else {
        yield put(NotificationsAction.addNotificationsFailure());
      }
      if (cb) {
        cb();
      }
    } catch (err) {
      yield put(NotificationsAction.addNotificationsFailure());
    }
  }

  static *getNotifications({ payload, cb }) {
    try {
      const { limit, offset, userId } = payload;

      let res;
      if (limit || offset) {
        res = yield ApiCaller.Get(
          `/notifications/getNotifications?limit=${limit}&offset=${offset}&userId=${userId}`
        );
      } else {
        res = yield ApiCaller.Get(
          `/notifications/getNotifications?userId=${userId}`
        );
      }
      if (res.status === 200) {
        yield put(NotificationsAction.getNotificationsSuccess(res.data.data));
      } else {
        yield put(NotificationsAction.getNotificationsSuccess([]));
      }
      if (cb) {
        cb();
      }
    } catch (err) {
      yield put(NotificationsAction.getNotificationsFailure());
    }
  }

  static *changeNotificationsStatus({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post(
        `/notifications/updateNotifications`,
        payload
      );
      if (res.status === 200) {
        yield put(
          NotificationsAction.changeNotificationsStatusSuccess(res.data.data)
        );
      } else {
        yield put(NotificationsAction.changeNotificationsStatusFailure());
      }
      if (cb) {
        cb();
      }
    } catch (err) {
      yield put(NotificationsAction.changeNotificationsStatusFailure());
    }
  }
}
