import {
  GET_UNIVERSITIES,
  GET_UNIVERSITIES_FAILURE,
  GET_UNIVERSITIES_SUCCESS,
  GET_ALL_UNIVERSITIES,
  GET_ALL_UNIVERSITIES_FAILURE,
  GET_ALL_UNIVERSITIES_SUCCESS,
  GET_UNIVERSITY_DETAILS,
  GET_UNIVERSITY_DETAILS_FAILURE,
  GET_UNIVERSITY_DETAILS_SUCCESS,
  COMPARE_UNIVERSITIES,
  COMPARE_UNIVERISTIES_SUCCESS,
  COMPARE_UNIVERISTIES_FAILURE,
} from "../constants";

const initialState = {
  count: 0,
  universities: [],
  allUniversities: [],
  university: {},
  compareUniversity: [],
  isLoading: true,
  error: null,
};

export default function UniversityReducer(state = initialState, action) {
  switch (action.type) {
    case GET_UNIVERSITIES:
    case GET_ALL_UNIVERSITIES:
    case GET_UNIVERSITY_DETAILS:
    case COMPARE_UNIVERSITIES:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;
    case GET_UNIVERSITIES_SUCCESS:
      state = {
        ...state,
        count: action?.payload?.count,
        universities: action?.payload?.data,
        isLoading: false,
      };
      break;
    case GET_ALL_UNIVERSITIES_SUCCESS:
      state = {
        ...state,
        allUniversities: action?.payload,
        isLoading: false,
      };
      break;
    case GET_UNIVERSITY_DETAILS_SUCCESS:
      state = {
        ...state,
        university: action.payload,
        isLoading: false,
      };
      break;
    case COMPARE_UNIVERISTIES_SUCCESS:
      state = {
        ...state,
        compareUniversity: action.payload,
        isLoading: false,
      };
      break;
    case GET_UNIVERSITIES_FAILURE:
    case GET_ALL_UNIVERSITIES_FAILURE:
    case GET_UNIVERSITY_DETAILS_FAILURE:
    case COMPARE_UNIVERISTIES_FAILURE:
      state = {
        ...state,
        isLoading: false,
      };
      break;
    default:
      break;
  }
  return state;
}
