import { combineReducers } from "redux";

import AnnouncementReducer from "./AnnouncementReducer";
import ApplicationReducer from "./ApplicationReducer";
import AuthReducer from "./AuthReducer";
import ChatbotReducer from "./ChatbotReducer";
import ContactReducer from "./ContactReducer";
import CounselorReducer from "./CounselorReducer";
import ConverterReducer from "./ConverterReducer";
import DeadlineReducer from "./DeadlineReducer";
import ETextbookReducer from "./ETextbookReducer";
import InteractiveLearningReducer from "./InteractiveLearningReducer";
import IndividualStudentReducer from "./IndividualStudentReducer";
import MarketplaceReducer from "./MarketplaceReducer";
import MeetingReducer from "./MeetingReducer";
import NotificationsReducer from "./NotificationsReducer";
import ProfileReducer from "./ProfileReducer";
import RecommenderReducer from "./RecommenderReducer";
import StudentReducer from "./StudentReducer";
import TrackerReducer from "./TrackerReducer";
import UniversityReducer from "./UniversityReducer";
import WatchlistReducer from "./WatchlistReducer";

const RootReducer = combineReducers({
  announcement: AnnouncementReducer,
  application: ApplicationReducer,
  auth: AuthReducer,
  chatbot: ChatbotReducer,
  contact: ContactReducer,
  counselors: CounselorReducer,
  converter: ConverterReducer,
  deadlines: DeadlineReducer,
  eTextbook: ETextbookReducer,
  interactiveLearning: InteractiveLearningReducer,
  individualStudent: IndividualStudentReducer,
  marketplace: MarketplaceReducer,
  meeting: MeetingReducer,
  notifications: NotificationsReducer,
  profile: ProfileReducer,
  recommender: RecommenderReducer,
  student: StudentReducer,
  tracker: TrackerReducer,
  university: UniversityReducer,
  watchlist: WatchlistReducer,
});

export default RootReducer;
