import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import AppRoutes from "./AppRoutes";
import AuthRoutes from "./AuthRoutes";
import { routes } from "./siteMap";

const AllRoutes = () => {
  const isLoggedIn = useSelector((state) => state?.auth?.isLoggedIn || false);
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (isLoggedIn) {
  //     navigate(routes.protected.home);
  //   }
  // }, [isLoggedIn]);

  if (isLoggedIn) {
    return <AppRoutes />;
  } else {
    return <AuthRoutes />;
  }
};

export default AllRoutes;
