import { put } from "redux-saga/effects";

import { AnnouncementAction } from "../actions";
import { ApiCaller } from "../../config";

export default class AnnouncementMiddleware {
  static *getAnnouncements({ payload, cb }) {
    try {
      const { limit, offset, schoolId, studentId } = payload;
      let res;
      if (limit || offset) {
        res = yield ApiCaller.Get(
          `/school/fetch-announcements?limit=${limit}&offset=${offset}&schoolId=${schoolId}&studentId=${studentId}&requestedBy=Student`
        );
      } else {
        res = yield ApiCaller.Get(
          `/school/fetch-announcements?schoolId=${schoolId}&studentId=${studentId}&requestedBy=Student`
        );
      }
      if (res.status === 200) {
        yield put(AnnouncementAction.getAnnouncementsSuccess(res.data.data));
      } else {
        yield put(AnnouncementAction.getAnnouncementsSuccess([]));
      }
      if (cb) {
        cb(res);
      }
    } catch (err) {
      yield put(AnnouncementAction.getAnnouncementsFailure());
    }
  }

  static *changeAnnouncementStatus({ id, status, cb }) {
    try {
      const res = yield ApiCaller.Get(
        `/school/change-announcement-status/${id}?status=${status}`
      );
      if (res.status === 200) {
        yield put(
          AnnouncementAction.changeAnnouncementStatusSuccess(res.data.data)
        );
      } else {
        yield put(AnnouncementAction.changeAnnouncementStatusFailure());
      }
      if (cb) {
        cb(res);
      }
    } catch (err) {
      yield put(AnnouncementAction.changeAnnouncementStatusFailure());
    }
  }
  static *getIndividualAnnouncements({ payload, cb }) {
    try {
      const { limit, offset, studentId } = payload;
      let res;
      if (limit || offset) {
        res = yield ApiCaller.Get(
          `/school/fetchIndividualAnnouncements?limit=${limit}&offset=${offset}&studentId=${studentId}&requestedBy=Student`
        );
      } else {
        res = yield ApiCaller.Get(
          `/school/fetchIndividualAnnouncements?studentId=${studentId}&requestedBy=Student`
        );
      }
      if (res.status === 200) {
        yield put(
          AnnouncementAction.getIndividualAnnouncementsSuccess(res.data.data)
        );
      } else {
        yield put(AnnouncementAction.getIndividualAnnouncementsSuccess([]));
      }
      if (cb) {
        cb(res);
      }
    } catch (err) {
      yield put(AnnouncementAction.getIndividualAnnouncementsFailure());
    }
  }

  static *changeIndividualAnnouncementStatus({ id, status, cb }) {
    try {
      const res = yield ApiCaller.Get(
        `/school/changeIndividualAnnouncementStatus/${id}?status=${status}`
      );
      if (res.status === 200) {
        yield put(
          AnnouncementAction.changeIndividualAnnouncementStatusSuccess(
            res.data.data
          )
        );
      } else {
        yield put(
          AnnouncementAction.changeIndividualAnnouncementStatusFailure()
        );
      }
      if (cb) {
        cb(res);
      }
    } catch (err) {
      yield put(AnnouncementAction.changeIndividualAnnouncementStatusFailure());
    }
  }
}
