import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { message } from "antd";

import { FaLink, FaRegCopy, FaCopy, FaAward } from "react-icons/fa";
import { BsFillPersonCheckFill } from "react-icons/bs";

import { Colors, Images } from "../../config";
import { Text, Layout } from "../../components";

import copy from "copy-to-clipboard";

import styles from "./styles";

const Refer = () => {
  const referralCode = useSelector(
    (state) =>
      state.student?.student?.studentDetails?.individualStudentId?.referralCode
  );

  const codeToCopy =
    process.env.REACT_APP_ENV === "production"
      ? `https://student.univision.hk/signup/personalInfo?type=individual&referral=${referralCode}`
      : `https://univision-student-staging.netlify.app/signup/personalInfo?type=individual&referral=${referralCode}`;

  const [iscopied, setIscopied] = useState(false);

  useEffect(() => {
    let timeout;
    if (iscopied) {
      timeout = setTimeout(() => setIscopied(false), 10000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [iscopied]);

  const copyToClipboard = (key) => {
    copy(key);
    setIscopied(true);
    message.success("Referral link copied to clipboard");
  };

  const encodedMessage = encodeURIComponent(
    `Join UniVision using my referral link: ${codeToCopy}`
  );

  return (
    <div style={styles.referfriend}>
      <div>
        <Text.Heading
          fontSize={"18px"}
          fontWeight={"600"}
          color={Colors.Primary}
          style={styles.mr10}
          text="Refer a Friend"
        />
      </div>
      <Layout.Spacer height={17} />

      <div style={styles.referfriendMain}>
        <div style={styles.referfriend1}>
          <div>
            <Text.Heading
              fontSize={"15px"}
              fontWeight={"600"}
              color={Colors.Primary}
              text="Spread the word and earn reward"
            />
          </div>
          <Layout.Spacer height={6} />
          <div>
            <Text.Heading
              fontSize={"14px"}
              fontWeight={"500"}
              color={"#474747"}
              text="Get amazing rewards for each person you refer to UniVision"
            />
          </div>
          <div style={{ ...styles.veticalLine, paddingRight: "43px" }}>
            <hr />
          </div>
          <Layout.Spacer height={10} />
          <div>
            <Text.Heading
              fontSize={"15px"}
              fontWeight={"600"}
              color={Colors.Primary}
              text="Share your unique referral link"
            />
          </div>
          <Layout.Spacer height={16} />

          <div style={styles.referfriend1LeftCopy}>
            <div style={styles.referfriend1Input}>{codeToCopy}</div>
            <div
              style={styles.referfriend1CopyButton}
              onClick={() => copyToClipboard(codeToCopy)}
            >
              {iscopied ? <FaCopy size={16} /> : <FaRegCopy size={16} />}
              <span style={{ fontSize: "16px" }}>
                {iscopied ? "Copied" : "Copy"}
              </span>
            </div>
          </div>

          <div style={styles.referfriend1SocialIcons}>
            <a
              style={styles.referfriend1Icons}
              href={`https://www.facebook.com/messages`}
              target="_blank"
              onClick={() => copyToClipboard(codeToCopy)}
            >
              <img src={Images.Facebook} style={styles.referfriend1IconsImg} />
            </a>
            <a
              style={styles.referfriend1Icons}
              href={`https://www.linkedin.com/messaging/`}
              target="_blank"
              onClick={() => copyToClipboard(codeToCopy)}
            >
              <img src={Images.LinkedIn} style={styles.referfriend1IconsImg} />
            </a>
            <a
              style={styles.referfriend1Icons}
              href={`https://www.instagram.com/direct/inbox/`}
              target="_blank"
              onClick={() => copyToClipboard(codeToCopy)}
            >
              <img src={Images.Instagram} style={styles.referfriend1IconsImg} />
            </a>
            <a
              style={styles.referfriend1Icons}
              href={`https://wa.me/?text=${encodedMessage}`}
              target="_blank"
              onClick={() => copyToClipboard(codeToCopy)}
            >
              <img src={Images.Whatsapp} style={styles.referfriend1IconsImg} />
            </a>
          </div>
          <Layout.Spacer height={25} />
        </div>

        <div style={styles.referfriend2}>
          <div style={styles.referfriend2main}>
            <div style={styles.referfriend2first}>
              <div style={styles.friend2reflinkIcon}>
                <FaLink
                  color={Colors.Primary}
                  style={{ width: "46px", height: "12.62px" }}
                />
              </div>
              <div style={styles.friend2reflinkContent}>
                <Text.Heading
                  fontSize={".85rem"}
                  fontWeight={"600"}
                  color={Colors.Black}
                  text="Share your referral link"
                />
                <div style={styles.reflinkContent}>
                  Invite your friends to join Univision via your unique referral
                  link
                </div>
              </div>
            </div>
          </div>
          <div style={styles.veticalLine}>
            <hr />
          </div>
          <div style={styles.referfriend2main}>
            <div style={styles.referfriend2first}>
              <div style={styles.friend2reflinkIcon}>
                <BsFillPersonCheckFill
                  color={Colors.Primary}
                  style={{ width: "46px", height: "14.62px" }}
                />
              </div>
              <div style={styles.friend2reflinkContent}>
                <Text.Heading
                  fontSize={".85rem"}
                  fontWeight={"600"}
                  color={Colors.Black}
                  text="Your friend joins UniVision"
                />
                <div style={styles.reflinkContent}>
                  When your friend joins Univision from your shared link you
                  both become a part of UniVision
                </div>
              </div>
            </div>
          </div>
          <div style={styles.veticalLine}>
            <hr />
          </div>
          <div style={styles.referfriend2main}>
            <div style={styles.referfriend2first}>
              <div style={styles.friend2reflinkIcon}>
                <FaAward
                  color={Colors.Primary}
                  style={{ width: "46px", height: "13.42px" }}
                />
              </div>
              <div style={styles.friend2reflinkContent}>
                <Text.Heading
                  fontSize={".85rem"}
                  fontWeight={"600"}
                  color={Colors.Black}
                  text="You both earn rewards"
                />
                <div style={styles.reflinkContent}>
                  You and your friend can earn amazing rewards by following our
                  referral program
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={styles.referfriendwork}>
        <div>
          <Text.Heading
            fontSize={"16px"}
            fontWeight={"600"}
            color={Colors.Primary}
            text="How it works?"
          />
        </div>
        <Layout.Spacer height={15} />
        <div style={styles.referfriendworkmain}>
          <div style={styles.referfriendworkmain1}>
            <div style={styles.referfriendworkmain1row}>
              <img src={Images.Medal} style={styles.referfriendworkmain1img} />{" "}
              <div style={styles.referworkmain1coins}>+ 300 Credits</div>
            </div>
            <Layout.Spacer height={20} />
            <Text.Heading
              fontSize={".8rem"}
              fontWeight={"600"}
              color={Colors.Primary}
              style={styles.referworkmain1heading}
              text="Referred UniVision to 2 friends"
            />
            <Layout.Spacer height={12} />
            <Text.Heading
              fontSize={"11px"}
              fontWeight={"500"}
              color={Colors.Primary}
              style={styles.referworkmain1heading}
              text="You will get free access to UniVision Pro for 7 days with many amazing features."
            />
          </div>
          <div style={styles.referfriendworkmain1}>
            <div style={styles.referfriendworkmain1row}>
              <img src={Images.Medal} style={styles.referfriendworkmain1img} />{" "}
              <div style={styles.referworkmain1coins}>+ 700 Credits</div>
            </div>
            <Layout.Spacer height={20} />
            <Text.Heading
              fontSize={".8rem"}
              fontWeight={"600"}
              color={Colors.Primary}
              style={styles.referworkmain1heading}
              text="Referred UniVision to 5 friends"
            />
            <Layout.Spacer height={12} />
            <Text.Heading
              fontSize={"11px"}
              fontWeight={"500"}
              color={Colors.Primary}
              style={styles.referworkmain1heading}
              text="You will get free Apple Watch"
            />
          </div>
          <div style={styles.referfriendworkmain1}>
            <div style={styles.referfriendworkmain1row}>
              <img src={Images.Medal} style={styles.referfriendworkmain1img} />
              <div style={styles.referworkmain1coins}>+ 1400 Credits</div>
            </div>
            <Layout.Spacer height={20} />
            <Text.Heading
              fontSize={".8rem"}
              fontWeight={"600"}
              color={Colors.Primary}
              style={styles.referworkmain1heading}
              text="Referred UniVision to 10 friends"
            />
            <Layout.Spacer height={12} />
            <Text.Heading
              fontSize={"11px"}
              fontWeight={"500"}
              color={Colors.Primary}
              style={styles.referworkmain1heading}
              text="You will get free iPhone 15 pro max"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Refer;
