import { useEffect, useState } from "react";
import { FaRankingStar } from "react-icons/fa6";

import { useSelector } from "react-redux";

import { Charts, Layout } from "../../components";
import { Images, Colors } from "../../config";

import premiumContent from "../../assets/images/premiunContent.svg";

import styles from "./styles";

function General({ data, secondaryQs }) {
  const [blurData, setBlurData] = useState(undefined);
  const studentDetail = useSelector((state) => state.auth.user.user);

  useEffect(() => {
    const currentDate = new Date();
    const validUntilDate = new Date(studentDetail.validUntil);

    if (
      validUntilDate < currentDate &&
      studentDetail.studentType === "individualStudent" &&
      studentDetail.subscriptionPlan === "Free" &&
      secondaryQs > 10
    ) {
      setBlurData(true);
    }
  }, []);

  const scores = [
    {
      key: "1",
      test: "SAT Evidence-based reading and writing",
      firstPercentile: data?.SATEnglish?.twentyFivePercentile
        ? data?.SATEnglish?.twentyFivePercentile
        : "NA",
      secondPercentile: data?.SATEnglish?.seventyFivePercentile
        ? data?.SATEnglish?.seventyFivePercentile
        : "NA",
    },
    {
      key: "2",
      test: "SAT Math",
      firstPercentile: data?.SATMath?.twentyFivePercentile
        ? data?.SATMath?.twentyFivePercentile
        : "NA",
      secondPercentile: data?.SATMath?.seventyFivePercentile
        ? data?.SATMath?.seventyFivePercentile
        : "NA",
    },
    {
      key: "3",
      test: "ACT Composite",
      firstPercentile: data?.ACTComposite?.twentyFivePercentile
        ? data?.ACTComposite?.twentyFivePercentile
        : "NA",
      secondPercentile: data?.ACTComposite?.seventyFivePercentile
        ? data?.ACTComposite?.seventyFivePercentile
        : "NA",
    },
    {
      key: "4",
      test: "ACT Math",
      firstPercentile: data?.ACTMath?.twentyFivePercentile
        ? data?.ACTMath?.twentyFivePercentile
        : "NA",
      secondPercentile: data?.ACTMath?.seventyFivePercentile
        ? data?.ACTMath?.seventyFivePercentile
        : "NA",
    },
    {
      key: "5",
      test: "ACT English",
      firstPercentile: data?.ACTEnglish?.twentyFivePercentile
        ? data?.ACTEnglish?.twentyFivePercentile
        : "NA",
      secondPercentile: data?.ACTEnglish?.seventyFivePercentile
        ? data?.ACTEnglish?.seventyFivePercentile
        : "NA",
    },
  ];

  const studentDemographics = [
    {
      key: "1",
      title: "Total UG Students",
      icon: Images.Education,
      value: data?.totalUGStudents ? data?.totalUGStudents : "NA",
    },
    {
      key: "2",
      title: "Average Class Size",
      icon: Images.ClassSize,
      value: data?.averageClassSize ? data?.averageClassSize : "NA",
    },
    {
      key: "3",
      title: "Faculty To Student Ratio",
      icon: Images.FacultyStudentRatio,
      value: data?.facultyToStudentRatio ? data?.facultyToStudentRatio : "NA",
    },
    {
      key: "4",
      title: "Male to Female Ratio",
      icon: Images.MaleToFemaleRatio,
      value: data?.maleToFemaleRatio ? data?.maleToFemaleRatio : "NA",
    },
  ];

  const coreInfo = [
    {
      key: "1",
      title: "Acceptance Rate",
      icon: Images.Thumb,
      value: data.acceptanceRate ? `${data.acceptanceRate}%` : "NA",
    },
    {
      key: "2",
      title: "Location Type",
      icon: Images.Location,
      value: data?.locationType ? data?.locationType : "NA",
    },
    {
      key: "3",
      title: "Status",
      icon: Images.Clipboard,
      value: data?.summary?.status ? data?.summary?.status : "NA",
    },
    {
      key: "4",
      title: "Research Output",
      icon: Images.Research,
      value: data?.summary?.research_output
        ? data?.summary?.research_output
        : "NA",
    },
  ];
  const rankings = [
    {
      key: "1",
      title: "QS World",
      value: data?.summary?.qsWorldRanking
        ? data?.summary?.qsWorldRanking
        : "NA",
    },
    {
      key: "2",
      title: "CWUR",
      value: data?.CWURRanking ? data?.CWURRanking : "NA",
    },
    {
      key: "3",
      title: "Times",
      value: data?.timesRanking ? data?.timesRanking : "NA",
    },
  ];
  const chartData = [
    {
      name: "Employed or in Graduate school",
      value: Number(
        data?.employedOrInGraduateSchool === "NA"
          ? 0
          : data?.employedOrInGraduateSchool?.replace(/%/g, "")
      ),
    },
    {
      name: "Gap Year or Seeking employment",
      value: Number(
        data?.gapYear === "NA" ? 0 : data?.gapYear?.replace(/%/g, "")
      ),
    },
  ];
  const postGraduation = [
    {
      key: "1",
      title: "Employed or in Graduate School",
      value:
        data?.employedOrInGraduateSchool === "NA"
          ? "No Data"
          : `${Number(data?.employedOrInGraduateSchool?.replace(/%/g, ""))}%`,
    },
    {
      key: "2",
      title: "GAP Year or Seeking Employment",
      value:
        data?.gapYear === "NA"
          ? "No Data"
          : `${Number(data?.gapYear?.replace(/%/g, ""))}%`,
    },
  ];
  const requirements = [
    {
      key: "1",
      title: "SAT or ACT",
      value: data?.requirements?.SAT ? data?.requirements?.SAT : "NA",
    },
    {
      key: "2",
      title: "TOEFL",
      value: data?.requirements?.TOEFL ? data?.requirements?.TOEFL : "NA",
    },
    {
      key: "3",
      title: "IELTS",
      value: data?.requirements?.IELTS ? data?.requirements?.IELTS : "NA",
    },
  ];

  const testScoreTitles = [
    {
      key: "1",
      title: "Test",
      width: "50%",
    },
    {
      key: "2",
      title: "25th Percentile",
      width: "25%",
    },
    {
      key: "3",
      title: "75th Percentile",
      width: "25%",
    },
  ];

  return (
    <div style={styles.generalUni}>
      <div style={styles.flexSpaceBetween}>
        <div style={styles.demoAndCoreContainer}>
          <p style={styles.uniDetailsHeader}>student Demographics</p>
          <div style={styles.infoBox}>
            {studentDemographics?.map((item) => (
              <div style={styles.uniDetailsComp} key={item.key}>
                <div>
                  <img src={item?.icon} style={styles.uniDetailsIcon} alt="" />
                </div>
                <p style={styles.uniDetailsText}>{item?.title}</p>
                <p style={styles.uniDetailsValue}>{item?.value}</p>
              </div>
            ))}
          </div>

          <Layout.Spacer height={30} />

          <div style={blurData && styles.blurrScreen}>
            <p style={styles.uniDetailsHeader}>Core Information</p>
            <div style={styles.infoBox}>
              {coreInfo?.map((item) => (
                <div style={styles.uniDetailsComp} key={item.key}>
                  <div>
                    <img src={item.icon} style={styles.uniDetailsIcon} alt="" />
                  </div>
                  <p style={styles.uniDetailsText}>{item.title}</p>
                  <p style={styles.uniDetailsValue}>{item?.value}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div style={blurData && styles.blurrScreen}>
          <img src={Images.Promotion} style={styles.promoImage} alt="" />
        </div>
      </div>

      <div style={blurData && styles.blurrScreen}>
        <Layout.Spacer height={30} />

        <div style={styles.flexSpaceBetween}>
          <div
            style={{
              flexDirection: "column",
              width: "100%",
              marginRight: "25px",
            }}
          >
            <p style={styles.uniDetailsHeader}>Rankings</p>
            <div style={styles.infoBox}>
              {rankings?.map((item) => (
                <div style={styles.uniDetailsComp} key={item.key}>
                  <div>
                    <FaRankingStar style={styles.uniDetailsIcon} />
                  </div>
                  <p style={styles.uniDetailsText}>{item?.title}</p>
                  <p style={styles.uniDetailsValue}>#{item?.value}</p>
                </div>
              ))}
            </div>
          </div>
          <div style={{ width: "100%" }}>
            <p style={styles.uniDetailsHeader}>Post Graduation</p>
            <div style={styles.postGrduation}>
              <div style={styles.postGraduationChart}>
                <Charts.PieChart data={chartData} />
              </div>
              <div style={styles.postGraduationValuesContainer}>
                {postGraduation?.map((item) => (
                  <div key={item.key}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          ...styles.postGraduationBox,
                          background:
                            item.key === "1" ? Colors.Primary : "#214854",
                        }}
                      ></div>
                      <div style={styles.postGraduationValues}>
                        {item.value}
                      </div>
                      <div style={styles.postGraduationTitle}>{item.title}</div>
                    </div>
                    {item.key === "1" && <Layout.Spacer height={20} />}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <Layout.Spacer height={30} />

        <div style={styles.flexSpaceBetween}>
          <div
            style={{
              width: "100%",
              marginRight: "25px",
            }}
          >
            <p style={styles.uniDetailsHeader}>Test Scores</p>
            <div style={styles.requirementsContainer}>
              {requirements?.map((item) => (
                <div style={styles.uniDetailsComp} key={item.key}>
                  <div>
                    <img
                      src={Images.Exam}
                      style={styles.uniDetailsIcon}
                      alt="uniDetailsIcon"
                    />
                  </div>
                  <p style={styles.requirementsTitle}>{item?.title}</p>
                  <p
                    style={{
                      ...styles.uniDetailsText,
                      color: item?.value === "required" ? "blue" : "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {item?.value}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div style={{ width: "100%" }}>
            <p style={styles.uniDetailsHeader}>Test Scores</p>
            {data?.SATMath?.twentyFivePercentile === "NA" &&
            data?.ACTComposite?.twentyFivePercentile === "NA" &&
            data?.ACTMath?.twentyFivePercentile === "NA" ? (
              <div style={styles.noScoreData}>
                <img src={Images.NoData} height={110} width={110} alt="" />
                <div style={styles.noScoresDataText}>No Data</div>
              </div>
            ) : (
              <div
                style={{
                  paddingBottom: "10px",
                  backgroundColor: "#F5F5F5",
                  borderRadius: "10px",
                }}
              >
                <div style={styles.scoreTitleContainer}>
                  {testScoreTitles?.map((item) => (
                    <div
                      style={{
                        textAlign: "center",
                        width: item.width,
                      }}
                      key={item.key}
                    >
                      {item?.title}
                    </div>
                  ))}
                </div>

                {scores.map((data) => (
                  <div style={styles.scoresValueContainer} key={data.key}>
                    <div
                      style={{
                        paddingLeft: "10px",
                        width: "50%",
                      }}
                    >
                      {data.test}
                    </div>
                    <div
                      style={{
                        textAlign: "center",
                        width: "25%",
                      }}
                    >
                      {data.firstPercentile}
                    </div>
                    <div
                      style={{
                        textAlign: "center",
                        width: "25%",
                      }}
                    >
                      {data.secondPercentile}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>

      {blurData && (
        <div style={styles.premiumModal}>
          <img src={premiumContent} />
          <p>
            To view more <b>Subscribe to our Pro Package</b>
          </p>
        </div>
      )}
    </div>
  );
}

export default General;
