import {
  ADD_APPLICATIONS,
  ADD_APPLICATIONS_FAILURE,
  ADD_APPLICATIONS_SUCCESS,
  GET_APPLICATIONS_STUDENT,
  GET_APPLICATIONS_SUCCESS_STUDENT,
  GET_APPLICATIONS_FAILURE,
} from "../constants";

export default class ApplicationAction {
  static addApplications(payload, cb) {
    return {
      type: ADD_APPLICATIONS,
      payload,
      cb,
    };
  }
  static addApplicationsSuccess(payload) {
    return {
      type: ADD_APPLICATIONS_SUCCESS,
      payload,
    };
  }
  static addApplicationsFailure(payload) {
    return {
      type: ADD_APPLICATIONS_FAILURE,
      payload,
    };
  }

  static getApplicationsStudent(payload, cb) {
    return {
      type: GET_APPLICATIONS_STUDENT,
      payload,
      cb,
    };
  }
  static getApplicationsSuccessStudent(payload) {
    return {
      type: GET_APPLICATIONS_SUCCESS_STUDENT,
      payload,
    };
  }
  static getApplicationsFailure(payload) {
    return {
      type: GET_APPLICATIONS_FAILURE,
      payload,
    };
  }
}
