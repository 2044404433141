import { put } from "redux-saga/effects";

import { MarketplaceAction } from "../actions";
import { ApiCaller } from "../../config";

export default class MarketplaceMiddleware {
  static *addProduct({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post(`/marketplace/addProduct`, payload);

      if (res.status === 201) {
        yield put(MarketplaceAction.addProductSuccess(res.data.data));

        if (cb) {
          cb({ data: res.data.data, status: res.status });
        }
      } else {
        yield put(MarketplaceAction.addProductFailure());
        if (cb) {
          cb({ data: res.data, status: res.status });
        }
      }
    } catch (err) {
      yield put(MarketplaceAction.addProductFailure());
    }
  }

  static *getProduct({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post(
        `/marketplace/getSpecificProduct`,
        payload
      );

      if (res.status === 200) {
        yield put(MarketplaceAction.getProductSuccess(res.data.data));
      } else {
        yield put(MarketplaceAction.getProductSuccess([]));
      }
      if (cb) {
        cb();
      }
    } catch (err) {
      yield put(MarketplaceAction.getProductFailure());
    }
  }
  static *getStudentProduct({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post(
        `/marketplace/getSpecificStudentProduct`,
        payload
      );

      if (res.status === 200) {
        yield put(MarketplaceAction.getStudentProductSuccess(res.data.data));
      } else {
        yield put(MarketplaceAction.getStudentProductSuccess([]));
      }
      if (cb) {
        cb(res.data.data);
      }
    } catch (err) {
      yield put(MarketplaceAction.getStudentProductFailure());
    }
  }
  static *getAllProduct({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post(`/marketplace/getProducts`, payload);

      if (res.status === 200) {
        yield put(MarketplaceAction.getAllProductSuccess(res.data.data));
      } else {
        yield put(MarketplaceAction.getAllProductSuccess([]));
      }
      if (cb) {
        cb(res.data.data);
      }
    } catch (err) {
      yield put(MarketplaceAction.getAllProductFailure());
    }
  }

  static *updateProduct({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post(`/marketplace/updateProduct`, payload);
      if (res.status === 200) {
        yield put(MarketplaceAction.updateProductSuccess(res.data.data));
      } else {
        yield put(MarketplaceAction.updateProductFailure());
      }
      if (cb) {
        cb(res.data.data);
      }
    } catch (err) {
      yield put(MarketplaceAction.updateProductFailure());
    }
  }
  static *deleteProduct({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post(`/marketplace/deleteProduct`, payload);
      if (res.status === 200) {
        yield put(MarketplaceAction.deleteProductSuccess(res.data.data));
      } else {
        yield put(MarketplaceAction.deleteProductFailure());
      }
      if (cb) {
        cb(res.data.data);
      }
    } catch (err) {
      yield put(MarketplaceAction.deleteProductFailure());
    }
  }
}
