import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import styled from "styled-components";

import { ChatbotAction } from "../../store/actions";

import ChatInput from "./ChatInput";

export default function ChatContainer({ currentChat, socket }) {
  const dispatch = useDispatch();
  const scrollRef = useRef();

  const loginUser = useSelector((state) => state.profile.user);

  const [messages, setMessages] = useState([]);
  const [arrivalMessage, setArrivalMessage] = useState(null);

  useEffect(() => {
    const payload = {
      from: loginUser._id,
      to: currentChat.userId,
    };
    dispatch(
      ChatbotAction.getMessages(payload, (res) => {
        setMessages(res.projectedMessages);
      })
    );
  }, [currentChat]);

  useEffect(() => {
    if (socket.current) {
      socket.current.on("msg-recieve", (msg) => {
        setArrivalMessage({ fromSelf: false, message: msg });
      });
    }
  }, []);

  useEffect(() => {
    arrivalMessage && setMessages((prev) => [...prev, arrivalMessage]);
  }, [arrivalMessage]);

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const handleSendMsg = async (msg) => {
    socket.current.emit("send-msg", {
      to: currentChat.userId,
      from: loginUser._id,
      msg,
    });
    const payload = {
      from: loginUser._id,
      to: currentChat.userId,
      message: msg,
    };
    dispatch(ChatbotAction.addMessage(payload));

    const msgs = [...messages];
    msgs.push({ fromSelf: true, message: msg });
    setMessages(msgs);
  };

  return (
    <Container>
      <div className="chat-header">
        <div className="user-details">
          <div className="avatar">
            <img src={currentChat.imageUrl} alt="" />
          </div>
          <div className="username">{currentChat.name}</div>
        </div>
      </div>
      <div className="chat-messages">
        {messages.map((message, index) => {
          return (
            <div ref={scrollRef} key={index}>
              <div
                className={`message ${
                  message.fromSelf ? "sended" : "recieved"
                }`}
              >
                <div className="content ">
                  <p>{message.message}</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <ChatInput handleSendMsg={handleSendMsg} />
    </Container>
  );
}

const Container = styled.div`
  display: grid;
  grid-template-rows: 10% 80% 10%;
  gap: 0.1rem;
  overflow: hidden;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  .chat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 2rem;
    background-color: #eff2f5;
    .user-details {
      display: flex;
      align-items: center;
      gap: 1rem;
      .avatar {
        img {
          height: 40px;
          width: 40px;
          border-radius: 100%;
        }
      }
      .username {
        color: black;
        font-size: 20px;
        font-weight: 600;
        text-transform: capitalize;
      }
    }
  }
  .chat-messages {
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow: auto;
    background-color: #fafafa;
    .message {
      display: flex;
      align-items: center;
      .content {
        max-width: 40%;
        overflow-wrap: break-word;
        padding: 1rem;
        font-size: 1.1rem;
        border-radius: 1rem;
        color: #fafafa;
      }
    }
    .sended {
      justify-content: flex-end;
      .content {
        background-color: #387f75;
      }
    }
    .recieved {
      justify-content: flex-start;
      .content {
        background-color: #eaeaec;
        color: black;
      }
    }
  }
`;
