import {
  GET_COUNSELORS,
  GET_COUNSELORS_SUCCESS,
  GET_COUNSELORS_FAILURE,
} from "../constants";

export default class CounselorAction {
  static getCounselors(payload, cb) {
    return {
      type: GET_COUNSELORS,
      payload,
      cb,
    };
  }
  static getCounselorsSuccess(payload) {
    return {
      type: GET_COUNSELORS_SUCCESS,
      payload,
    };
  }
  static getCounselorsFailure(payload) {
    return {
      type: GET_COUNSELORS_FAILURE,
      payload,
    };
  }
}
