import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

const SignupRedirect = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  const [searchParams, setSearchParams] = useSearchParams();

  const [referral, setReferral] = useState(searchParams.get("referral"));

  const [isMobile, setIsMobile] = useState(
    /android/i.test(userAgent) ||
      (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream)
  );

  useEffect(() => {
    if (isMobile && searchParams.get("referral")) {
      const appLink = `https://student.univision.hk/signup/personalInfo?type=individual&referral=${referral}`;
      const fallbackLink = `https://student.univision.hk/signup/personalInfo?type=individual&referral=${referral}`;

      const timeout = setTimeout(() => {
        window.location.replace(fallbackLink);
      }, 1000);

      window.location.replace(appLink);

      window.addEventListener("blur", () => clearTimeout(timeout));
    }
  }, [isMobile]);
  return <div>Signup Redirect</div>;
};

export default SignupRedirect;
