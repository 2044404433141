import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Pagination } from "antd";
import Lottie from "react-lottie-player";

import { AnnouncementAction } from "../../store/actions";

import { Button, Layout, Modal, Text, CardRow } from "../../components";
import { Colors } from "../../config";

import styles from "./styles";

import Done from "../../assets/images/announcement.json";

function Announcements() {
  const dispatch = useDispatch();

  const announcements = useSelector((state) => state.announcement);
  const schoolIdState = useSelector((state) => state.auth.user.user.schoolId);
  const studentIdState = useSelector((state) => state.auth.user.user.studentId);
  const studentType = useSelector((state) => state.auth.user.user.studentType);

  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [showModal, setShowModal] = useState(false);
  const [announcement, setAnnouncement] = useState(null);

  useEffect(() => {
    if (studentType !== "individualStudent") {
      const payload = {
        limit,
        offset,
        schoolId: schoolIdState,
        studentId: studentIdState,
      };
      dispatch(AnnouncementAction.getAnnouncements(payload));
    } else {
      const payload = {
        limit,
        offset,
        studentId: studentIdState,
      };
      dispatch(AnnouncementAction.getIndividualAnnouncements(payload));
    }
  }, []);

  const handleReadAnnouncement = (state) => {
    setAnnouncement(state);
    setShowModal(true);
    handleChangeAnnouncementStatus(state?._id, true);
  };

  const handlePaginationChange = (pageNumber) => {
    let offset = (pageNumber - 1) * limit;
    setOffset(offset);
    setPage(pageNumber);

    if (studentType !== "individualStudent") {
      const payload = {
        limit,
        offset,
        schoolId: schoolIdState,
        studentId: studentIdState,
      };
      dispatch(AnnouncementAction.getAnnouncements(payload));
    } else {
      const payload = {
        limit,
        offset,
        studentId: studentIdState,
      };
      dispatch(AnnouncementAction.getIndividualAnnouncements(payload));
    }
  };

  const handleChangeAnnouncementStatus = (id, status) => {
    if (studentType !== "individualStudent") {
      dispatch(
        AnnouncementAction.changeAnnouncementStatus(id, status, () => {
          const payload = {
            limit,
            offset: 0,
            schoolId: schoolIdState,
            studentId: studentIdState,
          };
          dispatch(AnnouncementAction.getAnnouncements(payload));
          setPage(1);
        })
      );
    } else {
      dispatch(
        AnnouncementAction.changeIndividualAnnouncementStatus(
          id,
          status,
          () => {
            const payload = {
              limit,
              offset: 0,
              studentId: studentIdState,
            };
            dispatch(AnnouncementAction.getIndividualAnnouncements(payload));
            setPage(1);
          }
        )
      );
    }
  };

  return (
    <>
      <div>
        <Text.Heading
          fontSize={"18px"}
          fontWeight={"600"}
          color={Colors.Primary}
          text="Announcements"
        />
      </div>
      <Layout.Spacer height={20} />
      {announcements?.announcements?.map((announcement, index) => (
        <CardRow
          data={announcement}
          announcement={true}
          handleReadAnnouncement={handleReadAnnouncement}
          key={index}
        />
      ))}
      <Layout.Spacer height={10} />
      <div style={styles.justifyCenter}>
        <Pagination
          responsive="true"
          showSizeChanger={false}
          hideOnSinglePage={true}
          current={page}
          pageSize={10}
          total={announcements.count}
          onChange={handlePaginationChange}
        />
      </div>
      {showModal && (
        <Modal.Basic
          isModalVisible={showModal}
          centered={true}
          handleCancel={() => setShowModal(false)}
          closable={false}
        >
          <div style={styles.justifyCenter}>
            <Lottie
              loop
              animationData={Done}
              play
              style={{ width: "120px", height: "120px" }}
            ></Lottie>
          </div>
          <Layout.Spacer height={20} />
          <div style={styles.justifyCenter}>
            <Text.Heading
              fontSize={"16px"}
              fontWeight={"500"}
              color={Colors.Primary}
              text={announcement?.subject}
            />
          </div>
          <Layout.Spacer height={10} />
          <div style={styles.justifyCenter}>
            <Text.Heading
              fontSize={"14px"}
              fontWeight={"400"}
              color={Colors.GrayText}
              text={announcement?.message}
            />
          </div>
          <Layout.Spacer height={30} />
          <div style={styles.justifyCenter}>
            <Button.Primary
              style={styles.button}
              onClick={() => {
                setShowModal(false);
                setAnnouncement(null);
              }}
            >
              Continue
            </Button.Primary>
          </div>
          <Layout.Spacer height={10} />
        </Modal.Basic>
      )}
    </>
  );
}

export default Announcements;
